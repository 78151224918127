import { Cached } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import I18n from "components/materials/I18n";
import useViewSwitcher from "hooks/useSwitchView";
import { memo } from "react";

type Props = {
  variant: "text" | "icon";
};

function SwitchViewButton({ variant }: Props) {
  const { switchView, isNextLocationExisting } = useViewSwitcher();

  if (!isNextLocationExisting) return null;

  if (variant === "text") {
    return (
      <Button
        variant="text"
        color="info"
        startIcon={<Cached />}
        onClick={switchView}
        disabled={!isNextLocationExisting}
      >
        <I18n map={"components.main_top_bar.switch_view"} />
      </Button>
    );
  }

  return (
    <IconButton onClick={switchView} disabled={!isNextLocationExisting}>
      <Cached />
    </IconButton>
  );
}

export default memo(SwitchViewButton);
