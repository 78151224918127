import { Alert, Snackbar, SnackbarOrigin } from "@mui/material";
import { ReactNode, memo } from "react";
import * as styles from "./styles";
import { Cancel, CheckCircle, Info, Warning } from "@mui/icons-material";
import useRootPortal from "hooks/useRootPortal";

type Severity = "success" | "error" | "warning" | "info";

type Props = {
  message: string;
  severity: Severity;
  isOpen: boolean;
  close: () => void;
};

const iconObjectMapping: Record<Severity, ReactNode> = {
  success: <CheckCircle />,
  error: <Cancel />,
  warning: <Warning />,
  info: <Info />,
};

const anchorOrigin: SnackbarOrigin = { vertical: "top", horizontal: "right" };

function CustomSnackBar(props: Props) {
  const { RootPortal } = useRootPortal();

  return (
    <RootPortal>
      <Snackbar
        open={props.isOpen}
        autoHideDuration={3000}
        onClose={props.close}
        anchorOrigin={anchorOrigin}
      >
        <Alert
          onClose={props.close}
          severity={props.severity}
          variant="outlined"
          sx={styles.alert(props.severity)}
          icon={iconObjectMapping[props.severity]}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </RootPortal>
  );
}

export default memo(CustomSnackBar);
