import { WidgetType } from "models/widgets/WidgetType";
import { DtoDate, DtoId } from "../common/Dto";
import { SensorDto } from "../sensor/SensorDto";
import { GridLayoutSize } from "models/common/GridLayoutSize";

type FixDateBoundaryDto = {
  fix: DtoDate;
};

type RelativeDateBoundaryDto = {
  relative: number;
};

export type DateBoundaryDto = FixDateBoundaryDto | RelativeDateBoundaryDto;

export function isFixDateBoundaryDto(
  dateBoundary: DateBoundaryDto
): dateBoundary is FixDateBoundaryDto {
  return "fix" in dateBoundary;
}

export function isRelativeDateBoundaryDto(
  dateBoundary: DateBoundaryDto
): dateBoundary is RelativeDateBoundaryDto {
  return "relative" in dateBoundary;
}

export type Co2AverageWidgetPropertiesDto = {
  sensorId: DtoId;
  fromDate: DateBoundaryDto;
  toDate: DateBoundaryDto;
};

export type BlockchainEnergyCostWidgetPropertiesDto = {
  sensorId: DtoId;
  fromDate: DateBoundaryDto;
  toDate: DateBoundaryDto;
};

export type SensorMapWidgetPropertiesDto = {
  targetSensorId: 1;
  sensorIds: DtoId[];
  isActiveAtDate: DateBoundaryDto;
};

export type Co2EvolutionWidgetPropertiesDto = {
  sensorIds: DtoId[];
  fromDate: DateBoundaryDto;
  toDate: DateBoundaryDto;
};

export type Co2RelativeAverageWidgetPropertiesDto = {
  targetSensorId: DtoId;
  sensorIds: DtoId[];
  fromDate: DateBoundaryDto;
  toDate: DateBoundaryDto;
};

type WidgetLayoutDto = {
  x: number;
  y: number;
  w: number;
  h: number;
};

type WidgetLayouts = Record<GridLayoutSize, WidgetLayoutDto>;

export type WidgetDto = {
  id: DtoId;
  type: WidgetType;
  layouts: WidgetLayouts;
  properties:
    | Co2AverageWidgetPropertiesDto
    | BlockchainEnergyCostWidgetPropertiesDto
    | SensorMapWidgetPropertiesDto
    | Co2EvolutionWidgetPropertiesDto
    | Co2RelativeAverageWidgetPropertiesDto;
  sensor?: SensorDto | null;
};
