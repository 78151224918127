import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { footerHeight } from "themes/constants";

export const footer: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => {
    return {
      ...{
        height: footerHeight,
        backgroundColor: theme.palette.background.default,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        color: "rgba(0, 0, 0, 0.38)",
        flexDirection: "column",
        gap: theme.spacing(2),
        paddingX: theme.spacing(2),
      },
      ...(mediaQueries.isSm && {
        flexDirection: "row",
      }),
    };
  };

export const leftSide: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 0,
    gap: theme.spacing(5),
  };
};

export const logo: SxProps<Theme> = () => {
  return {
    display: "flex",
    flexDirection: "row",
  };
};

export const list: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(4),
  };
};

export const copyright: SxProps<Theme> = () => {
  return {
    width: "211px",
    height: "23px",
    fontWeight: 300,
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.38)",
  };
};

export const listItem: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: "14px",
  color: "rgba(0, 0, 0, 0.6)",
};
