import { Download } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import assert from "assert";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import GlobalAuditionStatusChip from "components/element/chips/GlobalAuditionStatusChip";
import { IOption } from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import useIdValidation from "hooks/useIdValidation";
import useSensorAudit from "hooks/queries/useSensorAudit";
import AuditFileReport from "models/auditReport/AuditFileReport";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";
import DateUtils, { DateFormat } from "utils/DateUtils";
import DownloadUtils from "utils/DownloadUtils";
import AuditReportTable from "./AuditReportTable";
import FileSearchBar from "./FileSearchBar";
import * as styles from "./styles";
import { ExecutionStatus } from "models/sensor/ExecutionStatus";

function SensorAuditSection() {
  const params = useParams();
  const auditIdValidation = useIdValidation(params["auditId"]);
  const auditId = auditIdValidation.success ? auditIdValidation.data : null;
  const { audit } = useSensorAudit(auditId);

  const [files, setFiles] = useState<AuditFileReport[]>(
    audit?.report?.files ?? []
  );

  const [selectedFileOption, setSelectedFileOption] = useState<IOption | null>(
    null
  );

  useEffect(() => {
    setFiles(audit?.report?.files ?? []);
  }, [audit?.report?.files]);

  const fileSearchBarOptions = useMemo(() => {
    return (
      audit?.report?.files.map((file, index) => ({
        id: index,
        label: file.filepath,
      })) || []
    );
  }, [audit?.report?.files]);

  const handleFileSearchBarChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, option: IOption | null) => {
      if (option === null) {
        setFiles(audit?.report?.files ?? []);
        return;
      }
      const file = audit?.report?.files.find(
        (file) => file.filepath === option.label
      );

      setSelectedFileOption(option);

      setFiles(file ? [file] : []);
    },
    [audit?.report?.files]
  );

  const downloadReport = useCallback(() => {
    if (!audit?.report) {
      return;
    }

    const { global, ...restFields } = audit.report;
    const blob = new Blob(
      // We stringify the object to have a pretty JSON with indentation and fields order,
      // `global` field should be first
      [JSON.stringify({ global, ...restFields }, undefined, 2)],
      {
        type: "application/json",
      }
    );
    const targetDateOfAudit = audit.sensorEpoch?.date;
    assert(targetDateOfAudit, "sensorEpoch of audit is null or undefined");

    DownloadUtils.downloadBlob({
      blob,
      filename: `report_${audit.id}_${DateUtils.formatUTCDate(
        targetDateOfAudit,
        DateFormat.IsoDate
      )}.json`,
    });
  }, [audit]);

  const globalStatus = useMemo(() => {
    if (audit?.report?.global.status) {
      return audit.report.global.status;
    }

    if (audit?.executionStatus === ExecutionStatus.Error) {
      return GlobalAuditionStatus.FatalError;
    }

    return GlobalAuditionStatus.Unknown;
  }, [audit?.report?.global.status, audit?.executionStatus]);

  return (
    <Box sx={styles.root}>
      <Typography variant="h4">
        {I18n.translate("pages.audit.title")}
      </Typography>

      <Box sx={styles.row}>
        <FileSearchBar
          options={fileSearchBarOptions}
          onChange={handleFileSearchBarChange}
          value={selectedFileOption}
        />

        <Box sx={styles.container}>
          <GlobalAuditionStatusChip status={globalStatus} />

          <Button
            disabled={!audit?.report}
            variant="outlined"
            color="info"
            startIcon={<Download />}
            onClick={downloadReport}
          >
            {I18n.translate("pages.audit.download_report")}
          </Button>
        </Box>
      </Box>
      <AuditReportTable files={files} />
    </Box>
  );
}

export default memo(SensorAuditSection);
