import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CustomAutocompleteSingle, {
  IOption,
} from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import { Box } from "@mui/material";
import * as styles from "./styles";
import User from "models/user/User";

type Props = {
  users: User[];
  onChange?: (userId: ModelId | null) => unknown;
  value?: ModelId | null;
  errors?: string[];
  label?: ReactNode;
  required?: boolean;
};

function UsersInputSingle({
  label,
  required,
  users,
  onChange,
  value,
  errors,
}: Props) {
  const [userId, setUserId] = useState<ModelId | null>(value ?? null);

  const options: IOption[] = useMemo(
    () =>
      users
        .map((user) => {
          return {
            id: user.id,
            label: user.email,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [users]
  );

  const handleUserSelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: IOption | null) => {
      const userId = value?.id ?? null;
      setUserId(userId);
      onChange?.(userId);
    },
    [onChange, setUserId]
  );

  useEffect(() => {
    setUserId(value ?? null);
  }, [value]);

  return (
    <Box sx={styles.root}>
      {!!label && label}

      <CustomAutocompleteSingle
        required={required}
        options={options}
        errors={errors}
        onChange={handleUserSelection}
        placeholder={I18n.translate("general_text.search_user")}
        value={options.find((option) => option.id === userId)}
      />
    </Box>
  );
}

export default memo(UsersInputSingle);
