import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
});

export const doubleFieldContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
});
