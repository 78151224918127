export const noticeMarkdown = `
# Local Notice

## Prerequisites

- **Docker installed and running** Docker is a platform that uses OS-level virtualization to deliver software in packages called containers. Containers are isolated from each other and bundle their own software, libraries, and configuration files.

  - Docker v24.0.6 or higher
  - Docker Compose v2.22.0 or higher

> **To install docker** visit [Docker Hub](https://hub.docker.com/editions/community/docker-ce-desktop-windows) and download Docker Desktop. Follow the installation instructions.

## 1. Download the audit

The download will be in the archive file format \`.zip\`.

## 2. Unzip the archive file

Extract the contents of the \`.zip\` file to a desired location on your machine.

## 3. Launch the audit

Open a terminal and navigate to the root of the unzipped folder. Launch the audit with the two followings commands:

*docker-compose -p cea-moonshot-audit build*

*docker-compose -p cea-moonshot-audit up*

Or depending on your docker compose version, you may need to use the following commands:

*docker compose -p cea-moonshot-audit up --build*

*docker compose -p cea-moonshot-audit up*

## 4. Post-Audit Cleanup

The following commands are crucial for proper cleanup and system maintenance after the audit:

### **Shutting Down the Audit Containers:**

*docker-compose -p cea-moonshot-audit down*

Or depending on your docker compose version, you may need to use the following commands:

*docker compose -p cea-moonshot-audit down*

This command stops and removes the containers, networks, and volumes created by \`docker-compose up\`, ensuring no residual processes or data remain active.

### **Removing Specific Docker Images:**

These commands remove Docker images related to different steps of the audit, freeing up system resources and ensuring that your system uses the most current versions for future audits.

- Verifier Step 1:

*docker rmi cea-moonshot-audit-verifier-step-1:latest*

- Laboratory:


*docker rmi cea-moonshot-audit-laboratory:latest*

- Verifier Step 2:

*docker rmi cea-moonshot-audit-verifier-step-2:latest*

## 5. Reading the Audit Report

You can find the audit report in the \`output\` folder at the root of the unzipped folder. The report is the \`report.json\` file in \`JSON\` format.

The audit report is a JSON object (described below) containing the audit results.

### Report

\`\`\`json
{
  "global": {
    "status": "string",
    "message": "string"
  },
  "files": []
}
\`\`\`

- \`global\`: \`object\`, the global audit result, \`status\` and \`message\`:
  - \`status\`: \`string enum GlobalAuditionStatus\`, the global audit status documented below
  - \`message\`: \`string\`, the message of the audition result, the failure reason for example
- \`files\`: array of objects AuditFileReport, the list of audit result for each file, documented below

### GlobalAuditionStatus

\`GlobalAuditionStatus\` is a string having the following values:

- \`all_clear\`: All the files have been audited and no error has been found
- \`one_error\`: At least one file has been audited and an error has been found
- \`multiple_errors\`: At least 2 files have been audited and an error has been found for each of them
- \`unknown\`: The audit has not been executed completely yet
- \`fatal_error\`: An unexpected error has occurred during the audit execution

### AuditFileReport

\`\`\`json
{
  "filepath": "string",
  "storageFileHash": "string",
  "auditFileHash": "string",
  "isStorageIntegrityCompliant": true,
  "storageFileProof": [],
  "isStorageFileNotarized": true,
  "auditFileProof": [],
  "isAuditFileNotarized": true,
  "auditionStatus": "string",
  "message": "string"
}
\`\`\`

- \`filepath\`: \`string\` of the file path of the file audited
- \`storageFileHash\`: \`string\` or \`undefined\` the hash of the storage file stored on the laboratory, \`undefined\`if an error has occurred during the hashing of the storage file
- \`auditFileHash\`: \`string\` or \`undefined\` the hash of the audited file, \`undefined\`if an error has occurred during the hashing of the audited file
- \`isStorageIntegrityCompliant\`: \`boolean\`, \`true\` if the storage file hash is equal to the audit file hash
- \`storageFileProof\`: \`array of string\` or \`undefined\` the merkle proof of the storage file, \`undefined\`if an error has occurred during the proof generation
- \`isStorageFileNotarized\`: \`boolean\` or \`undefined\` \`true\` if the storage file has been notarized, \`undefined\`if an error has occurred during the notarization verification by smart contract
- \`auditFileProof\`: \`array of string\` or \`undefined\` the merkle proof of the audit file, \`undefined\`if an error has occurred during the proof generation
- \`isAuditFileNotarized\`: \`boolean\` or \`undefined\` \`true\` if the storage file has been notarized, \`undefined\`if an error has occurred during the notarization verification by smart contract
- \`auditionStatus\`: \`string enum FileAuditionStatus\`, the file audition status documented below
- \`message\`: \`string\`, the message of the file audition result, the failure reason for example

### FileAuditionStatus

\`FileAuditionStatus\` is a string having the following values:

- \`all_clear\`: The file has been audited and no error has been found
- \`storage_error\`: the storage file hash is not equal to the audit file hash
- \`anomaly_error\`: the audited file or its corresponding storage file is not notarized
- \`fatal_error\`: An unexpected error has occurred during the audit execution
- \`connection_error\`: An error has occurred during the connection to the blockchain
`;
