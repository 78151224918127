import Module from "components/materials/Module";
import HomePage from "components/pages/HomePage";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import ExternalUserSensorsPage from "components/pages/ExternalUserSensorsPage";
import { container } from "tsyringe";
import useUser from "hooks/queries/useUser";
import LaboratorySensorsPage from "components/pages/LaboratorySensorsPage";
import AssertRoles, { RolesMode } from "components/materials/AssertRoles";
import { RoleName } from "models/authentication/RoleName";
import Page404 from "components/pages/Page404";
import { memo } from "react";
import PageTemplate from "components/PageTemplate";
import SensorPage from "components/pages/SensorPage";
import SensorDataSection from "components/pages/SensorPage/SensorDataSection";
import SensorAuditsSection from "components/pages/SensorPage/SensorAuditsSection";
import SelectedSensorIdContextProvider from "contexts/SelectedSensorContextProvider";
import AssertRules, { RulesMode } from "components/materials/AssertRules";
import { RuleName } from "models/authentication/RuleName";
import { RuleAction } from "models/authentication/RuleAction";
import SensorAuditSection from "components/pages/SensorPage/SensorAuditSection";
import { routerPaths } from "hooks/useRoutes";
import AuditsRulePage from "components/pages/AuditsRulePage";
import SnackBarContextProvider from "contexts/SnackBarContextProvider";
import SensorCreationPage from "components/pages/SensorCreationPage";
import SensorEditionPage from "components/pages/SensorEditionPage";
import SensorTransactionsSection from "components/pages/SensorPage/SensorTransactionsSection";
import useApplicationConfig from "hooks/queries/useApplicationConfig";

const router = createBrowserRouter([
  {
    path: routerPaths.root.absolutePath,
    element: (
      <SnackBarContextProvider>
        <SelectedSensorIdContextProvider>
          <PageTemplate />
        </SelectedSensorIdContextProvider>
      </SnackBarContextProvider>
    ),
    children: [
      {
        path: routerPaths.home.relativePath,
        element: (
          <Module
            from={container.resolve(ModuleConfig).get().modules.pages.Home}
          >
            <HomePage />
          </Module>
        ),
      },
      {
        path: routerPaths.externalUser.relativePath,
        children: [
          {
            path: routerPaths.externalUser.sensors.relativePath,
            element: (
              <AssertRules
                mode={RulesMode.MatchAll}
                rules={[{ name: RuleName.Sensors, action: RuleAction.Read }]}
                isPage
              >
                <Module
                  from={
                    container.resolve(ModuleConfig).get().modules.pages
                      .ExternalUser.props.subPages.Sensors
                  }
                >
                  <ExternalUserSensorsPage />
                </Module>
              </AssertRules>
            ),
          },
          {
            path: routerPaths.externalUser.sensor.relativePath,
            element: (
              <AssertRules
                mode={RulesMode.MatchAll}
                rules={[{ name: RuleName.Sensors, action: RuleAction.Read }]}
                isPage
              >
                <Module
                  from={
                    container.resolve(ModuleConfig).get().modules.pages
                      .ExternalUser.props.subPages.Sensor
                  }
                >
                  <SensorPage />
                </Module>
              </AssertRules>
            ),
            children: [
              {
                path: "",
                element: (
                  <Navigate
                    to={routerPaths.externalUser.sensor.sensorData.relativePath}
                    replace
                  />
                ),
              },
              {
                path: routerPaths.externalUser.sensor.sensorData.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[
                      { name: RuleName.Sensors, action: RuleAction.Read },
                    ]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .ExternalUser.props.subPages.Sensor.props.subPages
                          .Data
                      }
                    >
                      <SensorDataSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.externalUser.sensor.sensorAudits.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[{ name: RuleName.Audits, action: RuleAction.Read }]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .ExternalUser.props.subPages.Sensor.props.subPages
                          .Audits
                      }
                    >
                      <SensorAuditsSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.externalUser.sensor.sensorTransactions
                  .relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[
                      { name: RuleName.Sensors, action: RuleAction.Read },
                    ]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .ExternalUser.props.subPages.Sensor.props.subPages
                          .Transactions
                      }
                    >
                      <SensorTransactionsSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.externalUser.sensor.sensorAudit.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[{ name: RuleName.Audits, action: RuleAction.Read }]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .ExternalUser.props.subPages.Sensor.props.subPages
                          .Audit
                      }
                    >
                      <SensorAuditSection />
                    </Module>
                  </AssertRules>
                ),
              },
            ],
          },
        ],
      },
      {
        path: routerPaths.laboratory.relativePath,
        children: [
          {
            path: routerPaths.laboratory.sensors.relativePath,
            element: (
              <AssertRoles
                mode={RolesMode.MatchAll}
                roles={[RoleName.LabUser]}
                isPage
              >
                <Module
                  from={
                    container.resolve(ModuleConfig).get().modules.pages
                      .Laboratory.props.subPages.Sensors
                  }
                >
                  <LaboratorySensorsPage />
                </Module>
              </AssertRoles>
            ),
          },
          {
            path: routerPaths.laboratory.sensorCreation.relativePath,
            element: (
              <AssertRoles
                mode={RolesMode.MatchAll}
                roles={[RoleName.LabUser]}
                isPage
              >
                <AssertRules
                  mode={RulesMode.MatchAll}
                  rules={[{ name: RuleName.Sensors, action: RuleAction.Write }]}
                  isPage
                >
                  <Module
                    from={
                      container.resolve(ModuleConfig).get().modules.pages
                        .Laboratory.props.subPages.SensorCreation
                    }
                  >
                    <SensorCreationPage />
                  </Module>
                </AssertRules>
              </AssertRoles>
            ),
          },
          {
            path: routerPaths.laboratory.sensor.sensorAuditsRule.absolutePath,
            element: (
              <AssertRoles
                mode={RolesMode.MatchAll}
                roles={[RoleName.LabUser]}
                isPage
              >
                <Module
                  from={
                    container.resolve(ModuleConfig).get().modules.pages
                      .Laboratory.props.subPages.Sensor.props.subPages
                      .AuditsRule
                  }
                >
                  <AuditsRulePage />
                </Module>
              </AssertRoles>
            ),
          },
          {
            path: routerPaths.laboratory.sensor.sensorEdition.absolutePath,
            element: (
              <AssertRoles
                mode={RolesMode.MatchAll}
                roles={[RoleName.LabUser]}
                isPage
              >
                <AssertRules
                  mode={RulesMode.MatchAll}
                  rules={[
                    { name: RuleName.Sensors, action: RuleAction.Update },
                  ]}
                  isPage
                >
                  <Module
                    from={
                      container.resolve(ModuleConfig).get().modules.pages
                        .Laboratory.props.subPages.Sensor.props.subPages.Edition
                    }
                  >
                    <SensorEditionPage />
                  </Module>
                </AssertRules>
              </AssertRoles>
            ),
          },
          {
            path: routerPaths.laboratory.sensor.relativePath,
            element: (
              <AssertRoles
                mode={RolesMode.MatchAll}
                roles={[RoleName.LabUser]}
                isPage
              >
                <Module
                  from={
                    container.resolve(ModuleConfig).get().modules.pages
                      .Laboratory.props.subPages.Sensor
                  }
                >
                  <SensorPage />
                </Module>
              </AssertRoles>
            ),
            children: [
              {
                path: "",
                element: (
                  <Navigate
                    to={routerPaths.laboratory.sensor.sensorData.relativePath}
                    replace
                  />
                ),
              },
              {
                path: routerPaths.laboratory.sensor.sensorData.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[
                      { name: RuleName.Sensors, action: RuleAction.Read },
                    ]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .Laboratory.props.subPages.Sensor.props.subPages.Data
                      }
                    >
                      <SensorDataSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.laboratory.sensor.sensorAudits.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[{ name: RuleName.Audits, action: RuleAction.Read }]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .Laboratory.props.subPages.Sensor.props.subPages
                          .Audits
                      }
                    >
                      <SensorAuditsSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.laboratory.sensor.sensorTransactions
                  .relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[
                      { name: RuleName.Sensors, action: RuleAction.Read },
                    ]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .Laboratory.props.subPages.Sensor.props.subPages
                          .Transactions
                      }
                    >
                      <SensorTransactionsSection />
                    </Module>
                  </AssertRules>
                ),
              },
              {
                path: routerPaths.laboratory.sensor.sensorAudit.relativePath,
                element: (
                  <AssertRules
                    mode={RulesMode.MatchAll}
                    rules={[{ name: RuleName.Audits, action: RuleAction.Read }]}
                  >
                    <Module
                      from={
                        container.resolve(ModuleConfig).get().modules.pages
                          .Laboratory.props.subPages.Sensor.props.subPages.Audit
                      }
                    >
                      <SensorAuditSection />
                    </Module>
                  </AssertRules>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
]);

function Router() {
  const { isFetched: isApplicationConfigFetched } = useApplicationConfig();
  const { isFetched: isUserFetched } = useUser();

  return (
    <>
      {isApplicationConfigFetched && isUserFetched && (
        <RouterProvider router={router}></RouterProvider>
      )}
    </>
  );
}

export default memo(Router);
