import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CSSProperties, memo } from "react";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import CloseButton from "components/element/CloseButton";
import AddWidgetAction from "./AddWidgetAction";
import { WidgetType } from "models/widgets/WidgetType";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorWidgets from "hooks/queries/useSensorWidgets";
import CircularLoader from "components/element/CircularLoader";

type Props = {
  isOpen: boolean;
  close: () => void;
};

const closeButtonStyles: CSSProperties = {
  position: "absolute",
  right: "12px",
  top: "18px",
};

function AddWidgetDialog({ isOpen, close }: Readonly<Props>) {
  const { sensorId } = useSelectedSensorIdContext();
  const { createWidgetQuery } = useSensorWidgets(sensorId);

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>
        <I18n map={"components.dialogs.add_widget.title"} />
        <CloseButton onClick={close} incomingStyle={closeButtonStyles} />
      </DialogTitle>

      <DialogContent sx={styles.content}>
        <AddWidgetAction
          createWidgetQuery={createWidgetQuery}
          widgetType={WidgetType.Co2Average}
        />
        <AddWidgetAction
          createWidgetQuery={createWidgetQuery}
          widgetType={WidgetType.BlockchainEnergyCost}
        />
        <AddWidgetAction
          createWidgetQuery={createWidgetQuery}
          widgetType={WidgetType.SensorMap}
        />
        <AddWidgetAction
          createWidgetQuery={createWidgetQuery}
          widgetType={WidgetType.Co2RelativeAverage}
        />
        <AddWidgetAction
          createWidgetQuery={createWidgetQuery}
          widgetType={WidgetType.Co2Evolution}
        />
      </DialogContent>

      <DialogActions sx={styles.actionsContainer}>
        <Button
          variant="outlined"
          color="info"
          onClick={close}
          disabled={createWidgetQuery.isLoading}
        >
          {I18n.translate("general_text.close")}
        </Button>
      </DialogActions>

      {createWidgetQuery.isLoading && (
        <Box sx={styles.loadingContainer}>
          <CircularLoader />
        </Box>
      )}
    </Dialog>
  );
}

export default memo(AddWidgetDialog);
