import { Box, Divider, Typography } from "@mui/material";
import * as styles from "./styles";
import Co2AverageChart from "components/element/Co2AverageChart";
import useCo2AverageData from "hooks/queries/widgetData/useCo2AverageData";
import { memo, useCallback, useEffect, useState } from "react";
import I18n from "components/materials/I18n";
import DateTimeRange from "components/element/DateTimeRange";
import SensorDataUtils from "utils/SensorResultUtils";
import { Co2AverageWidget } from "models/widgets/Widget";
import useMediaQueries from "hooks/useMediaQueries";
import EditionForm from "./EditionForm";
import { Nullable } from "types/common";
import { DateFormat } from "utils/DateUtils";
import CircularLoader from "components/element/CircularLoader";

type Props = {
  widget: Co2AverageWidget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<Co2AverageWidget["properties"]> | null
  ) => void;
  onUserValidation?: (properties: Co2AverageWidget["properties"]) => void;
};

function Co2Average({
  widget,
  shouldShowEditionForm,
  onPropertiesChange,
  onUserValidation,
}: Readonly<Props>) {
  const mediaQueries = useMediaQueries();

  const [properties, setProperties] = useState<Co2AverageWidget["properties"]>(
    widget.properties
  );

  useEffect(() => {
    setProperties(widget.properties);
  }, [widget.properties]);

  const { co2QuantitiesAverage, sensor, ...query } = useCo2AverageData(
    widget.id,
    properties
  );

  const validate = useCallback(
    (properties: Co2AverageWidget["properties"]) => {
      onUserValidation?.(properties);
      setProperties(properties);
    },
    [onUserValidation]
  );

  return (
    <Box sx={styles.root}>
      {shouldShowEditionForm && (
        <EditionForm
          properties={properties}
          onSubmit={validate}
          onPropertiesChange={onPropertiesChange}
        />
      )}

      <Box sx={styles.content(mediaQueries)}>
        <Box sx={styles.chartContainer(mediaQueries)}>
          {query.isLoading && (
            <Box sx={styles.loadingContainer}>
              <CircularLoader />
            </Box>
          )}
          {query.isFetched && !isNaN(co2QuantitiesAverage) && (
            <Co2AverageChart average={co2QuantitiesAverage} />
          )}
          {query.isFetched && !co2QuantitiesAverage && (
            <Typography sx={styles.unit}>
              <I18n map="general_text.no_data" />
            </Typography>
          )}
        </Box>

        <Divider orientation={"vertical"} variant="middle" />

        <Box sx={styles.description}>
          <Typography variant="h6">
            {sensor ? SensorDataUtils.formatDisplayName(sensor) : "--"}
          </Typography>
          <DateTimeRange
            fromDate={properties.fromDate}
            toDate={properties.toDate}
            format={DateFormat.DayMonthYearBulletHourMinute}
            wrapable
          />
        </Box>
      </Box>
    </Box>
  );
}

export default memo(Co2Average);
