import I18n from "components/materials/I18n";
import SensorsMap from "components/materials/SensorsMap";
import useHead from "hooks/useHead";
import useCurrentlyActiveSensors from "hooks/queries/useCurrentlyActiveSensors";
import Sensor from "models/sensor/Sensor";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { mainHeight } from "themes/constants";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import * as styles from "./styles";
import SensorsList from "./SensorsList";
import { ModelId } from "models/common/Model";
import ScrollUtils from "utils/ScrollUtils";
import useMediaQueries from "hooks/useMediaQueries";
import useUser from "hooks/queries/useUser";
import useSensors from "hooks/queries/useSensors";

function LaboratorySensorsPage() {
  useHead({
    title: I18n.translate("pages.sensors.title"),
  });

  const mediaQueries = useMediaQueries();

  const { userLaboratoryId } = useUser();
  const { sensors: laboratorySensors } = useSensors({
    queryParams: {
      laboratoryId: userLaboratoryId,
    },
  });
  const { currentlyActiveSensors } = useCurrentlyActiveSensors();

  const arrangedOtherSensors = arrangeOtherSensors(
    currentlyActiveSensors,
    userLaboratoryId ?? 0
  );

  const arrangedLaboratorySensors = arrangeLaboratorySensors(laboratorySensors);

  const allSensors = useMemo(
    () => [...arrangedLaboratorySensors, ...arrangedOtherSensors],
    [arrangedLaboratorySensors, arrangedOtherSensors]
  );

  const { sensorId: sensorIdFromContext, setSensorId: setSensorIdOfContext } =
    useSelectedSensorIdContext();
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(
    allSensors.find((s) => s.id === sensorIdFromContext) ?? null
  );

  const handleSensorSelection = useCallback(
    (sensor: Sensor | null) => {
      setSelectedSensor(sensor);

      setSensorIdOfContext(sensor?.id ?? null);
      sensor &&
        ScrollUtils.scrollToElementById({
          id: String(sensor.id),
        });
    },
    [setSensorIdOfContext]
  );

  useEffect(() => {
    const sensorToSelect =
      allSensors?.find((s) => s.id === sensorIdFromContext) ?? null;

    handleSensorSelection(sensorToSelect);
  }, [
    allSensors,
    currentlyActiveSensors,
    handleSensorSelection,
    selectedSensor,
    sensorIdFromContext,
  ]);

  return (
    <Box sx={styles.root(mainHeight, mediaQueries)}>
      <Box sx={styles.sensorsMapContainer}>
        <SensorsMap
          sensors={[...arrangedLaboratorySensors, ...arrangedOtherSensors]}
          interactive
          onSensorSelection={handleSensorSelection}
          selectedSensor={selectedSensor}
          isLaboratoryView
        />
      </Box>

      <Box sx={styles.sensorsListContainer(mediaQueries)}>
        {laboratorySensors.length > 0 && (
          <SensorsList
            onSensorSelection={handleSensorSelection}
            sensors={laboratorySensors}
            selectedSensor={selectedSensor}
            label={I18n.translate("components.sensors_list.my_sensors_title")}
            isLaboratorySensors
          />
        )}

        {arrangedOtherSensors.length > 0 && (
          <SensorsList
            onSensorSelection={handleSensorSelection}
            sensors={arrangedOtherSensors}
            selectedSensor={selectedSensor}
            label={I18n.translate(
              "components.sensors_list.other_sensors_title"
            )}
          />
        )}
      </Box>
    </Box>
  );
}

function arrangeOtherSensors(
  sensors: Sensor[],
  laboratoryId: ModelId
): Sensor[] {
  const sensorSortedByIdentifiers = sensors.sort((sensorA, sensorB) =>
    sensorA.identifier.localeCompare(sensorB.identifier)
  );

  const otherSensors = sensorSortedByIdentifiers.filter(
    (sensor) => sensor.laboratory?.id !== laboratoryId
  );

  return otherSensors;
}

function arrangeLaboratorySensors(sensors: Sensor[]): Sensor[] {
  const sensorSortedByIdentifiers = sensors.sort((sensorA, sensorB) =>
    sensorA.identifier.localeCompare(sensorB.identifier)
  );

  return sensorSortedByIdentifiers;
}

export default memo(LaboratorySensorsPage);
