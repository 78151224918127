import { NotificationType } from "models/notification/NotificationType";
import Notification, {
  NotificationCompletedAuditData,
} from "models/notification/Notification";
import { memo, useCallback, useState } from "react";
import DateUtils, { DateFormat } from "utils/DateUtils";
import { Box, Button, Typography } from "@mui/material";
import * as styles from "./styles";
import CloseButton from "components/element/CloseButton";
import { translation } from "components/element/chips/GlobalAuditionStatusChip";
import I18n from "components/materials/I18n";
import { useNavigate } from "react-router-dom";
import useRoutes from "hooks/useRoutes";
import useUserNotifications from "hooks/queries/useUserNotifications";
import DelayUtils from "utils/DelayUtils";
import { Warning } from "@mui/icons-material";

type Props = {
  notification: Notification & {
    type: NotificationType.CompletedAudit;
    data: NotificationCompletedAuditData;
  };
  close: () => void;
};

const dismissAnimationDuration = 300;
const estimatedMutationDuration = 100;

function NotificationCompletedAudit({ notification, close }: Props) {
  const {
    auditId,
    auditGlobalErrorStatus,
    sensorId,
    sensorIdentifier,
    auditExecutionDateTime,
  } = notification.data;
  const { updateNotificationQuery } = useUserNotifications();
  const navigate = useNavigate();
  const routes = useRoutes();
  const [isDismissed, setIsDismissed] = useState(false);

  const dismiss = useCallback(async () => {
    setIsDismissed(true);
    await DelayUtils.wait(dismissAnimationDuration - estimatedMutationDuration);
    await updateNotificationQuery.mutateAsync({
      notificationId: notification.id,
      dismissDate: new Date(),
    });
  }, [notification.id, updateNotificationQuery]);

  const viewAudit = useCallback(() => {
    navigate(
      routes.laboratory.sensor.sensorAudit.resolveRoute({
        sensorId: sensorId,
        auditId: auditId,
      })
    );
    close();
  }, [
    auditId,
    close,
    navigate,
    routes.laboratory.sensor.sensorAudit,
    sensorId,
  ]);

  return (
    <Box
      sx={styles.root({
        dismissAnimationDuration,
        isDismissed,
      })}
    >
      <Box sx={styles.header}>
        <Typography variant="caption" color="grey.600">
          {DateUtils.formatUTCDate(
            auditExecutionDateTime,
            DateFormat.DayMonthYearBulletHourMinute
          )}
        </Typography>

        <CloseButton onClick={dismiss} />
      </Box>

      <Box sx={styles.content}>
        <Warning color="warning" />

        <Box sx={styles.description}>
          <Typography variant="body1" fontWeight={500}>
            {sensorIdentifier}
          </Typography>

          <Typography variant="body2">
            {I18n.translate(
              "global_audition_status.".concat(
                translation[auditGlobalErrorStatus]
              )
            )}
          </Typography>

          <Button
            variant="outlined"
            color="info"
            onClick={viewAudit}
            sx={styles.action}
          >
            <I18n map="components.notifications.completed_audit.action" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(NotificationCompletedAudit);
