import { ContentCopy } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { CSSProperties, PropsWithChildren, memo, useCallback } from "react";
import * as styles from "./styles";

type Props = {
  text: string;
  incomingStyle?: CSSProperties;
  enablePropagation?: boolean;
};

function CopyToClipboard({
  children,
  text,
  incomingStyle,
  enablePropagation,
}: PropsWithChildren<Props>) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!enablePropagation) {
        e.preventDefault();
        e.stopPropagation();
      }

      navigator.clipboard.writeText(text);
    },
    [enablePropagation, text]
  );

  return (
    <Box sx={styles.root(incomingStyle)}>
      {children}
      <IconButton onClick={handleClick}>
        <ContentCopy />
      </IconButton>
    </Box>
  );
}

export default memo(CopyToClipboard);
