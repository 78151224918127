export default class UrlUtils {
  private static readonly appendStrategy: Record<
    string,
    (url: URL, key: string, value: any) => void
  > = {
    string: (url, key, value) => {
      url.searchParams.append(key, value);
    },
    number: (url, key, value) => {
      url.searchParams.append(key, value.toString());
    },
    boolean: (url, key, value) => {
      url.searchParams.append(key, value.toString());
    },
    object: (url, key, value) => {
      url.searchParams.append(key, JSON.stringify(value));
    },
  };

  public static appendQueryParams(
    url: URL,
    queryParams: Record<string, unknown>
  ): URL {
    for (const [key, value] of Object.entries(queryParams)) {
      const appendStrategy = this.appendStrategy[typeof value];

      if (appendStrategy) {
        appendStrategy(url, key, value);
      }
    }

    return url;
  }
}
