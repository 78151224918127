import { useCallback, useState } from "react";
import { MetadataUpdates } from ".";
import I18n from "components/materials/I18n";
import { isAddress as isBlockchainAddress } from "web3-validator";

export type MetadataFormErrors = Record<
  keyof MetadataUpdates | "extra",
  string[]
>;

const initialErrors: MetadataFormErrors = {
  image: [],
  identifier: [],
  type: [],
  name: [],
  blockchainAddress: [],
  blockchainActivationDate: [],
  extra: [],
};

function useMetadataValidation() {
  const [errors, setErrors] = useState(initialErrors);

  const validate: (metadata: MetadataUpdates) => {
    hasErrors: boolean;
    validatedData: {
      image: File | null;
      type: string;
      name: string;
      identifier: string;
      blockchainAddress: string;
      blockchainActivationDate: Date;
    } | null;
  } = useCallback((metadata) => {
    let errors = initialErrors;

    if (metadata.type === "") {
      errors = {
        ...errors,
        type: [
          ...errors.type,
          I18n.translate("components.sensor_metadata_form.type.errors.empty"),
        ],
      };
    }

    if (metadata.name === "") {
      errors = {
        ...errors,
        name: [
          ...errors.name,
          I18n.translate("components.sensor_metadata_form.name.errors.empty"),
        ],
      };
    }

    if (metadata.identifier === "") {
      errors = {
        ...errors,
        identifier: [
          ...errors.identifier,
          I18n.translate(
            "components.sensor_metadata_form.identifier.errors.empty"
          ),
        ],
      };
    }

    if (metadata.blockchainAddress === "") {
      errors = {
        ...errors,
        blockchainAddress: [
          ...errors.blockchainAddress,
          I18n.translate(
            "components.sensor_metadata_form.sensor_blockchain_address.errors.empty"
          ),
        ],
      };
    }

    if (
      metadata.blockchainAddress !== "" &&
      !isBlockchainAddress(metadata.blockchainAddress)
    ) {
      errors = {
        ...errors,
        blockchainAddress: [
          ...errors.blockchainAddress,
          I18n.translate("general_text.form_errors.invalid_blockchain_address"),
        ],
      };
    }

    if (metadata.blockchainActivationDate === null) {
      errors = {
        ...errors,
        blockchainActivationDate: [
          ...errors.blockchainActivationDate,
          I18n.translate(
            "components.sensor_metadata_form.sensor_blockchain_activation_date.errors.empty"
          ),
        ],
      };
    }

    if (
      metadata.blockchainActivationDate !== null &&
      metadata.blockchainActivationDate > new Date()
    ) {
      errors = {
        ...errors,
        blockchainActivationDate: [
          ...errors.blockchainActivationDate,
          I18n.translate(
            "components.sensor_metadata_form.sensor_blockchain_activation_date.errors.date_in_future"
          ),
        ],
      };
    }

    setErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error.length);
    return {
      hasErrors,
      validatedData: !hasErrors
        ? {
            image: metadata.image,
            type: metadata.type,
            name: metadata.name,
            identifier: metadata.identifier,
            blockchainAddress: metadata.blockchainAddress,
            blockchainActivationDate: metadata.blockchainActivationDate!,
          }
        : null,
    };
  }, []);

  return {
    errors,
    validate,
  };
}

export default useMetadataValidation;
