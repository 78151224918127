import I18n from "components/materials/I18n";
import SensorsMap from "components/materials/SensorsMap";
import useHead from "hooks/useHead";
import useCurrentlyActiveSensors from "hooks/queries/useCurrentlyActiveSensors";
import Sensor from "models/sensor/Sensor";
import { memo, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { mainHeight } from "themes/constants";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import DetailMapTooltip from "components/materials/SensorsMap/DetailMapTooltip";

function ExternalUserSensorsPage() {
  useHead({
    title: I18n.translate("pages.sensors.title"),
  });

  const { currentlyActiveSensors } = useCurrentlyActiveSensors();
  const { sensorId: sensorIdFromContext, setSensorId: setSensorIdOfContext } =
    useSelectedSensorIdContext();
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(
    currentlyActiveSensors?.find((s) => s.id === sensorIdFromContext) ?? null
  );

  useEffect(() => {
    setSelectedSensor(
      currentlyActiveSensors?.find((s) => s.id === sensorIdFromContext) ?? null
    );
  }, [currentlyActiveSensors, sensorIdFromContext]);

  const handleSensorSelection = useCallback(
    (sensor: Sensor) => {
      const currentlyActiveSensor = currentlyActiveSensors?.find(
        (s) => s.id === sensor.id
      );
      setSelectedSensor(currentlyActiveSensor ?? null);
      setSensorIdOfContext(currentlyActiveSensor?.id ?? null);
    },
    [currentlyActiveSensors, setSensorIdOfContext]
  );

  return (
    <Box height={mainHeight}>
      <SensorsMap
        sensors={currentlyActiveSensors ?? []}
        interactive
        onSensorSelection={handleSensorSelection}
        selectedSensor={selectedSensor}
      >
        {selectedSensor && <DetailMapTooltip sensor={selectedSensor} />}
      </SensorsMap>
    </Box>
  );
}

export default memo(ExternalUserSensorsPage);
