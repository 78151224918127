import { useCallback, useState } from "react";
import { z } from "zod";

export default function useValidation<T>(schema: z.ZodSchema<T>) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<z.ZodError | null>(null);

  const validateAsync = useCallback(
    async (data: Partial<T>) => {
      try {
        const validatedData = await schema.parseAsync(data);
        setData(validatedData);
        setError(null);
        return validatedData;
      } catch (error) {
        setError(error as z.ZodError);
        return null;
      }
    },
    [schema]
  );

  const validate = useCallback(
    (data: Partial<T>) => {
      try {
        const validatedData = schema.parse(data);
        setData(validatedData);
        return validatedData;
      } catch (error) {
        setError(error as z.ZodError);
        return null;
      }
    },
    [schema]
  );

  const isError = useCallback(
    (key: keyof T) => {
      return !!error?.issues.find((issue) => issue.path[0] === key);
    },
    [error?.issues]
  );

  const errorsOf = useCallback(
    (key: keyof T) =>
      error?.issues
        .filter((issue) => issue.path[0] === key)
        .map((issue) => issue.message) ?? [],
    [error?.issues]
  );

  return { data, isError, validate, validateAsync, errorsOf };
}
