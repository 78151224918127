import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  marginLeft: theme.spacing(3),
});

export const error: SxProps<Theme> = (theme) => ({
  fontSize: "12px",
  color: theme.palette.error.main,
});
