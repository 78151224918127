import { Link } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import ceaLogoIcon from "assets/images/icons/grey-cea-logo-icon.svg";
import ceaLogoText from "assets/images/icons/grey-cea-logo-text.svg";
import useMediaQueries from "hooks/useMediaQueries";
import { spacingXS } from "themes/constants";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import { memo } from "react";

function Footer() {
  const mediaQueries = useMediaQueries();

  return (
    <Box sx={styles.footer(mediaQueries)}>
      <Box sx={styles.leftSide}>
        <Container sx={styles.logo}>
          <Link to="/">
            <img
              src={ceaLogoIcon}
              style={{ marginRight: spacingXS, color: "blue" }}
              alt=""
            />
            <img src={ceaLogoText} alt="" />
          </Link>
        </Container>
        <Container sx={styles.list}>
          <Link to="/">
            <Typography sx={styles.listItem} variant="body1">
              <I18n map="components.footer.list1" />
            </Typography>
          </Link>
          <Link to="/">
            <Typography sx={styles.listItem} variant="body1">
              <I18n map="components.footer.list2" />
            </Typography>
          </Link>
          <Link to="/">
            <Typography sx={styles.listItem} variant="body1">
              <I18n map="components.footer.list3" />
            </Typography>
          </Link>
        </Container>
      </Box>
      <Typography sx={styles.copyright} variant="body1">
        <I18n map="components.footer.copyright" />
      </Typography>
    </Box>
  );
}

export default memo(Footer);
