import { Box } from "@mui/material";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { memo } from "react";
import * as styles from "./styles";
import BackButton from "./BackButton";
import CreationForm from "./CreationForm";

function SensorCreationPage() {
  useHead({
    title: I18n.translate("pages.sensor_creation.title"),
  });

  return (
    <Box sx={styles.root}>
      <BackButton />
      <CreationForm />
    </Box>
  );
}

export default memo(SensorCreationPage);
