import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const data: SxProps<Theme> = (theme) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "123.5%",
  letterSpacing: "0.25px",
  color: theme.palette.primary.main,
  fontSize: "34px",
});

export const unit: SxProps<Theme> = (theme) => {
  return {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    color: theme.palette.primary.main,
  };
};

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> = (
  mediaQueries
) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  ...(mediaQueries.isSm && {
    flexDirection: "row",
  }),

  ...(mediaQueries.isMd && {
    flexDirection: "column",
  }),
});
