import { Delete } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useWhitelist from "hooks/queries/useWhitelist";
import { memo } from "react";
import * as styles from "./styles";
import { LoadingButton } from "@mui/lab";

type Props = {
  onSave: () => Promise<void>;
  saveQuery: ReturnType<typeof useWhitelist>["updateWhitelistQuery"];
  onDelete: () => void;
  onCancel: () => void;
};

function Actions({ onSave, saveQuery, onCancel, onDelete }: Props) {
  return (
    <Box sx={styles.root}>
      <Box>
        <Button
          variant="text"
          color="error"
          onClick={onDelete}
          startIcon={<Delete />}
        >
          {I18n.translate("general_text.delete")}
        </Button>
      </Box>

      <Box sx={styles.rightActions}>
        <Button variant="outlined" color="info" onClick={onCancel}>
          {I18n.translate("general_text.cancel")}
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={saveQuery.isLoading}
          loading={saveQuery.isLoading}
        >
          {I18n.translate("general_text.save")}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default memo(Actions);
