import { RuleDto } from "services/apis/ceaMoonshot/dtos/authentication/RuleDto";
import { ModelId } from "../common/Model";

import { RuleAction } from "./RuleAction";
import { RuleName } from "./RuleName";

export default class Rule {
  public readonly id: ModelId;
  public readonly name: RuleName;
  public readonly action: RuleAction;

  private constructor(params: {
    id: ModelId;
    name: RuleName;
    action: RuleAction;
  }) {
    this.id = params.id;
    this.name = params.name;
    this.action = params.action;
  }

  public static fromDto(dto: RuleDto): Rule {
    return new Rule({
      id: dto.id,
      name: dto.name,
      action: dto.action,
    });
  }
}
