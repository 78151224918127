import { Box } from "@mui/material";
import { memo, useCallback, useState } from "react";
import * as styles from "./styles";
import MetadataForm, { MetadataUpdates } from "./MetadataForm";
import SituationForm, { SituationUpdates } from "./SituationForm";
import I18n from "components/materials/I18n";
import useSnackBarContext from "hooks/useSnackBarContext";
import useMediaQueries from "hooks/useMediaQueries";
import useMetadataValidation from "./MetadataForm/useMetadataValidation";
import useSituationValidation from "./SituationForm/useSituationValidation";
import useSensors from "hooks/queries/useSensors";
import assert from "assert";
import { isHttpError } from "utils/HttpErrorUtils";
import { LoadingButton } from "@mui/lab";
import Sensor from "models/sensor/Sensor";
import { container } from "tsyringe";
import SensorService from "services/SensorService";

export type SensorCreationUpdates = {
  metadata: MetadataUpdates;
  situation: SituationUpdates;
};

const newSensor: SensorCreationUpdates = {
  metadata: {
    image: null,
    identifier: "",
    type: "",
    name: "",
    blockchainAddress: "",
    blockchainActivationDate: null,
  },
  situation: {
    heightAboveGround: "",
    heightAboveSea: "",
    latitude: "",
    longitude: "",
    location: "",
    installationDate: null,
    activationDate: null,
    deactivationDate: null,
    useCase: "",
  },
};

function CreationForm() {
  const mediaQueries = useMediaQueries();
  const [isLoadingSensorImageUpdate, setIsLoadingSensorImageUpdate] =
    useState(false);
  const [sensorUpdates, setSensorUpdates] =
    useState<SensorCreationUpdates>(newSensor);
  const { createSensorQuery } = useSensors();

  const handleMetadataChange = useCallback(
    (metadata: SensorCreationUpdates["metadata"]) => {
      setSensorUpdates((prev) => ({
        ...prev,
        metadata,
      }));
    },
    []
  );

  const handleSituationChange = useCallback(
    (situation: SensorCreationUpdates["situation"]) => {
      setSensorUpdates((prev) => ({
        ...prev,
        situation,
      }));
    },
    []
  );

  const { errors: metadataFormErrors, validate: validateMetadataUpdates } =
    useMetadataValidation();

  const { errors: situationFormErrors, validate: validateSituationUpdates } =
    useSituationValidation();

  const snackBar = useSnackBarContext();
  const handleSave = useCallback(async () => {
    const {
      hasErrors: hasMetadataErrors,
      validatedData: validatedMetadataUpdates,
    } = validateMetadataUpdates(sensorUpdates.metadata);

    const {
      hasErrors: hasSituationErrors,
      validatedData: validatedSituationUpdates,
    } = validateSituationUpdates(sensorUpdates.situation);

    if (hasMetadataErrors || hasSituationErrors) return;
    assert(
      validatedMetadataUpdates && validatedSituationUpdates,
      "Invalid data for sensor creation."
    );

    const { image, ...validatedMetadataUpdatesWithoutImage } =
      validatedMetadataUpdates;

    let createdSensor: Sensor;
    try {
      createdSensor = await createSensorQuery.mutateAsync({
        metadata: validatedMetadataUpdatesWithoutImage,
        situation: validatedSituationUpdates,
      });
    } catch (error: unknown) {
      if (isHttpError(error) && error.response.status === 409) {
        const reason = error.body.error.message;

        if (reason.includes("identifier")) {
          snackBar.open({
            severity: "error",
            message: I18n.translate(
              "pages.sensor_creation.alert.create.conflict_identifier_error"
            ),
          });
          return;
        }

        if (reason.includes("blockchainAddress")) {
          snackBar.open({
            severity: "error",
            message: I18n.translate(
              "pages.sensor_creation.alert.create.conflict_blockchain_address_error"
            ),
          });
          return;
        }
      }

      snackBar.open({
        severity: "error",
        message: I18n.translate("pages.sensor_creation.alert.create.error"),
      });
      return;
    }

    if (image) {
      try {
        setIsLoadingSensorImageUpdate(true);
        await container.resolve(SensorService).updateImage({
          sensorId: createdSensor.id,
          image,
        });
      } catch (error: unknown) {
        snackBar.open({
          severity: "error",
          message: I18n.translate("pages.sensor_creation.alert.create.error"),
        });
        return;
      } finally {
        setIsLoadingSensorImageUpdate(false);
      }
    }

    snackBar.open({
      severity: "success",
      message: I18n.translate("pages.sensor_creation.alert.create.success"),
    });
    setSensorUpdates(newSensor);
  }, [
    createSensorQuery,
    sensorUpdates.metadata,
    sensorUpdates.situation,
    snackBar,
    validateMetadataUpdates,
    validateSituationUpdates,
  ]);

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Box sx={styles.mainContent}>
        <Box sx={styles.formsContainer}>
          <MetadataForm
            metadata={sensorUpdates.metadata}
            onMetadataChange={handleMetadataChange}
            errors={metadataFormErrors}
          />

          <SituationForm
            situation={sensorUpdates.situation}
            onSituationChange={handleSituationChange}
            errors={situationFormErrors}
          />

          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                createSensorQuery.isLoading || isLoadingSensorImageUpdate
              }
              loading={
                createSensorQuery.isLoading || isLoadingSensorImageUpdate
              }
            >
              {I18n.translate("general_text.save")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(CreationForm);
