import { useQuery } from "react-query";
import { container } from "tsyringe";
import ApplicationConfigService from "services/ApplicationConfigService";

const applicationConfigQueryKey = ["application-config"];
const staleTimeInMs = 1000 * 60 * 60; // 1 hour

function useApplicationConfig() {
  const query = useQuery(
    applicationConfigQueryKey,
    async () => container.resolve(ApplicationConfigService).get(),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      refetchInterval: staleTimeInMs,
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...query,
    applicationConfig: query.data ?? null,
  };
}

export default useApplicationConfig;
