import { container, singleton } from "tsyringe";
import ApplicationConfigApi from "./apis/ceaMoonshot/ApplicationConfigApi";
import ApplicationConfig from "models/applicationConfig/ApplicationConfig";

@singleton()
export default class ApplicationConfigService {
  public async get(): Promise<ApplicationConfig> {
    const applicationConfigDto = await container
      .resolve(ApplicationConfigApi)
      .get();

    return ApplicationConfig.fromDto(applicationConfigDto);
  }
}
