import { Box, IconButton, TextField, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import FormErrors from "components/element/FormErrors";
import { SituationFormErrors } from "./useSituationValidation";
import CustomDatePicker from "components/element/inputs/CustomDatePicker";
import { DayJsFormat } from "utils/DayjsUtils";
import { Close, Edit } from "@mui/icons-material";

export type SituationUpdates = {
  heightAboveGround: string;
  heightAboveSea: string;
  latitude: string;
  longitude: string;
  location: string;
  installationDate: Date | null;
  activationDate: Date | null;
  deactivationDate: Date | null;
  useCase: string;
};

type Props = {
  onSituationChange: (situation: SituationUpdates) => void;
  situation: SituationUpdates;
  errors: SituationFormErrors;
};

function SituationForm(props: Props) {
  const [criticalEditition, setCriticalEditition] = useState({
    installationDate: false,
  });

  const toggleCriticalEditition = useCallback(
    (field: keyof typeof criticalEditition) =>
      setCriticalEditition((prev) => ({ ...prev, [field]: !prev[field] })),
    []
  );

  const handleHeightAboveGroundChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        heightAboveGround: event.target.value,
      });
    },
    [props]
  );

  const handleHeightAboveSeaChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        heightAboveSea: event.target.value,
      });
    },
    [props]
  );

  const handleLatitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        latitude: event.target.value,
      });
    },
    [props]
  );

  const handleLongitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        longitude: event.target.value,
      });
    },
    [props]
  );

  const handleLocationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        location: event.target.value,
      });
    },
    [props]
  );

  const handleUseCaseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onSituationChange({
        ...props.situation,
        useCase: event.target.value,
      });
    },
    [props]
  );

  const handleInstallationDateChange = useCallback(
    (date: Date | null) => {
      props.onSituationChange({
        ...props.situation,
        installationDate: date,
      });
    },
    [props]
  );

  const handleActivationDateChange = useCallback(
    (date: Date | null) => {
      props.onSituationChange({
        ...props.situation,
        activationDate: date,
      });
    },
    [props]
  );

  const handleDeactivationDateChange = useCallback(
    (date: Date | null) => {
      props.onSituationChange({
        ...props.situation,
        deactivationDate: date,
      });
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="h5">
        <I18n map="components.sensor_situation_form.label" />
      </Typography>

      <TextField
        fullWidth
        required
        label={I18n.translate(
          "components.sensor_situation_form.location.label"
        )}
        placeholder={I18n.translate(
          "components.sensor_situation_form.location.placeholder"
        )}
        value={props.situation.location}
        onChange={handleLocationChange}
        error={props.errors.location.length > 0}
        helperText={<FormErrors errors={props.errors.location} isHelperText />}
      />

      <Box sx={styles.doubleFieldContainer}>
        <TextField
          fullWidth
          required
          label={I18n.translate(
            "components.sensor_situation_form.latitude.label"
          )}
          placeholder={I18n.translate(
            "components.sensor_situation_form.latitude.placeholder"
          )}
          value={props.situation.latitude}
          onChange={handleLatitudeChange}
          error={props.errors.latitude.length > 0}
          helperText={
            <FormErrors errors={props.errors.latitude} isHelperText />
          }
        />

        <TextField
          fullWidth
          required
          label={I18n.translate(
            "components.sensor_situation_form.longitude.label"
          )}
          placeholder={I18n.translate(
            "components.sensor_situation_form.longitude.placeholder"
          )}
          value={props.situation.longitude}
          onChange={handleLongitudeChange}
          error={props.errors.longitude.length > 0}
          helperText={
            <FormErrors errors={props.errors.longitude} isHelperText />
          }
        />
      </Box>

      <Box sx={styles.doubleFieldContainer}>
        <TextField
          fullWidth
          label={I18n.translate(
            "components.sensor_situation_form.height_above_ground.label"
          )}
          value={props.situation.heightAboveGround}
          onChange={handleHeightAboveGroundChange}
          error={props.errors.heightAboveGround.length > 0}
          helperText={
            <FormErrors errors={props.errors.heightAboveGround} isHelperText />
          }
        />

        <TextField
          fullWidth
          label={I18n.translate(
            "components.sensor_situation_form.height_above_sea.label"
          )}
          value={props.situation.heightAboveSea}
          onChange={handleHeightAboveSeaChange}
          error={props.errors.heightAboveSea.length > 0}
          helperText={
            <FormErrors errors={props.errors.heightAboveSea} isHelperText />
          }
        />
      </Box>

      <TextField
        fullWidth
        label={I18n.translate(
          "components.sensor_situation_form.use_case.label"
        )}
        placeholder={I18n.translate(
          "components.sensor_situation_form.use_case.placeholder"
        )}
        value={props.situation.useCase}
        onChange={handleUseCaseChange}
        error={props.errors.useCase.length > 0}
        helperText={<FormErrors errors={props.errors.useCase} isHelperText />}
      />

      <Box display="flex" alignItems="center" marginRight="auto">
        <CustomDatePicker
          date={props.situation.installationDate}
          onDateChange={handleInstallationDateChange}
          label={I18n.translate(
            "components.sensor_situation_form.installation_date.label"
          )}
          required
          withTime={true}
          errors={props.errors.installationDate}
          disableFuture
          format={DayJsFormat.DayMonthYearHourMinute}
          disabled={!criticalEditition.installationDate}
        />

        {!criticalEditition.installationDate && (
          <IconButton
            onClick={() => toggleCriticalEditition("installationDate")}
          >
            <Edit />
          </IconButton>
        )}

        {criticalEditition.installationDate && (
          <IconButton
            onClick={() => toggleCriticalEditition("installationDate")}
          >
            <Close />
          </IconButton>
        )}
      </Box>

      <Box marginRight="auto">
        <CustomDatePicker
          date={props.situation.activationDate}
          onDateChange={handleActivationDateChange}
          label={I18n.translate(
            "components.sensor_situation_form.activation_date.label"
          )}
          withTime={true}
          errors={props.errors.activationDate}
          format={DayJsFormat.DayMonthYearHourMinute}
        />
      </Box>

      <Box marginRight="auto">
        <CustomDatePicker
          date={props.situation.deactivationDate}
          onDateChange={handleDeactivationDateChange}
          label={I18n.translate(
            "components.sensor_situation_form.deactivation_date.label"
          )}
          withTime={true}
          errors={props.errors.deactivationDate}
          format={DayJsFormat.DayMonthYearHourMinute}
        />
      </Box>

      {props.errors.extra.length > 0 && (
        <FormErrors errors={props.errors.extra} />
      )}
    </Box>
  );
}

export default memo(SituationForm);
