import * as Leaflet from "leaflet";
import mapMarkerGray from "icons/mapMarkers/mapMarkerGray.svg";
import mapMarkerGraySelected from "icons/mapMarkers/mapMarkerGraySelected.svg";
import mapMarkerGreen from "icons/mapMarkers/mapMarkerGreen.svg";
import mapMarkerGreenSelected from "icons/mapMarkers/mapMarkerGreenSelected.svg";
import mapMarkerGreenDark from "icons/mapMarkers/mapMarkerGreenDark.svg";
import mapMarkerGreenDarkSelected from "icons/mapMarkers/mapMarkerGreenDarkSelected.svg";
import mapMarkerOrange from "icons/mapMarkers/mapMarkerOrange.svg";
import mapMarkerOrangeSelected from "icons/mapMarkers/mapMarkerOrangeSelected.svg";
import mapMarkerRed from "icons/mapMarkers/mapMarkerRed.svg";
import mapMarkerRedSelected from "icons/mapMarkers/mapMarkerRedSelected.svg";

type IconData = {
  iconUrl: string;
  iconSize: [number, number];
  iconAnchor: [number, number];
};

export function buildIcon({
  isSelectable,
  iconUrl,
  iconSize,
  iconAnchor,
}: IconData & {
  isSelectable?: boolean;
}) {
  return new Leaflet.Icon({
    className: isSelectable ? "selectable" : "unselectable",
    iconUrl,
    iconSize,
    iconAnchor,
  });
}

export const grayIcon: IconData = {
  iconUrl: mapMarkerGray,
  iconSize: [14, 20],
  iconAnchor: [7, 20],
};

export const grayIconSelected: IconData = {
  iconUrl: mapMarkerGraySelected,
  iconSize: [28, 40],
  iconAnchor: [14, 40],
};

export const redIcon: IconData = {
  iconUrl: mapMarkerRed,
  iconSize: [14, 20],
  iconAnchor: [7, 20],
};

export const redIconSelected: IconData = {
  iconUrl: mapMarkerRedSelected,
  iconSize: [28, 40],
  iconAnchor: [14, 40],
};

export const orangeIcon: IconData = {
  iconUrl: mapMarkerOrange,
  iconSize: [14, 20],
  iconAnchor: [7, 20],
};

export const orangeIconSelected: IconData = {
  iconUrl: mapMarkerOrangeSelected,
  iconSize: [28, 40],
  iconAnchor: [14, 40],
};

export const greenIcon: IconData = {
  iconUrl: mapMarkerGreen,
  iconSize: [14, 20],
  iconAnchor: [7, 20],
};

export const greenIconSelected: IconData = {
  iconUrl: mapMarkerGreenSelected,
  iconSize: [28, 40],
  iconAnchor: [14, 40],
};

export const greenDarkIcon: IconData = {
  iconUrl: mapMarkerGreenDark,
  iconSize: [14, 20],
  iconAnchor: [7, 20],
};

export const greenDarkIconSelected: IconData = {
  iconUrl: mapMarkerGreenDarkSelected,
  iconSize: [28, 40],
  iconAnchor: [14, 40],
};
