import useRoutes from "hooks/useRoutes";
import useUser from "hooks/queries/useUser";
import Rule from "models/authentication/Rule";
import { PropsWithChildren, ReactNode, memo, useMemo } from "react";
import { Navigate } from "react-router-dom";

export enum RulesMode {
  MatchOne = "match-one",
  MatchAll = "match-all",
}

type Props = {
  isPage?: boolean;
  mode: RulesMode;
  rules: Omit<Rule, "id">[];
  children: ReactNode;
};

function AssertRules({
  isPage,
  mode,
  rules,
  children,
}: PropsWithChildren<Props>) {
  const { user } = useUser();

  const routes = useRoutes();

  const isGranted = useMemo(() => {
    const matchedRules =
      user?.roles
        ?.flatMap((role) => role.rules)
        .filter((rule) =>
          rules.find((r) => r.name === rule.name && r.action === rule.action)
        ) ?? [];

    if (mode === RulesMode.MatchAll) {
      return matchedRules.length === rules.length;
    }

    if (mode === RulesMode.MatchOne) {
      return matchedRules.length >= 1;
    }

    return matchedRules.length > 0;
  }, [mode, rules, user?.roles]);

  if (!isGranted && isPage) {
    return <Navigate to={routes.home.resolveRoute()} replace />;
  }

  if (!isGranted) {
    return null;
  }

  return <>{children}</>;
}

export default memo(AssertRules);
