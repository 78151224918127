import { Box, Button } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import * as styles from "./styles";
import SensorDetail from "components/element/SensorDetail";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useMediaQueries from "hooks/useMediaQueries";
import useSensor from "hooks/queries/useSensor";
import SensorPageLinksAndActions from "./SensorPageLinksAndActions";
import { ChevronLeft } from "@mui/icons-material";
import useRoutes from "hooks/useRoutes";
import useCurrentPageType from "hooks/useCurrentPageType";
import useSensorIdParamsSynchronization from "hooks/useSensorIdParamsSynchronization";

function SensorPage() {
  useSensorIdParamsSynchronization();

  const mediaQueries = useMediaQueries();
  const navigate = useNavigate();
  const { sensorId } = useSelectedSensorIdContext();
  const { sensor, isFetched } = useSensor(sensorId);
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();

  useHead({
    title: I18n.translate("pages.sensor.title", {
      sensorId: String(sensor?.identifier ?? ""),
    }),
  });

  useEffect(() => {
    if (!sensor && isFetched) {
      navigate("/404", { replace: true });
    }
  }, [isFetched, navigate, sensor]);

  const goToSensors = useCallback(() => {
    navigate(routes[currentPageType].sensors.resolveRoute());
  }, [currentPageType, navigate, routes]);

  if (!sensor) {
    return null;
  }

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Button
        variant="outlined"
        color="info"
        startIcon={<ChevronLeft />}
        onClick={goToSensors}
        sx={styles.goBackButton}
      >
        {I18n.translate("pages.sensor.go_to_sensors")}
      </Button>
      <SensorDetail sensor={sensor} />
      <SensorPageLinksAndActions />
      <Outlet />
    </Box>
  );
}

export default memo(SensorPage);
