export type HttpError = {
  response: Response;
  body: {
    error: {
      message: string;
      name: string;
      status: number;
      details: unknown;
    };
  };
};

export const isHttpError = (error: any): error is HttpError => {
  return (
    !!error.response &&
    !!error.response.status &&
    !!error.body &&
    !!error.body.error &&
    !!error.body.error.message &&
    !!error.body.error.name &&
    !!error.body.error.status &&
    !!error.body.error.details
  );
};
