import { AlertColor, SxProps, Theme } from "@mui/material";

export const alert: (severity: AlertColor) => SxProps<Theme> =
  (severity) => (theme) => {
    const color =
      severity === "success"
        ? theme.palette.primary.main
        : theme.palette.error.main;
    return {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      color: color,
      borderColor: color,
      "& .MuiAlert-icon": {
        color: color,
      },
      "& .MuiAlert-action": {
        color: theme.palette.grey[500],
      },
    };
  };
