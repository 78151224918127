import { Notifications } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CustomBadge from "components/element/CustomBadge";
import useUserNotifications from "hooks/queries/useUserNotifications";
import useOpenable from "hooks/useOpenable";
import { memo, useCallback } from "react";
import NotificationDrawer from "./NotificationDrawer";

const badgeWidth = 20;
const badgeHeight = 16;

function NotificationsButton() {
  const { notifications } = useUserNotifications();
  const notificationDrawer = useOpenable();
  const handleNotificationClick = useCallback(() => {
    notificationDrawer.open();
  }, [notificationDrawer]);

  return (
    <>
      <IconButton onClick={handleNotificationClick}>
        <CustomBadge
          badgeContent={notifications.length}
          color="warning"
          badgeWidth={badgeWidth}
          badgeHeight={badgeHeight}
        >
          <Notifications />
        </CustomBadge>
      </IconButton>

      <NotificationDrawer
        isOpen={notificationDrawer.isOpen}
        close={notificationDrawer.close}
      />
    </>
  );
}

export default memo(NotificationsButton);
