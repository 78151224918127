import { Box } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import * as styles from "./styles";
import MetadataForm, { MetadataUpdates } from "./MetadataForm";
import SituationForm, { SituationUpdates } from "./SituationForm";
import I18n from "components/materials/I18n";
import useSnackBarContext from "hooks/useSnackBarContext";
import useMediaQueries from "hooks/useMediaQueries";
import useMetadataValidation from "./MetadataForm/useMetadataValidation";
import useSituationValidation from "./SituationForm/useSituationValidation";
import assert from "assert";
import useSensor from "hooks/queries/useSensor";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import { isHttpError } from "utils/HttpErrorUtils";
import { useNavigate } from "react-router-dom";
import useRoutes from "hooks/useRoutes";
import { LoadingButton } from "@mui/lab";
import { container } from "tsyringe";
import SensorService from "services/SensorService";
import Sensor from "models/sensor/Sensor";
import SensorPictureUtils from "utils/SensorPictureUtils";

export type SensorEditionUpdates = {
  metadata: MetadataUpdates;
  situation: SituationUpdates;
};

const initialUpdates: SensorEditionUpdates = {
  metadata: {
    image: null,
    identifier: "",
    type: "",
    name: "",
    blockchainAddress: "",
    blockchainActivationDate: null,
  },
  situation: {
    heightAboveGround: "",
    heightAboveSea: "",
    latitude: "",
    longitude: "",
    location: "",
    installationDate: null,
    activationDate: null,
    deactivationDate: null,
    useCase: "",
  },
};

function EditionForm() {
  const mediaQueries = useMediaQueries();
  const navigate = useNavigate();
  const routes = useRoutes();
  const [isLoadingSensorImageUpdate, setIsLoadingSensorImageUpdate] =
    useState(false);
  const { sensorId } = useSelectedSensorIdContext();
  const { sensor, updateSensorQuery } = useSensor(sensorId);

  const sensorRef = useRef<Sensor | null>(null);

  const [sensorUpdates, setSensorUpdates] =
    useState<SensorEditionUpdates>(initialUpdates);

  const handleMetadataChange = useCallback(
    (metadata: SensorEditionUpdates["metadata"]) => {
      setSensorUpdates((prev) => ({
        ...prev,
        metadata,
      }));
    },
    []
  );

  const handleSituationChange = useCallback(
    (situation: SensorEditionUpdates["situation"]) => {
      setSensorUpdates((prev) => ({
        ...prev,
        situation,
      }));
    },
    []
  );

  const { errors: metadataFormErrors, validate: validateMetadataUpdates } =
    useMetadataValidation();

  const { errors: situationFormErrors, validate: validateSituationUpdates } =
    useSituationValidation();

  const snackBar = useSnackBarContext();
  const handleSave = useCallback(async () => {
    const {
      hasErrors: hasMetadataErrors,
      validatedData: validatedMetadataUpdates,
    } = validateMetadataUpdates(sensorUpdates.metadata);

    const {
      hasErrors: hasSituationErrors,
      validatedData: validatedSituationUpdates,
    } = validateSituationUpdates(sensorUpdates.situation);

    if (hasMetadataErrors || hasSituationErrors) return;
    assert(
      validatedMetadataUpdates && validatedSituationUpdates,
      "Invalid data for sensor edition."
    );

    assert(
      sensorRef.current?.mostRecentSituation?.id,
      "Sensor should have a most recent situation."
    );

    try {
      const { image, ...validatedMetadataUpdatesWithoutImage } =
        validatedMetadataUpdates;
      // If validatedMetadataUpdates.image is undefined, it means that the user didn't change the image
      if (image !== undefined) {
        setIsLoadingSensorImageUpdate(true);
        await container.resolve(SensorService).updateImage({
          sensorId: sensorRef.current.id,
          image,
        });
      }

      await updateSensorQuery.mutateAsync({
        metadata: validatedMetadataUpdatesWithoutImage,
        situation: validatedSituationUpdates,
        situationId: sensorRef.current.mostRecentSituation.id,
      });
      snackBar.open({
        severity: "success",
        message: I18n.translate("pages.sensor_edition.alert.update.success"),
      });
      navigate(routes.laboratory.sensor.resolveRoute({ sensorId }));
    } catch (error: unknown) {
      if (isHttpError(error) && error.response.status === 409) {
        const reason = error.body.error.message;

        if (reason.includes("identifier")) {
          snackBar.open({
            severity: "error",
            message: I18n.translate(
              "pages.sensor_edition.alert.update.conflict_identifier_error"
            ),
          });
          return;
        }

        if (reason.includes("blockchainAddress")) {
          snackBar.open({
            severity: "error",
            message: I18n.translate(
              "pages.sensor_edition.alert.update.conflict_blockchain_address_error"
            ),
          });
          return;
        }
      }

      snackBar.open({
        severity: "error",
        message: I18n.translate("pages.sensor_edition.alert.update.error"),
      });
    } finally {
      setIsLoadingSensorImageUpdate(false);
    }
  }, [
    validateMetadataUpdates,
    sensorUpdates.metadata,
    sensorUpdates.situation,
    validateSituationUpdates,
    updateSensorQuery,
    snackBar,
    navigate,
    routes.laboratory.sensor,
    sensorId,
  ]);

  useEffect(() => {
    if (sensorRef.current === null) {
      sensorRef.current = sensor;
      setSensorUpdates(initUpdates(sensorRef.current));
    }
  }, [sensor]);

  // useEffect(() => {
  //   setSensorUpdates((prev) => ({
  //     ...prev,
  //     metadata: {
  //       ...prev.metadata,
  //       image: imageUrl,
  //     },
  //   }));
  // }, [imageUrl]);

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Box sx={styles.mainContent}>
        <Box sx={styles.formsContainer}>
          <MetadataForm
            metadata={sensorUpdates.metadata}
            onMetadataChange={handleMetadataChange}
            errors={metadataFormErrors}
          />

          <SituationForm
            situation={sensorUpdates.situation}
            onSituationChange={handleSituationChange}
            errors={situationFormErrors}
          />

          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                updateSensorQuery.isLoading || isLoadingSensorImageUpdate
              }
              loading={
                updateSensorQuery.isLoading || isLoadingSensorImageUpdate
              }
            >
              {I18n.translate("general_text.save")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(EditionForm);

function initUpdates(sensor: Sensor | null) {
  if (!sensor) {
    return initialUpdates;
  }

  const metadata = {
    image: SensorPictureUtils.getPicture(sensor),
    identifier: sensor.identifier,
    type: sensor.type,
    name: sensor.name,
    blockchainAddress: sensor.blockchainAddress,
    blockchainActivationDate: sensor.blockchainActivationDate,
  };

  const { mostRecentSituation } = sensor;
  const situation = {
    heightAboveGround: String(mostRecentSituation?.heightAboveGround ?? ""),
    heightAboveSea: String(mostRecentSituation?.heightAboveSea ?? ""),
    latitude: String(mostRecentSituation?.latitude ?? ""),
    longitude: String(mostRecentSituation?.longitude ?? ""),
    location: mostRecentSituation?.location ?? "",
    installationDate: mostRecentSituation?.installationDate ?? null,
    activationDate: mostRecentSituation?.activationDate ?? null,
    deactivationDate: mostRecentSituation?.deactivationDate ?? null,
    useCase: mostRecentSituation?.useCase ?? "",
  };

  return {
    metadata,
    situation,
  };
}
