import { memo, useCallback, useEffect, useMemo, useState } from "react";
import SensorDataUtils from "utils/SensorResultUtils";
import CustomAutocompleteSingle, {
  IOption,
} from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import Sensor from "models/sensor/Sensor";
import { Box, Typography } from "@mui/material";
import * as styles from "./styles";

type Props = {
  sensors: Sensor[];
  onChange?: (sensorId: ModelId | null) => unknown;
  value?: ModelId | null;
  errors?: string[];
  label?: string;
};

function SensorsInputSingle({
  label,
  sensors,
  onChange,
  value,
  errors,
}: Props) {
  const [sensorId, setSensorId] = useState<ModelId | null>(value ?? null);

  const options: IOption[] = useMemo(
    () =>
      sensors
        .map((sensor) => {
          return {
            id: sensor.id,
            label: SensorDataUtils.formatDisplayName(sensor),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [sensors]
  );

  const handleSensorSelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: IOption | null) => {
      const sensorId = value?.id ?? null;
      setSensorId(sensorId);
      onChange?.(sensorId);
    },
    [onChange, setSensorId]
  );

  useEffect(() => {
    setSensorId(value ?? null);
  }, [value]);

  return (
    <Box sx={styles.root}>
      {label && <Typography variant="body1">{label}</Typography>}

      <CustomAutocompleteSingle
        options={options}
        errors={errors}
        onChange={handleSensorSelection}
        placeholder={I18n.translate("general_text.search_sensor")}
        value={options.find((option) => option.id === sensorId)}
      />
    </Box>
  );
}

export default memo(SensorsInputSingle);
