import { ModelId } from "../common/Model";
import Sensor from "models/sensor/Sensor";
import { LaboratoryDto } from "services/apis/ceaMoonshot/dtos/laboratory/LaboratoryDto";

export default class Laboratory {
  public readonly id: ModelId;
  public readonly name: string;
  public readonly blockchainAddress: string;
  public readonly sensors?: Sensor[];

  private constructor(params: {
    id: ModelId;
    name: string;
    blockchainAddress: string;
    sensors?: Sensor[];
  }) {
    this.id = params.id;
    this.name = params.name;
    this.blockchainAddress = params.blockchainAddress;
    this.sensors = params.sensors;
  }

  public static fromDto(dto: LaboratoryDto): Laboratory {
    return new Laboratory({
      id: dto.id,
      name: dto.name,
      blockchainAddress: dto.blockchainAddress,
      sensors: dto.sensors
        ? dto.sensors.map((sensor) => Sensor.fromDto(sensor))
        : [],
    });
  }
}
