import { Add, Settings } from "@mui/icons-material";
import Action from "../Action";
import I18n from "components/materials/I18n";
import { memo, useCallback } from "react";
import useOpenable from "hooks/useOpenable";
import NewAuditDialog from "components/materials/dialogs/NewAuditDialog";
import { Box } from "@mui/material";
import * as styles from "./styles";
import useRoutes from "hooks/useRoutes";
import { useNavigate } from "react-router-dom";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensor from "hooks/queries/useSensor";
import useSensorAuditsRuleForUser from "hooks/queries/useUserAuditsRules";

function AuditsActions() {
  const newAuditDialog = useOpenable();
  const navigate = useNavigate();
  const routes = useRoutes();
  const { sensorId } = useSelectedSensorIdContext();
  const { isUserLaboratorySensor } = useSensor(sensorId);

  const isUserLaboratoryPage =
    isUserLaboratorySensor && routes.laboratory.sensor.sensorAudits.isCurrent;

  const { isUserAuthorizedToAudit } = useSensorAuditsRuleForUser(sensorId);

  const handleManageRuleClick = useCallback(() => {
    navigate(
      routes.laboratory.sensor.sensorAuditsRule.resolveRoute({ sensorId })
    );
  }, [navigate, routes.laboratory.sensor.sensorAuditsRule, sensorId]);

  return (
    <>
      <Box sx={styles.root}>
        {isUserLaboratoryPage && (
          <Action
            icon={<Settings />}
            label={I18n.translate(
              "components.sensor_page_links_and_actions.actions.manage_rule"
            )}
            onClick={handleManageRuleClick}
            variant="outlined"
            color="info"
          />
        )}

        {isUserAuthorizedToAudit && (
          <Action
            icon={<Add />}
            label={I18n.translate(
              "components.sensor_page_links_and_actions.actions.new_audit"
            )}
            onClick={newAuditDialog.open}
          />
        )}
      </Box>

      {newAuditDialog.isOpen && (
        <NewAuditDialog
          open={newAuditDialog.isOpen}
          onClose={newAuditDialog.close}
        />
      )}
    </>
  );
}

export default memo(AuditsActions);
