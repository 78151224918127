import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import { useMemo } from "react";
import useUser from "hooks/queries/useUser";
import assert from "assert";
import { currentlyActiveSensorsQueryKey } from "./useCurrentlyActiveSensors";
import { sensorsQueryKey } from "./useSensors";

function sensorQueryKey(
  id: ModelId | null,
  options?: { withResultsSessions?: boolean }
) {
  return options ? ["sensors", id, options] : ["sensors", id];
}

const staleTimeInMs = 1000 * 60 * 5;

function useSensor(
  id: ModelId | null,
  options?: { withResultsSessions?: boolean; withRawSessions?: boolean }
) {
  const sensorIdValidation = useIdValidation(id);
  const queryClient = useQueryClient();

  const sensorQuery = useQuery(
    sensorQueryKey(id, options),
    async () =>
      sensorIdValidation.success
        ? container
            .resolve(SensorService)
            .getById(sensorIdValidation.data, options)
        : null,
    {
      enabled: sensorIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  const updateSensorQuery = useMutation(
    async (
      params: {
        situationId: ModelId;
      } & Partial<{
        metadata: {
          type: string;
          name: string;
          identifier: string;
          blockchainAddress: string;
          blockchainActivationDate: Date;
        };
        situation: {
          heightAboveGround: number | null;
          heightAboveSea: number | null;
          latitude: number;
          longitude: number;
          location: string;
          installationDate: Date;
          activationDate: Date | null;
          deactivationDate: Date | null;
          useCase: string | null;
        };
      }>
    ) => {
      return sensorIdValidation.success
        ? await container.resolve(SensorService).updateSensor({
            sensorId: sensorIdValidation.data,
            ...params,
          })
        : null;
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async (sensor) => {
        await queryClient.invalidateQueries(currentlyActiveSensorsQueryKey);
        await queryClient.invalidateQueries(sensorsQueryKey());
        assert(sensor, "Sensor should not be null as the query is successful");
        await queryClient.invalidateQueries(sensorQueryKey(sensor.id, options));
      },
    }
  );

  const sensor = sensorQuery.data ?? null;

  const { userLaboratoryId } = useUser();
  const isUserLaboratorySensor = useMemo(() => {
    return !!userLaboratoryId && userLaboratoryId === sensor?.laboratory?.id;
  }, [userLaboratoryId, sensor?.laboratory?.id]);

  return {
    ...sensorQuery,
    sensor,
    isUserLaboratorySensor,
    updateSensorQuery,
  };
}

export default useSensor;
