import User from "models/user/User";
import { ModelId } from "../common/Model";
import { WhitelistDto } from "services/apis/ceaMoonshot/dtos/laboratory/WhitelistDto";
import Laboratory from "./Laboratory";
import SensorAuditsRule from "models/sensor/SensorAuditsRule";

export default class Whitelist {
  public readonly id: ModelId;
  public readonly name: string;
  public readonly laboratory?: Laboratory | null;
  public readonly creator?: User | null;
  public readonly whitelistedUsers?: User[];
  public readonly whitelistedLaboratories?: Laboratory[];
  public readonly sensorAuditsRules?: SensorAuditsRule[];
  public readonly totalUsers?: number;

  private constructor(params: {
    id: ModelId;
    name: string;
    laboratory?: Laboratory | null;
    creator?: User | null;
    whitelistedUsers?: User[];
    whitelistedLaboratories?: Laboratory[];
    sensorAuditsRules?: SensorAuditsRule[];
    totalUsers?: number;
  }) {
    this.id = params.id;
    this.name = params.name;
    this.laboratory = params.laboratory;
    this.creator = params.creator;
    this.whitelistedUsers = params.whitelistedUsers;
    this.whitelistedLaboratories = params.whitelistedLaboratories;
    this.sensorAuditsRules = params.sensorAuditsRules;
    this.totalUsers = params.totalUsers;
  }

  public static fromDto(dto: WhitelistDto): Whitelist {
    return new Whitelist({
      id: dto.id,
      name: dto.name,
      laboratory: dto.laboratory
        ? Laboratory.fromDto(dto.laboratory)
        : dto.laboratory,
      creator: dto.creator ? User.fromDto(dto.creator) : dto.creator,
      whitelistedUsers: dto.whitelistedUsers
        ? dto.whitelistedUsers.map((user) => User.fromDto(user))
        : [],
      whitelistedLaboratories: dto.whitelistedLaboratories?.map((laboratory) =>
        Laboratory.fromDto(laboratory)
      ),
      sensorAuditsRules: dto.sensorAuditsRules
        ? dto.sensorAuditsRules.map((rule) => SensorAuditsRule.fromDto(rule))
        : [],
      totalUsers: dto.totalUsers,
    });
  }
}
