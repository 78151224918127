export enum RuleName {
  Users = "users",
  Sensors = "sensors",
  Widgets = "widgets",
  Audits = "audits",
  Whitelists = "whitelists",
  AuditsRules = "audits-rules",
  Notifications = "notifications",
  Laboratories = "laboratories",
}
