import { useCallback, useState } from "react";
import { SituationUpdates } from ".";
import I18n from "components/materials/I18n";

export type SituationFormErrors = Record<
  keyof SituationUpdates | "extra",
  string[]
>;

const initialErrors: SituationFormErrors = {
  heightAboveGround: [],
  heightAboveSea: [],
  latitude: [],
  longitude: [],
  location: [],
  installationDate: [],
  activationDate: [],
  deactivationDate: [],
  useCase: [],
  extra: [],
};

function useSituationValidation() {
  const [errors, setErrors] = useState(initialErrors);

  const validate: (situation: SituationUpdates) => {
    hasErrors: boolean;
    validatedData: {
      heightAboveGround: number | null;
      heightAboveSea: number | null;
      latitude: number;
      longitude: number;
      location: string;
      installationDate: Date;
      activationDate: Date | null;
      deactivationDate: Date | null;
      useCase: string | null;
    } | null;
  } = useCallback((situation) => {
    let errors = initialErrors;

    if (situation.location === "") {
      errors = {
        ...errors,
        location: [
          ...errors.location,
          I18n.translate(
            "components.sensor_situation_form.location.errors.empty"
          ),
        ],
      };
    }

    if (situation.latitude === "") {
      errors = {
        ...errors,
        latitude: [
          ...errors.latitude,
          I18n.translate(
            "components.sensor_situation_form.latitude.errors.empty"
          ),
        ],
      };
    }

    if (situation.latitude !== "" && isNaN(Number(situation.latitude))) {
      errors = {
        ...errors,
        latitude: [
          ...errors.latitude,
          I18n.translate("general_text.form_errors.invalid_number"),
        ],
      };
    }

    if (situation.longitude === "") {
      errors = {
        ...errors,
        longitude: [
          ...errors.longitude,
          I18n.translate(
            "components.sensor_situation_form.longitude.errors.empty"
          ),
        ],
      };
    }

    if (situation.longitude !== "" && isNaN(Number(situation.longitude))) {
      errors = {
        ...errors,
        longitude: [
          ...errors.longitude,
          I18n.translate("general_text.form_errors.invalid_number"),
        ],
      };
    }

    if (situation.installationDate === null) {
      errors = {
        ...errors,
        installationDate: [
          ...errors.installationDate,
          I18n.translate(
            "components.sensor_situation_form.installation_date.errors.empty"
          ),
        ],
      };
    }

    if (
      situation.installationDate !== null &&
      situation.installationDate > new Date()
    ) {
      errors = {
        ...errors,
        installationDate: [
          ...errors.installationDate,
          I18n.translate(
            "components.sensor_situation_form.installation_date.errors.date_in_future"
          ),
        ],
      };
    }

    if (
      situation.activationDate !== null &&
      situation.installationDate !== null &&
      situation.activationDate < situation.installationDate
    ) {
      errors = {
        ...errors,
        activationDate: [
          ...errors.activationDate,
          I18n.translate(
            "components.sensor_situation_form.activation_date.errors.date_before_installation"
          ),
        ],
      };
    }

    if (
      situation.deactivationDate !== null &&
      situation.activationDate !== null &&
      situation.deactivationDate < situation.activationDate
    ) {
      errors = {
        ...errors,
        deactivationDate: [
          ...errors.deactivationDate,
          I18n.translate(
            "components.sensor_situation_form.deactivation_date.errors.date_before_activation"
          ),
        ],
      };
    }

    setErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error.length);
    return {
      hasErrors,
      validatedData: !hasErrors
        ? {
            heightAboveGround: situation.heightAboveGround
              ? Number(situation.heightAboveGround)
              : null,
            heightAboveSea: situation.heightAboveSea
              ? Number(situation.heightAboveSea)
              : null,
            latitude: Number(situation.latitude),
            longitude: Number(situation.longitude),
            location: situation.location,
            installationDate: situation.installationDate!,
            activationDate: situation.activationDate,
            deactivationDate: situation.deactivationDate,
            useCase: situation.useCase || null,
          }
        : null,
    };
  }, []);

  return {
    errors,
    validate,
  };
}

export default useSituationValidation;
