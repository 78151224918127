import { Cloud, Computer } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import I18n from "components/materials/I18n";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorAudits from "hooks/queries/useSensorAudits";
import { SensorAuditExecutionMode } from "models/sensor/SensorAuditExecutionMode";
import AuditTypeCard from "./AuditTypeCard";
import * as styles from "./styles";
import DateUtils from "utils/DateUtils";
import LocalNoticeDialog from "../LocalNoticeDialog";
import useOpenable from "hooks/useOpenable";
import useSnackBarContext from "hooks/useSnackBarContext";
import useCurrentPageType from "hooks/useCurrentPageType";
import useRoutes from "hooks/useRoutes";
import { useNavigate } from "react-router-dom";
import useFetchedApplicationConfig from "hooks/useFetchedApplicationConfig";
import CustomDatePicker from "components/element/inputs/CustomDatePicker";
import { DayJsFormat } from "utils/DayjsUtils";

type Props = {
  open: boolean;
  onClose: () => void;
  prefill?: {
    date: Date;
  };
};

function NewAuditDialog({ open, onClose, prefill }: Readonly<Props>) {
  const { applicationConfig } = useFetchedApplicationConfig();
  const { sensorId } = useSelectedSensorIdContext();
  const { auditSensorQuery } = useSensorAudits(sensorId ?? null);

  const [date, setDate] = useState<Date | null>(prefill?.date ?? null);
  const [auditExecutionMode, setAuditExecutionMode] =
    useState<SensorAuditExecutionMode | null>(null);

  const [downloadParams, setDownloadParams] = useState<{
    date: Date;
    executionMode: SensorAuditExecutionMode;
    sensorId: number;
  } | null>(null);

  const localNoticeOpenable = useOpenable();

  const handleRemoteAuditExecutionModeClick = useCallback(() => {
    setAuditExecutionMode(SensorAuditExecutionMode.Remote);
  }, []);

  const handleLocalAuditExecutionModeClick = useCallback(() => {
    setAuditExecutionMode(SensorAuditExecutionMode.Local);
  }, []);

  const handleDateChange = useCallback((date: Date | null) => {
    setDate(date);
  }, []);

  const { currentPageType } = useCurrentPageType();
  const routes = useRoutes();
  const navigate = useNavigate();

  const snackBar = useSnackBarContext();
  const submit = useCallback(async () => {
    if (!auditExecutionMode || !date || !sensorId) return;
    const params = {
      date: DateUtils.forceUTCDate(new Date(date)),
      executionMode: auditExecutionMode,
    };

    if (auditExecutionMode === SensorAuditExecutionMode.Local) {
      setDownloadParams({
        ...params,
        sensorId,
      });
      localNoticeOpenable.open();
    }

    if (auditExecutionMode === SensorAuditExecutionMode.Remote) {
      try {
        await auditSensorQuery.mutateAsync({
          ...params,
          fromDate: date,
          toDate: date,
        });
        snackBar.open({
          severity: "success",
          message: I18n.translate(
            "components.dialogs.new_audit.online.alert.audit.success"
          ),
        });
        if (routes[currentPageType].sensor.sensorTransactions.isCurrent) {
          navigate(
            routes[currentPageType].sensor.sensorAudits.resolveRoute({
              sensorId,
            })
          );
        }
        onClose();
      } catch {
        snackBar.open({
          severity: "error",
          message: I18n.translate(
            "components.dialogs.new_audit.online.alert.audit.error"
          ),
        });
      }
    }
  }, [
    auditExecutionMode,
    auditSensorQuery,
    currentPageType,
    date,
    localNoticeOpenable,
    navigate,
    onClose,
    routes,
    sensorId,
    snackBar,
  ]);

  const handleCloseNoticeDialog = useCallback(() => {
    localNoticeOpenable.close();
    onClose();
  }, [localNoticeOpenable, onClose]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={styles.root({ isVisible: !localNoticeOpenable.isOpen })}
      >
        <DialogTitle>
          <I18n map={"components.dialogs.new_audit.title"} />
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Box sx={styles.columnContainer}>
            <Typography variant="subtitle1">
              {I18n.translate("general_text.date")}
            </Typography>
            <CustomDatePicker
              format={DayJsFormat.DayMonthYear}
              date={date}
              onDateChange={handleDateChange}
              minDate={applicationConfig.auditionMinDate ?? undefined}
              maxDate={applicationConfig.auditionMaxDate ?? undefined}
              withTime={false}
            />
          </Box>
          <Box sx={styles.columnContainer}>
            <Typography variant="subtitle1">
              {I18n.translate("components.dialogs.new_audit.audit_type")}
            </Typography>
            <Box sx={styles.rowContainer}>
              <AuditTypeCard
                title={I18n.translate(
                  "components.dialogs.new_audit.online.title"
                )}
                text={I18n.translate(
                  "components.dialogs.new_audit.online.text"
                )}
                icon={<Cloud color="action" sx={{ width: 32, height: 32 }} />}
                onClick={handleRemoteAuditExecutionModeClick}
                active={auditExecutionMode === SensorAuditExecutionMode.Remote}
              />
              <AuditTypeCard
                title={I18n.translate(
                  "components.dialogs.new_audit.local.title"
                )}
                text={I18n.translate("components.dialogs.new_audit.local.text")}
                icon={
                  <Computer color="action" sx={{ width: 32, height: 32 }} />
                }
                onClick={handleLocalAuditExecutionModeClick}
                active={auditExecutionMode === SensorAuditExecutionMode.Local}
              />
            </Box>
          </Box>
          <Box sx={styles.buttonContainer}>
            <Button variant="outlined" color="info" onClick={onClose}>
              {I18n.translate("general_text.close")}
            </Button>

            <LoadingButton
              loading={auditSensorQuery.isLoading}
              variant="contained"
              color="primary"
              disabled={!auditExecutionMode || !date}
              onClick={submit}
            >
              {I18n.translate("components.dialogs.new_audit.launch_audit")}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>

      {downloadParams && (
        <LocalNoticeDialog
          isOpen={localNoticeOpenable.isOpen}
          onClose={handleCloseNoticeDialog}
          params={downloadParams}
        />
      )}
    </>
  );
}

export default memo(NewAuditDialog);
