import Role from "models/authentication/Role";
import { RoleName } from "models/authentication/RoleName";
import { ModelId } from "models/common/Model";
import Laboratory from "models/laboratory/Laboratory";
import { UserDto } from "services/apis/ceaMoonshot/dtos/user/UserDto";

export default class User {
  private constructor(
    public id: ModelId,
    public email: string,
    public roles?: Role[],
    public laboratory?: Laboratory | null
  ) {}

  public static fromDto(dto: UserDto): User {
    return new User(
      dto.id,
      dto.email,
      dto.roles?.map(Role.fromDto),
      dto.laboratory ? Laboratory.fromDto(dto.laboratory) : dto.laboratory
    );
  }

  public isLabUser(): boolean {
    return !!this.roles?.some((role) => role.name === RoleName.LabUser);
  }

  public isExternalUser(): boolean {
    return !!this.roles?.some((role) => role.name === RoleName.ExternalUser);
  }
}
