import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useOpenable from "hooks/useOpenable";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import useMediaQueries from "hooks/useMediaQueries";
import AddWhitelistDialog from "components/materials/dialogs/whitelistManagementDialogs/AddWhitelistDialog";

function Actions() {
  const mediaQueries = useMediaQueries();

  const addWhitelistDialog = useOpenable();

  const handleAddClick = useCallback(() => {
    addWhitelistDialog.open();
  }, [addWhitelistDialog]);

  return (
    <>
      <Box sx={styles.root(mediaQueries)}>
        <Button
          variant="outlined"
          startIcon={<Add />}
          color="info"
          onClick={handleAddClick}
        >
          <I18n map="components.whitelists_manager.actions.add_whitelist" />
        </Button>
      </Box>

      <AddWhitelistDialog
        close={addWhitelistDialog.close}
        isOpen={addWhitelistDialog.isOpen}
      />
    </>
  );
}

export default memo(Actions);
