import { memo } from "react";

type Props = {
  textList: string[];
};

function HelperText({ textList }: Props) {
  return (
    <>
      {textList.map((text) => (
        <span key={text}>
          <span>{text}</span>
          <br />
        </span>
      ))}
    </>
  );
}

export default memo(HelperText);
