import ModuleConfig from "configs/ModuleConfig";
import { Route } from "models/common/Route";
import { useCallback } from "react";
import { useMatch } from "react-router-dom";
import { container } from "tsyringe";

const rootPath = container.resolve(ModuleConfig).get().modules.pages.Root
  .props.path;

function fromRoot(path: string) {
  return rootPath.concat(path);
}

export const routerPaths = {
  root: {
    absolutePath: rootPath,
  },
  home: {
    absolutePath: fromRoot(
      [
        container.resolve(ModuleConfig).get().modules.pages.Home.props.path,
      ].join("/")
    ),
    relativePath: container.resolve(ModuleConfig).get().modules.pages.Home.props
      .path,
  },
  externalUser: {
    absolutePath: fromRoot(
      [
        container.resolve(ModuleConfig).get().modules.pages.ExternalUser.props
          .path,
      ].join("/")
    ),
    relativePath: container.resolve(ModuleConfig).get().modules.pages
      .ExternalUser.props.path,
    sensors: {
      absolutePath: fromRoot(
        [
          container.resolve(ModuleConfig).get().modules.pages.ExternalUser.props
            .path,
          container.resolve(ModuleConfig).get().modules.pages.ExternalUser.props
            .subPages.Sensors.props.path,
        ].join("/")
      ),
      relativePath: container.resolve(ModuleConfig).get().modules.pages
        .ExternalUser.props.subPages.Sensors.props.path,
    },
    sensor: {
      ...{
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .ExternalUser.props.subPages.Sensor.props.path,
      },
      sensorData: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.subPages.Data.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .ExternalUser.props.subPages.Sensor.props.subPages.Data.props.path,
      },
      sensorAudits: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.subPages.Audits.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .ExternalUser.props.subPages.Sensor.props.subPages.Audits.props.path,
      },
      sensorTransactions: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.subPages.Transactions.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .ExternalUser.props.subPages.Sensor.props.subPages.Transactions.props
          .path,
      },
      sensorAudit: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.ExternalUser
              .props.subPages.Sensor.props.subPages.Audit.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .ExternalUser.props.subPages.Sensor.props.subPages.Audit.props.path,
      },
    },
  },
  laboratory: {
    absolutePath: fromRoot(
      [
        container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
          .path,
      ].join("/")
    ),
    relativePath: container.resolve(ModuleConfig).get().modules.pages.Laboratory
      .props.path,
    sensors: {
      absolutePath: fromRoot(
        [
          container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
            .path,
          container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
            .subPages.Sensors.props.path,
        ].join("/")
      ),
      relativePath: container.resolve(ModuleConfig).get().modules.pages
        .Laboratory.props.subPages.Sensors.props.path,
    },
    sensorCreation: {
      absolutePath: fromRoot(
        [
          container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
            .path,
          container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
            .subPages.SensorCreation.props.path,
        ].join("/")
      ),
      relativePath: container.resolve(ModuleConfig).get().modules.pages
        .Laboratory.props.subPages.SensorCreation.props.path,
    },
    sensor: {
      ...{
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.path,
      },
      sensorData: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.Data.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.Data.props.path,
      },
      sensorEdition: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.Edition.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.Edition.props.path,
      },
      sensorAudits: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.Audits.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.Audits.props.path,
      },
      sensorTransactions: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.Transactions.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.Transactions.props
          .path,
      },
      sensorAuditsRule: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.AuditsRule.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.AuditsRule.props
          .path,
      },
      sensorAudit: {
        absolutePath: fromRoot(
          [
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.path,
            container.resolve(ModuleConfig).get().modules.pages.Laboratory.props
              .subPages.Sensor.props.subPages.Audit.props.path,
          ].join("/")
        ),
        relativePath: container.resolve(ModuleConfig).get().modules.pages
          .Laboratory.props.subPages.Sensor.props.subPages.Audit.props.path,
      },
    },
  },
};

function useRoutes() {
  return {
    home: useRoute({
      absolutePath: routerPaths.home.absolutePath,
      strictMatcher: true,
      hasView: true,
    }),
    externalUser: {
      ...useRoute({
        absolutePath: routerPaths.externalUser.absolutePath,
        strictMatcher: false,
        hasView: false,
      }),
      sensors: useRoute({
        absolutePath: routerPaths.externalUser.sensors.absolutePath,
        strictMatcher: true,
        hasView: true,
      }),
      sensor: {
        ...useRoute({
          absolutePath: routerPaths.externalUser.sensor.absolutePath,
          strictMatcher: false,
          hasView: false,
        }),
        sensorData: useRoute({
          absolutePath: routerPaths.externalUser.sensor.sensorData.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorAudits: useRoute({
          absolutePath:
            routerPaths.externalUser.sensor.sensorAudits.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorTransactions: useRoute({
          absolutePath:
            routerPaths.externalUser.sensor.sensorTransactions.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorAudit: useRoute({
          absolutePath:
            routerPaths.externalUser.sensor.sensorAudit.absolutePath,
          strictMatcher: false,
          hasView: true,
        }),
      },
    },
    laboratory: {
      ...useRoute({
        absolutePath: routerPaths.laboratory.absolutePath,
        strictMatcher: false,
        hasView: false,
      }),
      sensors: useRoute({
        absolutePath: routerPaths.laboratory.sensors.absolutePath,
        strictMatcher: true,
        hasView: true,
      }),
      sensorCreation: useRoute({
        absolutePath: routerPaths.laboratory.sensorCreation.absolutePath,
        strictMatcher: true,
        hasView: true,
      }),
      sensor: {
        ...useRoute({
          absolutePath: routerPaths.laboratory.sensor.absolutePath,
          strictMatcher: false,
          hasView: false,
        }),
        sensorData: useRoute({
          absolutePath: routerPaths.laboratory.sensor.sensorData.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorAudits: useRoute({
          absolutePath: routerPaths.laboratory.sensor.sensorAudits.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorTransactions: useRoute({
          absolutePath:
            routerPaths.laboratory.sensor.sensorTransactions.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorAuditsRule: useRoute({
          absolutePath:
            routerPaths.laboratory.sensor.sensorAuditsRule.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
        sensorAudit: useRoute({
          absolutePath: routerPaths.laboratory.sensor.sensorAudit.absolutePath,
          strictMatcher: false,
          hasView: true,
        }),
        sensorEdition: useRoute({
          absolutePath:
            routerPaths.laboratory.sensor.sensorEdition.absolutePath,
          strictMatcher: true,
          hasView: true,
        }),
      },
    },
  };
}

function useRoute({
  absolutePath,
  strictMatcher,
  hasView,
}: {
  absolutePath: string;
  strictMatcher: boolean;
  hasView: boolean;
}): Route {
  const resolveRoute = useCallback(
    (slugs?: Record<string, unknown>) => {
      if (slugs) {
        return Object.entries(slugs).reduce(
          (acc, [slug, value]) => acc.replace(`:${slug}`, String(value)),
          absolutePath
        );
      }

      if (!slugs && !absolutePath.includes(":")) {
        return absolutePath;
      }

      throw new Error(
        "You must provide slugs to resolve route with slugs or route must contain no slugs"
      );
    },
    [absolutePath]
  );
  const matcher = useMatch({
    path: absolutePath,
    end: strictMatcher,
  });

  const isCurrent = !!matcher;

  return {
    absolutePath,
    resolveRoute,
    isCurrent,
    hasView,
  };
}

export default useRoutes;
