import { ModelId } from "models/common/Model";
import { SensorMapWidget } from "models/widgets/Widget";
import { useMemo } from "react";
import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import { staleTimeInMs, widgetQueryKey, getRefetchInterval } from "./common";
import { ArrayUtils } from "utils/ArrayUtils";

function useSensorMapData(
  widgetId: ModelId,
  properties: SensorMapWidget["properties"]
) {
  const query = useQuery(
    widgetQueryKey({ widgetId, filter: properties }),
    async () =>
      container.resolve(SensorService).getActiveSensorsAtDate({
        isActiveAtDate: properties.isActiveAtDate,
        sensorIds: ArrayUtils.removeDuplicates([
          ...properties.sensorIds,
          properties.targetSensorId,
        ]),
      }),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: getRefetchInterval(properties),
    }
  );

  const sensors = useMemo(() => query.data ?? [], [query.data]);

  const targetSensor = useMemo(
    () =>
      sensors.find((sensor) => sensor.id === properties.targetSensorId) ?? null,
    [sensors, properties.targetSensorId]
  );

  return {
    ...query,
    targetSensor,
    sensors,
  };
}

export default useSensorMapData;
