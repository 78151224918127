import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { CSSProperties, memo, useCallback, useMemo } from "react";
import CopyToClipboard from "components/element/CopyToClipboard";
import I18n from "components/materials/I18n";
import Sensor from "models/sensor/Sensor";
import * as styles from "./styles";
import SensorDataUtils from "utils/SensorResultUtils";
import BlockchainAddressUtils from "utils/BlockchainAddressUtils";
import SensorPicture from "components/materials/SensorPicture";
import { Apartment, Edit } from "@mui/icons-material";
import useRoutes from "hooks/useRoutes";
import { useNavigate } from "react-router";
import useMediaQueries from "hooks/useMediaQueries";
import DateUtils, { DateFormat } from "utils/DateUtils";
import CoordinateUtils from "utils/CoordinateUtils";
import useUser from "hooks/queries/useUser";

type Props = {
  sensor: Sensor;
};

function SensorDetailXxsContent({ sensor }: Props) {
  const theme = useTheme();
  const copyToClipboardStyle: CSSProperties = useMemo(
    () => ({
      marginTop: `-${theme.spacing(1)}`,
      marginBottom: `-${theme.spacing(2)}`,
    }),
    [theme]
  );
  const mediaQueries = useMediaQueries();

  const { isFromUserLaboratory } = useUser();
  const isSensorFromLaboratory =
    sensor.laboratory?.id && isFromUserLaboratory(sensor.laboratory.id);

  const routes = useRoutes();
  const navigate = useNavigate();

  const handleEditClick = useCallback(() => {
    navigate(
      routes.laboratory.sensor.sensorEdition.resolveRoute({
        sensorId: sensor.id,
      })
    );
  }, [navigate, routes.laboratory.sensor.sensorEdition, sensor.id]);

  const situation =
    sensor.currentSituation ?? sensor.mostRecentSituation ?? null;

  return (
    <Box sx={styles.root}>
      <SensorPicture sensor={sensor} sx={styles.image(mediaQueries)} />

      <Box sx={styles.header}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Box sx={styles.headerInfo}>
            <Box sx={styles.singleRowDescription}>
              <Box color={theme.palette.grey[500]}>
                <Apartment />
              </Box>

              <Typography variant="body2">
                {sensor.laboratory ? sensor.laboratory.name : "--"}
              </Typography>
            </Box>

            <Typography variant="h6">
              {SensorDataUtils.formatDisplayName(sensor)}
            </Typography>
          </Box>

          {isSensorFromLaboratory && (
            <Box sx={styles.buttonContainer}>
              <Button
                variant="outlined"
                color="info"
                startIcon={<Edit />}
                onClick={handleEditClick}
              >
                <I18n map="general_text.edit" />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Divider sx={styles.firstDivider} />

      <Box sx={styles.content}>
        <Box sx={styles.description}>
          <Box sx={styles.singleRowDescription}>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.blockchain_activation_date" />
              :
            </Typography>
            <Typography variant="body2">
              {DateUtils.formatUTCDate(
                sensor.blockchainActivationDate,
                DateFormat.DayMonthYear
              )}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.laboratory_blockchain_address" />
            </Typography>
            {sensor.laboratory && (
              <CopyToClipboard
                text={sensor.laboratory.blockchainAddress}
                incomingStyle={copyToClipboardStyle}
              >
                <Typography variant="body2">
                  {sensor.laboratory
                    ? BlockchainAddressUtils.shortenAddress(
                        sensor.laboratory.blockchainAddress
                      )
                    : "--"}
                </Typography>
              </CopyToClipboard>
            )}
          </Box>

          <Box>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.sensor_blockchain_address" />
            </Typography>
            <CopyToClipboard
              text={sensor.blockchainAddress}
              incomingStyle={copyToClipboardStyle}
            >
              <Typography variant="body2">
                {BlockchainAddressUtils.shortenAddress(
                  sensor.blockchainAddress
                )}
              </Typography>
            </CopyToClipboard>
          </Box>
        </Box>

        <Divider sx={styles.divider} />

        <Box sx={styles.description}>
          <Box sx={styles.singleRowDescription}>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.type" />:
            </Typography>
            <Typography variant="body2">{sensor.type}</Typography>
          </Box>

          <Box sx={styles.singleRowDescription}>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.name" />:
            </Typography>
            <Typography variant="body2">{sensor.name}</Typography>
          </Box>

          <Box sx={styles.singleRowDescription}>
            <Typography variant="subtitle2" fontSize={14}>
              <I18n map="components.sensors_map.detail_tooltip.identifier" />:
            </Typography>
            <Typography variant="body2">{sensor.identifier}</Typography>
          </Box>
        </Box>

        <Divider sx={styles.divider} />

        {situation && (
          <>
            <Box sx={styles.description}>
              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.location" />
                  :
                </Typography>
                <Typography variant="body2">{situation.location}</Typography>
              </Box>

              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.coordinates" />
                  :
                </Typography>
                <Typography variant="body2">
                  {CoordinateUtils.toDegreeTime(situation.latitude)}N,{" "}
                  {CoordinateUtils.toDegreeTime(situation.longitude)}E
                </Typography>
              </Box>

              <Box sx={styles.singleRowDescription}>
                <Box sx={styles.singleRowDescription}>
                  <Typography variant="subtitle2" fontSize={14}>
                    <I18n map="components.sensors_map.detail_tooltip.situation.height_above_ground" />
                    :
                  </Typography>
                  <Typography variant="body2">
                    {situation.heightAboveGround !== null
                      ? `${situation.heightAboveGround}m`
                      : "--"}
                  </Typography>
                </Box>

                <Box sx={styles.singleRowDescription}>
                  <Typography variant="subtitle2" fontSize={14}>
                    <I18n map="components.sensors_map.detail_tooltip.situation.height_above_sea" />
                    :
                  </Typography>
                  <Typography variant="body2">
                    {situation.heightAboveSea !== null
                      ? `${situation.heightAboveSea}m`
                      : "--"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.use_case" />
                  :
                </Typography>
                <Typography variant="body2">
                  {situation.useCase ?? "--"}
                </Typography>
              </Box>
            </Box>

            <Divider sx={styles.divider} />

            <Box sx={styles.description}>
              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.installation_date" />
                  :
                </Typography>
                <Typography variant="body2">
                  {DateUtils.formatUTCDate(
                    situation.installationDate,
                    DateFormat.DayMonthYearBulletHourMinute
                  )}
                </Typography>
              </Box>

              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.activation_date" />
                  :
                </Typography>
                <Typography variant="body2">
                  {situation.activationDate
                    ? DateUtils.formatUTCDate(
                        situation.activationDate,
                        DateFormat.DayMonthYearBulletHourMinute
                      )
                    : "--"}
                </Typography>
              </Box>

              <Box sx={styles.singleRowDescription}>
                <Typography variant="subtitle2" fontSize={14}>
                  <I18n map="components.sensors_map.detail_tooltip.situation.deactivation_date" />
                  :
                </Typography>
                <Typography variant="body2">
                  {situation.deactivationDate
                    ? DateUtils.formatUTCDate(
                        situation.deactivationDate,
                        DateFormat.DayMonthYearBulletHourMinute
                      )
                    : "--"}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default memo(SensorDetailXxsContent);
