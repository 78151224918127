import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";

export const root: (incomingStyle?: CSSProperties) => SxProps<Theme> =
  (incomingStyle) => (theme) => ({
    marginTop: `-${theme.spacing(2)}`, // because of the iconButton padding
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    ...incomingStyle,
  });
