import { memo } from "react";
import { Box } from "@mui/material";
import * as styles from "./styles";
import { DayJsFormat } from "utils/DayjsUtils";
import I18n from "components/materials/I18n";
import CustomDatePicker from "../CustomDatePicker";

type Props = {
  onFromDateChange: (date: Date | null) => void;
  onToDateChange: (date: Date | null) => void;
  fromDate: Date | null;
  toDate: Date | null;
  minDate?: Date;
  maxDate?: Date;
  withTime: boolean;
};

function FixDateRangeInput({
  onFromDateChange,
  onToDateChange,
  fromDate,
  toDate,
  minDate,
  maxDate,
  withTime,
}: Props) {
  return (
    <Box sx={styles.rowContainer}>
      <CustomDatePicker
        format={DayJsFormat.DayMonthYear}
        sx={styles.datePicker}
        label={<I18n map="general_text.from" />}
        date={fromDate}
        onDateChange={onFromDateChange}
        minDate={minDate}
        maxDate={maxDate}
        withTime={withTime}
      />
      <CustomDatePicker
        format={DayJsFormat.DayMonthYear}
        sx={styles.datePicker}
        label={<I18n map="general_text.to" />}
        date={toDate}
        onDateChange={onToDateChange}
        minDate={minDate}
        maxDate={maxDate}
        withTime={withTime}
      />
    </Box>
  );
}

export default memo(FixDateRangeInput);
