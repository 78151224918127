import { SxProps, Theme } from "@mui/material";

export const root: (active: boolean) => SxProps<Theme> =
  (active) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    border: active
      ? `1px solid ${theme.palette.primary.main}`
      : `1px solid #BDBDBD`,
    backgroundColor: active ? theme.palette.primary.light : "transparent",
    cursor: "pointer",
    boxShadow: active ? theme.shadows[2] : "none",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
    },
  });
