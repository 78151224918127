import { useQuery } from "react-query";
import UserService from "services/UserService";
import { container } from "tsyringe";

const usersQueryKey = "users";
const staleTimeInMs = 1000 * 60 * 5;

function useUsers() {
  const query = useQuery(
    usersQueryKey,
    async () => container.resolve(UserService).findMany({}),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...query,
    users: query.data ?? [],
  };
}

export default useUsers;
