import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { memo, useCallback, useMemo } from "react";
import * as styles from "./styles";
import dayjs, { Dayjs } from "dayjs";
import DateUtils from "utils/DateUtils";
// Very important to import utc from dayjs/plugin/utc
import utc from "dayjs/plugin/utc";
import FormErrors from "../../FormErrors";
import { Box } from "@mui/material";

// Very important to extend dayjs with utc to activate utc plugin
dayjs.extend(utc);

type DatePickerExtraProps = Omit<
  DatePickerProps<AdapterDayjs>,
  "value" | "onChange" | "maxDate" | "minDate"
>;

type DateTimePickerExtraProps = Omit<
  DateTimePickerProps<AdapterDayjs>,
  "value" | "onChange" | "maxDate" | "minDate"
>;

export type CustomDatePickerExtraProps =
  | DatePickerExtraProps
  | DateTimePickerExtraProps;

type Props =
  | {
      onDateChange: (date: Date | null) => void;
      date: Date | null;
      datePickerIncomingStyle?: React.CSSProperties;
      withTime: boolean;
      minDate?: Date;
      maxDate?: Date;
      errors?: string[];
      required?: boolean;
    } & CustomDatePickerExtraProps;

const defaultDateTimePickerProps: DateTimePickerProps<AdapterDayjs> = {
  ampm: false,
  timeSteps: {
    hours: 1,
    minutes: 1,
    seconds: 1,
  },
};

const defaultDatePickerProps: DatePickerProps<AdapterDayjs> = {};

function CustomDatePicker({
  onDateChange,
  date,
  datePickerIncomingStyle,
  withTime,
  minDate,
  maxDate,
  errors,
  required,
  ...pickerProps
}: Props) {
  const datePickerProps = {
    ...(withTime ? defaultDateTimePickerProps : defaultDatePickerProps),
    ...pickerProps,
    ...(!!pickerProps.label &&
      required && {
        label: `${pickerProps.label} *`,
      }),
  };
  const minDayJsDate = minDate ? dayjs(minDate).utc() : undefined;
  const maxDayJsDate = maxDate ? dayjs(maxDate).utc() : undefined;
  const dayJsDate = useMemo(() => {
    if (!date) {
      return null;
    }

    return withTime
      ? dayjs(date).utc()
      : dayjs(DateUtils.excludeTime(date)).utc();
  }, [date, withTime]);

  const handleDateChange = useCallback(
    // There is an inconsistence with types of onChange of DatePicker
    // It accepts Date but actually works with dayjs, so we need to cast to avoid typings issues
    (date: Date | null) => {
      if (!date) {
        return onDateChange(null);
      }

      const dateConvertedFromDayJs = withTime
        ? (date as unknown as Dayjs).toDate()
        : DateUtils.excludeTime((date as unknown as Dayjs).toDate());

      onDateChange(dateConvertedFromDayJs);
    },
    [onDateChange, withTime]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={styles.container}>
        {withTime && (
          <DateTimePicker
            // There is an inconsistence with date types of value of DatePicker
            // It accepts Date but works with dayjs, so we need to cast to avoid typings issues
            {...(datePickerProps as DateTimePickerProps<Date>)}
            sx={styles.root({
              isError: !!errors && errors.length > 0,
              incomingStyle: datePickerIncomingStyle,
            })}
            value={dayJsDate as unknown as Date}
            minDate={minDayJsDate as unknown as Date}
            maxDate={maxDayJsDate as unknown as Date}
            onChange={handleDateChange}
            timezone="UTC"
          />
        )}
        {!withTime && (
          <DatePicker
            // There is an inconsistence with date types of value of DatePicker
            // It accepts Date but works with dayjs, so we need to cast to avoid typings issues
            {...(datePickerProps as DatePickerProps<Date>)}
            sx={styles.root({
              isError: !!errors && errors.length > 0,
              incomingStyle: datePickerIncomingStyle,
            })}
            value={dayJsDate as unknown as Date}
            minDate={minDayJsDate as unknown as Date}
            maxDate={maxDayJsDate as unknown as Date}
            onChange={handleDateChange}
            timezone="UTC"
          />
        )}
        {errors && errors.length > 0 && <FormErrors errors={errors} />}
      </Box>
    </LocalizationProvider>
  );
}

export default memo(CustomDatePicker);
