import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useUser from "./useUser";
import NotificationService from "services/NotificationService";
import { ModelId } from "models/common/Model";

const userNotificationsQueryKey = ["authenticated-user", "notifications"];
const staleTimeInMs = 1000 * 60 * 1;

function useUserNotifications() {
  const queryClient = useQueryClient();
  const { user } = useUser();

  const query = useQuery(
    userNotificationsQueryKey,
    async () =>
      user
        ? container.resolve(NotificationService).findMany({
            target: user.email,
            dismissDate: null,
          })
        : [],
    {
      enabled: !!user,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      refetchInterval: staleTimeInMs,
      staleTime: staleTimeInMs,
    }
  );

  const notifications = query.data ?? [];

  const updateNotificationQuery = useMutation(
    async (params: { notificationId: ModelId; dismissDate: Date | null }) => {
      return await container.resolve(NotificationService).updateNotification({
        notificationId: params.notificationId,
        dismissDate: params.dismissDate,
      });
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(userNotificationsQueryKey);
      },
    }
  );

  return {
    ...query,
    updateNotificationQuery,
    notifications,
  };
}

export default useUserNotifications;
