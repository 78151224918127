import usePortal from "react-useportal";

function useRootPortal() {
  const { Portal } = usePortal({
    bindTo: document.getElementById("root") as HTMLElement,
  });

  return { RootPortal: Portal };
}

export default useRootPortal;
