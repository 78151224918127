import { Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import User from "models/user/User";
import { memo } from "react";
import * as styles from "../styles";
import I18n from "components/materials/I18n";

type Props = {
  users: User[];
  onDelete: (user: User) => void;
};

function WhitelistUsers({ users, onDelete }: Props) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.header(!!users.length)}>
        <Typography variant="subtitle1">
          <I18n map={"components.whitelist_users.title"} />
        </Typography>
      </Box>

      <Box sx={styles.container}>
        {users.map((user) => {
          return (
            <Box key={user.id} sx={styles.card}>
              <Typography sx={styles.label}>{user.email}</Typography>

              <IconButton onClick={() => onDelete(user)}>
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default memo(WhitelistUsers);
