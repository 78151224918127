import {
  PropsWithChildren,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useState,
} from "react";
import CustomSnackBar from "components/element/CustomSnackBar";

type Severity = "success" | "error" | "warning" | "info";

type Props = {
  message: string;
  severity: Severity;
  isOpen?: boolean;
  close?: () => void;
};

type SnackBarContextType = {
  open: (props: Props) => void;
};

export const SnackBarContext = createContext<SnackBarContextType | null>(null);

function SnackBarContextProvider({ children }: PropsWithChildren) {
  const [snackBarComponent, setSnackBarComponent] = useState<ReactNode | null>(
    null
  );

  const open = useCallback((props: Props) => {
    setSnackBarComponent(
      <CustomSnackBar
        message={props.message}
        severity={props.severity}
        isOpen={props.isOpen || true}
        close={() => {
          setSnackBarComponent(null);
          props.close?.();
        }}
      />
    );
  }, []);

  return (
    <SnackBarContext.Provider value={{ open }}>
      {children}
      {snackBarComponent}
    </SnackBarContext.Provider>
  );
}

export default memo(SnackBarContextProvider);
