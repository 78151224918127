import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { DtoId } from "./dtos/common/Dto";
import { WidgetDto } from "./dtos/widget/WidgetDto";
import { WidgetLayouts } from "models/widgets/WidgetLayouts";
import { ModelId } from "models/common/Model";
import Widget from "models/widgets/Widget";
import { WidgetType } from "models/widgets/WidgetType";

@singleton()
export default class WidgetApi extends BaseApi {
  public async findMany(params: {
    sensorId: DtoId;
    queryParams?: {};
  }): Promise<WidgetDto[]> {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/widgets`);

    if (params.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<WidgetDto[]>(url);
  }

  public async updateManyLayouts(params: {
    sensorId: ModelId;
    widgetsLayouts: {
      id: ModelId;
      layouts: WidgetLayouts;
    }[];
  }) {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/widgets`);

    return this.patchRequest<WidgetDto[]>(url, {
      widgets: params.widgetsLayouts,
    });
  }

  public async updateWidgetProperties(params: {
    widgetId: number;
    properties: Widget["properties"];
  }): Promise<WidgetDto | null> {
    const url = new URL(`${this.apiUrl}/widgets/${params.widgetId}`);

    return this.patchRequest<WidgetDto>(url, {
      properties: params.properties,
    });
  }

  public async createSensorWidget(params: {
    sensorId: ModelId;
    widgetType: WidgetType;
  }): Promise<WidgetDto> {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/widgets`);

    return this.postRequest<WidgetDto>(url, {
      type: params.widgetType,
    });
  }

  public async deleteSensorWidget(params: { widgetId: number }) {
    const url = new URL(`${this.apiUrl}/widgets/${params.widgetId}`);

    return this.deleteRequest(url);
  }
}
