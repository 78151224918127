import ApplicationVariables from "configs/ApplicationVariables";
import { ModelMedia } from "models/common/Model";
import Sensor from "models/sensor/Sensor";
import { container } from "tsyringe";

export default class SensorPictureUtils {
  private static readonly sourceUrl = container
    .resolve(ApplicationVariables)
    .get().api.rootUrl;

  public static readonly fallbackPictureUrl =
    "/images/no-image-placeholder.png";

  public static getPictureUrlFromPictureProperty(
    picture: ModelMedia | null | undefined
  ): string | null {
    if (picture?.formats?.["small"]?.url) {
      return SensorPictureUtils.sourceUrl + picture.formats["small"].url;
    }
    if (picture?.formats?.["thumbnail"]?.url) {
      return SensorPictureUtils.sourceUrl + picture.formats["thumbnail"].url;
    }
    if (picture?.url) {
      return SensorPictureUtils.sourceUrl + picture.url;
    }

    return null;
  }

  public static getPicture(sensor: Sensor): string {
    const imageUrl = SensorPictureUtils.getPictureUrlFromPictureProperty(
      sensor.picture
    );
    if (imageUrl) {
      return imageUrl;
    }

    return SensorPictureUtils.fallbackPictureUrl;
  }
}
