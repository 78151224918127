import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const chartContainer: SxProps<Theme> = {
  flex: 1,
  minHeight: "440px",
};
