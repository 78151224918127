import { SxProps, Theme } from "@mui/material";

export const root: (params: { isVisible: boolean }) => SxProps<Theme> =
  ({ isVisible }) =>
  (theme) => ({
    ...(!isVisible && { visibility: "hidden" }),
  });

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
});

export const columnContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
});

export const rowContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(3),
});

export const buttonContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
});
