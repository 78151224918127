import { memo, useCallback, useEffect, useMemo, useState } from "react";
import SensorDataUtils from "utils/SensorResultUtils";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import CustomAutocompleteMulti, { IOption } from "../CustomAutocompleteMulti";
import * as styles from "./styles";
import Sensor from "models/sensor/Sensor";
import { Box, Typography } from "@mui/material";

type Props = {
  sensors: Sensor[];
  onChange?: (sensorIds: ModelId[]) => unknown;
  values?: ModelId[];
  errors?: string[];
  label?: string;
};

function SensorsInputMulti({
  label,
  sensors,
  onChange,
  values,
  errors,
}: Props) {
  const [selectedSensorIds, setSelectedSensorIds] = useState<ModelId[]>(
    values ?? []
  );

  const options: IOption[] = useMemo(
    () =>
      sensors
        ?.map((sensor) => {
          return {
            id: sensor.id,
            label: SensorDataUtils.formatDisplayName(sensor),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [sensors]
  );

  const handleSensorsSelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, values: IOption[] | null) => {
      const sensorIds = values?.map((option) => option.id) ?? [];
      setSelectedSensorIds(sensorIds);

      onChange?.(sensorIds);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedSensorIds(values ?? []);
  }, [values]);

  const selectedOptions = options.filter((option) =>
    selectedSensorIds.includes(option.id)
  );

  return (
    <Box sx={styles.root}>
      {label && <Typography variant="body1">{label}</Typography>}

      <CustomAutocompleteMulti
        options={options}
        errors={errors}
        onChange={handleSensorsSelection}
        placeholder={
          selectedOptions.length > 0
            ? ""
            : I18n.translate("general_text.search_sensor")
        }
        values={selectedOptions}
        sx={styles.autocomplete(selectedOptions.length > 0)}
      />
    </Box>
  );
}

export default memo(SensorsInputMulti);
