import { JwtPairDto } from "services/apis/ceaMoonshot/dtos/authentication/JwtPairDto";

export default class JwtPair {
  public readonly accessToken: string;
  public readonly refreshToken: string;

  private constructor(params: { accessToken: string; refreshToken: string }) {
    this.accessToken = params.accessToken;
    this.refreshToken = params.refreshToken;
  }

  public static fromDto(dto: JwtPairDto): JwtPair {
    return new JwtPair({
      accessToken: dto.accessToken,
      refreshToken: dto.refreshToken,
    });
  }

  public static fromLocalStorage(params: {
    accessToken: string;
    refreshToken: string;
  }): JwtPair {
    return new JwtPair({
      accessToken: params.accessToken,
      refreshToken: params.refreshToken,
    });
  }
}
