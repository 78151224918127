import useRoutes from "hooks/useRoutes";
import { memo } from "react";
import AuditsActions from "./AuditsActions";
import DataActions from "./DataActions";
import useSensor from "hooks/queries/useSensor";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";

function Actions() {
  const routes = useRoutes();

  const { sensorId } = useSelectedSensorIdContext();
  const { isUserLaboratorySensor } = useSensor(sensorId);

  const isLaboratoryDataSubPage = routes.laboratory.sensor.sensorData.isCurrent;
  const isAuditsSubPage =
    routes.laboratory.sensor.sensorAudits.isCurrent ||
    routes.externalUser.sensor.sensorAudits.isCurrent;

  return (
    <>
      {isLaboratoryDataSubPage && isUserLaboratorySensor && <DataActions />}
      {isAuditsSubPage && <AuditsActions />}
    </>
  );
}

export default memo(Actions);
