import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const form: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

export const firstField: SxProps<Theme> = (theme) => ({
  marginTop: theme.spacing(0),
});

export const button: SxProps<Theme> = (theme) => ({
  marginTop: theme.spacing(3),
  width: "100%",
});
