import { SxProps, Theme } from "@mui/material";
import { borderRadius, colorGrayLight3 } from "themes/constants";

export const autocomplete: SxProps<Theme> = (theme) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiOutlinedInput-root": {
    padding: theme.spacing(1),
  },

  backgroundColor: colorGrayLight3,
  borderRadius,
  width: "100%",
  maxWidth: "400px",
});

export const searchIcon: SxProps<Theme> = (theme) => ({
  marginLeft: theme.spacing(2),
});
