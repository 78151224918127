import { CSSProperties, memo, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "./styles";
import { DayJsFormat } from "utils/DayjsUtils";
import {
  DateBoundary,
  FixDateBoundary,
  RelativeDateBoundary,
  retrieveFixDateBoundary,
  retrieveRelativeDateBoundary,
} from "models/widgets/Widget";
import CustomDatePicker, {
  CustomDatePickerExtraProps,
} from "components/element/inputs/CustomDatePicker";
import RelativeDatePicker from "components/element/inputs/RelativeDatePicker";

type Props = {
  onDateBoundaryChange: (dateBoundary: DateBoundary | null) => void;
  dateBoundary: DateBoundary | null;
  dateType: keyof FixDateBoundary | keyof RelativeDateBoundary;
  relativeDateInputErrors?: string[];
  fixDateInputErrors?: string[];
  minDate?: Date;
  maxDate?: Date;
  minRelative?: number;
  maxRelative?: number;
  datePickerIncomingStyle?: CSSProperties;
  format: DayJsFormat;
  withTime: boolean;
  label: string;
  datePickerLabel: string;
} & CustomDatePickerExtraProps;

function RelativeOrFixDatePicker({
  onDateBoundaryChange,
  dateBoundary,
  dateType,
  relativeDateInputErrors,
  fixDateInputErrors,
  minDate,
  maxDate,
  minRelative,
  maxRelative,
  datePickerIncomingStyle,
  format,
  withTime,
  label,
  datePickerLabel,
  ...customDatePickerExtraProps
}: Props) {
  const handleFixDateChange = useCallback(
    (date: Date | null) => {
      onDateBoundaryChange(date ? { fix: date } : null);
    },
    [onDateBoundaryChange]
  );

  const relativeDate = dateBoundary
    ? retrieveRelativeDateBoundary(dateBoundary)
    : null;

  const handleRelativeDateChange = useCallback(
    (relativeDate: number) => {
      onDateBoundaryChange({ relative: relativeDate });
    },
    [onDateBoundaryChange]
  );

  return (
    <Box sx={styles.root}>
      <Box>
        <Typography>{label}</Typography>
      </Box>

      <Box>
        {dateType === "relative" && (
          <RelativeDatePicker
            defaultValue={relativeDate}
            onRelativeDateChange={handleRelativeDateChange}
            minRelative={minRelative}
            maxRelative={maxRelative}
            errors={relativeDateInputErrors}
          />
        )}

        {dateType === "fix" && (
          <CustomDatePicker
            onDateChange={handleFixDateChange}
            date={dateBoundary ? retrieveFixDateBoundary(dateBoundary) : null}
            withTime={withTime}
            minDate={minDate}
            maxDate={maxDate}
            datePickerIncomingStyle={datePickerIncomingStyle}
            format={format}
            label={datePickerLabel}
            errors={fixDateInputErrors}
            {...customDatePickerExtraProps}
          />
        )}
      </Box>
    </Box>
  );
}

export default memo(RelativeOrFixDatePicker);
