import dayjs from "dayjs";

export enum DayJsFormat {
  DayMonthYear = "DD/MM/YYYY",
  DayMonthYearHourMinute = "DD/MM/YYYY HH:mm",
}

export default class DayJsUtils {
  public static min(
    a: Date | null | undefined,
    b: Date | null | undefined
  ): dayjs.Dayjs {
    const difference = dayjs(a ?? 0).diff(dayjs(b ?? 0));
    return difference < 0 ? dayjs(a) : dayjs(b);
  }

  public static max(
    a: Date | null | undefined,
    b: Date | null | undefined
  ): dayjs.Dayjs {
    const difference = dayjs(a ?? 0).diff(dayjs(b ?? 0));
    return difference > 0 ? dayjs(a) : dayjs(b);
  }
}
