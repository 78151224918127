import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { theme } from "themes";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> = (
  mediaQueries
) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),

  ...(mediaQueries.isSm && {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  }),
});

export const tabsContainer: SxProps<Theme> = {
  display: "flex",
  overflowX: "scroll",
};

export const tabs: SxProps<Theme> = {
  minWidth: 350,
};

export const actions: (mediaQueries: MediaQueries) => SxProps<Theme> = (
  mediaQueries
) => ({
  ...(mediaQueries.isSm && {
    marginTop: "12px",
  }),
});
