import { CSSProperties, ReactNode, memo, useCallback } from "react";
import * as styles from "./styles";
import { Button, Typography } from "@mui/material";

type Props = {
  text?: string;
  icon?: ReactNode;
  backgroundColor: string;
  hoverColor: string;
  onClick?: () => void;
  incomingStyles?: CSSProperties;
  enablePropagation?: boolean;
};

function CustomButton({
  text,
  icon,
  backgroundColor,
  hoverColor,
  onClick,
  incomingStyles,
  enablePropagation,
}: Props) {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!enablePropagation) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (onClick) {
        onClick();
      }
    },
    [enablePropagation, onClick]
  );

  return (
    <Button
      sx={styles.root({ backgroundColor, hoverColor, incomingStyles })}
      onClick={handleClick}
      color="info"
    >
      {icon && icon}

      {text && (
        <Typography variant="body2" textTransform="initial">
          {text}
        </Typography>
      )}
    </Button>
  );
}

export default memo(CustomButton);
