import { Box, useTheme } from "@mui/material";
import Sensor from "models/sensor/Sensor";
import { memo } from "react";
import CustomButton from "../CustomButton";

type Props = {
  onClick: () => void;
  sensor: Sensor;
  markerSrc: string;
  isSelected: boolean;
};

function MapNavigationButton({
  onClick,
  sensor,
  markerSrc,
  isSelected,
}: Props) {
  const theme = useTheme();
  return (
    <CustomButton
      onClick={onClick}
      text={sensor.currentSituation?.location || "--"}
      icon={
        <Box
          component="img"
          src={markerSrc}
          alt={`map-icon-${sensor.identifier}`}
          height={16}
        />
      }
      backgroundColor={theme.palette.grey[isSelected ? 200 : 100]}
      hoverColor={theme.palette.grey[isSelected ? 300 : 200]}
    />
  );
}

export default memo(MapNavigationButton);
