import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { LaboratoryDto } from "./dtos/laboratory/LaboratoryDto";

@singleton()
export default class LaboratoryApi extends BaseApi {
  public async findMany(params: {
    queryParams?: {};
  }): Promise<LaboratoryDto[]> {
    const url = new URL(`${this.apiUrl}/laboratories`);

    if (params.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<LaboratoryDto[]>(url);
  }
}
