import { Typography, Card } from "@mui/material";
import * as styles from "./styles";
import React, { memo } from "react";

type Props = {
  title: string;
  text: string;
  icon: React.ReactNode;
  active: boolean;
  onClick: () => void;
};

function AuditTypeCard({ title, text, icon, active, onClick }: Props) {
  return (
    <Card sx={styles.root(active)} elevation={0} onClick={onClick}>
      {icon}
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2" maxWidth={228}>
        {text}
      </Typography>
    </Card>
  );
}

export default memo(AuditTypeCard);
