import { memo, useCallback, useEffect, useState } from "react";
import I18n from "components/materials/I18n";
import useBlockchainEnergyCostData from "hooks/queries/widgetData/useBlockchainEnergyCostData";
import SystemEnergyCostChart from "components/element/SystemEnergyCostChart";
import { BlockchainEnergyCostWidget } from "models/widgets/Widget";
import { Box, Divider, Typography } from "@mui/material";
import DateTimeRange from "components/element/DateTimeRange";
import SensorDataUtils from "utils/SensorResultUtils";
import * as styles from "./styles";
import { DateFormat } from "utils/DateUtils";
import { Nullable } from "types/common";
import EditionForm from "./EditionForm";
import CircularLoader from "components/element/CircularLoader";

type Props = {
  widget: BlockchainEnergyCostWidget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<BlockchainEnergyCostWidget["properties"]> | null
  ) => void;
  onUserValidation?: (
    properties: BlockchainEnergyCostWidget["properties"]
  ) => void;
};

function BlockchainEnergyCost({
  widget,
  shouldShowEditionForm,
  onPropertiesChange,
  onUserValidation,
}: Readonly<Props>) {
  const chartHeight = "200px";

  const [properties, setProperties] = useState<
    BlockchainEnergyCostWidget["properties"]
  >(widget.properties);

  useEffect(() => {
    setProperties(widget.properties);
  }, [widget.properties]);

  const { blockchainEnergyCost, sensor, ...query } =
    useBlockchainEnergyCostData(widget.id, properties);

  const validate = useCallback(
    (properties: BlockchainEnergyCostWidget["properties"]) => {
      onUserValidation?.(properties);
      setProperties(properties);
    },
    [onUserValidation]
  );

  return (
    <Box sx={styles.root}>
      {shouldShowEditionForm && (
        <EditionForm
          properties={properties}
          onSubmit={validate}
          onPropertiesChange={onPropertiesChange}
        />
      )}

      <Box sx={styles.content}>
        {query.isLoading && (
          <Box sx={styles.loadingContainer}>
            <CircularLoader />
          </Box>
        )}

        {query.isFetched && !isNaN(blockchainEnergyCost) && (
          <SystemEnergyCostChart
            blockchainEnergyCost={blockchainEnergyCost}
            height={chartHeight}
          />
        )}

        {query.isFetched && !blockchainEnergyCost && (
          <Typography sx={styles.unit}>
            <I18n map="general_text.no_data" />
          </Typography>
        )}

        <Divider orientation={"vertical"} variant="middle" flexItem />
        <Box sx={styles.description}>
          <Typography variant="h6">
            {sensor ? SensorDataUtils.formatDisplayName(sensor) : "--"}
          </Typography>
          <DateTimeRange
            fromDate={properties.fromDate}
            toDate={properties.toDate}
            format={DateFormat.DayMonthYearBulletHourMinute}
            wrapable
          />
        </Box>
      </Box>
    </Box>
  );
}

export default memo(BlockchainEnergyCost);
