import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";

export const root: ({
  isError,
  incomingStyle,
}: {
  isError: boolean;
  incomingStyle?: CSSProperties;
}) => SxProps<Theme> =
  ({ isError, incomingStyle }) =>
  (theme) => ({
    width: "100%",
    ...(isError && {
      "& label": {
        color: theme.palette.error.main + " !important",
      },
      "& .MuiInputBase-root": {
        color: theme.palette.error.main + " !important",
      },
      "& fieldset": {
        borderColor: theme.palette.error.main + " !important",
      },
    }),
    ...incomingStyle,
  });

export const container: SxProps<Theme> = (theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
});
