import { useQuery } from "react-query";
import OAuthProviderService from "services/OAuthProviderService";
import { container } from "tsyringe";

function getOAuthProviderQueryKey(name: string) {
  return ["oAuthProvider", name];
}

const staleTimeInMs = 1000 * 60 * 60; // 1 hour

function useOAuthProvider(name: string) {
  const query = useQuery(
    getOAuthProviderQueryKey(name),
    async () => container.resolve(OAuthProviderService).getByName(name),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      refetchInterval: staleTimeInMs,
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...query,
    oAuthProvider: query.data ?? null,
  };
}

export default useOAuthProvider;
