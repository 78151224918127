import { Box, Typography, useTheme } from "@mui/material";
import Sensor from "models/sensor/Sensor";
import { CSSProperties, memo, useCallback, useMemo, useState } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import CopyToClipboard from "components/element/CopyToClipboard";
import { useNavigate } from "react-router-dom";
import SensorDataUtils from "utils/SensorResultUtils";
import useMediaQueries from "hooks/useMediaQueries";
import BlockchainAddressUtils from "utils/BlockchainAddressUtils";
import useRoutes from "hooks/useRoutes";
import { Apartment, ChevronRightOutlined } from "@mui/icons-material";
import CustomButton from "components/element/CustomButton";
import MapNavigationButton from "components/element/MapNavigationButton";
import mapMarkerGreen from "icons/mapMarkers/mapMarkerGreen.svg";
import mapMarkerGray from "icons/mapMarkers/mapMarkerGray.svg";
import mapMarkerRed from "icons/mapMarkers/mapMarkerRed.svg";
import SensorPicture from "components/materials/SensorPicture";
import DelayUtils from "utils/DelayUtils";

type Props = {
  onSensorSelection: (sensor: Sensor | null) => void;
  sensor: Sensor;
  isSelected?: boolean;
  isLaboratorySensor?: boolean;
};

function SensorCard({
  onSensorSelection,
  sensor,
  isSelected,
  isLaboratorySensor,
}: Props) {
  const navigate = useNavigate();
  const routes = useRoutes();
  const mediaQueries = useMediaQueries();
  const theme = useTheme();
  const actionButtonStyle: CSSProperties = useMemo(
    () => ({ height: "100%", minWidth: "36px" }),
    []
  );

  const handleClickOnViewData = useCallback(() => {
    navigate(
      routes.laboratory.sensor.sensorData.resolveRoute({ sensorId: sensor.id })
    );
  }, [routes.laboratory.sensor.sensorData, navigate, sensor.id]);

  const formatBlockchainAddress = useCallback(
    (address: string): string => {
      if (mediaQueries.isStrictlyXxs) {
        return BlockchainAddressUtils.shortenAddress(address, 6, 6);
      }

      if (mediaQueries.isStrictlyXs) {
        return BlockchainAddressUtils.shortenAddress(address, 10, 10);
      }

      if (mediaQueries.isStrictlyMd) {
        return BlockchainAddressUtils.shortenAddress(address, 14, 14);
      }

      return address;
    },
    [
      mediaQueries.isStrictlyMd,
      mediaQueries.isStrictlyXs,
      mediaQueries.isStrictlyXxs,
    ]
  );

  const handleClickOnMarker = useCallback(async () => {
    // We use this hacky delay to force react to reset the map selection before selecting the new one
    onSensorSelection(null);
    await DelayUtils.wait(0);
    onSensorSelection(sensor);
  }, [onSensorSelection, sensor]);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovered(false);
  }, []);

  const markerSrc = useMemo(() => {
    if (!isLaboratorySensor) return mapMarkerGray;

    const now = new Date();
    const isNowAfterActivation =
      sensor.currentSituation?.activationDate &&
      sensor.currentSituation.activationDate.getTime() <= now.getTime();
    const isNowBeforeDeactivation =
      (sensor.currentSituation?.deactivationDate &&
        sensor.currentSituation.deactivationDate.getTime() >= now.getTime()) ||
      true;
    const isActive = isNowAfterActivation && isNowBeforeDeactivation;
    if (isActive) {
      return mapMarkerGreen;
    }

    return mapMarkerRed;
  }, [
    isLaboratorySensor,
    sensor.currentSituation?.activationDate,
    sensor.currentSituation?.deactivationDate,
  ]);

  return (
    <Box
      sx={styles.root(isSelected || isHovered)}
      onClick={handleClickOnMarker}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseOut}
    >
      <Box sx={styles.content(mediaQueries)}>
        <SensorPicture sensor={sensor} sx={styles.image(mediaQueries)} />

        <Box sx={styles.description}>
          <Box display="flex" gap={1} alignItems="center">
            <Box color={theme.palette.grey[500]}>
              <Apartment />
            </Box>

            <Typography variant="body2">
              {sensor.laboratory?.name || "--"}
            </Typography>
          </Box>

          <Box sx={styles.descriptionHeader}>
            <MapNavigationButton
              onClick={handleClickOnMarker}
              sensor={sensor}
              isSelected={isSelected || isHovered}
              markerSrc={markerSrc}
            />

            <Typography variant="subtitle1">
              {SensorDataUtils.formatDisplayName(sensor)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              <I18n map="components.sensors_map.detail_tooltip.laboratory_blockchain_address" />
            </Typography>

            {sensor.laboratory && (
              <CopyToClipboard text={sensor.laboratory.blockchainAddress}>
                <Typography variant="body2">
                  {sensor.laboratory
                    ? formatBlockchainAddress(
                        sensor.laboratory.blockchainAddress
                      )
                    : "--"}
                </Typography>
              </CopyToClipboard>
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2">
              <I18n map="components.sensors_map.detail_tooltip.sensor_blockchain_address" />
            </Typography>
            <CopyToClipboard text={sensor.blockchainAddress}>
              <Typography variant="body2">
                {formatBlockchainAddress(sensor.blockchainAddress)}
              </Typography>
            </CopyToClipboard>
          </Box>
        </Box>
      </Box>

      {isSelected && (
        <Box sx={styles.action}>
          <CustomButton
            onClick={handleClickOnViewData}
            icon={<ChevronRightOutlined sx={styles.actionIcon} />}
            backgroundColor={
              theme.palette.grey[isSelected || isHovered ? 200 : 100]
            }
            hoverColor={theme.palette.grey[isSelected || isHovered ? 300 : 200]}
            incomingStyles={actionButtonStyle}
          />
        </Box>
      )}
    </Box>
  );
}

export default memo(SensorCard);
