import OAuthProvider from "models/authentication/OAuthProvider";
import { container, singleton } from "tsyringe";
import OAuthProviderApi from "./apis/ceaMoonshot/OAuthProviderApi";

@singleton()
export default class OAuthProviderService {
  public async getByName(name: string): Promise<OAuthProvider> {
    const oAuthProviderDto = await container
      .resolve(OAuthProviderApi)
      .getByName(name);

    return OAuthProvider.fromDto(oAuthProviderDto);
  }
}
