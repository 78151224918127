import { SxProps, Theme } from "@mui/material";
import { footerHeight, headerHeight } from "themes/constants";

export const root: SxProps<Theme> = (theme) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,

  "& header": {
    height: headerHeight,
  },

  "& main": {
    flex: 1,
    flexDirection: "column",
    width: "100%",
  },

  "& footer": {
    height: footerHeight,
  },
});
