import React, {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classes from "./classes.module.scss";
import { Box, Button, CardMedia } from "@mui/material";
import I18n from "components/materials/I18n";
import { Delete, Edit } from "@mui/icons-material";
import * as styles from "./styles";
import useMediaQueries from "hooks/useMediaQueries";

type AllowedExtension = ".jpg" | ".jpeg" | ".png";

type Props = {
  allowedExtensions: AllowedExtension[];
  maxMbSize: number;
  onChange: (file: File | null, keyName: string) => void;
  name: string;
  defaultAvatar?: File | string | null;
  label?: ReactNode;
  description?: ReactNode;
};

function FileInput(props: Props) {
  const mediaQueries = useMediaQueries();
  const input = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    file: props.defaultAvatar || null,
    fileName: "Default",
    error: "",
  });

  const openFileExplorer = useCallback(() => {
    if (!input.current) return;
    input.current.click();
  }, []);

  const readFileUrl = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputFiles = e.target.files;
      if (!inputFiles || !inputFiles[0]) return;

      const file = inputFiles[0];
      const regex = /^(.*)(\.[a-z]+)$/;
      const expectedMatchesQuantity = 3;
      const matches = file.name.match(regex);
      if (!matches || matches.length < expectedMatchesQuantity) return;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, fileNameOnly, extension] = matches;
      if (!fileNameOnly || !extension) return;
      if (!props.allowedExtensions.includes(extension as AllowedExtension))
        return;

      let reader = new FileReader();
      reader.onload = (event) => {
        if (event.total > props.maxMbSize * 1024 * 1024) {
          return;
        }
        setState({
          fileName: fileNameOnly + extension,
          file: String(event.target?.result),
          error: "",
        });

        props.onChange(file, props.name);
      };
      reader.readAsDataURL(file);
    },
    [props]
  );

  const removeFile = useCallback(() => {
    setState({
      file: null,
      fileName: "Default",
      error: "",
    });
    props.onChange(null, props.name);
  }, [props]);

  useEffect(() => {
    setState({
      file: props.defaultAvatar || null,
      fileName: "Default",
      error: "",
    });
  }, [props.defaultAvatar]);

  const srcUrl = useMemo(() => {
    if (!state.file) {
      return "";
    }

    if (typeof state.file === "string") {
      return state.file;
    }

    return URL.createObjectURL(state.file);
  }, [state.file]);

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Box sx={styles.imageContainer}>
        <CardMedia component="img" sx={styles.image} image={srcUrl} alt="" />
      </Box>

      <Box sx={styles.content}>
        <Box sx={styles.description}>
          {!!props.label && props.label}

          {!!props.description && props.description}
        </Box>

        <Box sx={styles.buttonContainer}>
          <Button
            onClick={removeFile}
            startIcon={<Delete />}
            color="error"
            variant="contained"
          >
            {I18n.translate("general_text.remove")}
          </Button>

          <Button
            onClick={openFileExplorer}
            startIcon={<Edit />}
            variant="outlined"
            color="info"
          >
            {I18n.translate("general_text.modify")}
          </Button>
        </Box>

        <input
          className={classes["hidden"]}
          ref={input}
          type="file"
          onChange={readFileUrl}
          accept={props.allowedExtensions.join(",")}
          value=""
        />
      </Box>
    </Box>
  );
}

export default memo(FileInput);
