import { Box } from "@mui/material";
import { memo, useMemo } from "react";
import * as styles from "./styles";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensor from "hooks/queries/useSensor";
import TransactionsTable from "components/materials/TransactionsTable";
import SensorEpoch from "models/sensor/SensorEpoch";

function SensorTransactionsSection() {
  const { sensorId } = useSelectedSensorIdContext();
  const { sensor } = useSensor(sensorId, {
    withResultsSessions: true,
    withRawSessions: true,
  });

  const sensorEpoches = useMemo(() => {
    if (!sensor) return [];
    return (sensor.situations ?? []).flatMap((situation) =>
      (situation.sensorEpoches ?? []).filter(
        (sensorEpoch): sensorEpoch is SensorEpoch => !!sensorEpoch
      )
    );
  }, [sensor]);

  return (
    <Box sx={styles.root}>
      <TransactionsTable sensorEpoches={sensorEpoches} />
    </Box>
  );
}

export default memo(SensorTransactionsSection);
