import User from "models/user/User";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import useRoutes from "hooks/useRoutes";

function useAuthenticatedNavigation() {
  const navigate = useNavigate();
  const routes = useRoutes();

  const navigateToAuthenticatedLandingPage = useCallback(
    (user: User) => {
      if (user.isLabUser()) {
        navigate(routes.laboratory.sensors.resolveRoute());
        return;
      }

      if (user.isExternalUser()) {
        navigate(routes.externalUser.sensors.resolveRoute());
        return;
      }
    },
    [navigate, routes.externalUser.sensors, routes.laboratory.sensors]
  );

  return {
    navigateToAuthenticatedLandingPage,
  };
}

export default useAuthenticatedNavigation;
