import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useRoutes from "hooks/useRoutes";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const routes = useRoutes();
  const { sensorId } = useSelectedSensorIdContext();

  const handleBackToSensorClick = useCallback(() => {
    navigate(routes.laboratory.sensor.resolveRoute({ sensorId }));
  }, [navigate, routes.laboratory.sensor, sensorId]);

  return (
    <Button
      variant="outlined"
      color="info"
      startIcon={<ChevronLeft />}
      onClick={handleBackToSensorClick}
    >
      <I18n map="pages.sensor_edition.go_to_sensor" />
    </Button>
  );
}

export default memo(BackButton);
