import ApplicationVariables from "configs/ApplicationVariables";
import defer from "defer-promise";
import LocalStorageService from "services/LocalStorageService";
import { container } from "tsyringe";
import { JwtPairDto } from "./dtos/authentication/JwtPairDto";

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data;",
}

export default abstract class BaseApiService {
  protected readonly apiUrl = `${
    container.resolve(ApplicationVariables).get().api.rootUrl
  }/api`;

  private static queueAllRequests: boolean = false;
  private static queuedRequests: DeferPromise.Deferred<void> | null = null;
  private readonly localStorageService = container.resolve(LocalStorageService);

  protected buildHeaders(contentType: ContentType) {
    const headers = new Headers();

    if (contentType === ContentType.Json) {
      headers.set("Content-Type", contentType);
    }

    const accessToken = this.localStorageService.items.accessToken.get();

    if (accessToken) {
      headers.set("auth-token", accessToken);
    }

    return headers;
  }

  protected buildBody(body: { [key: string]: unknown }): string {
    return JSON.stringify(body);
  }

  protected async getRequest<T>(
    url: URL,
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "GET",
        headers: this.buildHeaders(ContentType.Json),
      });
    };

    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  protected async postRequest<T>(
    url: URL,
    body: { [key: string]: unknown } = {},
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "POST",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody(body),
      });
    };
    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  protected async putRequest<T>(
    url: URL,
    body: { [key: string]: unknown } = {},
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "PUT",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody(body),
      });
    };

    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  protected async patchRequest<T>(
    url: URL,
    body: { [key: string]: unknown } = {},
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "PATCH",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody(body),
      });
    };

    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  protected async deleteRequest<T>(
    url: URL,
    body: { [key: string]: unknown } = {},
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "DELETE",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody(body),
      });
    };

    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  protected async patchFormDataRequest<T>(
    url: URL,
    body: FormData,
    handleTokenExpiration = true,
    synchroneRequest = false
  ) {
    const request = async () => {
      return fetch(url, {
        method: "PATCH",
        headers: this.buildHeaders(ContentType.FormData),
        body,
      });
    };

    return this.stackSendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );
  }

  private async stackSendRequest<T>(
    request: () => Promise<Response>,
    handleTokenExpiration = true,
    synchroneRequest = false
  ): Promise<T> {
    if (BaseApiService.queueAllRequests) {
      BaseApiService.queuedRequests = BaseApiService.queuedRequests ?? defer();
      return BaseApiService.queuedRequests.promise.then(() => {
        return this.sendRequest<T>(
          request,
          handleTokenExpiration,
          synchroneRequest
        );
      });
    }

    if (synchroneRequest) {
      BaseApiService.queueAllRequests = true;
    }

    const p = this.sendRequest<T>(
      request,
      handleTokenExpiration,
      synchroneRequest
    );

    p.then(() => {
      if (synchroneRequest) {
        BaseApiService.queueAllRequests = false;
        BaseApiService.queuedRequests?.resolve();
        BaseApiService.queuedRequests = null;
      }
    }).catch((err) => {
      if (synchroneRequest) {
        BaseApiService.queueAllRequests = false;
        BaseApiService.queuedRequests?.reject(err);
        BaseApiService.queuedRequests = null;
      }
    });
    return p;
  }

  private async sendRequest<T>(
    request: () => Promise<Response>,
    handleTokenExpiration = true,
    synchroneRequest = false
  ): Promise<T> {
    const response = await request();
    return this.processResponse<T>(response, request, handleTokenExpiration);
  }

  protected async processResponse<T>(
    response: Response,
    request: () => Promise<Response>,
    handleTokenExpiration = true
  ): Promise<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let responseJson: any | null;
    try {
      responseJson = await response.json();
    } catch (err: unknown) {
      responseJson = null;
    }

    if (
      handleTokenExpiration &&
      responseJson?.error?.message &&
      ["TOKEN_EXPIRED", "jwt expired"].includes(responseJson.error.message)
    ) {
      try {
        await this.baseRefreshToken();

        const retryRequestResponse = await request();

        if (!retryRequestResponse.ok) {
          return Promise.reject(retryRequestResponse);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let retryRequestResponseJson: any | null;
        try {
          retryRequestResponseJson = await retryRequestResponse.json();
        } catch (err: unknown) {
          retryRequestResponseJson = null;
        }

        return retryRequestResponseJson as T;
      } catch (err: unknown) {
        await this.basePresign();
      }
    } else if (response.status === 401) {
      await this.basePresign();
    }

    if (!response.ok) {
      return Promise.reject({ response, body: responseJson });
    }

    return responseJson as T;
  }

  private async baseRefreshToken() {
    const response = await fetch(
      this.apiUrl.concat("/authentication").concat("/refresh-token"),
      {
        method: "POST",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody({
          accessToken: this.localStorageService.items.accessToken.get(),
          refreshToken: this.localStorageService.items.refreshToken.get(),
        }),
      }
    );

    if (!response.ok) {
      return Promise.reject(response);
    }

    const jwtPair = (await response.json()) as JwtPairDto;

    this.localStorageService.items.accessToken.set(jwtPair.accessToken);
    this.localStorageService.items.refreshToken.set(jwtPair.refreshToken);
    return;
  }

  private async basePresign() {
    const response = await fetch(
      this.apiUrl.concat("/authentication").concat("/presign"),
      {
        method: "POST",
        headers: this.buildHeaders(ContentType.Json),
      }
    );

    if (!response.ok) {
      return Promise.reject(response);
    }

    const jwtPair = (await response.json()) as JwtPairDto;
    this.localStorageService.items.accessToken.set(jwtPair.accessToken);
    this.localStorageService.items.refreshToken.set(jwtPair.refreshToken);
    return;
  }

  protected async postDownload(
    url: URL,
    body: { [key: string]: unknown } = {}
  ) {
    const request = async () =>
      await fetch(url, {
        method: "POST",
        headers: this.buildHeaders(ContentType.Json),
        body: this.buildBody(body),
      });
    const response = await request();
    return this.processBlobResponse(response, request);
  }

  protected async getDownload(url: URL) {
    const request = async () =>
      await fetch(url, {
        method: "GET",
        headers: this.buildHeaders(ContentType.Json),
      });
    const response = await request();
    return this.processBlobResponse(response, request);
  }

  protected async processBlobResponse(
    response: Response,
    request: () => Promise<Response>
  ): Promise<Blob> {
    let responseBlob: Blob | null;
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseBlob = await response.blob();
    } catch (err: unknown) {
      responseBlob = null;
    }

    if (response.status === 401) {
      try {
        await this.baseRefreshToken();

        const retryRequestResponse = await request();

        if (!retryRequestResponse.ok) {
          return Promise.reject(retryRequestResponse);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return await retryRequestResponse.blob();
      } catch (err: unknown) {
        await this.basePresign();
      }
    }

    if (!response.ok || !responseBlob) {
      return Promise.reject(response);
    }

    return responseBlob;
  }
}
