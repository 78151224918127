import { ModelId } from "models/common/Model";
import Widget from "models/widgets/Widget";

export function widgetQueryKey({
  widgetId,
  filter = {},
}: {
  widgetId: ModelId;
  filter?: Widget["properties"] | Record<string, never>;
}) {
  return ["widget", widgetId, filter];
}

export function getRefetchInterval(properties: Widget["properties"]) {
  return Widget.hasRelativeDates(properties)
    ? 1000 * 60 * 1 // 1 minute
    : false;
}

export const staleTimeInMs = 1000 * 60 * 1; // 1 minute
