import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
});

export const dateText: SxProps<Theme> = {
  fontSize: "14px",
};
