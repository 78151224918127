import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import SensorAuditService from "services/SensorAuditService";

function sensorAuditsQueryKey(id: ModelId | null) {
  return ["sensors", id, "audits"];
}

const staleTimeInMs = 1000 * 60 * 1;
const refetchIntervalInMs = 1000 * 60 * 1;

function useSensorAudits(id: ModelId | null) {
  const sensorIdValidation = useIdValidation(id);
  const queryClient = useQueryClient();

  const findManyQuery = useQuery(
    sensorAuditsQueryKey(id),
    async () =>
      sensorIdValidation.success
        ? container
            .resolve(SensorAuditService)
            .getSensorAudits({ sensorId: sensorIdValidation.data })
        : [],
    {
      enabled: sensorIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: refetchIntervalInMs,
    }
  );

  const auditSensorQuery = useMutation(
    sensorAuditsQueryKey(id),
    async (params: { fromDate: Date; toDate: Date }) =>
      sensorIdValidation.success
        ? container
            .resolve(SensorAuditService)
            .executeAudits({ ...params, sensorId: sensorIdValidation.data })
        : [],
    {
      onSuccess: () => {
        queryClient.invalidateQueries(sensorAuditsQueryKey(id));
      },
    }
  );

  return {
    findManyQuery,
    auditSensorQuery,
    audits: findManyQuery.data ?? [],
  };
}

export default useSensorAudits;
