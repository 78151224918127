import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  cursor: "pointer",
  overflow: "unset",
  border: `1px solid ${theme.palette.grey[400]}`,
});

export const content: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  paddingTop: 0,
};
