import { memo } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography } from "@mui/material";
import * as styles from "./styles";
import { DateFormat } from "utils/DateUtils";
import useMediaQueries from "hooks/useMediaQueries";
import {
  DateBoundary,
  isFixDateBoundary,
  isRelativeDateBoundary,
} from "models/widgets/Widget";
import I18n from "components/materials/I18n";
import RelativeDate from "./RelativeDate";
import FixDate from "./FixDate";

type Props = {
  fromDate: DateBoundary;
  toDate: DateBoundary;
  format: DateFormat;
  wrapable?: boolean;
};

function DateTimeRange({ fromDate, toDate, wrapable, format }: Props) {
  const mediaQueries = useMediaQueries();

  const isFromDateFix = isFixDateBoundary(fromDate);
  const isFromDateRelative = isRelativeDateBoundary(fromDate);

  const isToDateFix = isFixDateBoundary(toDate);
  const isToDateRelative = isRelativeDateBoundary(toDate);

  return (
    <Box sx={styles.dateContainer(mediaQueries, !!wrapable)}>
      <Box sx={styles.fromContent}>
        <Typography sx={styles.dateInfo}>
          {I18n.translate("general_text.from")}
        </Typography>
        <Box sx={styles.date}>
          {isFromDateFix && <FixDate fixDate={fromDate} format={format} />}

          {isFromDateRelative && (
            <RelativeDate relativeDate={fromDate} format={format} />
          )}

          {(!wrapable || mediaQueries.isSm) && (
            <ArrowForwardIcon sx={styles.arrowIcon} />
          )}
        </Box>
      </Box>
      <Box sx={styles.toContent}>
        <Typography sx={styles.dateInfo}>
          {I18n.translate("general_text.to")}
        </Typography>
        <Box sx={styles.date}>
          {isToDateFix && <FixDate fixDate={toDate} format={format} />}

          {isToDateRelative && (
            <RelativeDate relativeDate={toDate} format={format} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(DateTimeRange);
