import { memo } from "react";
import WidgetsContainer from "components/materials/WidgetsContainer";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorWidgets from "hooks/queries/useSensorWidgets";
import useCurrentPageType from "hooks/useCurrentPageType";
import useSensor from "hooks/queries/useSensor";

function SensorDataSection() {
  const { sensorId } = useSelectedSensorIdContext();
  const { widgets } = useSensorWidgets(sensorId);

  const { isUserLaboratorySensor } = useSensor(sensorId);
  const { isLaboratoryPage } = useCurrentPageType();

  const canEditWidgets = isUserLaboratorySensor && isLaboratoryPage;
  const canSaveDrag = isUserLaboratorySensor && isLaboratoryPage;

  return (
    <WidgetsContainer
      widgets={widgets}
      withEditAction={canEditWidgets}
      withDragAction
      canSaveDrag={canSaveDrag}
    />
  );
}

export default memo(SensorDataSection);
