import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
});

export const radioGroup: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
});
