import { ModelId } from "models/common/Model";
import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import SensorDataUtils from "utils/SensorResultUtils";
import { useMemo } from "react";
import { Co2AverageWidget } from "models/widgets/Widget";
import useSensor from "../useSensor";
import { widgetQueryKey, getRefetchInterval, staleTimeInMs } from "./common";

function useCo2AverageWidget(
  widgetId: ModelId,
  properties: Co2AverageWidget["properties"]
) {
  const query = useQuery(
    widgetQueryKey({ widgetId, filter: properties }),
    async () =>
      container.resolve(SensorService).getSensorsWithResults({
        fromDate: properties.fromDate,
        toDate: properties.toDate,
        sensorIds: [properties.sensorId],
      }),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: getRefetchInterval(properties),
    }
  );

  const { sensor } = useSensor(properties.sensorId);

  const co2QuantitiesAverage = useMemo(() => {
    const sensorWithData = query.data?.[0] ?? null;
    if (!sensorWithData) {
      return 0;
    }

    return SensorDataUtils.computeCo2QuantitiesAverage(
      SensorDataUtils.aggregateCo2Quantities(sensorWithData)
    );
  }, [query.data]);

  return {
    ...query,
    co2QuantitiesAverage,
    sensor,
  };
}

export default useCo2AverageWidget;
