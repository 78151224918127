import { CircularProgress, CircularProgressProps } from "@mui/material";
import { memo } from "react";
import * as styles from "./styles";

function CircularLoader(props: CircularProgressProps) {
  return (
    <CircularProgress size={props.size ?? "70px"} sx={styles.root(props.sx)} />
  );
}

export default memo(CircularLoader);
