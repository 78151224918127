import { Box, Typography } from "@mui/material";
import { Fragment, memo } from "react";
import * as styles from "./styles";

type Props = {
  errors: string[];
  isHelperText?: boolean;
};

function FormErrors({ errors, isHelperText }: Props) {
  if (isHelperText) {
    return (
      <>
        {errors.map((error) => (
          <Fragment key={error}>
            <Box component="span" sx={styles.error}>
              {error}
            </Box>
            <br />
          </Fragment>
        ))}
      </>
    );
  }

  return (
    <Box sx={styles.root}>
      {errors.map((error) => (
        <Typography key={error} sx={styles.error}>
          {error}
        </Typography>
      ))}
    </Box>
  );
}

export default memo(FormErrors);
