import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { DtoDate, DtoId } from "./dtos/common/Dto";
import { SensorAuditDto } from "./dtos/sensor/SensorAuditDto";
import { SensorAuditExecutionMode } from "models/sensor/SensorAuditExecutionMode";

@singleton()
export default class SensorAuditApi extends BaseApi {
  public async executeAudits(params: {
    sensorId: DtoId;
    fromDate: DtoDate;
    toDate: DtoDate;
  }) {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/audits`);

    const queryParams = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      executionMode: SensorAuditExecutionMode.Remote,
    };

    UrlUtils.appendQueryParams(url, queryParams);

    await this.postRequest(url);
  }

  public async downloadAudits(params: {
    sensorId: DtoId;
    fromDate: DtoDate;
    toDate: DtoDate;
  }): Promise<Blob> {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/audits`);

    const queryParams = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      executionMode: SensorAuditExecutionMode.Local,
    };

    UrlUtils.appendQueryParams(url, queryParams);

    return await this.postDownload(url);
  }

  public async findMany(params: {
    queryParams?: {
      sensorId?: DtoId;
    };
  }): Promise<SensorAuditDto[]> {
    const url = new URL(`${this.apiUrl}/audits`);

    if (params.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<SensorAuditDto[]>(url);
  }

  public async getById(id: DtoId): Promise<SensorAuditDto | null> {
    const url = new URL(`${this.apiUrl}/audits/${id}`);

    return this.getRequest<SensorAuditDto>(url);
  }
}
