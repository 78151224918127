import { useCallback } from "react";
import { useMap } from "react-leaflet";
import * as LeafLet from "leaflet";

function useFlyMap() {
  const map = useMap();
  const flyTo = useCallback(
    (position: { longitude: number; latitude: number }) => {
      map.flyTo([position.latitude, position.longitude] as LeafLet.LatLngTuple);
    },
    [map]
  );

  return { flyTo };
}

export default useFlyMap;
