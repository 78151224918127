import { TableCell, Box, Divider, Tooltip } from "@mui/material";
import { ArrowUpward, InfoOutlined } from "@mui/icons-material";
import { memo, useState } from "react";

import * as styles from "./styles";
import { Direction } from "models/common/Direction";

type Props = {
  children: React.ReactNode;
  divider?: boolean;
  onSort?: (direction: Direction) => void;
  tooltipText?: string;
  initialDirection?: Direction;
};

function SortTableCell({
  children,
  divider,
  onSort,
  tooltipText,
  initialDirection,
}: Readonly<Props>) {
  const [direction, setDirection] = useState<Direction>(
    initialDirection ?? Direction.Desc
  );

  return (
    <TableCell>
      <Box sx={styles.container}>
        <Box sx={styles.content(!!onSort)} onClick={handleSort}>
          {children}
          {onSort && <ArrowUpward sx={styles.arrow(direction)} />}
          {tooltipText && (
            <Tooltip title={tooltipText} placement="top-start">
              <InfoOutlined sx={{ cursor: "help" }} color="action" />
            </Tooltip>
          )}
        </Box>
        {divider && <Divider orientation="vertical" sx={{ height: 24 }} />}
      </Box>
    </TableCell>
  );

  function handleSort() {
    if (!onSort) return;
    const nextDirection =
      direction === Direction.Asc ? Direction.Desc : Direction.Asc;
    setDirection(nextDirection);
    onSort(nextDirection);
  }
}

export default memo(SortTableCell);
