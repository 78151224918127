import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const content: SxProps<Theme> = (theme) => ({
  height: "160px",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: theme.spacing(1),
});

export const loadingContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const description: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

export const unit: SxProps<Theme> = (theme) => {
  return {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    color: theme.palette.primary.main,
  };
};
