import I18n from "components/materials/I18n";
import useMediaQueries from "hooks/useMediaQueries";
import ReactApexChart from "react-apexcharts";
import { memo, useMemo } from "react";
import DateUtils, { DateFormat } from "utils/DateUtils";
import useCo2EvolutionData from "hooks/queries/widgetData/useCo2EvolutionData";

type Props = {
  allSensorResultsAveragesPerHour: ReturnType<
    typeof useCo2EvolutionData
  >["allSensorResultsAveragesPerHour"];
};

function Co2EvolutionChart({ allSensorResultsAveragesPerHour }: Props) {
  const mediaQueries = useMediaQueries();

  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      stroke: {
        curve: "smooth",
      },
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        show: true,
        position: "back",
        xaxis: {
          lines: {
            show: true,
            offsetX: 100,
            offsetY: 100,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        tickAmount: mediaQueries.isMd ? 16 : 8,
        tickPlacement: "on",
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 500,
          offsetX: 100,
          offsetY: 200,
        },
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: -1,
        },
        offsetX: 0,
        offsetY: 0,
        labels: {
          formatter: function (value: string, _, opts): string {
            if (opts.i === 0) {
              return "";
            }

            return DateUtils.formatUTCDateFromDateString(
              value,
              DateFormat.DayMonthShortYearHourMinute
            );
          },
        },
      },
      yaxis: {
        title: {
          text: "CO2 (ppm)",
          offsetX: 0,
          offsetY: 0,
        },
        tickAmount: 8,
        axisBorder: {
          show: true,
          offsetX: 0,
          offsetY: -1,
        },
        labels: {
          formatter: function (value: number, opts: number) {
            if (opts === 0) {
              return "";
            }

            return value === 0 ? "" : value.toFixed(2);
          },
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 500,
            cssClass: "styles.yaxisChart",
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "top",
        horizontalAlign: "left",
        fontSize: "14px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        offsetX: -30,
        offsetY: 0,
        labels: {
          colors: "white",
        },
        markers: {
          width: 70,
          height: 24,
          radius: 16,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 60,
          offsetY: 7,
        },
        itemMargin: {
          horizontal: -20,
          vertical: 8,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        followCursor: true,
        custom: undefined,
        inverseOrder: true,
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        x: {
          show: true,
          format: "dd MMM",
        },
        y: {
          formatter: (co2) =>
            co2
              ? co2.toFixed(2) + "ppm"
              : I18n.translate("general_text.missing_data"),
          title: {
            formatter: (title) => title + ": ",
          },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: false,
        },
        items: {
          display: "flex",
        },
      },
    }),
    [mediaQueries.isMd]
  );

  const series: ApexAxisChartSeries = useMemo(
    () =>
      allSensorResultsAveragesPerHour.map(
        ({ name, sensorResultsAveragesPerHour }) => {
          const data = sensorResultsAveragesPerHour.map(
            ({ hourlyCo2QuantitiesAverage, dateTime }) => {
              return {
                x: dateTime,
                y: hourlyCo2QuantitiesAverage || null,
              };
            }
          );

          return {
            name,
            data,
          };
        }
      ),
    [allSensorResultsAveragesPerHour]
  );

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={"100%"}
    />
  );
}

export default memo(Co2EvolutionChart);
