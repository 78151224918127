import User from "models/user/User";
import { container, singleton } from "tsyringe";
import UserApi from "./apis/ceaMoonshot/UserApi";

@singleton()
export default class UserService {
  public async findMany(params: {}): Promise<User[]> {
    const usersDto = await container.resolve(UserApi).findMany(params);
    return usersDto.map((dto) => User.fromDto(dto));
  }
}
