import Widget from "models/widgets/Widget";
import Co2Average from "components/materials/widgets/Co2Average";
import BlockchainEnergyCost from "components/materials/widgets/BlockchainEnergyCost";
import Co2Evolution from "components/materials/widgets/Co2Evolution";
import Co2RelativeAverage from "components/materials/widgets/Co2RelativeAverage";
import SensorMap from "components/materials/widgets/SensorMap";
import { memo } from "react";
import WidgetTemplate from "components/materials/widgets/WidgetTemplate";
import { Nullable } from "types/common";

type Props = {
  widget: Widget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<Widget["properties"]> | null
  ) => void;
  onUserValidation?: (properties: Widget["properties"]) => void;
  withEditAction: boolean;
  withDragAction: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};

function WidgetContainer({
  widget,
  onPropertiesChange,
  onUserValidation,
  withEditAction,
  withDragAction,
  shouldShowEditionForm,
  onDragStart,
  onDragEnd,
}: Props) {
  return (
    <WidgetTemplate
      widget={widget}
      withEditAction={withEditAction}
      withDragAction={withDragAction}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      {widget.isCo2AverageWidget() && (
        <Co2Average
          widget={widget}
          shouldShowEditionForm={shouldShowEditionForm}
          onPropertiesChange={onPropertiesChange}
          onUserValidation={onUserValidation}
        />
      )}

      {widget.isCo2EvolutionWidget() && (
        <Co2Evolution
          widget={widget}
          shouldShowEditionForm={shouldShowEditionForm}
          onPropertiesChange={onPropertiesChange}
          onUserValidation={onUserValidation}
        />
      )}

      {widget.isSensorMapWidget() && (
        <SensorMap
          widget={widget}
          shouldShowEditionForm={shouldShowEditionForm}
          onPropertiesChange={onPropertiesChange}
          onUserValidation={onUserValidation}
        />
      )}

      {widget.isCo2RelativeAverageWidget() && (
        <Co2RelativeAverage
          widget={widget}
          shouldShowEditionForm={shouldShowEditionForm}
          onPropertiesChange={onPropertiesChange}
          onUserValidation={onUserValidation}
        />
      )}

      {widget.isBlockchainEnergyCostWidget() && (
        <BlockchainEnergyCost
          widget={widget}
          shouldShowEditionForm={shouldShowEditionForm}
          onPropertiesChange={onPropertiesChange}
          onUserValidation={onUserValidation}
        />
      )}
    </WidgetTemplate>
  );
}

export default memo(WidgetContainer);
