import assert from "assert";
import { SnackBarContext } from "contexts/SnackBarContextProvider";
import { useContext } from "react";

export default function useSnackBarContext() {
  const context = useContext(SnackBarContext);
  assert(
    context !== null,
    "useSnackBarContext must be used within a SnackBarContextProvider"
  );
  return context;
}
