import { SxProps, Theme } from "@mui/material";

function getChipBackgroundColor(
  theme: Theme,
  isCompliant: boolean | undefined
) {
  if (isCompliant === undefined) {
    return theme.palette.info.light;
  }

  if (isCompliant) {
    return theme.palette.primary.main;
  }

  return theme.palette.warning.main;
}

export const root: (isCompliant: boolean | undefined) => SxProps<Theme> =
  (isCompliant) => (theme) => {
    return {
      color: theme.palette.primary.contrastText,
      background: getChipBackgroundColor(theme, isCompliant),
      height: theme.spacing(4),
      userSelect: "none",
    };
  };
