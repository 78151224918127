import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Sensor from "models/sensor/Sensor";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import CopyToClipboard from "components/element/CopyToClipboard";
import { useNavigate } from "react-router-dom";
import SensorDataUtils from "utils/SensorResultUtils";
import useMediaQueries from "hooks/useMediaQueries";
import BlockchainAddressUtils from "utils/BlockchainAddressUtils";
import { Apartment, ExpandLess } from "@mui/icons-material";
import mapMarkerGreen from "icons/mapMarkers/mapMarkerGreen.svg";
import useFlyMap from "hooks/useFlyMap";
import useRoutes from "hooks/useRoutes";
import SensorPicture from "components/materials/SensorPicture";

type Props = {
  sensor: Sensor;
};

function DetailMapTooltip({ sensor }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const routes = useRoutes();
  const mediaQueries = useMediaQueries();
  const { flyTo } = useFlyMap();

  const handleClickOnViewData = useCallback(() => {
    navigate(
      routes.externalUser.sensor.sensorData.resolveRoute({
        sensorId: sensor.id,
      })
    );
  }, [routes.externalUser.sensor.sensorData, navigate, sensor.id]);

  const handleClickOnAudit = useCallback(() => {
    navigate(
      routes.externalUser.sensor.sensorAudits.resolveRoute({
        sensorId: sensor.id,
      })
    );
  }, [routes.externalUser.sensor.sensorAudits, navigate, sensor.id]);

  const formatBlockchainAddress = useCallback(
    (address: string): string => {
      if (mediaQueries.isStrictlyXxs) {
        return BlockchainAddressUtils.shortenAddress(address, 6, 6);
      }

      return BlockchainAddressUtils.shortenAddress(address, 10, 10);
    },
    [mediaQueries.isStrictlyXxs]
  );

  const handleClickOnHeader = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const handleClickOnMarker = useCallback(() => {
    flyTo({
      latitude: sensor?.currentSituation?.latitude || 0,
      longitude: sensor?.currentSituation?.longitude || 0,
    });
  }, [
    flyTo,
    sensor?.currentSituation?.latitude,
    sensor?.currentSituation?.longitude,
  ]);

  return (
    <Paper sx={styles.root}>
      <Accordion elevation={0} defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<ExpandLess />}>
          <Box sx={styles.header} onClick={handleClickOnHeader}>
            <IconButton onClick={handleClickOnMarker}>
              <Box component="img" src={mapMarkerGreen} alt="" />
            </IconButton>

            <Typography variant="subtitle1">
              {SensorDataUtils.formatDisplayName(sensor)}
            </Typography>

            <Box color={theme.palette.grey[500]} ml={2} mt={1}>
              <Apartment />
            </Box>

            <Typography variant="body2">
              {sensor.laboratory ? sensor.laboratory.name : "--"}
            </Typography>
          </Box>
        </AccordionSummary>

        <Divider />

        <Box sx={styles.content}>
          <SensorPicture sensor={sensor} sx={styles.image} />

          <Box sx={styles.description}>
            <Box mt={2}>
              <Typography variant="subtitle2">
                <I18n map="components.sensors_map.detail_tooltip.laboratory_blockchain_address" />
              </Typography>
              {sensor.laboratory && (
                <CopyToClipboard text={sensor.laboratory.blockchainAddress}>
                  <Typography variant="body2">
                    {sensor.laboratory
                      ? formatBlockchainAddress(
                          sensor.laboratory.blockchainAddress
                        )
                      : "--"}
                  </Typography>
                </CopyToClipboard>
              )}
            </Box>

            <Box>
              <Typography variant="subtitle2">
                <I18n map="components.sensors_map.detail_tooltip.sensor_blockchain_address" />
              </Typography>
              <CopyToClipboard text={sensor.blockchainAddress}>
                <Typography variant="body2">
                  {formatBlockchainAddress(sensor.blockchainAddress)}
                </Typography>
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      </Accordion>

      <Box sx={styles.actions}>
        <Button
          variant="outlined"
          color="info"
          onClick={handleClickOnViewData}
          fullWidth
        >
          <I18n
            map={"components.sensors_map.detail_tooltip.action.view_data"}
          />
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOnAudit}
          fullWidth
        >
          <I18n map={"components.sensors_map.detail_tooltip.action.audits"} />
        </Button>
      </Box>
    </Paper>
  );
}

export default memo(DetailMapTooltip);
