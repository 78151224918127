import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
  };
};

export const container: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
};
