import { useMap } from "react-leaflet";
import { memo, useEffect } from "react";
import * as Leaflet from "leaflet";

function FlyMapTo({ position }: { position: Leaflet.LatLngExpression }) {
  const map = useMap();

  useEffect(() => {
    map.flyTo(position);
  }, [map, position]);

  return null;
}

export default memo(FlyMapTo);
