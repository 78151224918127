import { memo } from "react";
import HeaderXxs from "./HeaderXxs";
import HeaderSm from "./HeaderSm";
import useMediaQueries from "hooks/useMediaQueries";

function Header() {
  const mediaQueries = useMediaQueries();

  if (mediaQueries.isSm) {
    return <HeaderSm />;
  }

  return <HeaderXxs />;
}

export default memo(Header);
