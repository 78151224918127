import { SxProps, Theme } from "@mui/material";

export const rowContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(3),
});

export const datePicker: SxProps<Theme> = (theme) => ({
  width: "100%",
});
