import { SxProps, Theme } from "@mui/material";

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(0),
});

export const actionsContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
};

export const rightActions: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(2),
});
