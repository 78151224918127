import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import { OAuthProviderDto } from "./dtos/authentication/OAuthProviderDto";

@singleton()
export default class OAuthProviderApi extends BaseApi {
  public async getByName(name: string): Promise<OAuthProviderDto> {
    const url = new URL(`${this.apiUrl}/app-o-auth-providers/${name}`);

    return this.getRequest<OAuthProviderDto>(url);
  }
}
