import "react-grid-layout/css/styles.css"; // this is important for draggable
import "./customReactGridLayoutStyles.scss"; // this is important for draggable
import { memo, useCallback, useMemo, useState } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import Widget from "models/widgets/Widget";
import { GridLayoutSize } from "models/common/GridLayoutSize";
import WidgetsContainerUtils from "./utils";
import WidgetContainer from "./WidgetContainer";
import { Card } from "@mui/material";
import useMediaQueries from "hooks/useMediaQueries";
import * as styles from "./styles";
import { WidgetLayouts } from "models/widgets/WidgetLayouts";
import useSensorWidgets from "hooks/queries/useSensorWidgets";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";

const ResponsiveGridLayout = memo(WidthProvider(Responsive));

type Props = {
  widgets: Widget[];
  withDragAction: boolean;
  withEditAction: boolean;
  canSaveDrag: boolean;
};

function WidgetsContainer({
  widgets,
  withEditAction,
  withDragAction,
  canSaveDrag,
}: Props) {
  const { sensorId } = useSelectedSensorIdContext();
  const { updateManyLayoutsQuery } = useSensorWidgets(sensorId);
  const mediaQueries = useMediaQueries();
  const layouts: Layouts = useMemo(
    () =>
      Object.keys(WidgetsContainerUtils.gridLayoutConfig).reduce((acc, key) => {
        acc[key as GridLayoutSize] = widgets.map((widget) => {
          const layout = widget.layouts[key as GridLayoutSize];
          return {
            i: `${widget.id}`,
            x: layout.x,
            y: layout.y,
            w: layout.w,
            h: layout.h,
          };
        });
        return acc;
      }, {} as Record<GridLayoutSize, Layout[]>),
    [widgets]
  );

  const [draggable, setDraggable] = useState(false);

  const handleDragStart = useCallback(
    () => setDraggable(withDragAction),
    [withDragAction]
  );

  const handleDragEnd = useCallback(() => setDraggable(false), []);

  const handleLayoutChange = useCallback(
    (_: Layout[], allLayouts: Layouts) => {
      const widgetsLayoutsUpdates = widgets.map((widget) => {
        const layouts = Object.keys(allLayouts).reduce((acc, key) => {
          const { x, y, w, h } = allLayouts[key as GridLayoutSize]!.find(
            (layout) => layout.i === `${widget.id}`
          )!;
          acc[key as GridLayoutSize] = { x, y, w, h };
          return acc;
        }, {} as WidgetLayouts);
        return { id: widget.id, layouts };
      });

      if (canSaveDrag) {
        updateManyLayoutsQuery.mutate(widgetsLayoutsUpdates);
      }
    },
    [canSaveDrag, updateManyLayoutsQuery, widgets]
  );

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      breakpoints={WidgetsContainerUtils.breakpoints}
      cols={WidgetsContainerUtils.cols}
      containerPadding={[0, 0]}
      rowHeight={WidgetsContainerUtils.rowHeight(mediaQueries)}
      isResizable={false}
      isDraggable={draggable}
      onLayoutChange={handleLayoutChange}
    >
      {widgets.map((widget) => (
        <Card
          key={`${widget.id}`}
          elevation={0}
          sx={styles.widgetContainer({ widget, mediaQueries, layouts })}
        >
          <WidgetContainer
            shouldShowEditionForm={false}
            widget={widget}
            withEditAction={withEditAction}
            withDragAction={withDragAction}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          />
        </Card>
      ))}
    </ResponsiveGridLayout>
  );
}

export default memo(WidgetsContainer);
