import { Box, TextField, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import FormErrors from "components/element/FormErrors";
import CustomDatePicker from "components/element/inputs/CustomDatePicker";
import { MetadataFormErrors } from "./useMetadataValidation";
import { DayJsFormat } from "utils/DayjsUtils";
import FileInput from "components/element/FileInput";
import SensorPictureUtils from "utils/SensorPictureUtils";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import Module from "components/materials/Module";

export type MetadataUpdates = {
  image: File | null;
  identifier: string;
  type: string;
  name: string;
  blockchainAddress: string;
  blockchainActivationDate: Date | null;
};

type Props = {
  onMetadataChange: (metadata: MetadataUpdates) => void;
  metadata: MetadataUpdates;
  errors: MetadataFormErrors;
};

function MetadataForm(props: Props) {
  const handleFileChange = useCallback(
    (file: File | null) => {
      if (!file) {
        props.onMetadataChange({
          ...props.metadata,
          image: null,
        });
        return;
      }

      props.onMetadataChange({
        ...props.metadata,
        image: file,
      });
    },
    [props]
  );

  const handleIdentifierChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onMetadataChange({
        ...props.metadata,
        identifier: event.target.value,
      });
    },
    [props]
  );

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onMetadataChange({
        ...props.metadata,
        type: event.target.value,
      });
    },
    [props]
  );

  const handleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onMetadataChange({
        ...props.metadata,
        name: event.target.value,
      });
    },
    [props]
  );

  const handleBlockchainAddressChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onMetadataChange({
        ...props.metadata,
        blockchainAddress: event.target.value,
      });
    },
    [props]
  );

  const handleBlockchainActivationDateChange = useCallback(
    (date: Date | null) => {
      props.onMetadataChange({
        ...props.metadata,
        blockchainActivationDate: date,
      });
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="h5">
        <I18n map="components.sensor_metadata_form.label" />
      </Typography>

      <Module
        from={
          container.resolve(ModuleConfig).get().modules.components
            .sensorImageEdition.creation
        }
      >
        <Box mb={3}>
          <FileInput
            name="sensorImage"
            label={
              <Typography variant="subtitle1">
                <I18n map="components.sensor_metadata_form.image.label" />
              </Typography>
            }
            description={
              <Typography variant="body2">
                <I18n map="components.sensor_metadata_form.image.description" />
              </Typography>
            }
            onChange={handleFileChange}
            defaultAvatar={
              props.metadata.image ?? SensorPictureUtils.fallbackPictureUrl
            }
            allowedExtensions={[".png", ".jpg", ".jpeg"]}
            maxMbSize={8}
          />
        </Box>
      </Module>

      <TextField
        fullWidth
        required
        label={I18n.translate("components.sensor_metadata_form.type.label")}
        placeholder={I18n.translate(
          "components.sensor_metadata_form.type.placeholder"
        )}
        value={props.metadata.type}
        onChange={handleTypeChange}
        error={Boolean(props.errors.type.length)}
        helperText={<FormErrors errors={props.errors.type} isHelperText />}
      />

      <TextField
        fullWidth
        required
        label={I18n.translate("components.sensor_metadata_form.name.label")}
        placeholder={I18n.translate(
          "components.sensor_metadata_form.name.placeholder"
        )}
        value={props.metadata.name}
        onChange={handleNameChange}
        error={Boolean(props.errors.name.length)}
        helperText={<FormErrors errors={props.errors.name} isHelperText />}
      />

      <TextField
        fullWidth
        required
        label={I18n.translate(
          "components.sensor_metadata_form.identifier.label"
        )}
        placeholder={I18n.translate(
          "components.sensor_metadata_form.identifier.placeholder"
        )}
        value={props.metadata.identifier}
        onChange={handleIdentifierChange}
        error={Boolean(props.errors.identifier.length)}
        helperText={
          <FormErrors errors={props.errors.identifier} isHelperText />
        }
      />

      <TextField
        fullWidth
        required
        label={I18n.translate(
          "components.sensor_metadata_form.sensor_blockchain_address.label"
        )}
        placeholder={I18n.translate(
          "components.sensor_metadata_form.sensor_blockchain_address.placeholder"
        )}
        value={props.metadata.blockchainAddress}
        onChange={handleBlockchainAddressChange}
        error={Boolean(props.errors.blockchainAddress.length)}
        helperText={
          <FormErrors errors={props.errors.blockchainAddress} isHelperText />
        }
      />

      <Box marginRight="auto">
        <CustomDatePicker
          date={props.metadata.blockchainActivationDate}
          onDateChange={handleBlockchainActivationDateChange}
          label={I18n.translate(
            "components.sensor_metadata_form.sensor_blockchain_activation_date.label"
          )}
          required
          withTime={false}
          errors={props.errors.blockchainActivationDate}
          disableFuture
          format={DayJsFormat.DayMonthYear}
        />
      </Box>

      {props.errors.extra.length > 0 && (
        <FormErrors errors={props.errors.extra} />
      )}
    </Box>
  );
}

export default memo(MetadataForm);
