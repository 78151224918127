import { Chip } from "@mui/material";
import { memo } from "react";

import I18n from "components/materials/I18n";

import * as styles from "./styles";
import { FileAuditionStatus } from "models/auditReport/FileAuditionStatus";

const translation = {
  [FileAuditionStatus.AllClear]: "all_clear",
  [FileAuditionStatus.AnomalyError]: "anomaly_error",
  [FileAuditionStatus.StorageError]: "storage_error",
  [FileAuditionStatus.ConnectionError]: "connection_error",
  [FileAuditionStatus.FatalError]: "fatal_error",
} as const;

type Props = {
  status: FileAuditionStatus;
};

function FileAuditionStatusChip({ status }: Readonly<Props>) {
  return (
    <Chip
      sx={styles.root(status)}
      label={I18n.translate("file_audition_status.".concat(translation[status]))}
    />
  );
}

export default memo(FileAuditionStatusChip);
