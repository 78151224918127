import Sensor from "models/sensor/Sensor";
import { useEffect, useState } from "react";
import SensorPictureUtils from "utils/SensorPictureUtils";

type Props = {
  sensor: Sensor | null;
};

function useSensorPicture({ sensor }: Props) {
  const [imageUrl, setImageUrl] = useState(
    SensorPictureUtils.fallbackPictureUrl
  );

  const [isFallback, setIsFallback] = useState(false);

  useEffect(() => {
    if (!sensor) {
      setImageUrl(SensorPictureUtils.fallbackPictureUrl);
      setIsFallback(true);
      return;
    }

    const imageUrl = SensorPictureUtils.getPicture(sensor);
    setImageUrl(imageUrl);
    setIsFallback(false);
  }, [sensor]);

  return {
    imageUrl,
    isFallback,
  };
}

export default useSensorPicture;
