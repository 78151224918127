import { Card } from "@mui/material";
import { memo } from "react";
import Sensor from "models/sensor/Sensor";
import * as styles from "./styles";
import SensorDetailXxsContent from "./SensorDetailXxsContent";
import useMediaQueries from "hooks/useMediaQueries";
import SensorDetailSmContent from "./SensorDetailSmContent";

type Props = {
  sensor: Sensor;
};

function SensorDetail({ sensor }: Props) {
  const mediaQueries = useMediaQueries();

  return (
    <Card sx={styles.root} elevation={0}>
      {mediaQueries.isStrictlyXxs && <SensorDetailXxsContent sensor={sensor} />}
      {mediaQueries.isStrictlyXs && <SensorDetailXxsContent sensor={sensor} />}
      {mediaQueries.isSm && <SensorDetailSmContent sensor={sensor} />}
    </Card>
  );
}

export default memo(SensorDetail);
