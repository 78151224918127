import { SxProps, Theme } from "@mui/material";
import { borderRadius } from "themes/constants";

export const root: SxProps<Theme> = (theme) => {
  return {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    left: theme.spacing(3),
    right: theme.spacing(3),
    bottom: theme.spacing(4),
    zIndex: 1000,
    px: theme.spacing(3),
    pb: theme.spacing(3),
    maxWidth: "400px",
  };
};

export const header: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  cursor: "default",
});

export const headerInfo: SxProps<Theme> = (theme) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
});

export const coordinates: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const image: SxProps<Theme> = {
  width: "112px",
  height: "112px",
  objectFit: "cover",
  borderRadius,
};

export const content: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
};

export const description: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  padding: theme.spacing(2),
});

export const actions: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
});
