import { SensorResultDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorResultDto";
import DateUtils from "utils/DateUtils";
import SensorResultsSession from "./SensorResultsSession";
import { ModelId } from "models/common/Model";

export default class SensorResult {
  public readonly id: ModelId;
  public readonly co2Quantity: number;
  public readonly dateTime: Date;
  public readonly sensorResultsSession?: SensorResultsSession | null;

  private constructor(params: {
    id: ModelId;
    co2Quantity: number;
    dateTime: Date;
    sensorResultsSession?: SensorResultsSession | null;
  }) {
    this.id = params.id;
    this.co2Quantity = params.co2Quantity;
    this.dateTime = params.dateTime;
    this.sensorResultsSession = params.sensorResultsSession;
  }

  public static fromDto(dto: SensorResultDto): SensorResult {
    return new SensorResult({
      id: dto.id,
      co2Quantity: dto.co2Quantity,
      dateTime: DateUtils.fromIsoString(dto.dateTime),
      sensorResultsSession: dto.sensorResultsSession
        ? SensorResultsSession.fromDto(dto.sensorResultsSession)
        : dto.sensorResultsSession,
    });
  }
}
