import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useRoutes from "hooks/useRoutes";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const routes = useRoutes();

  const handleBackToSensorsClick = useCallback(() => {
    navigate(routes.laboratory.sensors.resolveRoute());
  }, [navigate, routes.laboratory.sensors]);

  return (
    <Button
      variant="outlined"
      color="info"
      startIcon={<ChevronLeft />}
      onClick={handleBackToSensorsClick}
    >
      <I18n map="pages.sensors.title" />
    </Button>
  );
}

export default memo(BackButton);
