import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { headerHeight } from "themes/constants";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      maxHeight: `calc(100vh - ${headerHeight})`,
      marginTop: headerHeight,
      width: "100%",
      boxSizing: "border-box",
    },
    "& .MuiPaper-root": {
      boxShadow:
        "0px 6px 5px 5px rgba(0, 0, 0, 0.12), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 8px 10px -5px rgba(0, 0, 0, 0.20);",
    },
    "& .MuiModal-backdrop": {
      background: "transparent",
    },

    ...(mediaQueries.isSm && {
      [`& .MuiDrawer-paper`]: {
        maxHeight: `calc(100vh - ${headerHeight})`,
        marginTop: headerHeight,
        width: "320px",
      },
    }),
  });

export const header: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingRight: theme.spacing(1),
});

export const sortButtonContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "flex-start",
});

export const sortMenu: SxProps<Theme> = (theme) => ({
  top: theme.spacing(4),
});

export const notificationsContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  borderTop: `1px solid ${theme.palette.divider}`,
});
