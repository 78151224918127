import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { borderRadius } from "themes/constants";

export const root: (isSelected?: boolean) => SxProps<Theme> =
  (isSelected) => (theme) => ({
    display: "flex",
    borderRadius,
    padding: theme.spacing(3),
    justifyContent: "space-between",
    ...(isSelected && { backgroundColor: theme.palette.grey[100] }),
  });

export const content: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),

    ...(mediaQueries.isSm && {
      gap: theme.spacing(2),
    }),
  });

export const image: (mediaQueries: MediaQueries) => SxProps<Theme> = (
  mediaQueries
) => ({
  width: 80,
  height: 80,
  objectFit: "cover",
  borderRadius,

  ...(mediaQueries.isXs &&
    mediaQueries.isBelow(450) && {
      width: 100,
      height: 100,
    }),

  ...(mediaQueries.isAbove(450) && {
    width: 160,
    height: 160,
  }),
});

export const description: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
});

export const descriptionHeader: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
  flexWrap: "wrap",
});

export const action: SxProps<Theme> = {
  display: "flex",
};

export const actionIcon: SxProps<Theme> = (theme) => ({
  color: theme.palette.grey[500],
});
