type HttpResponse = {
  status: number;
  statusText: string;
  ok: boolean;
  url: string;
};

export function isHttpResponse(error: any): error is HttpResponse {
  return (
    "status" in error &&
    "statusText" in error &&
    "ok" in error &&
    "url" in error
  );
}
