import useApplicationConfig from "./queries/useApplicationConfig";

function useFetchedApplicationConfig() {
  const { applicationConfig } = useApplicationConfig();

  if (!applicationConfig) {
    throw new Error(
      "Application config not found, please use the useApplicationConfig hook at application initialization level."
    );
  }

  return { applicationConfig };
}

export default useFetchedApplicationConfig;
