import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import EmailPasswordForm from "./EmailPasswordForm";
import GoogleForm from "./GoogleForm";
import * as styles from "./styles";
import useMediaQueries from "hooks/useMediaQueries";
import { ErrorBoundary } from "react-app-error-boundary";
import I18n from "components/materials/I18n";
import useUser from "hooks/queries/useUser";
import OrDivider from "components/element/OrDivider";
import useAuthenticatedNavigation from "./useAuthenticatedNavigation";
import { memo, useCallback } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export type SignInComponentsProps = {
  handleSignIn: () => void;
};

function SignInDialog({ isOpen, onClose }: Props) {
  const mediaQueries = useMediaQueries();
  const { autoConnect } = useUser();
  const { navigateToAuthenticatedLandingPage } = useAuthenticatedNavigation();

  const handleSignIn = useCallback(async () => {
    const userQueryResult = await autoConnect();
    const user = userQueryResult.data;
    if (!user) return;

    navigateToAuthenticatedLandingPage(user);
  }, [autoConnect, navigateToAuthenticatedLandingPage]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <I18n map={"components.authentication.sign_in_dialog.title"} />
      </DialogTitle>
      <DialogContent sx={styles.content(mediaQueries)}>
        <ErrorBoundary>
          <GoogleForm handleSignIn={handleSignIn} />
        </ErrorBoundary>

        <OrDivider />

        <Typography variant="subtitle1" sx={styles.labUserSigninDescription}>
          <I18n map="components.authentication.sign_in_dialog.content.lab_user" />
        </Typography>

        <ErrorBoundary>
          <EmailPasswordForm handleSignIn={handleSignIn} />
        </ErrorBoundary>
      </DialogContent>
    </Dialog>
  );
}

export default memo(SignInDialog);
