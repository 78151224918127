import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import SensorAuditsRuleService from "services/SensorAuditsRuleService";
import { AuditorsGroupType } from "models/sensor/SensorAuditsRule";

function sensorAuditsRuleQueryKey({ sensorId }: { sensorId: ModelId | null }) {
  return ["sensors", sensorId, "audits-rule"];
}

function useSensorAuditsRule(id: ModelId | null) {
  const queryClient = useQueryClient();
  const sensorIdValidation = useIdValidation(id);

  const getAuditsRuleQuery = useQuery(
    sensorAuditsRuleQueryKey({ sensorId: id }),
    async () =>
      sensorIdValidation.success
        ? container.resolve(SensorAuditsRuleService).getBySensorId({
            sensorId: sensorIdValidation.data,
          })
        : null,
    {
      enabled: sensorIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: 0,
    }
  );

  const upsertAuditsRuleQuery = useMutation(
    async (params: {
      whitelistIds: ModelId[];
      auditorsGroupType: AuditorsGroupType;
      from: Date | null;
      to: Date | null;
    }) => {
      return sensorIdValidation.success
        ? await container
            .resolve(SensorAuditsRuleService)
            .upsertAuditsRule({ ...params, sensorId: sensorIdValidation.data })
        : null;
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          sensorAuditsRuleQueryKey({
            sensorId: id,
          })
        );
      },
    }
  );

  return {
    getAuditsRuleQuery,
    upsertAuditsRuleQuery,
    auditsRule: getAuditsRuleQuery.data ?? null,
  };
}

export default useSensorAuditsRule;
