import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { UserDto } from "./dtos/user/UserDto";

@singleton()
export default class UserApi extends BaseApi {
  public async findMany(params: { queryParams?: {} }): Promise<UserDto[]> {
    const url = new URL(`${this.apiUrl}/users`);

    if (params.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<UserDto[]>(url);
  }
}
