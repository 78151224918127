import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import WhitelistsInputMulti from "components/element/inputs/WhitelistsInputMulti";
import useLaboratoryWhitelists from "hooks/queries/useLaboratoryWhitelists";
import { ModelId } from "models/common/Model";
import FormErrors from "components/element/FormErrors";
import { SensorAuditsRuleUpdates } from "..";
import { AuditorsGroupType } from "models/sensor/SensorAuditsRule";

type Props = {
  onAuditorsChange: (auditors: SensorAuditsRuleUpdates["auditors"]) => void;
  auditors: SensorAuditsRuleUpdates["auditors"];
  errors?: string[];
};

function AuditorsInput(props: Props) {
  const { whitelists } = useLaboratoryWhitelists();

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const groupType = event.target.value as AuditorsGroupType;

      props.onAuditorsChange({
        whitelistIds: props.auditors.whitelistIds ?? [],
        groupType,
      });
    },
    [props]
  );

  const handleWhitelistsChange = useCallback(
    (whitelistIds: ModelId[]) => {
      props.onAuditorsChange({
        groupType: props.auditors.groupType ?? AuditorsGroupType.Everyone,
        whitelistIds,
      });
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle1">
        <I18n map="components.audits_rule_form.auditors.label" />
      </Typography>

      <RadioGroup
        sx={styles.radioGroup}
        value={props.auditors.groupType ?? AuditorsGroupType.Everyone}
        onChange={handleTypeChange}
      >
        <FormControlLabel
          value={AuditorsGroupType.Everyone}
          control={<Radio />}
          label={<I18n map="components.audits_rule_form.auditors.everyone" />}
        />
        <FormControlLabel
          value={AuditorsGroupType.Specific}
          control={<Radio />}
          label={<I18n map="components.audits_rule_form.auditors.specific" />}
        />
      </RadioGroup>

      {props.auditors.groupType === AuditorsGroupType.Specific && (
        <WhitelistsInputMulti
          whitelists={whitelists}
          values={props.auditors.whitelistIds ?? []}
          onChange={handleWhitelistsChange}
        />
      )}

      {props.errors && <FormErrors errors={props.errors} />}
    </Box>
  );
}

export default memo(AuditorsInput);
