import { memo } from "react";
import Notification from "models/notification/Notification";
import NotificationCompletedAudit from "./NotificationCompletedAudit";

type Props = {
  notification: Notification;
  close: () => void;
};

function NotificationCard(props: Props) {
  return (
    <>
      {props.notification.isNotificationCompletedAudit() && (
        <NotificationCompletedAudit
          notification={props.notification}
          close={props.close}
        />
      )}
    </>
  );
}

export default memo(NotificationCard);
