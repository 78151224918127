import { ModelId } from "models/common/Model";
import { useQuery } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import SensorAuditsRuleService from "services/SensorAuditsRuleService";
import useUser from "./useUser";

function sensorAuditsRuleOfUserQueryKey({
  sensorId,
  userId,
}: {
  sensorId: ModelId | null;
  userId: ModelId | null;
}) {
  return ["sensors", sensorId, "audits-rule", "users", userId];
}

function useSensorAuditsRuleForUser(id: ModelId | null) {
  const sensorIdValidation = useIdValidation(id);

  const { user } = useUser();

  const isUserAuthorizedToAuditQuery = useQuery(
    sensorAuditsRuleOfUserQueryKey({ sensorId: id, userId: user?.id ?? null }),
    async () => {
      return sensorIdValidation.success && !!user
        ? container.resolve(SensorAuditsRuleService).isUserAuthorizedToAudit({
            sensorId: sensorIdValidation.data,
            userId: user.id,
          })
        : null;
    },
    {
      enabled: sensorIdValidation.success && !!user,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: 0,
    }
  );

  return {
    isUserAuthorizedToAudit: isUserAuthorizedToAuditQuery.data ?? false,
  };
}

export default useSensorAuditsRuleForUser;
