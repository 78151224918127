import { Box } from "@mui/material";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { memo } from "react";
import * as styles from "./styles";
import BackButton from "./BackButton";
import EditionForm from "./EditionForm";
import useSensorIdParamsSynchronization from "hooks/useSensorIdParamsSynchronization";

function SensorEditionPage() {
  useHead({
    title: I18n.translate("pages.sensor_edition.title"),
  });

  useSensorIdParamsSynchronization();

  return (
    <Box sx={styles.root}>
      <BackButton />
      <EditionForm />
    </Box>
  );
}

export default memo(SensorEditionPage);
