import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const dateContainer: (
  mediaQueries: MediaQueries,
  wrapable: boolean
) => SxProps<Theme> = (mediaQueries, wrapable) => (theme) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: wrapable && !mediaQueries.isSm ? "column" : "row",
  gap: wrapable && !mediaQueries.isSm ? theme.spacing(2) : 0,
});

export const fromContent: SxProps<Theme> = {};

export const toContent: SxProps<Theme> = {};

export const dateInfo: SxProps<Theme> = {
  height: "22px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "157%",
  letterSpacing: "0.1px",
  color: "#61A963",
};

export const date: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  whiteSpace: "nowrap",
};

export const dateText: SxProps<Theme> = {
  fontSize: "14px",
};

export const arrowIcon: SxProps<Theme> = (theme) => {
  return {
    margin: theme.spacing(0, 3),
    color: theme.palette.primary.main,
  };
};
