import { Box, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useLaboratoryWhitelists from "hooks/queries/useLaboratoryWhitelists";
import { memo } from "react";
import * as styles from "./styles";
import { LoadingButton } from "@mui/lab";

type Props = {
  onSave: () => Promise<void>;
  saveQuery: ReturnType<typeof useLaboratoryWhitelists>["createWhitelistQuery"];
  onCancel: () => void;
};

function Actions({ onSave, saveQuery, onCancel }: Props) {
  return (
    <Box sx={styles.root}>
      <Button variant="outlined" color="info" onClick={onCancel}>
        {I18n.translate("general_text.cancel")}
      </Button>

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onSave}
        disabled={saveQuery.isLoading}
        loading={saveQuery.isLoading}
      >
        {I18n.translate("general_text.save")}
      </LoadingButton>
    </Box>
  );
}

export default memo(Actions);
