import { memo } from "react";
import NavTabs from "components/element/NavTabs";
import I18n from "components/materials/I18n";
import useRoutes from "hooks/useRoutes";
import { ContentPasteOutlined, Receipt, ViewModule } from "@mui/icons-material";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useCurrentPageType from "hooks/useCurrentPageType";
import { Box } from "@mui/material";
import * as styles from "./styles";
import Actions from "./Actions";
import useMediaQueries from "hooks/useMediaQueries";

function SensorPageLinksAndActions() {
  const { sensorId } = useSelectedSensorIdContext();
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();
  const mediaQueries = useMediaQueries();

  if (!sensorId) {
    return null;
  }

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Box sx={styles.tabsContainer}>
        <Box sx={styles.tabs}>
          <NavTabs
            tabs={[
              {
                href: routes[currentPageType].sensor.sensorData.resolveRoute({
                  sensorId,
                }),
                label: I18n.translate("pages.sensor.sub_pages.data.nav_link"),
                icon: <ViewModule />,
                iconPosition: "start",
                selected: routes[currentPageType].sensor.sensorData.isCurrent,
              },
              {
                href: routes[currentPageType].sensor.sensorAudits.resolveRoute({
                  sensorId,
                }),
                label: I18n.translate("pages.sensor.sub_pages.audits.nav_link"),
                icon: <ContentPasteOutlined />,
                iconPosition: "start",
                selected: routes[currentPageType].sensor.sensorAudits.isCurrent,
              },
              {
                href: routes[
                  currentPageType
                ].sensor.sensorTransactions.resolveRoute({
                  sensorId,
                }),
                label: I18n.translate(
                  "pages.sensor.sub_pages.transactions.nav_link"
                ),
                icon: <Receipt />,
                iconPosition: "start",
                selected:
                  routes[currentPageType].sensor.sensorTransactions.isCurrent,
              },
            ]}
            height={60}
          />
        </Box>
      </Box>

      <Box sx={styles.actions(mediaQueries)}>
        <Actions />
      </Box>
    </Box>
  );
}

export default memo(SensorPageLinksAndActions);
