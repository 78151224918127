import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";

export const currentlyActiveSensorsQueryKey = "currentlyActiveSensors";

const staleTimeInMs = 1000 * 60 * 5;

function useCurrentlyActiveSensors() {
  const currentlyActiveSensorsQuery = useQuery(
    currentlyActiveSensorsQueryKey,
    async () => container.resolve(SensorService).getCurrentlyActiveSensors(),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...currentlyActiveSensorsQuery,
    currentlyActiveSensors: currentlyActiveSensorsQuery.data ?? [],
  };
}

export default useCurrentlyActiveSensors;
