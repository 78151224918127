import { memo, useCallback, useEffect, useState } from "react";
import useSensorMapData from "hooks/queries/widgetData/useSensorMapData";
import SimpleMapTooltip from "components/materials/SensorsMap/SimpleMapTooltip";
import { SensorMapWidget } from "models/widgets/Widget";
import { Box } from "@mui/material";
import * as styles from "./styles";
import SensorsMap from "components/materials/SensorsMap";
import EditionForm from "./EditionForm";
import { Nullable } from "types/common";

type Props = {
  widget: SensorMapWidget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<SensorMapWidget["properties"]> | null
  ) => void;
  onUserValidation?: (properties: SensorMapWidget["properties"]) => void;
};

function SensorMap({
  widget,
  shouldShowEditionForm,
  onPropertiesChange,
  onUserValidation,
}: Readonly<Props>) {
  const [properties, setProperties] = useState<SensorMapWidget["properties"]>(
    widget.properties
  );

  useEffect(() => {
    setProperties(widget.properties);
  }, [widget.properties]);

  const { targetSensor, sensors } = useSensorMapData(widget.id, properties);

  const validate = useCallback(
    (properties: SensorMapWidget["properties"]) => {
      onUserValidation?.(properties);
      setProperties(properties);
    },
    [onUserValidation]
  );

  return (
    <Box sx={styles.root}>
      {shouldShowEditionForm && (
        <EditionForm
          properties={properties}
          onSubmit={validate}
          onPropertiesChange={onPropertiesChange}
        />
      )}
      <Box sx={styles.chartContainer}>
        <SensorsMap
          selectedSensor={targetSensor}
          sensors={sensors}
          interactive={false}
        >
          {targetSensor && (
            <SimpleMapTooltip
              sensor={targetSensor}
              isActiveAtDate={properties.isActiveAtDate}
            />
          )}
        </SensorsMap>
      </Box>
    </Box>
  );
}

export default memo(SensorMap);
