import I18n from "components/materials/I18n";
import Widget, {
  SensorMapWidget,
  isRelativeDateBoundary,
  retrieveFixDateBoundary,
} from "models/widgets/Widget";
import { useCallback, useMemo, useState } from "react";
import { Nullable } from "types/common";
import DateUtils, { DateFormat } from "utils/DateUtils";
import RelativeDateUtils from "utils/RelativeDateUtils";

type ValidationErrors = {
  sensorIdErrors?: string[];
  isActiveAtDateInputErrors?: string[];
};

function useValidation({
  properties,
  nowUTC,
  minDate,
  maxDate,
}: {
  properties: Nullable<SensorMapWidget["properties"]>;
  nowUTC: Date;
  minDate?: Date;
  maxDate?: Date;
}) {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const hasErrors = Object.values(errors).some(
    (subErrors) => subErrors.length > 0
  );

  const validateSensorId = useCallback(
    (properties: Nullable<SensorMapWidget["properties"]>) => {
      const errors = [];
      if (!properties.targetSensorId) {
        errors.push(I18n.translate("widgets.common.edition.errors.sensor_id"));
      }
      return errors;
    },
    []
  );

  const isActiveAtDate = properties.isActiveAtDate
    ? retrieveFixDateBoundary(properties.isActiveAtDate)
    : null;

  const minIsActiveAtDate = useMemo(() => {
    if (isActiveAtDate === null) return undefined;

    return DateUtils.min(minDate ?? nowUTC, nowUTC);
  }, [isActiveAtDate, minDate, nowUTC]);

  const maxIsActiveAtDate = useMemo(() => {
    if (isActiveAtDate === null) return undefined;

    return DateUtils.min(isActiveAtDate, maxDate ?? nowUTC);
  }, [isActiveAtDate, maxDate, nowUTC]);

  const validateIsActiveAtDate = useCallback(
    (properties: Nullable<SensorMapWidget["properties"]>) => {
      const errors = [];
      if (properties.isActiveAtDate) {
        const isMinError =
          !!minIsActiveAtDate &&
          retrieveFixDateBoundary(properties.isActiveAtDate).getTime() <
            minIsActiveAtDate.getTime();

        const isMaxError =
          !!maxIsActiveAtDate &&
          retrieveFixDateBoundary(properties.isActiveAtDate).getTime() >
            maxIsActiveAtDate.getTime();

        if (isMinError || isMaxError) {
          minIsActiveAtDate &&
            errors.push(
              `${I18n.translate("widgets.common.edition.errors.anterior")} ${
                isRelativeDateBoundary(properties.isActiveAtDate)
                  ? RelativeDateUtils.fromSecondsTotext(
                      properties.isActiveAtDate.relative
                    )
                  : DateUtils.formatUTCDate(
                      properties.isActiveAtDate.fix,
                      DateFormat.DayMonthYearHourMinute
                    )
              }`
            );

          maxIsActiveAtDate &&
            errors.push(
              `${I18n.translate("widgets.common.edition.errors.posterior")} ${
                isRelativeDateBoundary(properties.isActiveAtDate)
                  ? RelativeDateUtils.fromSecondsTotext(
                      properties.isActiveAtDate.relative
                    )
                  : DateUtils.formatUTCDate(
                      properties.isActiveAtDate.fix,
                      DateFormat.DayMonthYearHourMinute
                    )
              }`
            );
        }
      }
      return errors;
    },
    [minIsActiveAtDate, maxIsActiveAtDate]
  );

  const validateProperties = useCallback(
    (
      properties: Nullable<SensorMapWidget["properties"]>
    ): {
      errors: ValidationErrors;
      validatedProperties: SensorMapWidget["properties"] | null;
    } => {
      const updatedErrors: ValidationErrors = {
        ...errors,
        sensorIdErrors: validateSensorId(properties),
        isActiveAtDateInputErrors: validateIsActiveAtDate(properties),
      };

      setErrors(updatedErrors);

      const hasErrors = Object.values(updatedErrors).some(
        (subErrors) => subErrors.length > 0
      );

      return {
        errors,
        validatedProperties:
          Widget.isPropertiesFullFilled(properties) && !hasErrors
            ? properties
            : null,
      };
    },
    [errors, validateIsActiveAtDate, validateSensorId]
  );

  return {
    errors,
    hasErrors,
    validateProperties,
  };
}

export default useValidation;
