import { SxProps, Theme } from "@mui/material";
import { FileAuditionStatus } from "models/auditReport/FileAuditionStatus";

function getChipBackgroundColor(
  theme: Theme
): Record<FileAuditionStatus, string> {
  return {
    [FileAuditionStatus.AllClear]: theme.palette.primary.main,
    [FileAuditionStatus.ConnectionError]: theme.palette.error.main,
    [FileAuditionStatus.FatalError]: theme.palette.error.main,
    [FileAuditionStatus.AnomalyError]: theme.palette.error.main,
    [FileAuditionStatus.StorageError]: theme.palette.error.main,
  };
}

export const root: (status: FileAuditionStatus) => SxProps<Theme> =
  (status) => (theme) => {
    return {
      color: theme.palette.primary.contrastText,
      background: getChipBackgroundColor(theme)[status],
      height: theme.spacing(4),
      userSelect: "none",
      cursor: "help",
    };
  };
