import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import I18n from "components/materials/I18n";
import SortTableCell from "components/element/SortTableCell";
import BlockchainAddressUtils from "utils/BlockchainAddressUtils";
import CopyToClipboard from "components/element/CopyToClipboard";
import ComplianceChip from "components/element/chips/ComplianceChip";
import FileAuditionStatusChip from "components/element/chips/FileAuditionStatusChip";
import { Direction } from "models/common/Direction";
import SortUtils from "utils/SortUtils";
import AuditFileReport from "models/auditReport/AuditFileReport";

type Props = {
  files: AuditFileReport[];
};

function AuditReportTable(props: Readonly<Props>) {
  const [files, setFiles] = useState<AuditFileReport[]>(props.files);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  const handleSort = useCallback((direction: Direction) => {
    setFiles((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.filepath,
          valueB: b.filepath,
          direction,
        })
      ),
    ]);
  }, []);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 1432, overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <SortTableCell
              onSort={files.length > 1 ? handleSort : undefined}
              divider
            >
              {I18n.translate("pages.audit.table.file_name")}
            </SortTableCell>
            <SortTableCell divider>
              {I18n.translate("pages.audit.table.storage_file_hash")}
            </SortTableCell>

            <SortTableCell divider>
              {I18n.translate("pages.audit.table.audit_file_hash")}
            </SortTableCell>
            <SortTableCell
              divider
              tooltipText={I18n.translate(
                "pages.audit.table.tooltip.storage_integrity"
              )}
            >
              {I18n.translate("pages.audit.table.storage_integrity")}
            </SortTableCell>
            <SortTableCell
              divider
              tooltipText={I18n.translate(
                "pages.audit.table.tooltip.storage_notarization_status"
              )}
            >
              {I18n.translate("pages.audit.table.storage_notarization_status")}
            </SortTableCell>
            <SortTableCell
              divider
              tooltipText={I18n.translate(
                "pages.audit.table.tooltip.audit_notarization_status"
              )}
            >
              {I18n.translate("pages.audit.table.audit_notarization_status")}
            </SortTableCell>
            <SortTableCell
              tooltipText={I18n.translate(
                "pages.audit.table.tooltip.file_audition_status"
              )}
            >
              {I18n.translate("pages.audit.table.file_audition_status")}
            </SortTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index) => (
            <TableRow key={`${file.filepath} - ${index}`}>
              <TableCell>{file.filepath}</TableCell>
              <TableCell>
                {file.storageFileHash ? (
                  <CopyToClipboard text={file.storageFileHash}>
                    {BlockchainAddressUtils.shortenAddress(
                      file.storageFileHash,
                      5,
                      4
                    )}
                  </CopyToClipboard>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>
                {file.auditFileHash ? (
                  <CopyToClipboard text={file.auditFileHash}>
                    {BlockchainAddressUtils.shortenAddress(
                      file.auditFileHash,
                      5,
                      4
                    )}
                  </CopyToClipboard>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>
                <ComplianceChip
                  isCompliant={file.isStorageIntegrityCompliant}
                />
              </TableCell>
              <TableCell>
                <ComplianceChip isCompliant={file.isStorageFileNotarized} />
              </TableCell>
              <TableCell>
                <ComplianceChip isCompliant={file.isAuditFileNotarized} />
              </TableCell>
              <Tooltip title={file.message} placement="top-start">
                <TableCell>
                  <FileAuditionStatusChip status={file.auditionStatus} />
                </TableCell>
              </Tooltip>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(AuditReportTable);
