import { Box, Button, Typography } from "@mui/material";
import I18n from "components/materials/I18n";
import Sensor from "models/sensor/Sensor";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import { Add } from "@mui/icons-material";
import SensorCard from "./SensorCard";
import useMediaQueries from "hooks/useMediaQueries";
import useRoutes from "hooks/useRoutes";
import { useNavigate } from "react-router-dom";

type Props = {
  onSensorSelection: (sensor: Sensor | null) => void;
  sensors: Sensor[];
  selectedSensor: Sensor | null;
  label: string;
  isLaboratorySensors?: boolean;
};

function SensorsList({
  onSensorSelection,
  sensors,
  selectedSensor,
  label,
  isLaboratorySensors,
}: Props) {
  const mediaQueries = useMediaQueries();
  const routes = useRoutes();
  const navigate = useNavigate();

  const addSensor = useCallback(() => {
    navigate(routes.laboratory.sensorCreation.absolutePath);
  }, [navigate, routes.laboratory.sensorCreation.absolutePath]);

  return (
    <Box>
      <Box sx={styles.header(mediaQueries)}>
        <Typography variant="h5">{label}</Typography>

        {isLaboratorySensors && (
          <Button
            onClick={addSensor}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            <I18n map="components.sensors_list.add_sensor" />
          </Button>
        )}
      </Box>

      <Box sx={styles.listContainer}>
        {sensors.map((sensor) => (
          <Box id={String(sensor.id)} key={sensor.id}>
            <SensorCard
              onSensorSelection={onSensorSelection}
              sensor={sensor}
              isSelected={sensor.id === selectedSensor?.id}
              isLaboratorySensor={isLaboratorySensors}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default memo(SensorsList);
