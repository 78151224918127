import { ModelId } from "models/common/Model";
import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import SensorDataUtils from "utils/SensorResultUtils";
import { useMemo } from "react";
import { BlockchainEnergyCostWidget } from "models/widgets/Widget";
import { getRefetchInterval, staleTimeInMs, widgetQueryKey } from "./common";
import useFetchedApplicationConfig from "hooks/useFetchedApplicationConfig";

function useBlockchainEnergyCostData(
  widgetId: ModelId,
  properties: BlockchainEnergyCostWidget["properties"]
) {
  const { applicationConfig } = useFetchedApplicationConfig();

  const query = useQuery(
    widgetQueryKey({ widgetId, filter: properties }),
    async () =>
      container.resolve(SensorService).getSensorsWithResults({
        fromDate: properties.fromDate,
        toDate: properties.toDate,
        sensorIds: [properties.sensorId],
      }),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: getRefetchInterval(properties),
    }
  );

  const sensor = useMemo(() => query.data?.[0] ?? null, [query.data]);

  const blockchainEnergyCost = useMemo(() => {
    if (!sensor) {
      return 0;
    }

    return (
      SensorDataUtils.aggregateSensorResultsSessions(sensor).length *
      applicationConfig.blockchainEnergyCostFactor
    );
  }, [applicationConfig.blockchainEnergyCostFactor, sensor]);

  return {
    ...query,
    blockchainEnergyCost,
    sensor,
  };
}

export default useBlockchainEnergyCostData;
