import { memo } from "react";
import { Box } from "@mui/material";
import Footer from "components/materials/Footer";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import Header from "components/materials/Header";
import { Outlet } from "react-router-dom";

function PageTemplate() {
  return (
    <I18n>
      <Box id="page-template" sx={styles.root}>
        <header>
          <Header />
        </header>
        <main>
          <Outlet />
        </main>
        <footer>
          <Footer />
        </footer>
      </Box>
    </I18n>
  );
}

export default memo(PageTemplate);
