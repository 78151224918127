import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const root: (
  height: string,
  mediaQueries: MediaQueries
) => SxProps<Theme> = (height, mediaQueries) => ({
  display: "flex",
  // width and height are necessary to set because of map usage
  width: "100%",
  height,
  flexDirection: "column",

  ...(mediaQueries.isMd && {
    flexDirection: "row-reverse",
  }),
});

export const sensorsMapContainer: SxProps<Theme> = {
  flex: 1,
};

export const sensorsListContainer: (
  mediaQueries: MediaQueries
) => SxProps<Theme> = (mediaQueries) => (theme) => ({
  flex: 1,
  paddingX: theme.spacing(0),
  paddingTop: theme.spacing(4),
  overflow: "scroll",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),

  ...(mediaQueries.isMd && {
    paddingX: theme.spacing(3),
  }),
});
