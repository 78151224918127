import EditWidgetDialog from "components/materials/dialogs/EditWidgetDialog";
import * as styles from "./styles";
import { Edit } from "@mui/icons-material";
import useOpenable from "hooks/useOpenable";
import Widget from "models/widgets/Widget";
import { memo, CSSProperties } from "react";

type Props = {
  actionStyles: CSSProperties;
  widget: Widget;
};

function EditAction({ actionStyles, widget }: Props) {
  const { open, close, isOpen } = useOpenable();

  return (
    <>
      <Edit sx={styles.root(actionStyles)} color="action" onClick={open} />
      <EditWidgetDialog widget={widget} isOpen={isOpen} close={close} />
    </>
  );
}

export default memo(EditAction);
