import { ModelId } from "models/common/Model";
import { Co2RelativeAverageWidget } from "models/widgets/Widget";
import { useMemo } from "react";
import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import SensorDataUtils from "utils/SensorResultUtils";
import { getRefetchInterval, staleTimeInMs, widgetQueryKey } from "./common";
import { ArrayUtils } from "utils/ArrayUtils";

function useCo2RelativeAverageData(
  widgetId: ModelId,
  properties: Co2RelativeAverageWidget["properties"]
) {
  const query = useQuery(
    widgetQueryKey({ widgetId, filter: properties }),
    async () =>
      container.resolve(SensorService).getSensorsWithResults({
        fromDate: properties.fromDate,
        toDate: properties.toDate,
        sensorIds: ArrayUtils.removeDuplicates([
          ...properties.sensorIds,
          properties.targetSensorId,
        ]),
      }),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: getRefetchInterval(properties),
    }
  );

  const sensors = useMemo(() => query.data ?? [], [query.data]);

  const co2QuantitiesAveragesStatistics = useMemo(
    () => SensorDataUtils.computeCo2Statistics(sensors),
    [sensors]
  );

  const targetSensor = useMemo(
    () =>
      sensors.find((sensor) => sensor.id === properties.targetSensorId) ?? null,
    [sensors, properties.targetSensorId]
  );

  const targetSensorCo2QuantitiesAverage = useMemo(
    () =>
      targetSensor
        ? SensorDataUtils.computeCo2QuantitiesAverage(
            SensorDataUtils.aggregateCo2Quantities(targetSensor)
          )
        : 0,
    [targetSensor]
  );

  return {
    ...query,
    co2QuantitiesAveragesStatistics,
    targetSensorCo2QuantitiesAverage,
    targetSensor,
    sensors,
  };
}

export default useCo2RelativeAverageData;
