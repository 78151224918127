import I18n from "components/materials/I18n";
import { useEffect } from "react";

type Props = {
  title: string;
};

function useHead(props: Props) {
  const siteName = I18n.translate("general_text.site_name");
  useEffect(() => {
    const title = `${props.title} | ${siteName}`;
    document.title = title;
  }, [props.title, siteName]);
}

export default useHead;
