import { Chip } from "@mui/material";
import { memo } from "react";

import I18n from "components/materials/I18n";

import * as styles from "./styles";

type Props = {
  isCompliant: boolean | undefined;
};

function ComplianceChip({ isCompliant }: Readonly<Props>) {
  return (
    <Chip
      sx={styles.root(isCompliant)}
      label={
        isCompliant === undefined
          ? "N/A"
          : isCompliant
          ? I18n.translate("general_text.compliant")
          : I18n.translate("general_text.non_compliant")
      }
    />
  );
}

export default memo(ComplianceChip);
