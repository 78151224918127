import { Box, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import * as styles from "./styles";
import AuditorsInput from "./AuditorsInput";
import DurationInput from "./DurationInput";
import WhitelistsManager from "components/materials/WhitelistsManager";
import useMediaQueries from "hooks/useMediaQueries";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import SensorAuditsRule, {
  AuditorsGroupType,
  AuditsRuleDurationType,
} from "models/sensor/SensorAuditsRule";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorAuditsRule from "hooks/queries/useSensorAuditsRule";
import useSnackBarContext from "hooks/useSnackBarContext";
import { LoadingButton } from "@mui/lab";

export type SensorAuditsRuleUpdates = {
  auditors: {
    groupType: AuditorsGroupType;
    whitelistIds: ModelId[];
  };
  duration: {
    type: AuditsRuleDurationType;
    from: Date | null;
    to: Date | null;
  };
};

const newAuditsRule: SensorAuditsRuleUpdates = {
  auditors: {
    groupType: AuditorsGroupType.Specific,
    whitelistIds: [],
  },
  duration: {
    type: AuditsRuleDurationType.Unlimited,
    from: null,
    to: null,
  },
};

function EditionForm() {
  const mediaQueries = useMediaQueries();

  const { sensorId } = useSelectedSensorIdContext();

  const { auditsRule, upsertAuditsRuleQuery } = useSensorAuditsRule(sensorId);

  const [auditsRuleUpdates, setAuditsRuleUpdates] =
    useState<SensorAuditsRuleUpdates>(
      auditsRule ? initUpdates(auditsRule) : newAuditsRule
    );

  const handleAuditorsChange = useCallback(
    (auditors: SensorAuditsRuleUpdates["auditors"]) => {
      setAuditsRuleUpdates((prev) => ({
        ...prev,
        auditors: { ...prev.auditors, ...auditors },
      }));
    },
    []
  );

  const handleDurationChange = useCallback(
    (duration: SensorAuditsRuleUpdates["duration"]) => {
      setAuditsRuleUpdates((prev) => ({
        ...prev,
        duration: {
          ...prev.duration,
          from:
            duration.type === AuditsRuleDurationType.Unlimited
              ? null
              : duration.from,
          to:
            duration.type === AuditsRuleDurationType.Unlimited
              ? null
              : duration.to,
          type: duration.type,
        },
      }));
    },
    []
  );

  const [auditorsInputErrors, setAuditorsInputErrors] = useState<string[]>([]);
  const [durationInputErrors, setDurationInputErrors] = useState<string[]>([]);

  const snackBar = useSnackBarContext();
  const handleSave = useCallback(async () => {
    const auditorsErrors: string[] = [];
    const durationErrors: string[] = [];

    if (auditsRuleUpdates.duration.type === AuditsRuleDurationType.Limited) {
      const isDurationFulfilled =
        !auditsRuleUpdates.duration.from || !auditsRuleUpdates.duration.to;
      if (isDurationFulfilled) {
        durationErrors.push(
          I18n.translate(
            "components.audits_rule_form.duration.errors.duration_empty"
          )
        );
      }

      const isChronologyError =
        auditsRuleUpdates.duration.from &&
        auditsRuleUpdates.duration.to &&
        auditsRuleUpdates.duration.from > auditsRuleUpdates.duration.to;
      if (isChronologyError) {
        durationErrors.push(
          I18n.translate("general_text.form_errors.date_range_chronology")
        );
      }
    }

    setAuditorsInputErrors(auditorsErrors);
    setDurationInputErrors(durationErrors);

    if ([...auditorsErrors, ...durationErrors].length > 0) return;

    try {
      await upsertAuditsRuleQuery.mutateAsync({
        from: auditsRuleUpdates.duration.from,
        to: auditsRuleUpdates.duration.to,
        auditorsGroupType: auditsRuleUpdates.auditors.groupType,
        whitelistIds: auditsRuleUpdates.auditors.whitelistIds,
      });
      snackBar.open({
        severity: "success",
        message: I18n.translate("pages.audits_rule.alert.upsert.success"),
      });
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate("pages.audits_rule.alert.upsert.error"),
      });
    }
  }, [
    auditsRuleUpdates.auditors.groupType,
    auditsRuleUpdates.auditors.whitelistIds,
    auditsRuleUpdates.duration.from,
    auditsRuleUpdates.duration.to,
    auditsRuleUpdates.duration.type,
    snackBar,
    upsertAuditsRuleQuery,
  ]);

  useEffect(() => {
    setAuditsRuleUpdates(auditsRule ? initUpdates(auditsRule) : newAuditsRule);
  }, [auditsRule]);

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Box sx={styles.mainContent}>
        <Typography variant="h5">
          <I18n map="pages.audits_rule.title" />
        </Typography>

        <Box sx={styles.auditsRuleForm}>
          <AuditorsInput
            auditors={auditsRuleUpdates.auditors}
            onAuditorsChange={handleAuditorsChange}
            errors={auditorsInputErrors}
          />
          <DurationInput
            duration={auditsRuleUpdates.duration}
            onDurationChange={handleDurationChange}
            errors={durationInputErrors}
          />

          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={upsertAuditsRuleQuery.isLoading}
              loading={upsertAuditsRuleQuery.isLoading}
            >
              {I18n.translate("general_text.save")}
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      {mediaQueries.isMd && <Box sx={styles.centerMargin} />}

      <Box sx={styles.whitelistManagementContainer}>
        <WhitelistsManager />
      </Box>
    </Box>
  );
}

export default memo(EditionForm);

function initUpdates(auditsRule: SensorAuditsRule): SensorAuditsRuleUpdates {
  return {
    auditors: {
      groupType: auditsRule.auditorsGroupType,
      whitelistIds:
        auditsRule.whitelists?.map((whitelist) => whitelist.id) ?? [],
    },
    duration: {
      type: auditsRule.durationType,
      from: auditsRule.from,
      to: auditsRule.to,
    },
  };
}
