import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    gap: theme.spacing(2),

    ...(mediaQueries.isSm && {
      flexDirection: "row-reverse",
    }),
  });
