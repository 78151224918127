import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
});

export const row: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
};

export const container: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    gap: theme.spacing(3),
    alignItems: "center",
  };
};
