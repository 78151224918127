import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const root: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const content: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    minHeight: "160px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    gap: theme.spacing(2),

    ...(mediaQueries.isXs && {
      gap: theme.spacing(0),
    }),
  });

export const chartContainer: (mediaQueries: MediaQueries) => SxProps<Theme> = (
  mediaQueries
) => ({
  minWidth: "125px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ...(mediaQueries.isSm && {
    minWidth: "165px",
  }),

  ...(mediaQueries.isMd && {
    minWidth: "125px",
  }),
});

export const loadingContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const description: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};

export const unit: SxProps<Theme> = (theme) => {
  return {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "160%",
    letterSpacing: "0.15px",
    color: theme.palette.primary.main,
  };
};
