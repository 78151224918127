import {
  Autocomplete,
  AutocompleteRenderInputParams,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { memo, useCallback } from "react";
import FormErrors from "../../FormErrors";

export type IOption = {
  id: number;
  label: string;
};

type Props = {
  options: IOption[];
  values?: IOption[];
  placeholder?: string;
  onChange?: (
    _: React.SyntheticEvent<Element, Event>,
    values: IOption[] | null
  ) => void;
  sx?: SxProps<Theme>;
  errors?: string[];
  required?: boolean;
};

function CustomAutocompleteMulti({
  onChange,
  options,
  placeholder,
  values,
  sx,
  errors,
  required,
}: Props) {
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          placeholder: `${placeholder ?? ""}${
            !!placeholder && !!required ? " *" : ""
          }`,
          error: (errors ?? []).length > 0,
        }}
      />
    ),
    [errors, placeholder, required]
  );

  return (
    <>
      <Autocomplete
        sx={sx}
        options={options}
        fullWidth
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={renderInput}
        filterSelectedOptions
        onChange={onChange}
        value={values}
        multiple
      />

      <FormErrors errors={errors ?? []} />
    </>
  );
}

export default memo(CustomAutocompleteMulti);
