import SensorResult from "./SensorResult";
import { SensorResultsSessionDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorResultsSessionDto";
import SensorEpoch from "./SensorEpoch";
import { ModelId } from "models/common/Model";

export default class SensorResultsSession {
  public readonly id: ModelId;
  public readonly isFileNotarized: boolean;
  public readonly transactionHash: string;
  public readonly version: number;
  public readonly sensorEpoch?: SensorEpoch | null;
  public readonly sensorResults?: SensorResult[];

  private constructor(params: {
    id: ModelId;
    isFileNotarized: boolean;
    transactionHash: string;
    version: number;
    sensorEpoch?: SensorEpoch | null;
    sensorResults?: SensorResult[];
  }) {
    this.id = params.id;
    this.isFileNotarized = params.isFileNotarized;
    this.transactionHash = params.transactionHash;
    this.version = params.version;
    this.sensorEpoch = params.sensorEpoch;
    this.sensorResults = params.sensorResults;
  }

  public static fromDto(dto: SensorResultsSessionDto): SensorResultsSession {
    return new SensorResultsSession({
      id: dto.id,
      isFileNotarized: dto.isFileNotarized,
      transactionHash: dto.transactionHash,
      version: dto.version,
      sensorEpoch: dto.sensorEpoch
        ? SensorEpoch.fromDto(dto.sensorEpoch)
        : dto.sensorEpoch,
      sensorResults: dto.sensorResults?.map(SensorResult.fromDto),
    });
  }
}
