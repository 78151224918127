import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import { SensorDto } from "./dtos/sensor/SensorDto";
import UrlUtils from "utils/UrlUtils";
import { DtoDate, DtoId } from "./dtos/common/Dto";
import { DateBoundaryDto } from "./dtos/widget/WidgetDto";

@singleton()
export default class SensorApi extends BaseApi {
  public async findMany(params?: {
    queryParams?: {
      sensorIds?: DtoId[];
      laboratoryId?: DtoId;
      isActiveAtDate?: DateBoundaryDto;
      withSensorResults?: {
        fromDate?: DateBoundaryDto;
        toDate?: DateBoundaryDto;
      };
    };
  }): Promise<SensorDto[]> {
    const url = new URL(`${this.apiUrl}/sensors`);

    if (params?.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<SensorDto[]>(url);
  }

  public async getById(
    id: DtoId,
    options?: { withResultsSessions?: boolean; withRawSessions?: boolean }
  ): Promise<SensorDto | null> {
    const url = new URL(`${this.apiUrl}/sensors/${id}`);

    if (options) {
      UrlUtils.appendQueryParams(url, options);
    }

    return this.getRequest<SensorDto>(url);
  }

  public async create({
    laboratoryId,
    metadata,
    situation,
  }: {
    laboratoryId: DtoId;
    metadata: {
      type: string;
      name: string;
      identifier: string;
      blockchainAddress: string;
      blockchainActivationDate: DtoDate;
    };
    situation: {
      heightAboveGround: number | null;
      heightAboveSea: number | null;
      latitude: number;
      longitude: number;
      location: string;
      installationDate: DtoDate;
      activationDate: DtoDate | null;
      deactivationDate: DtoDate | null;
      useCase: string | null;
    };
  }): Promise<SensorDto> {
    const url = new URL(`${this.apiUrl}/laboratories/${laboratoryId}/sensors`);

    return this.postRequest<SensorDto>(url, {
      metadata,
      situation,
    });
  }

  public async update(
    params: {
      sensorId: DtoId;
      situationId: DtoId;
    } & Partial<{
      metadata: {
        type: string;
        name: string;
        identifier: string;
        blockchainAddress: string;
        blockchainActivationDate: DtoDate;
      };
      situation: {
        heightAboveGround: number | null;
        heightAboveSea: number | null;
        latitude: number;
        longitude: number;
        location: string;
        installationDate: DtoDate;
        activationDate: DtoDate | null;
        deactivationDate: DtoDate | null;
        useCase: string | null;
      };
    }>
  ): Promise<SensorDto | null> {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}`);

    return this.patchRequest<SensorDto>(url, params);
  }

  public async updateImage(params: {
    sensorId: DtoId;
    image: File | null;
  }): Promise<SensorDto | null> {
    const url = new URL(`${this.apiUrl}/sensors/${params.sensorId}/image`);
    const formData = new FormData();
    formData.append("file", params.image as Blob);

    return await this.patchFormDataRequest<SensorDto>(url, formData);
  }
}
