import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import I18n from "components/materials/I18n";
import { FixDateBoundary, RelativeDateBoundary } from "models/widgets/Widget";
import { memo, useCallback, useState } from "react";
import * as styles from "./styles";

type Props = {
  defaultValue: keyof FixDateBoundary | keyof RelativeDateBoundary;
  onDateTypeChange: (
    dateType: keyof FixDateBoundary | keyof RelativeDateBoundary
  ) => void;
  disableFix?: boolean;
};

function DateTypeInput({ onDateTypeChange, defaultValue, disableFix }: Props) {
  const [dateType, setDateType] = useState<
    keyof FixDateBoundary | keyof RelativeDateBoundary
  >(defaultValue);

  const handleDateTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const dateTypeValue = event.target.value as
        | keyof FixDateBoundary
        | keyof RelativeDateBoundary;
      setDateType(dateTypeValue);
      onDateTypeChange(dateTypeValue);
    },
    [onDateTypeChange]
  );

  return (
    <RadioGroup
      sx={styles.root}
      value={dateType}
      onChange={handleDateTypeChange}
    >
      <FormControlLabel
        value="relative"
        control={<Radio />}
        label={<I18n map="general_text.date_type.relative" />}
      />

      <FormControlLabel
        value="fix"
        control={<Radio />}
        label={<I18n map="general_text.date_type.fix" />}
        disabled={disableFix}
      />
    </RadioGroup>
  );
}

export default memo(DateTypeInput);
