import { useCallback } from "react";
import { z } from "zod";
import useValidation from "./useValidation";

export default function useFormValidation<T>(schema: z.ZodSchema<T>) {
  const validation = useValidation(schema);
  const { errorsOf, isError } = validation;

  const isErrorConsideringCustoms = useCallback(
    (key: keyof T, conditions?: boolean[]) => {
      return (
        isError(key) ||
        Boolean(conditions?.some((condition) => condition === true))
      );
    },
    [isError]
  );

  const embedWithCustomErrors = useCallback(
    (key: keyof T, customErrors?: { message: string; isError: boolean }[]) => {
      const messages = [...errorsOf(key)];

      customErrors?.forEach((customError) => {
        if (customError.isError) {
          messages.push(customError.message);
        }
      });

      return messages;
    },
    [errorsOf]
  );

  return {
    ...validation,
    isError: isErrorConsideringCustoms,
    errorsOf: embedWithCustomErrors,
  };
}
