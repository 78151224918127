import SensorEpoch from "./SensorEpoch";
import { ModelId } from "models/common/Model";
import { SensorRawSessionDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorRawSessionDto";

export default class SensorRawSession {
  public readonly id: ModelId;
  public readonly isFileNotarized: boolean;
  public readonly hash: string;
  public readonly transactionHash: string;
  public readonly sensorEpoch?: SensorEpoch | null;

  private constructor(params: {
    id: ModelId;
    isFileNotarized: boolean;
    hash: string;
    transactionHash: string;
    sensorEpoch?: SensorEpoch | null;
  }) {
    this.id = params.id;
    this.isFileNotarized = params.isFileNotarized;
    this.hash = params.hash;
    this.transactionHash = params.transactionHash;
    this.sensorEpoch = params.sensorEpoch;
  }

  public static fromDto(dto: SensorRawSessionDto): SensorRawSession {
    return new SensorRawSession({
      id: dto.id,
      isFileNotarized: dto.isFileNotarized,
      hash: dto.hash,
      transactionHash: dto.transactionHash,
      sensorEpoch: dto.sensorEpoch
        ? SensorEpoch.fromDto(dto.sensorEpoch)
        : dto.sensorEpoch,
    });
  }
}
