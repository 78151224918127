import { format } from "date-fns";
import { utcToZonedTime, toDate } from "date-fns-tz";

export enum DateFormat {
  DayMonthYear = "dd/MM/yyyy",
  DayMonthShortYearHourMinute = "dd/MM/yy HH:mm",
  DayMonthYearHourMinute = "dd/MM/yyyy HH:mm",
  DayMonthYearBulletHourMinute = "dd/MM/yyyy ● HH:mm",
  DayMonthYearHourMinuteSecondsNoSpace = "yyyyMMdd'T'HHmmss",
  IsoDate = "yyyy-MM-dd",
  IsoDateTimeZone = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
}

export default class DateUtils {
  public static fromIsoString(isoString: string): Date {
    return new Date(isoString);
  }

  public static fromNullableIsoString(isoString: string | null): Date | null {
    return isoString ? DateUtils.fromIsoString(isoString) : null;
  }

  public static formatUTCDate(date: Date, outputFormat: DateFormat): string {
    const zonedDate = utcToZonedTime(date, "UTC"); // Keep date as UTC
    return format(zonedDate, outputFormat); // Format date
  }

  public static forceUTCDate(date: Date): Date {
    // Force date to be UTC
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  public static forceUTCDateFromStr(date: string): Date {
    // Force date to be UTC
    return DateUtils.forceUTCDate(new Date(date));
  }

  public static nowUTC(): Date {
    return new Date();
  }

  public static formatUTCDateFromDateString(
    value: string,
    outputFormat: DateFormat
  ): string {
    const date = toDate(value); // Convert string to date
    return DateUtils.formatUTCDate(date, outputFormat); // Format date
  }

  public static min(a: Date, b: Date): Date {
    return a.getTime() < b.getTime() ? a : b;
  }

  public static max(a: Date, b: Date): Date {
    return a.getTime() > b.getTime() ? a : b;
  }

  public static excludeTime(date: Date): Date {
    const zonedDate = utcToZonedTime(date, "UTC"); // Keep date as UTC

    return new Date(
      Date.UTC(
        zonedDate.getFullYear(),
        zonedDate.getMonth(),
        zonedDate.getDate(),
        0,
        0,
        0
      )
    );
  }
}
