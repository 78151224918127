import assert from "assert";
import { SelectedSensorIdContext } from "contexts/SelectedSensorContextProvider";
import { useContext } from "react";

export default function useSelectedSensorIdContext() {
  const context = useContext(SelectedSensorIdContext);
  assert(
    context !== null,
    "useSelectedSensorContext must be used within a SelectedSensorContextProvider"
  );
  return context;
}
