import { ModelId } from "models/common/Model";
import { useQuery } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import SensorDataUtils from "utils/SensorResultUtils";
import { addHours, differenceInHours } from "date-fns";
import Sensor from "models/sensor/Sensor";
import { useMemo } from "react";
import {
  Co2EvolutionWidget,
  retrieveFixDateBoundary,
} from "models/widgets/Widget";
import { getRefetchInterval, staleTimeInMs, widgetQueryKey } from "./common";

function useCo2EvolutionData(
  widgetId: ModelId,
  properties: Co2EvolutionWidget["properties"]
) {
  const query = useQuery(
    widgetQueryKey({ widgetId, filter: properties }),
    async () =>
      container.resolve(SensorService).getSensorsWithResults({
        fromDate: properties.fromDate,
        toDate: properties.toDate,
        sensorIds: properties.sensorIds,
      }),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: getRefetchInterval(properties),
    }
  );

  const sensors = useMemo(() => query.data ?? [], [query.data]);

  const allSensorResultsAveragesPerHour = useMemo(() => {
    const fixFromDate = retrieveFixDateBoundary(properties.fromDate);
    const fixToDate = retrieveFixDateBoundary(properties.toDate);

    const everyHoursOfDateRange = Array.from(
      { length: differenceInHours(fixToDate, fixFromDate) + 1 },
      (_, i) => addHours(fixFromDate, i)
    );

    return sensors.map((sensor: Sensor) => {
      const sensorResults = SensorDataUtils.aggregateSensorResults(sensor);
      const sensorResultsAveragesPerHour = everyHoursOfDateRange.map(
        (dateTime) => {
          const currentTime = dateTime.getTime();
          const nextHourTime = addHours(dateTime, 1).getTime();

          const untilNextHourResults = sensorResults.filter(
            (result) =>
              result.dateTime.getTime() >= currentTime &&
              result.dateTime.getTime() < nextHourTime
          );

          const untilNextHourCo2Quantities = untilNextHourResults.map(
            (result) => result.co2Quantity
          );
          const untilNextHourCo2QuantitiesAverage =
            SensorDataUtils.computeCo2QuantitiesAverage(
              untilNextHourCo2Quantities
            );

          return {
            hourlyCo2QuantitiesAverage: untilNextHourCo2QuantitiesAverage,
            dateTime,
          };
        }
      );

      return {
        name: SensorDataUtils.formatDisplayName(sensor),
        sensorResultsAveragesPerHour,
      };
    });
  }, [properties.fromDate, properties.toDate, sensors]);

  return {
    ...query,
    allSensorResultsAveragesPerHour,
    sensors,
  };
}

export default useCo2EvolutionData;
