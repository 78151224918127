import { useMemo } from "react";
import SchemaUtils from "utils/SchemaUtils";

function useIdValidation(id: unknown) {
  const safeParsedValidation = useMemo(() => {
    return SchemaUtils.idSchema.safeParse(id);
  }, [id]);

  return { ...safeParsedValidation };
}

export default useIdValidation;
