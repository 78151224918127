import { Box, Typography } from "@mui/material";
import { FixDateBoundary } from "models/widgets/Widget";
import { memo } from "react";
import DateUtils, { DateFormat } from "utils/DateUtils";
import * as styles from "./styles";

type Props = {
  fixDate: FixDateBoundary;
  format: DateFormat;
};

function FixDate({ fixDate, format }: Props) {
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.dateText}>
        {DateUtils.formatUTCDate(fixDate.fix, format)}
      </Typography>
    </Box>
  );
}

export default memo(FixDate);
