import assert from "assert";
import useUser from "hooks/queries/useUser";
import useIdValidation from "hooks/useIdValidation";
import { ModelId } from "models/common/Model";
import { DateBoundary } from "models/widgets/Widget";
import { useMutation, useQuery, useQueryClient } from "react-query";
import SensorService from "services/SensorService";
import { container } from "tsyringe";
import { currentlyActiveSensorsQueryKey } from "./useCurrentlyActiveSensors";

export function sensorsQueryKey(filter?: {
  queryParams?: {
    sensorIds?: ModelId[];
    laboratoryId?: ModelId;
    isActiveAtDate?: DateBoundary;
    withSensorResults?: {
      fromDate?: DateBoundary;
      toDate?: DateBoundary;
    };
  };
}) {
  return filter ? ["sensors", filter] : ["sensors"];
}

const staleTimeInMs = 1000 * 60 * 5;

function useSensors(filter?: {
  queryParams?: {
    sensorIds?: ModelId[];
    laboratoryId?: ModelId;
    isActiveAtDate?: DateBoundary;
    withSensorResults?: {
      fromDate?: DateBoundary;
      toDate?: DateBoundary;
    };
  };
}) {
  const queryClient = useQueryClient();
  const { userLaboratoryId } = useUser();
  const laboratoryIdValidation = useIdValidation(userLaboratoryId);

  const findSensorsQuery = useQuery(
    sensorsQueryKey(filter),
    async () => container.resolve(SensorService).findSensors(filter),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  const createSensorQuery = useMutation(
    async (params: {
      metadata: {
        type: string;
        name: string;
        identifier: string;
        blockchainAddress: string;
        blockchainActivationDate: Date;
      };
      situation: {
        heightAboveGround: number | null;
        heightAboveSea: number | null;
        latitude: number;
        longitude: number;
        location: string;
        installationDate: Date;
        activationDate: Date | null;
        deactivationDate: Date | null;
        useCase: string | null;
      };
    }) => {
      assert(laboratoryIdValidation.success);
      return await container.resolve(SensorService).createSensor({
        ...params,
        laboratoryId: laboratoryIdValidation.data,
      });
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async () => {
        assert(laboratoryIdValidation.success);
        await queryClient.invalidateQueries(sensorsQueryKey());
        await queryClient.invalidateQueries(currentlyActiveSensorsQueryKey);
      },
    }
  );

  return {
    ...findSensorsQuery,
    createSensorQuery,
    sensors: findSensorsQuery.data ?? [],
  };
}

export default useSensors;
