import { Logout } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import I18n from "components/materials/I18n";
import useUser from "hooks/queries/useUser";
import { memo, useCallback } from "react";

type Props = {
  variant: "text" | "icon";
};

function SignOutButton({ variant }: Props) {
  const { clearUser } = useUser();
  const handleSignOut = useCallback(async () => {
    await clearUser();
  }, [clearUser]);

  if (variant === "text") {
    return (
      <Button
        variant="text"
        color="info"
        onClick={handleSignOut}
        startIcon={<Logout />}
      >
        <I18n map={"components.authentication.sign_out_button"} />
      </Button>
    );
  }

  return (
    <IconButton onClick={handleSignOut}>
      <Logout />
    </IconButton>
  );
}

export default memo(SignOutButton);
