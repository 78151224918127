import { Box, Typography } from "@mui/material";
import Co2RelativeAverageChart from "components/element/Co2RelativeAverageChart";
import I18n from "components/materials/I18n";
import useCo2RelativeAverageData from "hooks/queries/widgetData/useCo2RelativeAverageData";
import SensorDataUtils from "utils/SensorResultUtils";
import { Co2RelativeAverageWidget } from "models/widgets/Widget";
import DateTimeRange from "components/element/DateTimeRange";
import * as styles from "./styles";
import { DateFormat } from "utils/DateUtils";
import { Nullable } from "types/common";
import { useCallback, useEffect, useState } from "react";
import EditionForm from "./EditionForm";
import CircularLoader from "components/element/CircularLoader";

type Props = {
  widget: Co2RelativeAverageWidget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<Co2RelativeAverageWidget["properties"]> | null
  ) => void;
  onUserValidation?: (
    properties: Co2RelativeAverageWidget["properties"]
  ) => void;
};

function Co2RelativeAverage({
  widget,
  shouldShowEditionForm,
  onPropertiesChange,
  onUserValidation,
}: Readonly<Props>) {
  const [properties, setProperties] = useState<
    Co2RelativeAverageWidget["properties"]
  >(widget.properties);

  useEffect(() => {
    setProperties(widget.properties);
  }, [widget.properties]);

  const {
    sensors,
    co2QuantitiesAveragesStatistics,
    targetSensor,
    targetSensorCo2QuantitiesAverage,
    ...query
  } = useCo2RelativeAverageData(widget.id, properties);

  const { minimum, quartile25, median, quartile75, maximum } =
    co2QuantitiesAveragesStatistics;

  const validate = useCallback(
    (properties: Co2RelativeAverageWidget["properties"]) => {
      onUserValidation?.(properties);
      setProperties(properties);
    },
    [onUserValidation]
  );

  if (!sensors.length || !targetSensor) {
    return (
      <Typography variant="body2">
        <I18n map="general_text.no_data" />
      </Typography>
    );
  }

  return (
    <Box sx={styles.root}>
      {shouldShowEditionForm && (
        <EditionForm
          properties={properties}
          onSubmit={validate}
          onPropertiesChange={onPropertiesChange}
        />
      )}
      <Box sx={styles.content}>
        <Box sx={styles.rangeDateContainer}>
          <DateTimeRange
            fromDate={properties.fromDate}
            toDate={properties.toDate}
            format={DateFormat.DayMonthYearBulletHourMinute}
          />
        </Box>

        <Box>
          {query.isLoading && (
            <Box sx={styles.loadingContainer}>
              <CircularLoader />
            </Box>
          )}

          {query.isFetched && (
            <Box sx={styles.chartContainer}>
              <Co2RelativeAverageChart
                minimum={minimum}
                quartile25={quartile25}
                median={median}
                quartile75={quartile75}
                maximum={maximum}
                focusAverage={targetSensorCo2QuantitiesAverage}
                focusLabel={SensorDataUtils.formatDisplayName(targetSensor)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Co2RelativeAverage;
