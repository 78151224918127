import {
  Autocomplete,
  AutocompleteRenderInputParams,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { memo, useCallback } from "react";
import FormErrors from "../../FormErrors";

export type IOption = {
  id: number;
  label: string;
};

type Props = {
  options: IOption[];
  value?: IOption | null;
  placeholder?: string;
  startIcon?: React.ReactNode;
  onChange?: (
    _: React.SyntheticEvent<Element, Event>,
    value: IOption | null
  ) => void;
  sx?: SxProps<Theme>;
  errors?: string[];
  required?: boolean;
};

function CustomAutocompleteSingle({
  onChange,
  options,
  placeholder,
  value,
  startIcon,
  sx,
  errors,
  required,
}: Props) {
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: startIcon,
          error: (errors ?? []).length > 0,
        }}
        placeholder={`${placeholder ?? ""}${required ? " *" : ""}`}
      />
    ),
    [errors, placeholder, required, startIcon]
  );

  return (
    <>
      <Autocomplete
        sx={sx}
        options={options}
        fullWidth
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={renderInput}
        onChange={onChange}
        value={value ?? null}
      />

      <FormErrors errors={errors ?? []} />
    </>
  );
}

export default memo(CustomAutocompleteSingle);
