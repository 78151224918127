import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import SortTableCell from "components/element/SortTableCell";
import { memo, useCallback, useEffect, useState } from "react";
import { Direction } from "models/common/Direction";
import SortUtils from "utils/SortUtils";
import Whitelist from "models/laboratory/Whitelist";
import I18n from "components/materials/I18n";
import WhitelistTableRow from "./WhitelistTableRow";

type Props = {
  whitelists: Whitelist[];
};

function WhitelistsTable(props: Readonly<Props>) {
  const [whitelistsState, setWhitelistsState] = useState<Whitelist[]>([]);

  useEffect(() => {
    setWhitelistsState([
      ...props.whitelists.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.name,
          valueB: b.name,
          direction: Direction.Asc,
        })
      ),
    ]);
  }, [props.whitelists]);

  const sortByName = useCallback((direction: Direction) => {
    setWhitelistsState((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.name,
          valueB: b.name,
          direction,
        })
      ),
    ]);
  }, []);

  const sortByCreator = useCallback((direction: Direction) => {
    setWhitelistsState((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.creator?.email ?? "",
          valueB: b.creator?.email ?? "",
          direction,
        })
      ),
    ]);
  }, []);

  const sortByUsersQuantity = useCallback((direction: Direction) => {
    setWhitelistsState((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABNum({
          valueA:
            a.whitelistedUsers !== undefined ? a.whitelistedUsers.length : 0,
          valueB:
            b.whitelistedUsers !== undefined ? b.whitelistedUsers.length : 0,
          direction,
        })
      ),
    ]);
  }, []);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: "1px solid", borderColor: "divider", maxHeight: "400px" }}
    >
      <Table sx={{ overflowX: "auto" }} stickyHeader>
        <TableHead>
          <TableRow>
            <SortTableCell
              onSort={sortByName}
              divider
              initialDirection={Direction.Asc}
            >
              <I18n map="components.whitelists_manager.table.name" />
            </SortTableCell>
            <SortTableCell onSort={sortByCreator} divider>
              <I18n map="components.whitelists_manager.table.creator" />
            </SortTableCell>
            <SortTableCell onSort={sortByUsersQuantity} divider>
              <I18n map="components.whitelists_manager.table.users_quantity" />
            </SortTableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {whitelistsState.map((whitelist) => (
            <WhitelistTableRow key={whitelist.id} whitelist={whitelist} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(WhitelistsTable);
