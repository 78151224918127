import { SxProps, Theme } from "@mui/material";
import bigLeaves from "assets/images/bigLeaves.svg";
import footerLeaves from "assets/images/footerLeaves.svg";
import smallLeaves from "assets/images/smallLeaves.svg";
import { MediaQueries } from "hooks/useMediaQueries";
import { colorGrayLight } from "themes/constants";

export const content: SxProps<Theme> = {
  paddingTop: "10%",
  backgroundColor: colorGrayLight,
  minHeight: "200vh",
  display: "grid",
  placeContent: "center",
};

// -------- First Text -------- //
export const title: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    height: theme.spacing(5),
  };
};

export const explainSubTitle: SxProps<Theme> = (theme) => {
  return {
    paddingTop: theme.spacing(4),
    maxWidth: "800px",
    fontWeight: 700,
    fontSize: theme.spacing(6),
  };
};

export const explainText: SxProps<Theme> = (theme) => {
  return {
    paddingTop: theme.spacing(4),
    maxWidth: "800px",
    color: "rgba(0, 0, 0, 0.6)",
  };
};

export const signInButton: SxProps<Theme> = (theme) => {
  return {
    paddingTop: theme.spacing(4),
  };
};

// ---------- Wo Are we ---------- //
export const whoAreWeContainer: (
  mediaQueries: MediaQueries
) => SxProps<Theme> = (mediaQueries) => () => {
  return {
    ...{
      display: "flex",
      padding: "6%",
      borderRadius: "16px",
      marginTop: "35vh",
      width: "80vw",
      minHeight: "500px",
      background: "#FFFFFF",
      overflow: "hidden",
      flexDirection: "column",
    },
    ...(mediaQueries.isMd && {
      flexDirection: "row",
      minWidth: "80vw",
    }),
    ...(mediaQueries.isLg && {
      flexDirection: "row",
      minWidth: "65vw",
    }),
    ...(mediaQueries.isSm && {
      width: "70vw",
    }),
    // ...(mediaQueries.isXs &&{
    //   minWidth: "1vw",
    //   width: "50vw"
    // }),
  };
};

export const whoAreWe: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => () => {
    return {
      ...{
        position: "relative",
        width: "100%",
        padding: 0,
        top: "-230px",
        marginBottom: "-230px",
      },

      ...(mediaQueries.isSm && {
        width: "70%",
      }),
      ...(mediaQueries.isMd && {
        width: "100%",
        top: 0,
        marginBottom: 0,
      }),
    };
  };

export const whoAreWeTextOnly: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => () => {
    return {
      ...(mediaQueries.isSm && {
        width: "70%",
      }),
      ...(mediaQueries.isMd && {
        width: "100%",
      }),
    };
  };

export const whoAreWeText: SxProps<Theme> = (theme) => {
  return {
    ...{
      minWidth: "85%",
      marginTop: theme.spacing(5),
      fontWeight: 600,
    },
  };
};

export const whoAreSubText: SxProps<Theme> = (theme) => {
  return {
    width: "95%",
    marginTop: theme.spacing(2),
    color: "rgba(0, 0, 0, 0.8)",
  };
};

export const whoAreWeIcons: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => {
    return {
      ...{
        maxWidth: "100%",
        marginTop: theme.spacing(5),
        display: "grid",
        gridTemplateColumns: "150px 150px",
        gridColumnGap: theme.spacing(1),
        gridRowGap: theme.spacing(4),
      },

      ...(mediaQueries.isMd && {
        gridTemplateColumns: "repeat(4, 1fr)",
        gap: theme.spacing(6),
      }),
      ...(mediaQueries.isLg && {
        gap: "64px",
      }),
    };
  };

// -------- Partners -------- //

export const partners: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(6),
    marginTop: "30vh",
  };
};

export const partnersTitle: SxProps<Theme> = {
  fontWeight: 7000,
  fontSize: "34px",
  textAlign: "center",
  color: "#447745",
};

export const partnersText: SxProps<Theme> = {
  width: "80%",
  fontWeight: 600,
  textAlign: "center",
  color: "rgba(0, 0, 0, 0.87)",
};

export const partnersIcons: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => {
    return {
      ...{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        gap: theme.spacing(5),
        marginBottom: "15vh",
        img: {
          width: "158px",
          height: "49px",
        },
      },
      ...(mediaQueries.isSm && {
        padding: `${theme.spacing(5)} 0px`,
        flexDirection: "row",
        gap: theme.spacing(4),
      }),
      ...(mediaQueries.isMd && {
        gap: "128px",
        img: {
          width: "284px",
          height: "85px",
        },
      }),
    };
  };

// -------- Leaves Images -------- //

export const bigLeaveImage: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => () => {
    return {
      ...{
        position: "absolute",
        width: "800px",

        height: "648px",
        right: "0px",
        top: "25vh",
        background: `url(${bigLeaves})`,
        display: "none",
      },
      ...(mediaQueries.isMd && {
        display: "inline",
      }),
      ...(mediaQueries.isLg && {
        width: "891px",
      }),
    };
  };

export const smallLeaveImage: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => () => {
    return {
      ...{
        background: `url(${smallLeaves})`,
        position: "relative",
        width: "367px",
        height: "314px",
        left: "-30%",
        top: "-120px",
      },
      ...(mediaQueries.isSm && {
        left: "-1.5%",
      }),
      ...(mediaQueries.isMd && {
        top: 0,
      }),
    };
  };

export const footerLeavesImage: (
  mediaQueries: MediaQueries
) => SxProps<Theme> = (mediaQueries) => (theme) => ({
  position: "relative",
  width: "350px",
  height: "302px",
  marginLeft: "-30px",
  bottom: "0px",
  background: `url(${footerLeaves})`,
  mixBlendMode: "multiply",

  ...(mediaQueries.isXs && {
    width: "414px",
    margin: "auto",
  }),

  ...(mediaQueries.isMd && {
    bottom: "-60px",
  }),
});

// --------- Responsive -------- //
