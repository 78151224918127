import { ModelId } from "models/common/Model";
import { PropsWithChildren, createContext, useState } from "react";

type SelectedSensorContextType = {
  sensorId: ModelId | null;
  setSensorId: (sensorId: ModelId | null) => void;
};

export const SelectedSensorIdContext =
  createContext<SelectedSensorContextType | null>(null);

function SelectedSensorIdContextProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const [sensorId, setSensorId] = useState<ModelId | null>(null);

  return (
    <SelectedSensorIdContext.Provider value={{ sensorId, setSensorId }}>
      {children}
    </SelectedSensorIdContext.Provider>
  );
}

export default SelectedSensorIdContextProvider;
