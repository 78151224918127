import { Box, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import {
  Co2AverageWidget,
  DateBoundary,
  FixDateBoundary,
  RelativeDateBoundary,
  retrieveFixDateBoundary,
  retrieveRelativeDateBoundary,
} from "models/widgets/Widget";
import { memo, useCallback, useState } from "react";
import { Nullable } from "types/common";
import * as styles from "./styles";
import RelativeOrFixDateRangeInput from "components/element/inputs/RelativeOrFixDateRangeInput";
import { DayJsFormat } from "utils/DayjsUtils";
import DateUtils from "utils/DateUtils";
import useValidation from "./useValidation";
import SensorsInputSingle from "components/element/inputs/SensorsInputSingle";
import useSensors from "hooks/queries/useSensors";
import useUser from "hooks/queries/useUser";
import useFetchedApplicationConfig from "hooks/useFetchedApplicationConfig";

type Props = {
  properties: Co2AverageWidget["properties"];
  onSubmit: (properties: Co2AverageWidget["properties"]) => void;
  onPropertiesChange?: (
    properties: Nullable<Co2AverageWidget["properties"]>
  ) => void;
};

function EditionForm({ properties, onSubmit, onPropertiesChange }: Props) {
  const { applicationConfig } = useFetchedApplicationConfig();
  const { userLaboratoryId } = useUser();
  const { sensors } = useSensors({
    queryParams: {
      laboratoryId: userLaboratoryId,
    },
  });
  const [propertiesState, setPropertiesState] =
    useState<Nullable<Co2AverageWidget["properties"]>>(properties);

  const handleDateTypeChange = useCallback(
    (dateType: keyof FixDateBoundary | keyof RelativeDateBoundary) => {
      let updatedProperties: Nullable<Co2AverageWidget["properties"]> =
        propertiesState;

      if (dateType === "fix") {
        updatedProperties = {
          ...propertiesState,
          fromDate: propertiesState.fromDate
            ? { fix: retrieveFixDateBoundary(propertiesState.fromDate) }
            : null,
          toDate: propertiesState.toDate
            ? { fix: retrieveFixDateBoundary(propertiesState.toDate) }
            : null,
        };
      }

      if (dateType === "relative") {
        updatedProperties = {
          ...propertiesState,
          fromDate: propertiesState.fromDate
            ? {
                relative: retrieveRelativeDateBoundary(
                  propertiesState.fromDate
                ),
              }
            : null,
          toDate: propertiesState.toDate
            ? { relative: retrieveRelativeDateBoundary(propertiesState.toDate) }
            : null,
        };
      }
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleFromDateChange = useCallback(
    (fromDate: DateBoundary | null) => {
      const updatedProperties = {
        ...propertiesState,
        ...(fromDate !== null && {
          fromDate,
        }),
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleToDateChange = useCallback(
    (toDate: DateBoundary | null) => {
      const updatedProperties = {
        ...propertiesState,
        ...(toDate !== null && {
          toDate,
        }),
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleSensorChange = useCallback(
    (sensorId: ModelId | null) => {
      const updatedProperties = {
        ...propertiesState,
        sensorId,
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const nowUTC = DateUtils.nowUTC();
  const minDate = applicationConfig.dashboardResultsMinDate ?? undefined;
  // We want to disable the future
  const maxDate = DateUtils.min(
    applicationConfig.dashboardResultsMaxDate ?? nowUTC,
    nowUTC
  );

  const { validateProperties, errors } = useValidation({
    properties: propertiesState,
    nowUTC,
    minDate,
    maxDate,
  });

  const submit = useCallback(() => {
    if (!propertiesState.fromDate || !propertiesState.toDate) {
      return;
    }

    const { validatedProperties } = validateProperties(propertiesState);
    if (!validatedProperties) {
      return;
    }

    onSubmit(validatedProperties);
  }, [onSubmit, propertiesState, validateProperties]);

  return (
    <Box sx={styles.root}>
      <SensorsInputSingle
        sensors={sensors ?? []}
        onChange={handleSensorChange}
        value={propertiesState?.sensorId ?? null}
        errors={errors.sensorIdErrors}
      />

      <RelativeOrFixDateRangeInput
        onDateTypeChange={handleDateTypeChange}
        fromDate={propertiesState.fromDate}
        toDate={propertiesState.toDate}
        onFromDateChange={handleFromDateChange}
        onToDateChange={handleToDateChange}
        minDate={minDate}
        maxDate={maxDate}
        fromDateFormat={DayJsFormat.DayMonthYearHourMinute}
        toDateFormat={DayJsFormat.DayMonthYearHourMinute}
        withTime={true}
        fromRelativeDateInputErrors={errors.fromRelativeDateInputErrors}
        toRelativeDateInputErrors={errors.toRelativeDateInputErrors}
        toFixDateInputErrors={errors.toFixDateInputErrors}
      />

      <Button onClick={submit} variant="contained" sx={styles.submitButton}>
        <I18n map="widgets.common.edition.validate" />
      </Button>
    </Box>
  );
}

export default memo(EditionForm);
