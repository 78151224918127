import { container, singleton } from "tsyringe";
import Laboratory from "models/laboratory/Laboratory";
import LaboratoryApi from "./apis/ceaMoonshot/LaboratoryApi";

@singleton()
export default class LaboratoryService {
  public async findMany(params: {}): Promise<Laboratory[]> {
    const laboratoriesDto = await container
      .resolve(LaboratoryApi)
      .findMany(params);
    return laboratoriesDto.map((dto) => Laboratory.fromDto(dto));
  }
}
