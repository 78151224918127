import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useTheme,
} from "@mui/material";
import {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import Widget from "models/widgets/Widget";
import CSSUtils from "utils/CSSUtils";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorWidgets from "hooks/queries/useSensorWidgets";
import CloseButton from "components/element/CloseButton";
import { Nullable } from "types/common";
import useMediaQueries from "hooks/useMediaQueries";
import { getSensorPagePadding } from "components/pages/SensorPage/styles";
import WidgetsContainerUtils from "components/materials/WidgetsContainer/utils";
import WidgetContainer from "components/materials/WidgetsContainer/WidgetContainer";
import { Delete } from "@mui/icons-material";
import useSnackBarContext from "hooks/useSnackBarContext";
import { LoadingButton } from "@mui/lab";

type Props = {
  isOpen: boolean;
  close: () => void;
  widget: Widget;
  fromAddWidgetDialog?: boolean;
};

function EditWidgetDialog({
  isOpen,
  close,
  widget,
  fromAddWidgetDialog,
}: Readonly<Props>) {
  // We use a ref to freeze the widget properties when the dialog is opened, so prevent any updates from refetch intervals
  const widgetRef = useRef<Widget>(widget);
  const mediaQueries = useMediaQueries();
  const theme = useTheme();
  const closeButtonStyles: CSSProperties = useMemo(
    () => ({
      position: "absolute",
      right: "12px",
      top: "16px",
      color: theme.palette.info.main,
    }),
    [theme.palette.info.main]
  );

  const dialogPaperStyles = useMemo(
    () => ({
      sx: {
        maxWidth: "unset",
        margin: 0,
      },
    }),
    []
  );

  const [updatedProperties, setUpdatedProperties] = useState<Nullable<
    Widget["properties"]
  > | null>(null);

  const { sensorId } = useSelectedSensorIdContext();
  const { updateWidgetPropertiesQuery, deleteWidgetQuery } =
    useSensorWidgets(sensorId);

  const snackBar = useSnackBarContext();
  const updateWidgetProperties = useCallback(async () => {
    if (!updatedProperties) {
      return;
    }
    if (!Widget.isPropertiesFullFilled(updatedProperties)) {
      return;
    }

    try {
      const updatedWidget = await updateWidgetPropertiesQuery.mutateAsync({
        id: widget.id,
        properties: updatedProperties,
      });

      if (updatedWidget) {
        widgetRef.current = updatedWidget;
      }

      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.edit_widget.alert.update.success"
        ),
      });
      setUpdatedProperties(null);
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.edit_widget.alert.update.error"
        ),
      });
    }
    close();
  }, [
    close,
    snackBar,
    updateWidgetPropertiesQuery,
    updatedProperties,
    widget.id,
  ]);

  const handlePropertiesChange = useCallback(
    (_: Nullable<Widget["properties"]> | null) =>
      // We don't want to update the widget properties if the user has not validated them
      // Instead, we reset the updated properties to null in case of changes
      {
        setUpdatedProperties(null);
      },
    []
  );

  const handleUserValidation = useCallback(
    (properties: Widget["properties"]) => {
      setUpdatedProperties(properties);
    },
    []
  );

  const deleteWidget = useCallback(async () => {
    try {
      await deleteWidgetQuery.mutateAsync({
        id: widget.id,
      });
      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.edit_widget.alert.delete.success"
        ),
      });
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.edit_widget.alert.delete.error"
        ),
      });
    }
    close();
  }, [close, deleteWidgetQuery, snackBar, widget.id]);

  // We need to calculate the width of the content to fit the widget
  const contentStyles = useMemo(() => {
    const screenSize = mediaQueries.size;
    const screenSizeColsQuantity = WidgetsContainerUtils.cols[screenSize];
    const layoutColsQuantity = widget.layouts[screenSize].w;
    const widgetColsFactor = screenSizeColsQuantity / layoutColsQuantity; // 1 or 2 depending on screensize and widget layout;
    const paddingX =
      2 * CSSUtils.getSizeNumberFromPx(getSensorPagePadding(mediaQueries));

    const contentWidth = `calc(100vw / ${widgetColsFactor} - ${paddingX}px)`;

    return {
      display: "flex",
      width: contentWidth,
      overflow: "hidden",
    };
  }, [mediaQueries, widget.layouts]);

  useEffect(() => {
    widgetRef.current = widget;
  }, [widget]);

  return (
    <Dialog open={isOpen} onClose={close} PaperProps={dialogPaperStyles}>
      <CloseButton onClick={close} incomingStyle={closeButtonStyles} />
      <DialogContent sx={styles.content}>
        <Box sx={contentStyles}>
          <WidgetContainer
            widget={widgetRef.current}
            shouldShowEditionForm
            onPropertiesChange={handlePropertiesChange}
            onUserValidation={handleUserValidation}
            withDragAction={false}
            withEditAction={false}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actionsContainer}>
        <Box>
          <LoadingButton
            variant="text"
            color="error"
            onClick={deleteWidget}
            startIcon={<Delete />}
            disabled={deleteWidgetQuery.isLoading}
            loading={deleteWidgetQuery.isLoading}
          >
            {I18n.translate("general_text.delete")}
          </LoadingButton>
        </Box>

        <Box sx={styles.rightActions}>
          {!fromAddWidgetDialog && (
            <Button variant="outlined" color="info" onClick={close}>
              {I18n.translate("general_text.cancel")}
            </Button>
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={updateWidgetProperties}
            disabled={!updatedProperties}
            loading={updateWidgetPropertiesQuery.isLoading}
          >
            {I18n.translate("general_text.save")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default memo(EditWidgetDialog);
