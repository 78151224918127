import { ModelId } from "../common/Model";
import { RoleName } from "./RoleName";
import { RoleDto } from "services/apis/ceaMoonshot/dtos/authentication/RoleDto";
import Rule from "./Rule";

export default class Role {
  public readonly id: ModelId;
  public readonly name: RoleName;
  public readonly rules: Rule[];

  private constructor(params: { id: ModelId; name: RoleName; rules: Rule[] }) {
    this.id = params.id;
    this.name = params.name;
    this.rules = params.rules;
  }

  public static fromDto(dto: RoleDto): Role {
    return new Role({
      id: dto.id,
      name: dto.name,
      rules: dto.rules ? dto.rules.map((rule) => Rule.fromDto(rule)) : [],
    });
  }
}
