import { singleton } from "tsyringe";
import developmentConfig from "./development.json";
import preprodConfig from "./preprod.json";
import stagingConfig from "./staging.json";

@singleton()
export default class ModuleConfig {
  private config: typeof developmentConfig = developmentConfig;
  public constructor() {
    this.setConfig();
  }

  public get() {
    return this.config;
  }

  private setConfig() {
    switch (process.env["REACT_APP_ENV_NAME"]) {
      case "staging":
        this.config = stagingConfig;
        break;
      case "preprod":
        this.config = preprodConfig;
        break;
    }
  }
}
