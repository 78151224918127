import { memo, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

type Props = {
  minimum: number;
  quartile25: number;
  median: number;
  quartile75: number;
  maximum: number;
  focusAverage: number;
  focusLabel: string;
};

function CO2RelativeAverageChart({
  minimum,
  quartile25,
  median,
  quartile75,
  maximum,
  focusAverage: focusNumber,
  focusLabel,
}: Props) {
  const options: ApexCharts.ApexOptions = useMemo(
    () => ({
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        colors: ["#447745"],
      },
      fill: {
        opacity: 0.5,
      },
      xaxis: {
        axisBorder: {
          show: false,
          offsetX: 3,
          offsetY: -1,
        },
        labels: {
          show: false,
        },
        tickAmount: 0,
      },
      yaxis: {
        title: {
          text: "CO2 (ppm)",
          style: {
            color: "rgba(0, 0, 0, 0.87)",
          },
          offsetX: 0,
          offsetY: 0,
        },
        min: minimum - (maximum - minimum) * 0.2,
        max: maximum + (maximum - minimum) * 0.2,
        tickAmount: 8,
        axisBorder: {
          show: true,
          offsetX: 3,
          offsetY: -1,
        },
        labels: {
          formatter: function (value: number, opts: number) {
            if (opts === 0) {
              return "";
            }

            return value === 0 ? "" : value.toFixed(2);
          },
          style: {
            fontSize: "12px",
            fontFamily: "Inter",
            fontWeight: 500,
            cssClass: "styles.yaxisChart",
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: focusNumber,
            borderColor: "#447745",
            borderWidth: 2,
            strokeDashArray: 0,
            label: {
              textAnchor: "start",
              position: "bottom",
              offsetX: 5,
              offsetY: -10,
              borderColor: "#447745",
              borderWidth: 4,
              style: {
                color: "white",
                background: "#447745",
              },
              text: focusLabel,
            },
          },
        ],
      },
      plotOptions: {
        bar: {
          columnWidth: "10%",
        },
        boxPlot: {
          colors: {
            upper: "#447745",
            lower: "#447745",
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    }),
    [focusLabel, focusNumber, maximum, minimum]
  );

  const series: ApexAxisChartSeries = useMemo(
    () => [
      {
        data: [
          {
            x: "",
            y: [minimum, quartile25, median, quartile75, maximum],
          },
        ],
      },
    ],
    [maximum, median, minimum, quartile25, quartile75]
  );

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="boxPlot"
      height={"100%"}
    />
  );
}

export default memo(CO2RelativeAverageChart);
