import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const content: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
});

export const rangeDateContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "flex-start",
  marginLeft: theme.spacing(4),
});

export const chartContainer: SxProps<Theme> = {
  flex: 1,
  height: "360px",
};

export const loadingContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
