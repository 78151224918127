import * as styles from "./styles";
import { Box, useTheme } from "@mui/material";
import { memo, useMemo } from "react";
import DragAction from "./DragAction";
import EditAction from "./EditAction";
import Module from "components/materials/Module";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import Widget from "models/widgets/Widget";

type Props = {
  widget: Widget;
  withEditAction: boolean;
  withDragAction: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};

function WidgetActions({
  widget,
  withEditAction,
  withDragAction,
  onDragStart,
  onDragEnd,
}: Props) {
  const theme = useTheme();
  const actionStyles = useMemo(
    () => ({
      padding: styles.padding,
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
    [theme.palette.action.hover]
  );

  return (
    <Box sx={styles.root}>
      {withEditAction && (
        <Module
          from={
            container.resolve(ModuleConfig).get().modules.components
              .widgetActions.edit
          }
        >
          <EditAction actionStyles={actionStyles} widget={widget} />
        </Module>
      )}

      {withDragAction && (
        <Module
          from={
            container.resolve(ModuleConfig).get().modules.components
              .widgetActions.drag
          }
        >
          <DragAction
            actionStyles={actionStyles}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        </Module>
      )}
    </Box>
  );
}

export default memo(WidgetActions);
