import { memo, useCallback, useRef, useState } from "react";
import { TextField, Button, Box, FormControl } from "@mui/material";
import { SignInComponentsProps } from "..";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import { container } from "tsyringe";
import AuthenticationService from "services/AuthenticationService";
import { z } from "zod";
import { isHttpResponse } from "models/common/HttpResponse";
import useFormValidation from "hooks/useFormValidation";
import HelperText from "components/element/HelperText";

const formSchema = z.object({
  email: z
    .string()
    .email(
      I18n.translate(
        "components.authentication.sign_in_dialog.email_password_form.email.errors.invalid_format"
      )
    ),
  password: z.string(),
});

function EmailPasswordForm({ handleSignIn }: SignInComponentsProps) {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [is401Error, setIs401Error] = useState(false);

  const formValidation = useFormValidation(formSchema);

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      const validatedData = await formValidation.validateAsync({
        email: emailInputRef.current?.value,
        password: passwordInputRef.current?.value,
      });
      if (!validatedData) return;

      try {
        await container
          .resolve(AuthenticationService)
          .createJwtPairByEmailPassword(validatedData);
      } catch (error) {
        if (isHttpResponse(error) && error.status === 401) {
          setIs401Error(true);
        }
        return;
      }

      handleSignIn();
    },
    [formValidation, handleSignIn]
  );

  return (
    <Box sx={styles.root}>
      <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
        <FormControl fullWidth margin="normal" sx={styles.firstField}>
          <TextField
            inputRef={emailInputRef}
            variant="standard"
            label={I18n.translate(
              "components.authentication.sign_in_dialog.email_password_form.email.label"
            )}
            placeholder={I18n.translate(
              "components.authentication.sign_in_dialog.email_password_form.email.placeholder"
            )}
            error={formValidation.isError("email")}
            helperText={
              <HelperText textList={formValidation.errorsOf("email")} />
            }
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            inputRef={passwordInputRef}
            variant="standard"
            label={I18n.translate(
              "components.authentication.sign_in_dialog.email_password_form.password.label"
            )}
            type="password"
            placeholder={I18n.translate(
              "components.authentication.sign_in_dialog.email_password_form.password.placeholder"
            )}
            error={formValidation.isError("password", [is401Error])}
            helperText={
              <HelperText
                textList={formValidation.errorsOf("password", [
                  {
                    message: I18n.translate(
                      "components.authentication.sign_in_dialog.email_password_form.password.errors.incorrect_password"
                    ),
                    isError: is401Error,
                  },
                ])}
              />
            }
          />
        </FormControl>

        <Button
          sx={styles.button}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          <I18n
            map={
              "components.authentication.sign_in_dialog.email_password_form.button"
            }
          />
        </Button>
      </Box>
    </Box>
  );
}

export default memo(EmailPasswordForm);
