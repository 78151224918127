import {
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import useUserNotifications from "hooks/queries/useUserNotifications";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import NotificationCard from "./NotificationCard";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import { FilterList } from "@mui/icons-material";
import useMediaQueries from "hooks/useMediaQueries";
import SortUtils from "utils/SortUtils";
import { Direction } from "models/common/Direction";
import Notification from "models/notification/Notification";
import useOpenable from "hooks/useOpenable";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";

type Props = {
  isOpen: boolean;
  close: () => void;
};

const importanceScore: Record<GlobalAuditionStatus, number> = {
  [GlobalAuditionStatus.AllClear]: 0,
  [GlobalAuditionStatus.Unknown]: 1,
  [GlobalAuditionStatus.FatalError]: 2,
  [GlobalAuditionStatus.OneError]: 3,
  [GlobalAuditionStatus.MultipleErrors]: 4,
};

function NotificationDrawer(props: Props) {
  const mediaQueries = useMediaQueries();
  const { notifications } = useUserNotifications();

  const sortButtonRef = useRef<HTMLDivElement>(null);
  const sortMenu = useOpenable();

  const [sortedNotifications, setSortedNotifications] = useState<
    Notification[]
  >([]);

  const sortByDateAscending = useCallback(() => {
    setSortedNotifications((prev) =>
      prev.sort((a, b) => {
        return SortUtils.sortABNum({
          valueA: a.data?.auditExecutionDateTime?.getTime() ?? 0,
          valueB: b.data?.auditExecutionDateTime?.getTime() ?? 0,
          direction: Direction.Asc,
        });
      })
    );
    sortMenu.close();
  }, [sortMenu]);

  const sortByDateDescending = useCallback(() => {
    setSortedNotifications((prev) =>
      prev.sort((a, b) => {
        return SortUtils.sortABNum({
          valueA: a.data?.auditExecutionDateTime?.getTime() ?? 0,
          valueB: b.data?.auditExecutionDateTime?.getTime() ?? 0,
          direction: Direction.Desc,
        });
      })
    );
    sortMenu.close();
  }, [sortMenu]);

  const sortByImportanceAscending = useCallback(() => {
    setSortedNotifications((prev) =>
      prev.sort((a, b) => {
        if (
          a.isNotificationCompletedAudit() &&
          b.isNotificationCompletedAudit()
        ) {
          return SortUtils.sortABNum({
            valueA: importanceScore[a.data.auditGlobalErrorStatus],
            valueB: importanceScore[b.data.auditGlobalErrorStatus],
            direction: Direction.Asc,
          });
        }
        return 0;
      })
    );
    sortMenu.close();
  }, [sortMenu]);

  const sortByImportanceDescending = useCallback(() => {
    setSortedNotifications((prev) =>
      prev.sort((a, b) => {
        if (
          a.isNotificationCompletedAudit() &&
          b.isNotificationCompletedAudit()
        ) {
          return SortUtils.sortABNum({
            valueA: importanceScore[a.data.auditGlobalErrorStatus],
            valueB: importanceScore[b.data.auditGlobalErrorStatus],
            direction: Direction.Desc,
          });
        }
        return 0;
      })
    );
    sortMenu.close();
  }, [sortMenu]);

  useEffect(() => {
    if (!notifications.length) return;

    setSortedNotifications(
      notifications.sort((a, b) => {
        return SortUtils.sortABNum({
          valueA: a.data?.auditExecutionDateTime?.getTime() ?? 0,
          valueB: b.data?.auditExecutionDateTime?.getTime() ?? 0,
          direction: Direction.Desc,
        });
      })
    );
  }, [notifications]);

  return (
    <Drawer
      open={props.isOpen}
      onClose={props.close}
      anchor="right"
      sx={styles.root(mediaQueries)}
      elevation={0}
    >
      <Box sx={styles.header}>
        <Typography variant="h6">
          <I18n map="general_text.notifications" />
        </Typography>

        <Box ref={sortButtonRef} sx={styles.sortButtonContainer}>
          <IconButton onClick={sortMenu.open}>
            <FilterList />
          </IconButton>
        </Box>

        <Menu
          open={sortMenu.isOpen}
          onClose={sortMenu.close}
          anchorEl={sortButtonRef.current}
          sx={styles.sortMenu}
        >
          <MenuItem dense onClick={sortByDateAscending}>
            <I18n map="components.notifications.sort_menu.date_ascending" />
          </MenuItem>

          <MenuItem dense onClick={sortByDateDescending}>
            <I18n map="components.notifications.sort_menu.date_descending" />
          </MenuItem>

          <MenuItem dense onClick={sortByImportanceAscending}>
            <I18n map="components.notifications.sort_menu.importance_ascending" />
          </MenuItem>

          <MenuItem dense onClick={sortByImportanceDescending}>
            <I18n map="components.notifications.sort_menu.importance_descending" />
          </MenuItem>
        </Menu>
      </Box>

      <Box sx={styles.notificationsContainer}>
        {sortedNotifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            close={props.close}
          />
        ))}
      </Box>
    </Drawer>
  );
}

export default memo(NotificationDrawer);
