import { memo, useCallback, useMemo } from "react";
import useCurrentlyActiveSensors from "hooks/queries/useCurrentlyActiveSensors";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import SensorDataUtils from "utils/SensorResultUtils";
import CustomAutocompleteSingle, {
  IOption,
} from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import useSensorPageNavigation from "hooks/useSensorPageNavigation";
import { Search } from "@mui/icons-material";
import * as styles from "./styles";
import useRoutes from "hooks/useRoutes";
import useUser from "hooks/queries/useUser";

type Props = {
  onChange?: (sensorId: ModelId | null) => unknown;
};

function ExternalUserSensorsSearchBar({ onChange }: Props) {
  const { navigateToSensorPage } = useSensorPageNavigation();
  const { currentlyActiveSensors } = useCurrentlyActiveSensors();
  const routes = useRoutes();
  const { userLaboratoryId } = useUser();

  const shouldDisplayOnlyLaboratorySensors =
    routes.laboratory.sensor.sensorAuditsRule.isCurrent;

  const sensorsToDisplay = shouldDisplayOnlyLaboratorySensors
    ? currentlyActiveSensors?.filter(
        (sensor) => sensor.laboratory?.id === userLaboratoryId
      )
    : currentlyActiveSensors;

  const options: IOption[] = useMemo(
    () =>
      sensorsToDisplay
        ?.map((sensor) => {
          return {
            id: sensor.id,
            label: SensorDataUtils.formatDisplayName(sensor),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [sensorsToDisplay]
  );

  const { sensorId, setSensorId } = useSelectedSensorIdContext();

  const handleSensorSelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: IOption | null) => {
      const sensorId = value?.id ?? null;
      setSensorId(sensorId);
      onChange?.(sensorId);

      if (sensorId) {
        navigateToSensorPage(sensorId);
      }
    },
    [navigateToSensorPage, onChange, setSensorId]
  );

  return (
    <CustomAutocompleteSingle
      options={options}
      onChange={handleSensorSelection}
      placeholder={I18n.translate("general_text.search_sensor")}
      value={options.find((option) => option.id === sensorId)}
      startIcon={<Search sx={styles.searchIcon} color="action" />}
      sx={styles.autocomplete}
    />
  );
}

export default memo(ExternalUserSensorsSearchBar);
