import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const dateText: SxProps<Theme> = {
  fontSize: "14px",
};
