import { container, singleton } from "tsyringe";
import SensorApi from "./apis/ceaMoonshot/SensorApi";
import Sensor from "models/sensor/Sensor";
import { ModelId } from "models/common/Model";
import { DateBoundary, convertToDateBoundaryDto } from "models/widgets/Widget";
import DateUtils from "utils/DateUtils";

@singleton()
export default class SensorService {
  public async findSensors(params?: {
    queryParams?: {
      sensorIds?: ModelId[];
      laboratoryId?: ModelId;
      isActiveAtDate?: DateBoundary;
      withSensorResults?: {
        fromDate?: DateBoundary;
        toDate?: DateBoundary;
      };
    };
  }): Promise<Sensor[]> {
    const paramsDto = {
      queryParams: {
        sensorIds: params?.queryParams?.sensorIds,
        laboratoryId: params?.queryParams?.laboratoryId,
        ...(params?.queryParams?.isActiveAtDate && {
          isActiveAtDate: convertToDateBoundaryDto(
            params.queryParams.isActiveAtDate
          ),
        }),
        ...(params?.queryParams?.withSensorResults && {
          withSensorResults: {
            fromDate:
              params.queryParams.withSensorResults.fromDate &&
              convertToDateBoundaryDto(
                params.queryParams.withSensorResults.fromDate
              ),
            toDate:
              params.queryParams.withSensorResults.toDate &&
              convertToDateBoundaryDto(
                params.queryParams.withSensorResults.toDate
              ),
          },
        }),
      },
    };

    const sensorsDto = await container.resolve(SensorApi).findMany(paramsDto);

    return sensorsDto.map((dto) => Sensor.fromDto(dto));
  }

  public async getCurrentlyActiveSensors(): Promise<Sensor[]> {
    const sensorsDto = await container.resolve(SensorApi).findMany({
      queryParams: {
        isActiveAtDate: {
          fix: DateUtils.nowUTC().toISOString(),
        },
      },
    });

    return sensorsDto.map((dto) => Sensor.fromDto(dto));
  }

  public async getActiveSensorsAtDate({
    sensorIds,
    isActiveAtDate,
  }: {
    sensorIds: ModelId[];
    isActiveAtDate: DateBoundary;
  }): Promise<Sensor[]> {
    const sensorsDto = await container.resolve(SensorApi).findMany({
      queryParams: {
        isActiveAtDate: convertToDateBoundaryDto(isActiveAtDate),
        sensorIds,
      },
    });

    return sensorsDto.map((dto) => Sensor.fromDto(dto));
  }

  public async getById(
    id: ModelId,
    options?: { withResultsSessions?: boolean }
  ): Promise<Sensor | null> {
    const sensorDto = await container.resolve(SensorApi).getById(id, options);

    return sensorDto ? Sensor.fromDto(sensorDto) : null;
  }

  public async getSensorsWithResults({
    sensorIds,
    fromDate,
    toDate,
  }: {
    sensorIds: ModelId[];
    fromDate: DateBoundary;
    toDate: DateBoundary;
  }): Promise<Sensor[]> {
    const sensorsDto = await container.resolve(SensorApi).findMany({
      queryParams: {
        withSensorResults: {
          fromDate: convertToDateBoundaryDto(fromDate),
          toDate: convertToDateBoundaryDto(toDate),
        },
        sensorIds,
      },
    });

    return sensorsDto.map((dto) => Sensor.fromDto(dto));
  }

  public async createSensor({
    laboratoryId,
    metadata,
    situation,
  }: {
    laboratoryId: ModelId;
    metadata: {
      type: string;
      name: string;
      identifier: string;
      blockchainAddress: string;
      blockchainActivationDate: Date;
    };
    situation: {
      heightAboveGround: number | null;
      heightAboveSea: number | null;
      latitude: number;
      longitude: number;
      location: string;
      installationDate: Date;
      activationDate: Date | null;
      deactivationDate: Date | null;
      useCase: string | null;
    };
  }): Promise<Sensor> {
    const sensorDto = await container.resolve(SensorApi).create({
      laboratoryId,
      metadata: {
        ...metadata,
        blockchainActivationDate:
          metadata.blockchainActivationDate.toISOString(),
      },
      situation: {
        ...situation,
        installationDate: situation.installationDate.toISOString(),
        activationDate: situation.activationDate?.toISOString() ?? null,
        deactivationDate: situation.deactivationDate?.toISOString() ?? null,
      },
    });

    return Sensor.fromDto(sensorDto);
  }

  public async updateSensor(
    params: {
      sensorId: ModelId;
      situationId: ModelId;
    } & Partial<{
      metadata: {
        type: string;
        name: string;
        identifier: string;
        blockchainAddress: string;
        blockchainActivationDate: Date;
      };
      situation: {
        heightAboveGround: number | null;
        heightAboveSea: number | null;
        latitude: number;
        longitude: number;
        location: string;
        installationDate: Date;
        activationDate: Date | null;
        deactivationDate: Date | null;
        useCase: string | null;
      };
    }>
  ): Promise<Sensor | null> {
    const sensorDto = await container.resolve(SensorApi).update({
      sensorId: params.sensorId,
      situationId: params.situationId,
      ...(params.metadata && {
        metadata: {
          ...params.metadata,
          blockchainActivationDate:
            params.metadata.blockchainActivationDate?.toISOString(),
        },
      }),
      ...(params.situation && {
        situation: {
          ...params.situation,
          installationDate: params.situation.installationDate.toISOString(),
          activationDate:
            params.situation.activationDate?.toISOString() ?? null,
          deactivationDate:
            params.situation.deactivationDate?.toISOString() ?? null,
        },
      }),
    });

    return sensorDto ? Sensor.fromDto(sensorDto) : null;
  }

  public async updateImage(params: {
    sensorId: ModelId;
    image: File | null;
  }): Promise<Sensor | null> {
    const sensorDto = await container.resolve(SensorApi).updateImage(params);

    return sensorDto ? Sensor.fromDto(sensorDto) : null;
  }
}
