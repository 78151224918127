import { container, singleton } from "tsyringe";
import AuthenticationApi from "./apis/ceaMoonshot/AuthenticationApi";
import LocalStorageService from "./LocalStorageService";
import User from "models/user/User";
import JwtPair from "models/authentication/JwtPair";
import { z } from "zod";
import { RoleName } from "models/authentication/RoleName";
import { RuleName } from "models/authentication/RuleName";
import jwtDecode from "jwt-decode";

export type DecodedAccessToken = z.infer<
  (typeof AuthenticationService.prototype)["decodedAccessTokenSchema"]
>;

@singleton()
export default class AuthenticationService {
  private readonly decodedAccessTokenSchema = z.object({
    jti: z.string(),
    sub: z.string(),
    iat: z.number(),
    exp: z.number(),
    iss: z.string(),
    userId: z.number(),
    email: z.string(),
    roles: z.array(
      z.object({
        name: z.nativeEnum(RoleName),
        rules: z.array(
          z.object({
            name: z.nativeEnum(RuleName),
            action: z.string(),
          })
        ),
      })
    ),
  });

  public async signOut(): Promise<void> {
    const localStorageService = container.resolve(LocalStorageService);
    const accessToken = localStorageService.items.accessToken.get();
    if (accessToken) {
      container.resolve(AuthenticationApi).signOut(accessToken);
    }

    localStorageService.items.accessToken.delete();
    localStorageService.items.refreshToken.delete();
  }

  public async createJwtPairByGoogleCredential(
    credential: string
  ): Promise<void> {
    await this.signIn(() =>
      container
        .resolve(AuthenticationApi)
        .createJwtPairByGoogleCredential(credential)
    );
  }

  public async createJwtPairByEmailPassword({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> {
    await this.signIn(() =>
      container
        .resolve(AuthenticationApi)
        .createJwtPairByEmailPassword({ email, password })
    );
  }

  private async signIn(signInMethod: () => Promise<JwtPair>) {
    try {
      const jwtPairDto = await signInMethod();
      const jwtPair = JwtPair.fromDto(jwtPairDto);

      this.setLocalStorageJwtPair(jwtPair);
    } catch (error) {
      this.signOut();
    }
  }

  private async presign(): Promise<void> {
    await this.signIn(() => container.resolve(AuthenticationApi).presign());
  }

  public async autoConnect(): Promise<User | null> {
    const jwtPair = this.getJwtPair();
    if (!jwtPair) {
      await this.presign();
      return null;
    }

    try {
      const userDto = await container.resolve(AuthenticationApi).signIn();
      const user = User.fromDto(userDto);
      return user;
    } catch (error) {
      return null;
    }
  }

  private setLocalStorageJwtPair(jwtPair: JwtPair) {
    container
      .resolve(LocalStorageService)
      .items.accessToken.set(jwtPair.accessToken);
    container
      .resolve(LocalStorageService)
      .items.refreshToken.set(jwtPair.refreshToken);
  }

  public getDecodedAccessToken(accessToken: string): DecodedAccessToken | null {
    const decodedAccessToken = jwtDecode(accessToken);

    try {
      return this.decodedAccessTokenSchema.parse(decodedAccessToken);
    } catch {
      return null;
    }
  }

  private getJwtPair(): JwtPair | null {
    const accessToken = container
      .resolve(LocalStorageService)
      .items.accessToken.get();
    if (!accessToken) return null;

    const refreshToken = container
      .resolve(LocalStorageService)
      .items.refreshToken.get();
    if (!refreshToken) return null;

    return JwtPair.fromLocalStorage({ accessToken, refreshToken });
  }
}
