import DateUtils from "utils/DateUtils";
import SensorEpoch from "./SensorEpoch";
import { ExecutionStatus } from "./ExecutionStatus";
import { SensorAuditExecutionMode } from "./SensorAuditExecutionMode";
import { SensorAuditDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorAuditDto";
import AuditReport from "models/auditReport/AuditReport";
import { ModelId } from "models/common/Model";

export default class SensorAudit {
  public readonly id: ModelId;
  public readonly executionDateTime: Date;
  public readonly executionStatus: ExecutionStatus;
  public readonly executionMode: SensorAuditExecutionMode;
  public readonly report: AuditReport | null;
  public readonly sensorEpoch?: SensorEpoch | null;

  private constructor(params: {
    id: ModelId;
    executionDateTime: Date;
    executionStatus: ExecutionStatus;
    executionMode: SensorAuditExecutionMode;
    report: AuditReport | null;
    sensorEpoch?: SensorEpoch | null;
  }) {
    this.id = params.id;
    this.executionDateTime = params.executionDateTime;
    this.executionStatus = params.executionStatus;
    this.executionMode = params.executionMode;
    this.report = params.report;
    this.sensorEpoch = params.sensorEpoch;
  }

  public static fromDto(dto: SensorAuditDto): SensorAudit {
    return new SensorAudit({
      id: dto.id,
      executionDateTime: DateUtils.fromIsoString(dto.executionDateTime),
      executionStatus: dto.executionStatus,
      executionMode: dto.executionMode,
      report: dto.report,
      sensorEpoch: dto.sensorEpoch
        ? SensorEpoch.fromDto(dto.sensorEpoch)
        : dto.sensorEpoch,
    });
  }
}
