import { memo } from "react";

import CustomAutocompleteSingle, {
  IOption,
} from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import { Search } from "@mui/icons-material";

type Props = {
  onChange?: (
    _: React.SyntheticEvent<Element, Event>,
    option: IOption | null
  ) => void;
  options: IOption[];
  value?: IOption | null;
};

function FileSearchBar({ onChange, options, value }: Readonly<Props>) {
  return (
    <CustomAutocompleteSingle
      options={options}
      onChange={onChange}
      placeholder={I18n.translate("pages.audit.search_file")}
      value={value ?? null}
      startIcon={<Search sx={styles.searchIcon} color="action" />}
      sx={styles.autocomplete}
    />
  );
}

export default memo(FileSearchBar);
