import { useCallback } from "react";
import useRoutes from "./useRoutes";
import { ModelId } from "models/common/Model";
import { useNavigate } from "react-router-dom";
import useCurrentPageType from "./useCurrentPageType";

function useSensorPageNavigation() {
  const navigate = useNavigate();
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();

  const navigateToSensorPage = useCallback(
    (sensorId: ModelId) => {
      if (
        (!routes[currentPageType].sensor.isCurrent &&
          !routes[currentPageType].sensor.isCurrent) ||
        !sensorId
      ) {
        return;
      }

      if (routes[currentPageType].sensor.sensorData.isCurrent) {
        navigate(
          routes[currentPageType].sensor.sensorData.resolveRoute({ sensorId })
        );
      }
      if (routes[currentPageType].sensor.sensorAudits.isCurrent) {
        navigate(
          routes[currentPageType].sensor.sensorAudits.resolveRoute({ sensorId })
        );
      }
      if (routes[currentPageType].sensor.sensorTransactions.isCurrent) {
        navigate(
          routes[currentPageType].sensor.sensorTransactions.resolveRoute({
            sensorId,
          })
        );
      }
      if (routes.laboratory.sensor.sensorAuditsRule.isCurrent) {
        navigate(
          routes.laboratory.sensor.sensorAuditsRule.resolveRoute({ sensorId })
        );
      }
      if (routes.laboratory.sensor.sensorEdition.isCurrent) {
        navigate(
          routes.laboratory.sensor.sensorEdition.resolveRoute({ sensorId })
        );
      }
    },
    [currentPageType, navigate, routes]
  );

  return { navigateToSensorPage };
}

export default useSensorPageNavigation;
