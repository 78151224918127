import { Box, Button, Container, Link, Typography } from "@mui/material";
import { memo } from "react";
import { Link as ReactLink } from "react-router-dom";
import CEAIcon from "assets/images/icons/cea-logo-icon.svg";
import CEAImage from "assets/images/landingIcons/ceaIcon.svg";
import IcosCities from "assets/images/landingIcons/icosCities.png";
import IcosFRImage from "assets/images/landingIcons/icosFRIcon.svg";
import LetiImage from "assets/images/landingIcons/letiIcon.svg";
import ListImage from "assets/images/landingIcons/listIcon.svg";
import LsceImage from "assets/images/landingIcons/lsceIcon.svg";
import OriginEarth from "assets/images/landingIcons/originEarth.svg";
import SignInButton from "components/materials/authentication/SignInButton";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import useMediaQueries from "hooks/useMediaQueries";
import useRoutes from "hooks/useRoutes";
import * as styles from "./styles";
import useUser from "hooks/queries/useUser";

function HomePage() {
  useHead({
    title: I18n.translate("pages.home.title"),
  });
  const mediaQueries = useMediaQueries();
  const routes = useRoutes();
  const { user } = useUser();

  return (
    <Container sx={styles.content}>
      <Container>
        <Box sx={styles.title}>
          <img src={CEAIcon} style={{ width: "32px" }} alt="" />
          <Typography variant="h6">
            <I18n map="pages.home.explainTitle" />
          </Typography>
        </Box>

        <Box sx={styles.explainSubTitle}>
          <Typography color="#447745" variant="h3">
            <I18n map="pages.home.explainSubTitle1" />
          </Typography>
          <Typography variant="h3">
            <I18n map="pages.home.explainSubTitle2" />
          </Typography>
        </Box>

        <Typography sx={styles.explainText} variant="h6">
          <I18n map="pages.home.explainText" />
        </Typography>

        <Box sx={{ marginTop: "24px" }}>
          {user && (
            <ReactLink
              to={
                user.isLabUser()
                  ? routes.laboratory.sensors.resolveRoute()
                  : routes.externalUser.sensors.resolveRoute()
              }
            >
              <Button variant="contained" color="primary">
                {I18n.translate("pages.home.view_more_sensors")}
              </Button>
            </ReactLink>
          )}
          {!user && (
            <SignInButton
              signInText={I18n.translate("pages.home.view_more_sensors")}
            />
          )}
        </Box>
      </Container>

      <Box sx={styles.bigLeaveImage(mediaQueries)} />

      <Container sx={styles.whoAreWeContainer(mediaQueries)}>
        <Box sx={styles.smallLeaveImage(mediaQueries)} />
        <Container sx={styles.whoAreWe(mediaQueries)}>
          <Typography color="#447745" variant="h4">
            <I18n map="pages.home.whoTitle" />
          </Typography>
          <Typography sx={styles.whoAreWeText} variant="subtitle1">
            <I18n map="pages.home.whoText" />
          </Typography>
          <Typography sx={styles.whoAreSubText} variant="body1">
            <I18n map="pages.home.whoSubText" />
          </Typography>

          <Box sx={styles.whoAreWeIcons(mediaQueries)}>
            <Link href="https://www.cea.fr/">
              <img
                src={CEAImage}
                style={{ width: "112px", height: "91.38px" }}
                alt=""
              />
            </Link>
            <Link href="https://www.leti-cea.com/cea-tech/leti/english">
              <img
                src={LetiImage}
                style={{ width: "112px", height: "91.38px" }}
                alt=""
              />
            </Link>
            <Link href="https://list.cea.fr/en/">
              <img
                src={ListImage}
                style={{ width: "112px", height: "91.38px" }}
                alt=""
              />
            </Link>
            <Link href="https://www.lsce.ipsl.fr">
              <img
                src={LsceImage}
                style={{ width: "112px", height: "91.38px" }}
                alt=""
              />
            </Link>
          </Box>
        </Container>
      </Container>

      <Box sx={styles.partners}>
        <Typography sx={styles.partnersTitle} variant="h4">
          <I18n map="pages.home.partnersTitle" />
        </Typography>
        <Typography sx={styles.partnersText} variant="subtitle1">
          <I18n map="pages.home.partnersText" />
          <br />
          <I18n map="pages.home.partnersText2" />
        </Typography>
        <Container sx={styles.partnersIcons(mediaQueries)}>
          <Link href="https://icos-france.fr/">
            <img
              src={IcosFRImage}
              style={{ width: "200px", height: "auto" }}
              alt=""
            />
          </Link>
          <Link href="https://www.icos-cp.eu/">
            <img
              src={IcosCities}
              style={{ width: "180px", height: "auto" }}
              alt=""
            />
          </Link>
          <Link href="https://www.origins.earth/">
            <img
              src={OriginEarth}
              style={{ width: "160px", height: "auto" }}
              alt=""
            />
          </Link>
        </Container>
      </Box>

      <Box sx={styles.footerLeavesImage(mediaQueries)} />
    </Container>
  );
}

export default memo(HomePage);
