import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { screenXS } from "themes/constants";
import CSSUtils from "utils/CSSUtils";

export type MediaQueries = ReturnType<typeof useMediaQueries>;

export enum MediaQueriesSize {
  Xxs = "xxs",
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

function useMediaQueries() {
  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  const isAbove = useCallback(
    (customSize: number) => windowWidth > customSize,
    [windowWidth]
  );

  const isBelow = useCallback(
    (customSize: number) => windowWidth <= customSize,
    [windowWidth]
  );

  const isXxs = useMediaQuery(theme.breakpoints.up(0));
  const isStrictlyXxs = useMediaQuery(
    theme.breakpoints.down(CSSUtils.getSizeNumberFromPx(screenXS))
  );

  const isXs = useMediaQuery(theme.breakpoints.up(MediaQueriesSize.Xs));
  const isStrictlyXs =
    useMediaQuery(theme.breakpoints.only(MediaQueriesSize.Xs)) &&
    !isStrictlyXxs;

  const isSm = useMediaQuery(theme.breakpoints.up(MediaQueriesSize.Sm));
  const isStrictlySm = useMediaQuery(
    theme.breakpoints.only(MediaQueriesSize.Sm)
  );

  const isMd = useMediaQuery(theme.breakpoints.up(MediaQueriesSize.Md));
  const isStrictlyMd = useMediaQuery(
    theme.breakpoints.only(MediaQueriesSize.Md)
  );

  const isLg = useMediaQuery(theme.breakpoints.up(MediaQueriesSize.Lg));
  const isStrictlyLg = useMediaQuery(
    theme.breakpoints.only(MediaQueriesSize.Lg)
  );

  const isXl = useMediaQuery(theme.breakpoints.up(MediaQueriesSize.Xl));
  const isStrictlyXl = useMediaQuery(
    theme.breakpoints.only(MediaQueriesSize.Xl)
  );

  const size = useMemo(() => {
    if (isStrictlyXxs) return MediaQueriesSize.Xxs;
    if (isStrictlyXs) return MediaQueriesSize.Xs;
    if (isStrictlySm) return MediaQueriesSize.Sm;
    if (isStrictlyMd) return MediaQueriesSize.Md;
    if (isStrictlyLg) return MediaQueriesSize.Lg;
    if (isStrictlyXl) return MediaQueriesSize.Xl;

    throw new Error("Invalid size");
  }, [
    isStrictlyXxs,
    isStrictlyXs,
    isStrictlySm,
    isStrictlyMd,
    isStrictlyLg,
    isStrictlyXl,
  ]);

  return {
    isXxs,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isStrictlyXxs,
    isStrictlyXs,
    isStrictlySm,
    isStrictlyMd,
    isStrictlyLg,
    isStrictlyXl,
    size,
    isAbove,
    isBelow,
  };
}

export default useMediaQueries;
