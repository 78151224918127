import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
import { memo, useMemo } from "react";

const constantToAlwaysFulfillRadialBar = 1000;

type Props = {
  blockchainEnergyCost: number;
  height: string;
};

function SystemEnergyCostChart({ blockchainEnergyCost, height }: Props) {
  const theme = useTheme();
  const formattedBlockchainEnergyCostWithUnit = useMemo(() => {
    if (blockchainEnergyCost < 1000)
      return {
        value: blockchainEnergyCost,
        unit: "Wh",
      };

    if (blockchainEnergyCost < 1000000)
      return {
        value: blockchainEnergyCost / 1000,
        unit: "kWh",
      };

    return {
      value: blockchainEnergyCost / 1000000,
      unit: "MWh",
    };
  }, [blockchainEnergyCost]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      // height: 100,
      // width: 100,
      type: "radialBar",
      offsetY: -10,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 400,
        animateGradually: {
          enabled: true,
          delay: 100,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 0,
        },
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            offsetY: 20,
            show: true,
            color: theme.palette.primary.main,
            fontSize: "14px",
          },
          value: {
            offsetY: -20,
            fontSize: "18px",
            color: theme.palette.primary.main,
            fontWeight: 700,
            formatter: function (val) {
              return (val / constantToAlwaysFulfillRadialBar).toFixed(1);
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [formattedBlockchainEnergyCostWithUnit.unit],
    fill: {
      colors: [theme.palette.primary.main],
    },
  };

  const series = [
    formattedBlockchainEnergyCostWithUnit.value *
      constantToAlwaysFulfillRadialBar,
  ];

  return (
    <ReactApexChart
      type="radialBar"
      options={options}
      series={series}
      height={height}
      width={135}
    />
  );
}

export default memo(SystemEnergyCostChart);
