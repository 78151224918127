import { singleton } from "tsyringe";
import developmentVariables from "./development.json";
import preprodVariables from "./preprod.json";
import stagingVariables from "./staging.json";

@singleton()
export default class ApplicationVariables {
  private variables: typeof developmentVariables = developmentVariables;
  public constructor() {
    this.setConfig();
  }

  public get() {
    return this.variables;
  }

  private setConfig() {
    switch (process.env["REACT_APP_ENV_NAME"]) {
      case "staging":
        this.variables = stagingVariables;
        break;
      case "preprod":
        this.variables = preprodVariables;
        break;
      case "production":
        this.variables = {
          app: process.env['REACT_APP_APP'] as string,
          versions: [
            {
              "version": process.env['REACT_APP_VERSION'] as string,
              "date": process.env['REACT_APP_VERSION_DATE'] as string
            }
          ],
          api: {
            rootUrl: process.env['REACT_APP_API_ROOT_URL'] as string
          }
        };
    }

  }

}
