export default class UserNotConnectedError extends Error {
  public constructor() {
    super("User is not connected");
  }
}

export function isUserNotConnectedError(
  error: unknown
): error is UserNotConnectedError {
  return error instanceof UserNotConnectedError;
}
