import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { ModelId } from "models/common/Model";
import { WhitelistDto } from "./dtos/laboratory/WhitelistDto";

@singleton()
export default class WhitelistApi extends BaseApi {
  public async findMany(params: {
    laboratoryId: ModelId;
    queryParams?: {};
  }): Promise<WhitelistDto[]> {
    const url = new URL(
      `${this.apiUrl}/laboratories/${params.laboratoryId}/whitelists`
    );

    if (params.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<WhitelistDto[]>(url);
  }

  public async getById(params: {
    whitelistId: ModelId;
  }): Promise<WhitelistDto> {
    const url = new URL(`${this.apiUrl}/whitelists/${params.whitelistId}`);

    return this.getRequest<WhitelistDto>(url);
  }

  public async updateWhitelist(params: {
    whitelistId: ModelId;
    name?: string;
    userIds?: ModelId[];
    laboratoryIds?: ModelId[];
  }): Promise<WhitelistDto | null> {
    const url = new URL(`${this.apiUrl}/whitelists/${params.whitelistId}`);

    return this.patchRequest<WhitelistDto>(url, {
      name: params.name,
      whitelistedUserIds: params.userIds,
      whitelistedLaboratoryIds: params.laboratoryIds,
    });
  }

  public async createWhitelist(params: {
    laboratoryId: ModelId;
    name: string;
    userIds: ModelId[];
    laboratoryIds: ModelId[];
  }): Promise<WhitelistDto> {
    const url = new URL(
      `${this.apiUrl}/laboratories/${params.laboratoryId}/whitelists`
    );

    return this.postRequest<WhitelistDto>(url, {
      name: params.name,
      whitelistedUserIds: params.userIds,
      whitelistedLaboratoryIds: params.laboratoryIds,
    });
  }

  public async deleteWhitelist(params: { whitelistId: number }) {
    const url = new URL(`${this.apiUrl}/whitelists/${params.whitelistId}`);

    return this.deleteRequest(url);
  }
}
