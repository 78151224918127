import { Box, Divider, Typography } from "@mui/material";
import { memo } from "react";

function OrDivider() {
  return (
    <Box display="flex" alignItems="center">
      <Box flex="1">
        <Divider />
      </Box>
      <Box mx={2}>
        <Typography variant="body1">or</Typography>
      </Box>
      <Box flex="1">
        <Divider />
      </Box>
    </Box>
  );
}

export default memo(OrDivider);
