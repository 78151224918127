import { memo } from "react";
import { Link } from "react-router-dom";
import ceaLogoIcon from "assets/images/icons/cea-logo-icon.svg";
import ceaLogoText from "assets/images/icons/cea-logo-text.svg";
import { spacingLG, spacingMD, spacingXS } from "themes/constants";
import useRoutes from "hooks/useRoutes";
import useMediaQueries from "hooks/useMediaQueries";
import { Box } from "@mui/material";

function HeaderLogo() {
  const routes = useRoutes();
  const { isStrictlyXxs } = useMediaQueries();
  const logoHeight = isStrictlyXxs ? spacingMD : spacingLG;

  return (
    <Link to={routes.home.resolveRoute()}>
      <Box display="flex">
        <img
          src={ceaLogoIcon}
          style={{ marginRight: spacingXS }}
          alt=""
          height={logoHeight}
        />

        <img src={ceaLogoText} alt="logo" height={logoHeight} />
      </Box>
    </Link>
  );
}

export default memo(HeaderLogo);
