import { SxProps, Theme } from "@mui/material";
import { ExecutionStatus } from "models/sensor/ExecutionStatus";

function getChipBackgroundColor(theme: Theme): Record<ExecutionStatus, string> {
  return {
    [ExecutionStatus.Pending]: theme.palette.warning.main,
    [ExecutionStatus.Completed]: theme.palette.primary.main,
    [ExecutionStatus.Error]: theme.palette.error.main,
  };
}

export const root: (status: ExecutionStatus | undefined) => SxProps<Theme> =
  (status) => (theme) => {
    return {
      color: theme.palette.primary.contrastText,
      background: status
        ? getChipBackgroundColor(theme)[status]
        : theme.palette.info.light,
      height: theme.spacing(4),
      userSelect: "none",
    };
  };
