import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

export const rightActions: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(2),
});
