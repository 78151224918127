import { CardMedia, SxProps, Theme } from "@mui/material";
import { WidgetType } from "models/widgets/WidgetType";
import { memo, useEffect, useState } from "react";
import WidgetPictureUtils from "utils/WidgetPictureUtils";

type Props = {
  widgetType: WidgetType;
  alt: string;
  sx?: SxProps<Theme>;
};

function WidgetPicture({ widgetType, alt, sx }: Props) {
  const [imageUrl, setImageUrl] = useState(
    WidgetPictureUtils.getPicture(widgetType)
  );

  // If the image is not found, it will display the fallback picture
  const handleError = () => {
    setImageUrl(WidgetPictureUtils.fallbackPictureUrl);
  };

  useEffect(() => {
    setImageUrl(WidgetPictureUtils.getPicture(widgetType));
  }, [widgetType]);

  return (
    <CardMedia
      component="img"
      sx={sx}
      image={imageUrl}
      onError={handleError}
      alt={alt}
    />
  );
}

export default memo(WidgetPicture);
