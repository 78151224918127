import { Box, Typography } from "@mui/material";
import I18n from "components/materials/I18n";
import {
  RelativeDateBoundary,
  retrieveFixDateBoundary,
} from "models/widgets/Widget";
import { memo } from "react";
import DateUtils, { DateFormat } from "utils/DateUtils";
import RelativeDateUtils from "utils/RelativeDateUtils";
import * as styles from "./styles";

type Props = {
  relativeDate: RelativeDateBoundary;
  format: DateFormat;
};

function RelativeDate({ relativeDate, format }: Props) {
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.dateText}>
        {RelativeDateUtils.fromSecondsTotext(relativeDate.relative)}{" "}
        {I18n.translate("general_text.ago")}
      </Typography>

      <Typography sx={styles.dateText}>
        (
        {DateUtils.formatUTCDate(retrieveFixDateBoundary(relativeDate), format)}
        )
      </Typography>
    </Box>
  );
}

export default memo(RelativeDate);
