import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
});

export const autocomplete: (isFilled: boolean) => SxProps<Theme> =
  (isFilled) => (theme) => ({
    ...(isFilled && {
      "& .MuiOutlinedInput-root": {
        padding: "5.5px",
      },
    }),
  });
