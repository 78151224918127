import { Direction } from "models/common/Direction";

export default class SortUtils {
  public static sortABNum({
    valueA,
    valueB,
    direction,
  }: {
    valueA: number;
    valueB: number;
    direction: Direction;
  }): number {
    return (valueA - valueB) * (direction === Direction.Asc ? 1 : -1);
  }

  public static sortABStr({
    valueA,
    valueB,
    direction,
  }: {
    valueA: string;
    valueB: string;
    direction: Direction;
  }): number {
    return (
      valueA.localeCompare(valueB) * (direction === Direction.Asc ? 1 : -1)
    );
  }
}
