import { container, singleton } from "tsyringe";
import { ModelId } from "models/common/Model";
import SensorAuditApi from "./apis/ceaMoonshot/SensorAuditApi";
import SensorAudit from "models/sensor/SensorAudit";
import { SensorAuditExecutionMode } from "models/sensor/SensorAuditExecutionMode";
import DateUtils, { DateFormat } from "utils/DateUtils";

@singleton()
export default class SensorAuditService {
  public async getSensorAudits(params: {
    sensorId?: ModelId;
  }): Promise<SensorAudit[]> {
    const sensorAuditsDto = await container
      .resolve(SensorAuditApi)
      .findMany({ queryParams: params });

    return sensorAuditsDto.map((dto) => SensorAudit.fromDto(dto));
  }

  public async executeAudits(params: {
    sensorId: ModelId;
    fromDate: Date;
    toDate: Date;
  }): Promise<void> {
    await container.resolve(SensorAuditApi).executeAudits({
      ...params,
      fromDate: params.fromDate.toISOString(),
      toDate: params.toDate.toISOString(),
    });
  }

  public async downloadAudits(params: {
    sensorId: ModelId;
    fromDate: Date;
    toDate: Date;
    executionMode: SensorAuditExecutionMode;
  }): Promise<Blob> {
    return await container.resolve(SensorAuditApi).downloadAudits({
      ...params,
      fromDate: DateUtils.formatUTCDate(params.fromDate, DateFormat.IsoDate),
      toDate: DateUtils.formatUTCDate(params.toDate, DateFormat.IsoDate),
    });
  }

  public async getById(id: ModelId): Promise<SensorAudit | null> {
    const sensorDto = await container.resolve(SensorAuditApi).getById(id);

    return sensorDto ? SensorAudit.fromDto(sensorDto) : null;
  }
}
