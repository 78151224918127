import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingX: "12px",
  height: "100%",
  borderRadius: "unset",
};

export const navBar: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(3),
  justifyContent: "space-between",
  width: "100%",
});

export const actions: SxProps<Theme> = (theme) => ({
  display: "flex",
  marginLeft: theme.spacing(2),
  gap: theme.spacing(1),
});
