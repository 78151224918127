import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: theme.spacing(4),
  padding: theme.spacing(3),
});

export const titleContainer: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
