import { Box, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import {
  SensorMapWidget,
  DateBoundary,
  FixDateBoundary,
  RelativeDateBoundary,
  retrieveFixDateBoundary,
  retrieveRelativeDateBoundary,
} from "models/widgets/Widget";
import { memo, useCallback, useState } from "react";
import { Nullable } from "types/common";
import * as styles from "./styles";
import { DayJsFormat } from "utils/DayjsUtils";
import DateUtils from "utils/DateUtils";
import useValidation from "./useValidation";
import useSensors from "hooks/queries/useSensors";
import SensorsInputMulti from "components/element/inputs/SensorsInputMulti";
import RelativeOrFixDateSingleInput from "components/element/inputs/RelativeOrFixDateSingleInput";
import SensorsInputSingle from "components/element/inputs/SensorsInputSingle";
import useUser from "hooks/queries/useUser";
import useFetchedApplicationConfig from "hooks/useFetchedApplicationConfig";

type Props = {
  properties: SensorMapWidget["properties"];
  onSubmit: (properties: SensorMapWidget["properties"]) => void;
  onPropertiesChange?: (
    properties: Nullable<SensorMapWidget["properties"]>
  ) => void;
};

function EditionForm({ properties, onSubmit, onPropertiesChange }: Props) {
  const { applicationConfig } = useFetchedApplicationConfig();
  const { userLaboratoryId } = useUser();
  const { sensors } = useSensors({
    queryParams: {
      laboratoryId: userLaboratoryId,
    },
  });
  const [propertiesState, setPropertiesState] =
    useState<Nullable<SensorMapWidget["properties"]>>(properties);

  const handleDateTypeChange = useCallback(
    (dateType: keyof FixDateBoundary | keyof RelativeDateBoundary) => {
      let updatedProperties: Nullable<SensorMapWidget["properties"]> =
        propertiesState;

      if (dateType === "fix") {
        updatedProperties = {
          ...propertiesState,
          isActiveAtDate: propertiesState.isActiveAtDate
            ? { fix: retrieveFixDateBoundary(propertiesState.isActiveAtDate) }
            : null,
        };
      }

      if (dateType === "relative") {
        updatedProperties = {
          ...propertiesState,
          isActiveAtDate: propertiesState.isActiveAtDate
            ? {
                relative: retrieveRelativeDateBoundary(
                  propertiesState.isActiveAtDate
                ),
              }
            : null,
        };
      }
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleIsActiveAtDateChange = useCallback(
    (isActiveAtDate: DateBoundary | null) => {
      const updatedProperties = {
        ...propertiesState,
        ...(isActiveAtDate !== null && {
          isActiveAtDate,
        }),
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleSensorsChange = useCallback(
    (sensorIds: ModelId[] | null) => {
      const updatedProperties = {
        ...propertiesState,
        sensorIds,
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const handleTargetSensorChange = useCallback(
    (sensorId: ModelId | null) => {
      const updatedProperties = {
        ...propertiesState,
        targetSensorId: sensorId,
      };
      setPropertiesState(updatedProperties);
      onPropertiesChange?.(updatedProperties);
    },
    [onPropertiesChange, propertiesState]
  );

  const nowUTC = DateUtils.nowUTC();
  const minDate = applicationConfig.dashboardResultsMinDate ?? undefined;
  // We want to disable the future
  const maxDate = DateUtils.min(
    applicationConfig.dashboardResultsMaxDate ?? nowUTC,
    nowUTC
  );

  const { validateProperties, errors } = useValidation({
    properties: propertiesState,
    nowUTC,
    minDate,
    maxDate,
  });

  const submit = useCallback(() => {
    if (!propertiesState.isActiveAtDate) {
      return;
    }

    const { validatedProperties } = validateProperties(propertiesState);
    if (!validatedProperties) {
      return;
    }

    onSubmit(validatedProperties);
  }, [onSubmit, propertiesState, validateProperties]);

  return (
    <Box sx={styles.root}>
      <SensorsInputSingle
        sensors={sensors ?? []}
        onChange={handleTargetSensorChange}
        value={propertiesState?.targetSensorId ?? null}
        errors={errors.sensorIdErrors}
        label={I18n.translate("widgets.sensor_map.edition.target_sensor")}
      />

      <SensorsInputMulti
        sensors={
          sensors?.filter(
            (sensor) => sensor.id !== propertiesState.targetSensorId
          ) ?? []
        }
        onChange={handleSensorsChange}
        values={propertiesState?.sensorIds ?? []}
        label={I18n.translate("widgets.sensor_map.edition.sensors_to_compare")}
      />

      <RelativeOrFixDateSingleInput
        label={I18n.translate("widgets.sensor_map.edition.active_at_date")}
        datePickerLabel={I18n.translate("general_text.date")}
        onDateBoundaryChange={handleIsActiveAtDateChange}
        dateBoundary={propertiesState.isActiveAtDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={DayJsFormat.DayMonthYearHourMinute}
        withTime={true}
        relativeDateInputErrors={errors.isActiveAtDateInputErrors}
        fixDateInputErrors={errors.isActiveAtDateInputErrors}
        onDateTypeChange={handleDateTypeChange}
      />

      <Button onClick={submit} variant="contained" sx={styles.submitButton}>
        <I18n map="widgets.common.edition.validate" />
      </Button>
    </Box>
  );
}

export default memo(EditionForm);
