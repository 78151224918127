import { Box } from "@mui/material";
import { memo } from "react";
import AuditsTable from "components/materials/AuditsTable";
import * as styles from "./styles";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useSensorAudits from "hooks/queries/useSensorAudits";

function SensorAuditsSection() {
  const { sensorId } = useSelectedSensorIdContext();
  const { audits } = useSensorAudits(sensorId ?? null);

  return (
    <Box sx={styles.root}>
      <AuditsTable audits={audits} />
    </Box>
  );
}

export default memo(SensorAuditsSection);
