import { useNavigate, useParams } from "react-router-dom";
import useIdValidation from "./useIdValidation";
import useSelectedSensorIdContext from "./useSelectedSensorContext";
import { useEffect } from "react";

function useSensorIdParamsSynchronization() {
  const params = useParams();
  const sensorIdValidation = useIdValidation(params["sensorId"]);
  const sensorId = sensorIdValidation.success ? sensorIdValidation.data : null;
  const { setSensorId } = useSelectedSensorIdContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sensorIdValidation.success) {
      navigate("/404", { replace: true });
      return;
    }

    setSensorId(sensorId);
  }, [navigate, sensorId, sensorIdValidation.success, setSensorId]);
}

export default useSensorIdParamsSynchronization;
