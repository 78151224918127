import { ChevronLeft } from "@mui/icons-material";
import { Button } from "@mui/material";
import I18n from "components/materials/I18n";
import useRoutes from "hooks/useRoutes";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  const routes = useRoutes();
  const { sensorId } = useSelectedSensorIdContext();

  const handleBackToAuditsClick = useCallback(() => {
    navigate(routes.laboratory.sensor.sensorAudits.resolveRoute({ sensorId }));
  }, [navigate, routes.laboratory.sensor.sensorAudits, sensorId]);

  return (
    <Button
      variant="outlined"
      color="info"
      startIcon={<ChevronLeft />}
      onClick={handleBackToAuditsClick}
    >
      <I18n map="pages.sensor.sub_pages.audits.nav_link" />
    </Button>
  );
}

export default memo(BackButton);
