import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import assert from "assert";
import WhitelistService from "services/WhitelistService";
import Whitelist from "models/laboratory/Whitelist";
import { laboratoryWhitelistsQueryKey } from "./useLaboratoryWhitelists";
import { ArrayUtils } from "utils/ArrayUtils";
import useUser from "hooks/queries/useUser";

export function whitelistQueryKey({
  whitelistId,
}: {
  whitelistId: ModelId | null;
}) {
  return ["whitelists", whitelistId];
}

function useWhitelist(id: ModelId | null) {
  const queryClient = useQueryClient();
  const whitelistIdValidation = useIdValidation(id);
  const { userLaboratoryId } = useUser();

  const getWhitelistQuery = useQuery(
    whitelistQueryKey({ whitelistId: id }),
    async () =>
      whitelistIdValidation.success
        ? container.resolve(WhitelistService).getById({
            whitelistId: whitelistIdValidation.data,
          })
        : null,
    {
      enabled: whitelistIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: 0,
    }
  );

  const updateWhitelistQuery = useMutation(
    async (params: {
      name?: Whitelist["name"];
      userIds?: ModelId[];
      laboratoryIds?: ModelId[];
    }) => {
      return whitelistIdValidation.success
        ? await container.resolve(WhitelistService).updateWhitelist({
            whitelistId: whitelistIdValidation.data,
            name: params.name,
            ...(params.userIds && {
              userIds: ArrayUtils.removeDuplicates(params.userIds),
            }),
            ...(params.laboratoryIds && {
              laboratoryIds: ArrayUtils.removeDuplicates(params.laboratoryIds),
            }),
          })
        : null;
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async (whitelist) => {
        await queryClient.invalidateQueries(
          laboratoryWhitelistsQueryKey(userLaboratoryId ?? null)
        );
        assert(
          whitelist,
          "Whitelist should not be null as the query is successful"
        );
        await queryClient.invalidateQueries(
          whitelistQueryKey({
            whitelistId: whitelist.id,
          })
        );
      },
    }
  );

  const deleteWhitelistQuery = useMutation(
    async () => {
      if (whitelistIdValidation.success) {
        await container.resolve(WhitelistService).deleteWhitelist({
          whitelistId: whitelistIdValidation.data,
        });
      }
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          laboratoryWhitelistsQueryKey(userLaboratoryId ?? null)
        );
      },
    }
  );

  return {
    getWhitelistQuery,
    updateWhitelistQuery,
    deleteWhitelistQuery,
    whitelist: getWhitelistQuery.data ?? null,
  };
}

export default useWhitelist;
