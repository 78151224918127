import { NotificationType } from "models/notification/NotificationType";
import { DtoDate, DtoId } from "../common/Dto";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";
import { SensorDto } from "../sensor/SensorDto";
import { z } from "zod";
import SchemaUtils from "utils/SchemaUtils";

type NotificationCompletedAuditDataDto = {
  auditId: DtoId;
  auditGlobalErrorStatus: GlobalAuditionStatus;
  auditExecutionDateTime: DtoDate;
  sensorId: DtoId;
  sensorIdentifier: SensorDto["identifier"];
};

export type NotificationDto = {
  id: DtoId;
  type: NotificationType;
  target: string;
  creationDate: DtoDate;
  dismissDate: DtoDate | null;
  data: NotificationCompletedAuditDataDto | null;
};

export function isNotificationCompletedAuditDto(
  notificationDto: NotificationDto
): notificationDto is NotificationDto & {
  type: NotificationType.CompletedAudit;
  data: NotificationCompletedAuditDataDto;
} {
  const dataValidation = z
    .object({
      auditId: SchemaUtils.idSchema,
      auditGlobalErrorStatus: z.nativeEnum(GlobalAuditionStatus),
      auditExecutionDateTime: z.coerce.date(),
      sensorId: SchemaUtils.idSchema,
      sensorIdentifier: z.string(),
    })
    .safeParse(notificationDto.data);

  return (
    notificationDto.type === NotificationType.CompletedAudit &&
    dataValidation.success
  );
}
