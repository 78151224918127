import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import WidgetService from "services/WidgetService";
import { WidgetLayouts } from "models/widgets/WidgetLayouts";
import Widget from "models/widgets/Widget";
import SchemaUtils from "utils/SchemaUtils";
import { staleTimeInMs, widgetQueryKey } from "./widgetData/common";
import assert from "assert";
import { WidgetType } from "models/widgets/WidgetType";

function sensorWidgetsQueryKey(id: ModelId | null) {
  return ["sensors", id, "widgets"];
}

function useSensorWidgets(id: ModelId | null) {
  const queryClient = useQueryClient();
  const sensorIdValidation = useIdValidation(id);

  const findManyQuery = useQuery(
    sensorWidgetsQueryKey(id),
    async () =>
      sensorIdValidation.success
        ? container
            .resolve(WidgetService)
            .getSensorWidgets({ sensorId: sensorIdValidation.data })
        : [],
    {
      enabled: sensorIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
      refetchInterval: 1000 * 60 * 1,
    }
  );

  const updateManyLayoutsQuery = useMutation(
    sensorWidgetsQueryKey(id),
    async (widgetsLayouts: { id: ModelId; layouts: WidgetLayouts }[]) =>
      sensorIdValidation.success
        ? container.resolve(WidgetService).updateManyLayouts({
            sensorId: sensorIdValidation.data,
            widgetsLayouts,
          })
        : [],
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
    }
  );

  const updateWidgetPropertiesQuery = useMutation(
    sensorWidgetsQueryKey(id),
    async (params: { id: ModelId; properties: Widget["properties"] }) => {
      const widgetIdValidation = SchemaUtils.idSchema.safeParse(params.id);
      return widgetIdValidation.success
        ? await container.resolve(WidgetService).updateSensorWidgetProperties({
            widgetId: widgetIdValidation.data,
            properties: params.properties,
          })
        : null;
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async (widget) => {
        assert(widget, "Widget should not be null as the query is successful");
        await queryClient.invalidateQueries(sensorWidgetsQueryKey(id));
        await queryClient.invalidateQueries(
          widgetQueryKey({
            widgetId: widget.id,
          })
        );
      },
    }
  );

  const createWidgetQuery = useMutation(
    sensorWidgetsQueryKey(id),
    async (params: { widgetType: WidgetType }) => {
      assert(sensorIdValidation.success);
      return await container.resolve(WidgetService).createSensorWidget({
        sensorId: sensorIdValidation.data,
        widgetType: params.widgetType,
      });
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async (widget) => {
        assert(widget, "Widget should not be null as the query is successful");
        await queryClient.invalidateQueries(sensorWidgetsQueryKey(id));
        await queryClient.invalidateQueries(
          widgetQueryKey({
            widgetId: widget.id,
          })
        );
      },
    }
  );

  const deleteWidgetQuery = useMutation(
    sensorWidgetsQueryKey(id),
    async (params: { id: ModelId }) => {
      const widgetIdValidation = SchemaUtils.idSchema.safeParse(params.id);
      if (widgetIdValidation.success) {
        await container.resolve(WidgetService).deleteSensorWidget({
          widgetId: widgetIdValidation.data,
        });
      }
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(sensorWidgetsQueryKey(id));
      },
    }
  );

  return {
    findManyQuery,
    updateManyLayoutsQuery,
    updateWidgetPropertiesQuery,
    createWidgetQuery,
    deleteWidgetQuery,
    widgets: findManyQuery.data ?? [],
  };
}

export default useSensorWidgets;
