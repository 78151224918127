import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    width: "100%",

    ...(mediaQueries.isSm && {
      maxWidth: "700px",
    }),
  });

export const mainContent: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  flex: 1,
});

export const formsContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
});
