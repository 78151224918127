import { Typography } from "@mui/material";
import I18n from "components/materials/I18n";
import useHead from "hooks/useHead";
import { memo } from "react";
import { Link } from "react-router-dom";

function Page404() {
  useHead({
    title: I18n.translate("pages.page404.title"),
  });

  return (
    <Typography>
      <I18n map="pages.page404.text" /> <Link to="/">Go home</Link>
    </Typography>
  );
}

export default memo(Page404);
