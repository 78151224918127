import { Route } from "models/common/Route";

export default class RouteUtils {
  public static isRoute(route: any): route is Route {
    return (
      "hasView" in route &&
      "absolutePath" in route &&
      "resolveRoute" in route &&
      "isCurrent" in route
    );
  }
  public static collectRoutesWithView(
    routes: Record<string, Route> | Route
  ): Route[] {
    let collectedRoutes: Route[] = [];

    Object.values(routes).forEach((value) => {
      if (typeof value !== "object" || !RouteUtils.isRoute(value)) {
        return;
      }

      if (value.hasView) {
        collectedRoutes.push(value);
      }

      collectedRoutes = collectedRoutes.concat(
        RouteUtils.collectRoutesWithView(value)
      );
    });

    return collectedRoutes;
  }
}
