import { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import * as styles from "./styles";
import { DayJsFormat } from "utils/DayjsUtils";
import {
  DateBoundary,
  FixDateBoundary,
  RelativeDateBoundary,
  isFixDateBoundary,
} from "models/widgets/Widget";
import { CustomDatePickerExtraProps } from "components/element/inputs/CustomDatePicker";
import DateTypeInput from "components/element/inputs/DateTypeInput";
import RelativeOrFixDatePicker from "components/element/inputs/RelativeOrFixDatePicker";

type Props = {
  onDateTypeChange: (
    dateType: keyof FixDateBoundary | keyof RelativeDateBoundary
  ) => void;
  onDateBoundaryChange: (date: DateBoundary | null) => void;
  dateBoundary: DateBoundary | null;
  minDate?: Date;
  maxDate?: Date;
  dateFormat: DayJsFormat;
  withTime: boolean;
  datePickerExtraProps?: CustomDatePickerExtraProps;
  relativeDateInputErrors?: string[];
  fixDateInputErrors?: string[];
  datePickerLabel: string;
  label: string;
};

function RelativeOrFixDateSingleInput(props: Props) {
  const [dateType, setDateType] = useState<
    keyof FixDateBoundary | keyof RelativeDateBoundary
  >(
    props.dateBoundary && isFixDateBoundary(props.dateBoundary)
      ? "fix"
      : "relative"
  );

  const handleDateTypeChange = useCallback(
    (dateType: keyof FixDateBoundary | keyof RelativeDateBoundary) => {
      setDateType(dateType);
      props.onDateTypeChange(dateType);
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <DateTypeInput
        onDateTypeChange={handleDateTypeChange}
        defaultValue={dateType}
      />

      <Box sx={styles.rowContainer}>
        <Box sx={styles.datePicker}>
          <RelativeOrFixDatePicker
            {...(props.datePickerExtraProps && {
              ...props.datePickerExtraProps,
            })}
            dateType={dateType}
            onDateBoundaryChange={props.onDateBoundaryChange}
            dateBoundary={props.dateBoundary}
            minDate={props.minDate}
            maxDate={props.maxDate}
            withTime={props.withTime}
            label={props.label}
            datePickerLabel={props.datePickerLabel}
            format={props.dateFormat}
            relativeDateInputErrors={props.relativeDateInputErrors}
            fixDateInputErrors={props.fixDateInputErrors}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default memo(RelativeOrFixDateSingleInput);
