import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { spacingLG, spacingMD } from "themes/constants";

export const getSensorPagePadding = (mediaQueries: MediaQueries) =>
  mediaQueries.isSm ? spacingLG : spacingMD;

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    paddingX: getSensorPagePadding(mediaQueries),
    paddingY: theme.spacing(4),
  });

export const goBackButton: SxProps<Theme> = {
  alignSelf: "flex-start",
};
