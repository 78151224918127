import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";

export const signInDialogSmSize = "400px";

export const content: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    ...(mediaQueries.isSm && {
      width: signInDialogSmSize,
    }),
  });

export const labUserSigninDescription: SxProps<Theme> = (theme) => ({
  padding: theme.spacing(0, 2),
  textAlign: "center",
});
