import { Chip } from "@mui/material";
import { memo } from "react";

import I18n from "components/materials/I18n";

import * as styles from "./styles";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";

export const translation = {
  [GlobalAuditionStatus.AllClear]: "all_clear",
  [GlobalAuditionStatus.OneError]: "one_error",
  [GlobalAuditionStatus.MultipleErrors]: "multiple_errors",
  [GlobalAuditionStatus.FatalError]: "fatal_error",
  [GlobalAuditionStatus.Unknown]: "unknown",
} as const;

type Props = {
  status: GlobalAuditionStatus;
};

function GlobalAuditionStatusChip({ status }: Readonly<Props>) {
  return (
    <Chip
      sx={styles.root(status)}
      label={I18n.translate(
        "global_audition_status.".concat(translation[status])
      )}
    />
  );
}

export default memo(GlobalAuditionStatusChip);
