import { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import * as styles from "./styles";
import { DayJsFormat } from "utils/DayjsUtils";
import {
  DateBoundary,
  FixDateBoundary,
  RelativeDateBoundary,
  isFixDateBoundary,
} from "models/widgets/Widget";
import { CustomDatePickerExtraProps } from "components/element/inputs/CustomDatePicker";
import DateTypeInput from "components/element/inputs/DateTypeInput";
import RelativeOrFixDatePicker from "components/element/inputs/RelativeOrFixDatePicker";
import I18n from "components/materials/I18n";

type Props = {
  onDateTypeChange: (
    dateType: keyof FixDateBoundary | keyof RelativeDateBoundary
  ) => void;
  onFromDateChange: (date: DateBoundary | null) => void;
  onToDateChange: (date: DateBoundary | null) => void;
  fromDate: DateBoundary | null;
  toDate: DateBoundary | null;
  minDate?: Date;
  maxDate?: Date;
  fromDateFormat: DayJsFormat;
  toDateFormat: DayJsFormat;
  withTime: boolean;
  fromDatePickerExtraProps?: CustomDatePickerExtraProps;
  toDatePickerExtraProps?: CustomDatePickerExtraProps;
  fromRelativeDateInputErrors?: string[];
  toRelativeDateInputErrors?: string[];
  fromFixDateInputErrors?: string[];
  toFixDateInputErrors?: string[];
};

function RelativeOrFixDateRangeInput(props: Props) {
  const [dateType, setDateType] = useState<
    keyof FixDateBoundary | keyof RelativeDateBoundary
  >(props.fromDate && isFixDateBoundary(props.fromDate) ? "fix" : "relative");

  const handleDateTypeChange = useCallback(
    (dateType: keyof FixDateBoundary | keyof RelativeDateBoundary) => {
      setDateType(dateType);
      props.onDateTypeChange(dateType);
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <DateTypeInput
        onDateTypeChange={handleDateTypeChange}
        defaultValue={dateType}
      />

      <Box sx={styles.rowContainer}>
        <Box sx={styles.datePicker}>
          <RelativeOrFixDatePicker
            {...(props.fromDatePickerExtraProps && {
              ...props.fromDatePickerExtraProps,
            })}
            dateType={dateType}
            onDateBoundaryChange={props.onFromDateChange}
            dateBoundary={props.fromDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
            withTime={props.withTime}
            label={I18n.translate("general_text.from")}
            datePickerLabel={I18n.translate("general_text.start_date")}
            format={props.fromDateFormat}
            relativeDateInputErrors={props.fromRelativeDateInputErrors}
            fixDateInputErrors={props.fromFixDateInputErrors}
          />
        </Box>

        <Box sx={styles.datePicker}>
          <RelativeOrFixDatePicker
            {...(props.toDatePickerExtraProps && {
              ...props.toDatePickerExtraProps,
            })}
            dateType={dateType}
            onDateBoundaryChange={props.onToDateChange}
            dateBoundary={props.toDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
            withTime={props.withTime}
            label={I18n.translate("general_text.to")}
            datePickerLabel={I18n.translate("general_text.end_date")}
            format={props.toDateFormat}
            relativeDateInputErrors={props.toRelativeDateInputErrors}
            fixDateInputErrors={props.toFixDateInputErrors}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default memo(RelativeOrFixDateRangeInput);
