import { container, singleton } from "tsyringe";
import { ModelId } from "models/common/Model";
import Whitelist from "models/laboratory/Whitelist";
import WhitelistApi from "./apis/ceaMoonshot/WhitelistApi";

@singleton()
export default class WhitelistService {
  public async getLaboratoryWhitelists(params: {
    laboratoryId: ModelId;
  }): Promise<Whitelist[]> {
    const whitelistsDto = await container
      .resolve(WhitelistApi)
      .findMany(params);

    return whitelistsDto.map((dto) => Whitelist.fromDto(dto));
  }

  public async getById(params: {
    whitelistId: ModelId;
  }): Promise<Whitelist | null> {
    const whitelistDto = await container.resolve(WhitelistApi).getById(params);

    return whitelistDto ? Whitelist.fromDto(whitelistDto) : null;
  }

  public async updateWhitelist(params: {
    whitelistId: ModelId;
    name?: string;
    userIds?: ModelId[];
    laboratoryIds?: ModelId[];
  }): Promise<Whitelist | null> {
    const whitelistDto = await container
      .resolve(WhitelistApi)
      .updateWhitelist(params);

    return whitelistDto ? Whitelist.fromDto(whitelistDto) : null;
  }

  public async createWhitelist(params: {
    laboratoryId: ModelId;
    name: string;
    userIds: ModelId[];
    laboratoryIds: ModelId[];
  }): Promise<Whitelist> {
    const whitelistDto = await container
      .resolve(WhitelistApi)
      .createWhitelist(params);

    return Whitelist.fromDto(whitelistDto);
  }

  public async deleteWhitelist(params: { whitelistId: ModelId }) {
    await container.resolve(WhitelistApi).deleteWhitelist(params);
  }
}
