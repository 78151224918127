export default class ScrollUtils {
  private static defaultScrollIntoViewOptions: ScrollIntoViewOptions = {
    behavior: "smooth",
  };

  public static scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  public static scrollToBottom(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  public static scrollToElement({
    element,
    options = ScrollUtils.defaultScrollIntoViewOptions,
  }: {
    element: HTMLElement;
    options?: ScrollIntoViewOptions;
  }): void {
    element.scrollIntoView(options);
  }

  public static scrollToElementById({
    id,
    options = ScrollUtils.defaultScrollIntoViewOptions,
  }: {
    id: string;
    options?: ScrollIntoViewOptions;
  }): void {
    const element = document.getElementById(id);
    if (!element) {
      console.warn(`Element with id ${id} does not exist`);
    }
    if (element) {
      element.scrollIntoView(options);
    }
  }
}
