import { Edit } from "@mui/icons-material";
import { TableRow, TableCell, Button } from "@mui/material";
import I18n from "components/materials/I18n";
import EditWhitelistDialog from "components/materials/dialogs/whitelistManagementDialogs/EditWhitelistDialog";
import useOpenable from "hooks/useOpenable";
import Whitelist from "models/laboratory/Whitelist";
import { memo } from "react";

type Props = {
  whitelist: Whitelist;
};

function WhitelistTableRow({ whitelist }: Props) {
  const editWhitelistDialog = useOpenable();

  return (
    <TableRow key={whitelist.id}>
      <TableCell>{whitelist.name}</TableCell>
      <TableCell>{whitelist.creator?.email ?? "--"}</TableCell>
      <TableCell>
        {whitelist.totalUsers !== undefined ? whitelist.totalUsers : "--"}
      </TableCell>
      <TableCell>
        <Button
          variant="text"
          color="info"
          onClick={editWhitelistDialog.open}
          startIcon={<Edit />}
        >
          <I18n map="components.whitelists_manager.table.manage" />
        </Button>

        {editWhitelistDialog.isOpen && (
          <EditWhitelistDialog
            isOpen={editWhitelistDialog.isOpen}
            close={editWhitelistDialog.close}
            whitelistId={whitelist.id}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default memo(WhitelistTableRow);
