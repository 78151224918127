import useRoutes from "hooks/useRoutes";
import useUser from "hooks/queries/useUser";
import { RoleName } from "models/authentication/RoleName";
import { PropsWithChildren, ReactNode, memo, useMemo } from "react";
import { Navigate } from "react-router-dom";

export enum RolesMode {
  MatchOne = "match-one",
  MatchAll = "match-all",
}

type Props = {
  isPage?: boolean;
  mode: RolesMode;
  roles: RoleName[];
  children: ReactNode;
};

function AssertRoles({
  isPage,
  mode,
  roles,
  children,
}: PropsWithChildren<Props>) {
  const { user } = useUser();

  const routes = useRoutes();

  const isGranted = useMemo(() => {
    const matchedRoles =
      user?.roles?.filter((role) => roles.includes(role.name)) ?? [];

    if (mode === RolesMode.MatchAll) {
      return matchedRoles.length === roles.length;
    }

    if (mode === RolesMode.MatchOne) {
      return matchedRoles.length >= 1;
    }

    return matchedRoles.length > 0;
  }, [mode, roles, user?.roles]);

  if (!isGranted && isPage) {
    return <Navigate to={routes.home.resolveRoute()} replace />;
  }

  if (!isGranted) {
    return null;
  }

  return <>{children}</>;
}

export default memo(AssertRoles);
