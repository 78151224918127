import useRoutes from "hooks/useRoutes";
import { PropsWithChildren, memo } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  isPage?: boolean;
  from: {
    enabled: boolean;
    props?: {
      [key: string]: unknown;
    };
  };
};

function Module({ from, isPage, children }: PropsWithChildren<Props>) {
  const routes = useRoutes();

  if (from.enabled) {
    return <>{children}</>;
  }

  if (isPage) {
    return <Navigate to={routes.home.resolveRoute()} />;
  }

  return null;
}

export default memo(Module);
