import { Chip } from "@mui/material";
import { memo } from "react";
import I18n from "components/materials/I18n";

import * as styles from "./styles";
import { ExecutionStatus } from "models/sensor/ExecutionStatus";

const translation = {
  [ExecutionStatus.Pending]: "pending",
  [ExecutionStatus.Completed]: "completed",
  [ExecutionStatus.Error]: "error",
} as const;

type Props = {
  status: ExecutionStatus | undefined;
};

function ExecutionStatusChip({ status }: Readonly<Props>) {
  return (
    <Chip
      sx={styles.root(status)}
      label={
        status
          ? I18n.translate("execution_status.".concat(translation[status]))
          : "-"
      }
    />
  );
}

export default memo(ExecutionStatusChip);
