import { Box, IconButton, Paper } from "@mui/material";
import { memo, useCallback, useState } from "react";
import useUser from "hooks/queries/useUser";
import * as styles from "./styles";
import ExternalUserSensorsSearchBar from "components/materials/Header/ExternalUserSensorsSearchBar";
import SignInButton from "components/materials/authentication/SignInButton";
import SignOutButton from "components/materials/authentication/SignOutButton";
import HeaderLogo from "components/materials/Header/HeaderLogo";
import Module from "components/materials/Module";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import SwitchViewButton from "components/materials/Header/SwitchViewButton";
import NotificationsButton from "components/materials/Header/NotificationsButton";
import useCurrentPageType, { CurrentPageType } from "hooks/useCurrentPageType";
import { Close, Search } from "@mui/icons-material";
import LaboratorySensorsSearchBar from "../LaboratorySensorsSearchBar";
import useRoutes from "hooks/useRoutes";

function HeaderXxs() {
  const { user } = useUser();
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();
  const [shouldDisplaySearchBar, setShouldDisplaySearchBar] = useState(false);

  const displaySearchBar = useCallback(() => {
    setShouldDisplaySearchBar(true);
  }, []);

  const hideSearchBar = useCallback(() => {
    setShouldDisplaySearchBar(false);
  }, []);

  return (
    <Paper sx={styles.root} elevation={0}>
      <Box sx={styles.navBar}>
        <HeaderLogo />

        {user && !routes.home.isCurrent && shouldDisplaySearchBar && (
          <Box display="flex" gap={1} width="100%">
            {user && currentPageType === CurrentPageType.ExternalUser && (
              <ExternalUserSensorsSearchBar />
            )}
            {user?.isLabUser() &&
              currentPageType === CurrentPageType.Laboratory && (
                <LaboratorySensorsSearchBar />
              )}
            <IconButton onClick={hideSearchBar}>
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>

      {!shouldDisplaySearchBar && (
        <Box sx={styles.actions}>
          {!routes.home.isCurrent && (
            <IconButton onClick={displaySearchBar}>
              <Search />
            </IconButton>
          )}

          {user?.isLabUser() &&
            currentPageType === CurrentPageType.Laboratory &&
            !routes.home.isCurrent && (
              <Module
                from={
                  container.resolve(ModuleConfig).get().modules.components
                    .header.notifications
                }
              >
                <NotificationsButton />
              </Module>
            )}

          {user?.isLabUser() && !routes.home.isCurrent && (
            <Module
              from={
                container.resolve(ModuleConfig).get().modules.components.header
                  .switchView
              }
            >
              <SwitchViewButton variant="icon" />
            </Module>
          )}

          {!user && <SignInButton />}
          {user && <SignOutButton variant="icon" />}
        </Box>
      )}
    </Paper>
  );
}

export default memo(HeaderXxs);
