export type ParsedRelativeDate = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export default class RelativeDateUtils {
  public static fromSeconds(seconds: number): ParsedRelativeDate {
    const absoluteDefaultValue = Math.abs(seconds ?? 0);
    return {
      days: Math.floor(absoluteDefaultValue / (24 * 60 * 60)),
      hours: Math.floor((absoluteDefaultValue % (24 * 60 * 60)) / (60 * 60)),
      minutes: Math.floor((absoluteDefaultValue % (60 * 60)) / 60),
      seconds: Math.floor(absoluteDefaultValue % 60),
    };
  }

  public static toText(
    parsedRelativeDate: ParsedRelativeDate,
    options: { showSeconds?: boolean } = { showSeconds: false }
  ) {
    return `${parsedRelativeDate.days}d ${RelativeDateUtils.padTime(
      parsedRelativeDate.hours
    )}h ${RelativeDateUtils.padTime(parsedRelativeDate.minutes)}m${
      options.showSeconds
        ? ` ${RelativeDateUtils.padTime(parsedRelativeDate.seconds)}s`
        : ""
    }`;
  }

  public static fromSecondsTotext(seconds: number) {
    return RelativeDateUtils.toText(RelativeDateUtils.fromSeconds(seconds));
  }

  public static padTime(number: number) {
    return number.toString().padStart(2, "0");
  }

  public static toSeconds(parsedRelativeDate: ParsedRelativeDate) {
    return (
      parsedRelativeDate.days * 24 * 60 * 60 +
      parsedRelativeDate.hours * 60 * 60 +
      parsedRelativeDate.minutes * 60 +
      parsedRelativeDate.seconds
    );
  }
}
