import { Box, Typography } from "@mui/material";
import I18n from "components/materials/I18n";
import Actions from "./Actions";
import { memo } from "react";
import * as styles from "./styles";
import useMediaQueries from "hooks/useMediaQueries";
import useLaboratoryWhitelists from "hooks/queries/useLaboratoryWhitelists";
import WhitelistsTable from "./WhitelistsTable";

function WhitelistsManager() {
  const mediaQueries = useMediaQueries();
  const { whitelists } = useLaboratoryWhitelists();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header(mediaQueries)}>
        <Typography variant="subtitle1">
          <I18n map="components.whitelists_manager.label" />
        </Typography>

        <Actions />
      </Box>

      <Box>
        <WhitelistsTable whitelists={whitelists} />
      </Box>
    </Box>
  );
}

export default memo(WhitelistsManager);
