import { ModelId } from "models/common/Model";
import { OAuthProviderDto } from "services/apis/ceaMoonshot/dtos/authentication/OAuthProviderDto";

export default class OAuthProvider {
  public readonly id: ModelId;
  public readonly name: string;
  public readonly clientId: string;
  public readonly scopes: string[] | null;

  private constructor(params: {
    id: ModelId;
    name: string;
    clientId: string;
    scopes: string[] | null;
  }) {
    this.id = params.id;
    this.name = params.name;
    this.clientId = params.clientId;
    this.scopes = params.scopes;
  }

  public static fromDto(dto: OAuthProviderDto): OAuthProvider {
    return new OAuthProvider({
      id: dto.id,
      name: dto.name,
      clientId: dto.clientId,
      scopes: dto.scopes,
    });
  }
}
