import useRoutes from "./useRoutes";

export enum CurrentPageType {
  Laboratory = "laboratory",
  ExternalUser = "externalUser",
}

function useCurrentPageType() {
  const routes = useRoutes();

  const currentPageType = routes.laboratory.isCurrent
    ? CurrentPageType.Laboratory
    : CurrentPageType.ExternalUser;

  const isLaboratoryPage = currentPageType === CurrentPageType.Laboratory;
  const isExternalUserPage = currentPageType === CurrentPageType.ExternalUser;

  return { currentPageType, isLaboratoryPage, isExternalUserPage };
}

export default useCurrentPageType;
