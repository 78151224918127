/* eslint-disable */
import EventService from "services/EventEmitter";
import en from "configs/I18n/en.json";

export enum ELang {
  En = "en",
}

type IVars = { [key: string]: string };

export type ILngType = {
  [key in string]: string | ILngType;
};

const lngs: { [key: string]: ILngType } = {
  en,
};

export default class I18nStore {
  private static instance: I18nStore;
  private readonly event = new EventService();
  private static readonly defaultLng = ELang.En;
  private _assetDefault: ILngType = lngs[I18nStore.defaultLng]!;
  private _asset: ILngType = lngs[ELang.En]!;
  private cache = new Map<string, string | undefined>();

  private constructor() {
    this.autoDetectAndSetLanguage();
  }

  public static getInstance(): I18nStore {
    return (this.instance = I18nStore.instance ?? new I18nStore());
  }

  public get assetDefault() {
    return this._assetDefault;
  }

  public get asset() {
    return this._asset;
  }

  public get lang() {
    return localStorage.getItem("lang") ?? I18nStore.defaultLng;
  }

  /**
   * @returns removelistener callback
   */
  public onChange(callback: (asset: ILngType) => void) {
    this.event.on("change", callback);
    return () => {
      this.event.off("change", callback);
    };
  }

  public toggleTo(key: string) {
    this.switch(lngs[key]!, key);
    return this.asset;
  }

  public getTranslations(map: string | string[], vars?: IVars): string[] {
    const translations = [];

    if (typeof map === "string") {
      translations.push(this.translate(map, vars));
      return translations;
    }

    translations.push(
      ...map.map((key) => {
        return this.translate(key, vars);
      })
    );

    return translations;
  }

  public translate(key: string, vars: IVars = {}) {
    const cacheKey = key.concat(JSON.stringify(vars));

    const value = this.getCache(cacheKey);

    if (value !== undefined) return value;

    const assetValue: string | null =
      this.getFromObjectByKeyString(key, this.asset) ??
      this.getFromObjectByKeyString(key, this.assetDefault);

    if (!assetValue) return key;

    const resultWithVariables = Object.keys(vars).reduce(
      (str, key) => str.replaceAll(`{{${key}}}`, vars[key] ?? ""),
      assetValue
    );

    this.setCache(cacheKey, resultWithVariables);
    return resultWithVariables;
  }

  private switch(asset: ILngType, key: string) {
    this.storeLang(key);
    if (asset === this._asset) return;
    this._asset = asset;
    // whenever the application's language changes the localization cache is fully cleared:
    this.cache.clear();

    this.event.emit("change", { lang: key, ...this._asset });
  }

  private getFromObjectByKeyString(key: string, asset: { [key: string]: any }) {
    const keys = key.split(".");

    return keys.reduce((asset, key) => asset?.[key] ?? null, asset) as any;
  }

  private getCache(key: string): string | undefined {
    return this.cache.get(key);
  }

  private setCache(key: string, value: string) {
    this.cache.set(key, value);
  }
  private autoDetectAndSetLanguage() {
    let lng: string =
      localStorage.getItem("lang") ?? window.navigator.language.split("-")[0]!;
    if (!lngs[lng]) lng = I18nStore.defaultLng;
    this.toggleTo(lng);
  }
  private storeLang(key: string) {
    const lng = localStorage.getItem("lang");
    if (!lng || lng !== key) localStorage.setItem("lang", key);
  }
}
