import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { borderRadius } from "themes/constants";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
});

export const header: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
});

export const headerInfo: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
});

export const image: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    width: "120px",
    height: "120px",
    objectFit: "cover",
    borderRadius,

    ...(mediaQueries.isStrictlyXs && {
      width: "160px",
      height: "160px",
    }),
  });

export const content: SxProps<Theme> = (theme) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  alignItems: "flex-start",
});

export const description: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  "& *": { wordBreak: "break-word" },
});

export const singleRowDescription: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  "& *": { wordBreak: "break-word" },
});

export const buttonContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
});

export const firstDivider: SxProps<Theme> = (theme) => ({
  width: "100%",
  marginBottom: theme.spacing(1),
});

export const divider: SxProps<Theme> = (theme) => ({
  width: "100%",
  marginY: theme.spacing(2),
});
