import React from "react";

import I18nStore, { ILngType } from "./I18nStore";

type IVars = { [key: string]: string };

type IProps = {
  children?: React.ReactNode;
  map?: string | string[];
  vars?: IVars;
  content?: (trads: string[]) => React.ReactNode;
};

type IState = {
  asset: ILngType;
};

/**
 * @example: usage <I18n map="menu_status.bla" vars={{myStringValue: this.state.myStringValue}}/>
 */
export default class I18n extends React.PureComponent<IProps, IState> {
  private removeOnChange?: () => void;

  public override render(): React.ReactNode {
    if (this.props.children) {
      return this.props.children;
    }

    const trads = this.props.map
      ? I18nStore.getInstance().getTranslations(this.props.map, this.props.vars)
      : [];

    if (this.props.content) {
      return this.props.content(trads);
    }

    return trads.join(" ");
  }

  public override componentDidMount() {
    this.removeOnChange = I18nStore.getInstance().onChange((asset) => {
      this.setState({ asset });
      this.forceUpdate();
    });
  }

  public override componentWillUnmount() {
    this.removeOnChange && this.removeOnChange();
  }

  public static translate(map: string, vars?: IVars) {
    return I18nStore.getInstance().translate(map, vars);
  }

  public static getLang() {
    return I18nStore.getInstance().lang;
  }
}
