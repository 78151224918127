import { useQuery } from "react-query";
import LaboratoryService from "services/LaboratoryService";
import { container } from "tsyringe";

const laboratoriesQueryKey = "laboratories";
const staleTimeInMs = 1000 * 60 * 5;

function useLaboratories() {
  const query = useQuery(
    laboratoriesQueryKey,
    async () => container.resolve(LaboratoryService).findMany({}),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...query,
    laboratories: query.data ?? [],
  };
}

export default useLaboratories;
