import { CardMedia, SxProps, Theme } from "@mui/material";
import useSensorPicture from "hooks/useSensorPicture";
import Sensor from "models/sensor/Sensor";
import { memo } from "react";
import SensorDataUtils from "utils/SensorResultUtils";

type Props = {
  sensor: Sensor;
  sx?: SxProps<Theme>;
};

function SensorPicture({ sensor, sx }: Props) {
  const { imageUrl, isFallback } = useSensorPicture({ sensor });

  return (
    <CardMedia
      component="img"
      sx={sx}
      image={imageUrl}
      alt={
        isFallback
          ? `Fallback image`
          : `${SensorDataUtils.formatDisplayName(sensor)} image`
      }
    />
  );
}

export default memo(SensorPicture);
