import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CustomAutocompleteSingle, {
  IOption,
} from "components/element/inputs/CustomAutocompleteSingle";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import { Box } from "@mui/material";
import * as styles from "./styles";
import Laboratory from "models/laboratory/Laboratory";

type Props = {
  laboratories: Laboratory[];
  onChange?: (laboratoryId: ModelId | null) => unknown;
  value?: ModelId | null;
  errors?: string[];
  label?: ReactNode;
  required?: boolean;
};

function LaboratoriesInputSingle({
  label,
  required,
  laboratories,
  onChange,
  value,
  errors,
}: Props) {
  const [laboratoryId, setLaboratoryId] = useState<ModelId | null>(
    value ?? null
  );

  const options: IOption[] = useMemo(
    () =>
      laboratories
        .map((laboratory) => {
          return {
            id: laboratory.id,
            label: laboratory.name,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    [laboratories]
  );

  const handleLaboratorySelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, value: IOption | null) => {
      const laboratoryId = value?.id ?? null;
      setLaboratoryId(laboratoryId);
      onChange?.(laboratoryId);
    },
    [onChange, setLaboratoryId]
  );

  useEffect(() => {
    setLaboratoryId(value ?? null);
  }, [value]);

  return (
    <Box sx={styles.root}>
      {!!label && label}

      <CustomAutocompleteSingle
        required={required}
        options={options}
        errors={errors}
        onChange={handleLaboratorySelection}
        placeholder={I18n.translate("general_text.search_laboratory")}
        value={options.find((option) => option.id === laboratoryId)}
      />
    </Box>
  );
}

export default memo(LaboratoriesInputSingle);
