import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import * as styles from "./styles";
import { CSSProperties, memo } from "react";

type Props = {
  incomingStyle?: CSSProperties;
  onClick: () => void;
};

function CloseButton({ incomingStyle, onClick }: Props) {
  return (
    <IconButton sx={styles.root(incomingStyle)} onClick={onClick}>
      <Close />
    </IconButton>
  );
}

export default memo(CloseButton);
