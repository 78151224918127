import { Badge, BadgeOwnProps } from "@mui/material";
import { CSSProperties, PropsWithChildren, memo, useMemo } from "react";
import * as styles from "./styles";

type Props = BadgeOwnProps & {
  badgeWidth?: CSSProperties["width"];
  badgeHeight?: CSSProperties["height"];
};

function CustomBadge({
  children,
  badgeWidth,
  badgeHeight,
  ...bagdgeOwnProps
}: PropsWithChildren<Props>) {
  const customStyles = useMemo(
    () => ({
      badgeWidth,
      badgeHeight,
      ...bagdgeOwnProps.sx,
    }),
    [badgeWidth, badgeHeight, bagdgeOwnProps.sx]
  );

  return (
    <Badge {...{ ...bagdgeOwnProps }} sx={styles.root(customStyles)}>
      {children}
    </Badge>
  );
}

export default memo(CustomBadge);
