import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import FixDateRangeInput from "components/element/inputs/FixDateRangeInput";
import FormErrors from "components/element/FormErrors";
import { AuditsRuleDurationType } from "models/sensor/SensorAuditsRule";
import { SensorAuditsRuleUpdates } from "..";

type Props = {
  onDurationChange: (duration: SensorAuditsRuleUpdates["duration"]) => void;
  duration: SensorAuditsRuleUpdates["duration"];
  errors?: string[];
};

function DurationInput(props: Props) {
  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const typeValue = event.target.value as AuditsRuleDurationType;
      props.onDurationChange({
        ...props.duration,
        type: typeValue,
      });
    },
    [props]
  );

  const handleFromDateChange = useCallback(
    (date: Date | null) => {
      props.onDurationChange({
        ...props.duration,
        from: date,
      });
    },
    [props]
  );

  const handleToDateChange = useCallback(
    (date: Date | null) => {
      props.onDurationChange({
        ...props.duration,
        to: date,
      });
    },
    [props]
  );

  return (
    <Box sx={styles.root}>
      <Typography variant="subtitle1">
        <I18n map="components.audits_rule_form.duration.label" />
      </Typography>

      <RadioGroup
        sx={styles.radioGroup}
        value={props.duration.type}
        onChange={handleTypeChange}
      >
        <FormControlLabel
          value={AuditsRuleDurationType.Unlimited}
          control={<Radio />}
          label={<I18n map="components.audits_rule_form.duration.unlimited" />}
        />
        <FormControlLabel
          value={AuditsRuleDurationType.Limited}
          control={<Radio />}
          label={<I18n map="components.audits_rule_form.duration.limited" />}
        />
      </RadioGroup>

      {props.duration.type === AuditsRuleDurationType.Limited && (
        <FixDateRangeInput
          fromDate={props.duration.from}
          onFromDateChange={handleFromDateChange}
          toDate={props.duration.to}
          onToDateChange={handleToDateChange}
          withTime={false}
        />
      )}

      {props.errors && <FormErrors errors={props.errors} />}
    </Box>
  );
}

export default memo(DurationInput);
