import { z } from "zod";
import { ApplicationConfigDto } from "services/apis/ceaMoonshot/dtos/applicationConfig/applicationConfigDto";

const applicationConfigSchema = z.object({
  blockExplorerUrl: z.string(),
  auditionMaxRangeDays: z.number(),
  blockchainEnergyCostFactor: z.number(),
  dashboardResultsMinDate: z.preprocess(
    (dateStr) => (dateStr ? new Date(String(dateStr)) : dateStr),
    z.date().nullable()
  ),
  dashboardResultsMaxDate: z.preprocess(
    (dateStr) => (dateStr ? new Date(String(dateStr)) : dateStr),
    z.date().nullable()
  ),
  auditionMinDate: z.preprocess(
    (dateStr) => (dateStr ? new Date(String(dateStr)) : dateStr),
    z.date().nullable()
  ),
  auditionMaxDate: z.preprocess(
    (dateStr) => (dateStr ? new Date(String(dateStr)) : dateStr),
    z.date().nullable()
  ),
});

export default class ApplicationConfig {
  public readonly blockExplorerUrl: string;
  public readonly auditionMaxRangeDays: number;
  public readonly blockchainEnergyCostFactor: number;
  public readonly dashboardResultsMinDate: Date | null;
  public readonly dashboardResultsMaxDate: Date | null;
  public readonly auditionMinDate: Date | null;
  public readonly auditionMaxDate: Date | null;

  private constructor(params: {
    blockExplorerUrl: string;
    auditionMaxRangeDays: number;
    blockchainEnergyCostFactor: number;
    dashboardResultsMinDate: Date | null;
    dashboardResultsMaxDate: Date | null;
    auditionMinDate: Date | null;
    auditionMaxDate: Date | null;
  }) {
    this.blockExplorerUrl = params.blockExplorerUrl;
    this.auditionMaxRangeDays = params.auditionMaxRangeDays;
    this.blockchainEnergyCostFactor = params.blockchainEnergyCostFactor;
    this.dashboardResultsMinDate = params.dashboardResultsMinDate;
    this.dashboardResultsMaxDate = params.dashboardResultsMaxDate;
    this.auditionMinDate = params.auditionMinDate;
    this.auditionMaxDate = params.auditionMaxDate;
  }

  public static fromDto(dto: ApplicationConfigDto): ApplicationConfig {
    const validatedApplicationConfig = applicationConfigSchema.parse(dto);

    return new ApplicationConfig(validatedApplicationConfig);
  }
}
