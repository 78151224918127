import { container, singleton } from "tsyringe";
import { ModelId } from "models/common/Model";
import NotificationApi from "./apis/ceaMoonshot/NotificationApi";
import Notification from "models/notification/Notification";

@singleton()
export default class NotificationService {
  public async findMany(params?: {
    target?: string;
    dismissDate?: Date | null;
  }): Promise<Notification[]> {
    const notificationsDto = await container.resolve(NotificationApi).findMany({
      queryParams: {
        ...(params?.target !== undefined && { target: params.target }),
        ...(params?.dismissDate !== undefined && {
          dismissDate: params.dismissDate
            ? params.dismissDate.toISOString()
            : null,
        }),
      },
    });

    return notificationsDto.map((dto) => Notification.fromDto(dto));
  }

  public async updateNotification(params: {
    notificationId: ModelId;
    dismissDate?: Date | null;
  }): Promise<Notification | null> {
    const NotificationDto = await container
      .resolve(NotificationApi)
      .updateNotification({
        notificationId: params.notificationId,
        ...(params.dismissDate !== undefined && {
          dismissDate: params.dismissDate
            ? params.dismissDate.toISOString()
            : null,
        }),
      });

    return NotificationDto ? Notification.fromDto(NotificationDto) : null;
  }
}
