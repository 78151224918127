import { container, singleton } from "tsyringe";
import { ModelId } from "models/common/Model";
import Widget from "models/widgets/Widget";
import WidgetApi from "./apis/ceaMoonshot/WidgetApi";
import { WidgetLayouts } from "models/widgets/WidgetLayouts";
import { WidgetType } from "../models/widgets/WidgetType";

@singleton()
export default class WidgetService {
  public async getSensorWidgets(params: {
    sensorId: ModelId;
  }): Promise<Widget[]> {
    const widgetsDto = await container.resolve(WidgetApi).findMany(params);

    return widgetsDto.map((dto) => Widget.fromDto(dto));
  }

  public async updateManyLayouts(params: {
    sensorId: ModelId;
    widgetsLayouts: {
      id: ModelId;
      layouts: WidgetLayouts;
    }[];
  }): Promise<Widget[]> {
    const widgetsDto = await container
      .resolve(WidgetApi)
      .updateManyLayouts(params);

    return widgetsDto.map((dto) => Widget.fromDto(dto));
  }

  public async updateSensorWidgetProperties(params: {
    widgetId: ModelId;
    properties: Widget["properties"];
  }): Promise<Widget | null> {
    const widgetDto = await container
      .resolve(WidgetApi)
      .updateWidgetProperties(params);

    return widgetDto ? Widget.fromDto(widgetDto) : null;
  }

  public async createSensorWidget(params: {
    sensorId: ModelId;
    widgetType: WidgetType;
  }): Promise<Widget> {
    const widgetDto = await container
      .resolve(WidgetApi)
      .createSensorWidget(params);

    return Widget.fromDto(widgetDto);
  }

  public async deleteSensorWidget(params: { widgetId: number }) {
    await container.resolve(WidgetApi).deleteSensorWidget(params);
  }
}
