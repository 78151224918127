import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
});

export const textFields: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(1),
});
