import Sensor from "models/sensor/Sensor";
import SensorResult from "models/sensor/SensorResult";
import SensorResultsSession from "models/sensor/SensorResultsSession";

export default class SensorDataUtils {
  public static aggregateSensorResults(sensor: Sensor): SensorResult[] {
    const res =
      sensor.situations?.flatMap(
        (situation) =>
          situation.sensorEpoches?.flatMap(
            (sensorEpoch) => sensorEpoch.lastResultsSession?.sensorResults ?? []
          ) ?? []
      ) ?? [];

    return res;
  }

  public static aggregateSensorResultsSessions(
    sensor: Sensor
  ): SensorResultsSession[] {
    const res =
      sensor.situations?.flatMap(
        (situation) =>
          situation.sensorEpoches?.flatMap(
            (sensorEpoch) => sensorEpoch.sensorResultsSessions ?? []
          ) ?? []
      ) ?? [];

    return res;
  }

  public static aggregateCo2Quantities(sensor: Sensor): number[] {
    return SensorDataUtils.aggregateSensorResults(sensor).map(
      (result) => result.co2Quantity
    );
  }

  public static computeCo2QuantitiesAverage(co2Quantities: number[]): number {
    return +(
      co2Quantities.reduce((acc: number, co2Quantity) => co2Quantity + acc, 0) /
      co2Quantities.length
    ).toFixed(2);
  }

  public static getAllSensorsCo2QuantitiesAverages(sensors: Sensor[]) {
    return sensors
      .map((sensor) =>
        SensorDataUtils.computeCo2QuantitiesAverage(
          SensorDataUtils.aggregateCo2Quantities(sensor)
        )
      )
      .filter((average) => !!average);
  }

  public static computeCo2Statistics(sensors: Sensor[]): {
    minimum: number;
    quartile25: number;
    median: number;
    quartile75: number;
    maximum: number;
  } {
    const co2QuantitiesAverages =
      SensorDataUtils.getAllSensorsCo2QuantitiesAverages(sensors).sort(
        (v1, v2) => v1 - v2
      );

    const minimum = co2QuantitiesAverages[0] ?? 0;
    const quartile25 =
      co2QuantitiesAverages[
        Math.floor((co2QuantitiesAverages.length * 25) / 100)
      ] ?? 0;
    const quartile75 =
      co2QuantitiesAverages[
        Math.floor((co2QuantitiesAverages.length * 75) / 100)
      ] ?? 0;
    const maximum =
      co2QuantitiesAverages[co2QuantitiesAverages.length - 1] ?? 0;

    const median =
      co2QuantitiesAverages[Math.round(co2QuantitiesAverages.length / 2) - 1] ??
      0;

    return { minimum, quartile25, median, quartile75, maximum };
  }

  public static formatDisplayName(sensor: Sensor): string {
    return sensor.identifier;
  }
}
