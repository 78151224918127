import { matchPath, useLocation, useNavigate } from "react-router-dom";
import useCurrentPageType, { CurrentPageType } from "./useCurrentPageType";
import { useCallback, useMemo } from "react";
import useRoutes, { routerPaths } from "./useRoutes";
import RouteUtils from "utils/RouteUtils";

function useViewSwitcher() {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();

  const nextLocation = useMemo(() => {
    if (currentPageType === CurrentPageType.Laboratory) {
      return location.pathname.replace(
        routerPaths.laboratory.relativePath,
        routerPaths.externalUser.relativePath
      );
    }
    if (currentPageType === CurrentPageType.ExternalUser) {
      return location.pathname.replace(
        routerPaths.externalUser.relativePath,
        routerPaths.laboratory.relativePath
      );
    }

    return location.pathname;
  }, [currentPageType, location.pathname]);

  const isNextLocationExisting = useMemo(() => {
    const routeLists = RouteUtils.collectRoutesWithView(routes);

    const bool =
      routeLists.findIndex((route) => {
        const isNextLocationFound = !!matchPath(
          { path: route.absolutePath, end: true },
          nextLocation
        );

        return isNextLocationFound;
      }) !== -1;

    return bool;
  }, [nextLocation, routes]);

  const switchView = useCallback(() => {
    if (!isNextLocationExisting) {
      return;
    }

    navigate(nextLocation);
  }, [isNextLocationExisting, navigate, nextLocation]);

  return { switchView, isNextLocationExisting };
}

export default useViewSwitcher;
