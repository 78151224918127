import { SxProps, Theme } from "@mui/material";

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const columnContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
});

export const rowContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(3),
});

export const actionsContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
});

export const loadingContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(150, 150, 150, 0.5)",
});
