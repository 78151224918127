import { container, singleton } from "tsyringe";
import { ModelId } from "models/common/Model";
import SensorAuditsRuleApi from "./apis/ceaMoonshot/SensorAuditsRuleApi";
import SensorAuditsRule from "models/sensor/SensorAuditsRule";

@singleton()
export default class SensorAuditsRuleService {
  public async getBySensorId(params: {
    sensorId: ModelId;
  }): Promise<SensorAuditsRule | null> {
    const sensorAuditsRuleDto = await container
      .resolve(SensorAuditsRuleApi)
      .getBySensorId(params);

    return sensorAuditsRuleDto
      ? SensorAuditsRule.fromDto(sensorAuditsRuleDto)
      : null;
  }

  public async upsertAuditsRule(params: {
    sensorId: ModelId;
    whitelistIds?: ModelId[];
    auditorsGroupType: SensorAuditsRule["auditorsGroupType"];
    from: Date | null;
    to: Date | null;
  }): Promise<SensorAuditsRule | null> {
    const sensorAuditsRuleDto = await container
      .resolve(SensorAuditsRuleApi)
      .upsertAuditsRule({
        sensorId: params.sensorId,
        whitelistIds: params.whitelistIds,
        auditorsGroupType: params.auditorsGroupType,
        from: params.from?.toISOString() ?? null,
        to: params.to?.toISOString() ?? null,
      });

    return sensorAuditsRuleDto
      ? SensorAuditsRule.fromDto(sensorAuditsRuleDto)
      : null;
  }

  public isUserAuthorizedToAudit(params: {
    sensorId: ModelId;
    userId: ModelId;
  }): Promise<boolean> {
    return container
      .resolve(SensorAuditsRuleApi)
      .isUserAuthorizedToAudit(params);
  }
}
