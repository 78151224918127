import { ModelId } from "models/common/Model";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import assert from "assert";
import WhitelistService from "services/WhitelistService";
import Whitelist from "models/laboratory/Whitelist";
import { ArrayUtils } from "utils/ArrayUtils";
import useUser from "./useUser";

export function laboratoryWhitelistsQueryKey(id: ModelId | null) {
  return ["laboratories", id, "whitelists"];
}

function useLaboratoryWhitelists() {
  const queryClient = useQueryClient();
  const { userLaboratoryId } = useUser();
  const laboratoryIdValidation = useIdValidation(userLaboratoryId);

  const findManyQuery = useQuery(
    laboratoryWhitelistsQueryKey(userLaboratoryId ?? null),
    async () =>
      laboratoryIdValidation.success
        ? container.resolve(WhitelistService).getLaboratoryWhitelists({
            laboratoryId: laboratoryIdValidation.data,
          })
        : [],
    {
      enabled: laboratoryIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: 0,
    }
  );

  const createWhitelistQuery = useMutation(
    laboratoryWhitelistsQueryKey(userLaboratoryId ?? null),
    async (params: {
      name: Whitelist["name"];
      userIds: ModelId[];
      laboratoryIds: ModelId[];
    }) => {
      assert(laboratoryIdValidation.success);

      return await container.resolve(WhitelistService).createWhitelist({
        laboratoryId: laboratoryIdValidation.data,
        name: params.name,
        userIds: ArrayUtils.removeDuplicates(params.userIds),
        laboratoryIds: ArrayUtils.removeDuplicates(params.laboratoryIds),
      });
    },
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      onSuccess: async (whitelist) => {
        assert(
          whitelist,
          "Whitelist should not be null as the query is successful"
        );
        await queryClient.invalidateQueries(
          laboratoryWhitelistsQueryKey(userLaboratoryId ?? null)
        );
      },
    }
  );

  return {
    findManyQuery,
    createWhitelistQuery,
    whitelists: findManyQuery.data ?? [],
  };
}

export default useLaboratoryWhitelists;
