import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import Widget from "models/widgets/Widget";
import { Layouts } from "react-grid-layout";
import WidgetsContainerUtils from "./utils";

export const widgetContainer: (params: {
  mediaQueries: MediaQueries;
  widget: Widget;
  layouts: Layouts;
}) => SxProps<Theme> = (params) => ({
  display: "flex",
  flexDirection: "column",
  ...(WidgetsContainerUtils.isWidgetAloneOnRow(params) && {
    minWidth: "100%",
  }),
});
