import { SxProps, Theme } from "@mui/material";

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  overflow: "scroll",
});

export const actionsContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
};
