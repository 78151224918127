import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import { SignInComponentsProps } from "..";
import { Box } from "@mui/material";
import * as styles from "./styles";
import AuthenticationService from "services/AuthenticationService";
import { container } from "tsyringe";
import { signInDialogSmSize } from "../styles";
import useMediaQueries from "hooks/useMediaQueries";
import { memo, useCallback } from "react";
import useOAuthProvider from "hooks/queries/useOAuthProvider";

function GoogleForm({ handleSignIn }: SignInComponentsProps) {
  const { oAuthProvider } = useOAuthProvider("google");

  const mediaQueries = useMediaQueries();

  const handleSignInSuccess = useCallback(
    async (response: CredentialResponse) => {
      await container
        .resolve(AuthenticationService)
        .createJwtPairByGoogleCredential(response.credential!);

      handleSignIn();
    },
    [handleSignIn]
  );

  if (!oAuthProvider) return null;

  return (
    <GoogleOAuthProvider clientId={oAuthProvider.clientId}>
      <Box sx={styles.root}>
        <GoogleLogin
          onSuccess={handleSignInSuccess}
          text="signin_with"
          locale="en"
          width={mediaQueries.isSm ? signInDialogSmSize : ""}
        />
      </Box>
    </GoogleOAuthProvider>
  );
}

export default memo(GoogleForm);
