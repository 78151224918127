export default class BlockchainAddressUtils {
  public static shortenAddress(
    address: string,
    startNumber = 8,
    endNumber = 8
  ): string {
    return `${address.substring(0, startNumber)}...${address.substring(
      address.length - endNumber,
      address.length
    )}`;
  }
}
