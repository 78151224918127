import Whitelist from "models/laboratory/Whitelist";
import Sensor from "./Sensor";
import { ModelId } from "models/common/Model";
import { SensorAuditsRuleDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorAuditsRuleDto";

export enum AuditorsGroupType {
  Specific = "Specific",
  Everyone = "Everyone",
}

export enum AuditsRuleDurationType {
  Unlimited = "Unlimited",
  Limited = "Limited",
}

export default class SensorAuditsRule {
  public readonly id: ModelId;
  public readonly whitelists?: Whitelist[];
  public readonly auditorsGroupType: AuditorsGroupType;
  public readonly from: Date | null;
  public readonly to: Date | null;
  public readonly sensor?: Sensor | null;

  private constructor(params: {
    id: ModelId;
    whitelists?: Whitelist[];
    auditorsGroupType: AuditorsGroupType;
    from: Date | null;
    to: Date | null;
    sensor?: Sensor | null;
  }) {
    this.id = params.id;
    this.whitelists = params.whitelists;
    this.auditorsGroupType = params.auditorsGroupType;
    this.from = params.from;
    this.to = params.to;
    this.sensor = params.sensor;
  }

  public static fromDto(dto: SensorAuditsRuleDto): SensorAuditsRule {
    return new SensorAuditsRule({
      id: dto.id,
      whitelists: dto.whitelists?.map(Whitelist.fromDto),
      auditorsGroupType: dto.auditorsGroupType,
      from: dto.from ? new Date(dto.from) : null,
      to: dto.to ? new Date(dto.to) : null,
      sensor: dto.sensor ? Sensor.fromDto(dto.sensor) : dto.sensor,
    });
  }

  public get durationType(): AuditsRuleDurationType {
    return this.from && this.to
      ? AuditsRuleDurationType.Limited
      : AuditsRuleDurationType.Unlimited;
  }
}
