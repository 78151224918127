import { memo, useCallback, useEffect, useMemo, useState } from "react";
import I18n from "components/materials/I18n";
import { ModelId } from "models/common/Model";
import CustomAutocompleteMulti, { IOption } from "../CustomAutocompleteMulti";
import * as styles from "./styles";
import { Box, Typography } from "@mui/material";
import Whitelist from "models/laboratory/Whitelist";

type Props = {
  whitelists: Whitelist[];
  onChange?: (whitelistIds: ModelId[]) => unknown;
  values?: ModelId[];
  errors?: string[];
  label?: string;
};

function WhitelistsInputMulti({
  label,
  whitelists,
  onChange,
  values,
  errors,
}: Props) {
  const [selectedWhitelistIds, setSelectedWhitelistIds] = useState<ModelId[]>(
    values ?? []
  );

  const options: IOption[] = useMemo(
    () =>
      whitelists
        ?.map((whitelist) => {
          return {
            id: whitelist.id,
            label: whitelist.name,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
    [whitelists]
  );

  const handleWhitelistsSelection = useCallback(
    (_: React.SyntheticEvent<Element, Event>, values: IOption[] | null) => {
      const whitelistIds = values?.map((option) => option.id) ?? [];
      setSelectedWhitelistIds(whitelistIds);

      onChange?.(whitelistIds);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedWhitelistIds(values ?? []);
  }, [values]);

  const selectedOptions = options.filter((option) =>
    selectedWhitelistIds.includes(option.id)
  );

  return (
    <Box sx={styles.root}>
      {label && <Typography variant="body1">{label}</Typography>}

      <CustomAutocompleteMulti
        options={options}
        errors={errors}
        onChange={handleWhitelistsSelection}
        placeholder={
          selectedOptions.length > 0
            ? ""
            : I18n.translate("general_text.search_whitelists")
        }
        values={selectedOptions}
        sx={styles.autocomplete(selectedOptions.length > 0)}
      />
    </Box>
  );
}

export default memo(WhitelistsInputMulti);
