import { SxProps, Theme } from "@mui/material";

const hoverSize = 12;
export const padding = `${hoverSize}px`;
const negativeMarginToCompensatePadding = `${-hoverSize}px`;

export const root: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    gap: theme.spacing(1),
    marginTop: negativeMarginToCompensatePadding,
    marginRight: negativeMarginToCompensatePadding,
  };
};
