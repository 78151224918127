import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { borderRadius } from "themes/constants";

export const root: ({
  backgroundColor,
  hoverColor,
  incomingStyles,
}: {
  backgroundColor: string;
  hoverColor: string;
  incomingStyles?: CSSProperties;
}) => SxProps<Theme> =
  ({ backgroundColor, hoverColor, incomingStyles }) =>
  (theme) => ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    cursor: "pointer",
    borderRadius,
    backgroundColor,
    "&:hover": {
      backgroundColor: hoverColor,
    },
    ...incomingStyles,
  });
