import { SxProps, Theme } from "@mui/material";
import { borderRadius } from "themes/constants";

const border = "1px solid";
const getBorderColor = (theme: Theme) => theme.palette.grey[400];

export const root: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  border,
  borderColor: getBorderColor(theme),
  borderRadius,
  overflow: "hidden",
  backgroundColor: theme.palette.grey[50],
});

export const header: (isTableFilled: boolean) => SxProps<Theme> =
  (isTableFilled) => (theme) => ({
    backgroundColor: theme.palette.grey[100],
    paddingY: theme.spacing(2),
    paddingX: theme.spacing(3),

    ...(isTableFilled && {
      borderBottom: border,
      borderColor: getBorderColor(theme),
    }),
  });

export const container: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  height: "170px",
};

export const card: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingX: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderBottom: border,
  borderColor: getBorderColor(theme),
});

export const label: SxProps<Theme> = {
  wordBreak: "break-all",
};
