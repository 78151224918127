import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import { ApplicationConfigDto } from "./dtos/applicationConfig/applicationConfigDto";

@singleton()
export default class ApplicationConfigApi extends BaseApi {
  public async get(): Promise<ApplicationConfigDto> {
    const url = new URL(`${this.apiUrl}/application-config`);

    return this.getRequest<ApplicationConfigDto>(url);
  }
}
