import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { CSSProperties } from "react";

export const root: (params: {
  incomingStyles?: CSSProperties;
  badgeWidth?: CSSProperties["width"];
  badgeHeight?: CSSProperties["height"];
}) => SxProps<Theme> = ({ incomingStyles, badgeWidth, badgeHeight }) => ({
  ...incomingStyles,
  "& .MuiBadge-badge": {
    ...(badgeWidth && { width: badgeWidth }),
    ...(badgeHeight && { height: badgeHeight }),
  },
});
