import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = (theme) => {
  return {
    position: "absolute",
    display: "flex",
    left: theme.spacing(3),
    bottom: theme.spacing(4),
    zIndex: 1000,
    px: theme.spacing(2),
    py: theme.spacing(1),
    gap: theme.spacing(2),
    alignItems: "center",
  };
};

export const sensorName: SxProps<Theme> = {
  fontWeight: "bold",
};
