import { Box, Card, Typography } from "@mui/material";
import React, { memo } from "react";
import * as styles from "./styles";
import WidgetActions from "./WidgetActions";
import Widget from "models/widgets/Widget";
import I18n from "components/materials/I18n";

type Props = {
  children: React.ReactNode;
  widget: Widget;
  withEditAction: boolean;
  withDragAction: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};

function WidgetTemplate({
  widget,
  children,
  withEditAction,
  withDragAction,
  onDragStart,
  onDragEnd,
}: Props) {
  return (
    <Card id={`widget-${widget.id}`} sx={styles.root} elevation={0}>
      <Box sx={styles.titleContainer}>
        <Typography variant="h6">{retrieveWidgetTitle(widget)}</Typography>
        <WidgetActions
          widget={widget}
          withEditAction={withEditAction}
          withDragAction={withDragAction}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
      </Box>
      {children}
    </Card>
  );
}

function retrieveWidgetTitle(widget: Widget) {
  if (widget.isCo2AverageWidget()) {
    return I18n.translate("widgets.co2_average.title");
  }
  if (widget.isCo2EvolutionWidget()) {
    return I18n.translate("widgets.co2_evolution.title");
  }
  if (widget.isCo2RelativeAverageWidget()) {
    return I18n.translate("widgets.co2_relative_average.title");
  }
  if (widget.isSensorMapWidget()) {
    return I18n.translate("widgets.sensor_map.title");
  }
  if (widget.isBlockchainEnergyCostWidget()) {
    return I18n.translate("widgets.blockchain_energy_cost.title");
  }
  return "";
}

export default memo(WidgetTemplate);
