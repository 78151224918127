import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import { DtoDate, DtoId } from "./dtos/common/Dto";
import { SensorAuditsRuleDto } from "./dtos/sensor/SensorAuditsRuleDto";

@singleton()
export default class SensorAuditsRuleApi extends BaseApi {
  public async getBySensorId(params: {
    sensorId: DtoId;
  }): Promise<SensorAuditsRuleDto | null> {
    const url = new URL(
      `${this.apiUrl}/sensors/${params.sensorId}/audits-rule`
    );

    return this.getRequest<SensorAuditsRuleDto>(url);
  }

  public async upsertAuditsRule(params: {
    sensorId: DtoId;
    whitelistIds?: DtoId[];
    auditorsGroupType: string;
    from: DtoDate | null;
    to: DtoDate | null;
  }): Promise<SensorAuditsRuleDto | null> {
    const url = new URL(
      `${this.apiUrl}/sensors/${params.sensorId}/audits-rule`
    );

    return this.postRequest<SensorAuditsRuleDto>(url, {
      whitelistIds: params.whitelistIds,
      auditorsGroupType: params.auditorsGroupType,
      from: params.from,
      to: params.to,
    });
  }

  public isUserAuthorizedToAudit(params: {
    sensorId: number;
    userId: number;
  }): Promise<boolean> {
    const url = new URL(
      `${this.apiUrl}/sensors/${params.sensorId}/audits-rule/users/${params.userId}`
    );

    return this.getRequest<boolean>(url);
  }
}
