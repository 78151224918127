import DateUtils from "utils/DateUtils";
import { ModelId } from "../common/Model";
import { SensorSituationDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorSituationDto";
import Sensor from "./Sensor";
import SensorEpoch from "./SensorEpoch";

export default class SensorSituation {
  public readonly id: ModelId;
  public readonly latitude: number;
  public readonly longitude: number;
  public readonly heightAboveSea: number | null;
  public readonly heightAboveGround: number | null;
  public readonly location: string;
  public readonly installationDate: Date;
  public readonly activationDate: Date | null;
  public readonly deactivationDate: Date | null;
  public readonly useCase: string | null;
  public readonly sensor?: Sensor | null;
  public readonly sensorEpoches?: SensorEpoch[];

  private constructor(params: {
    id: ModelId;
    latitude: number;
    longitude: number;
    heightAboveSea: number | null;
    heightAboveGround: number | null;
    location: string;
    installationDate: Date;
    activationDate: Date | null;
    deactivationDate: Date | null;
    useCase: string | null;
    sensor?: Sensor | null;
    sensorEpoches?: SensorEpoch[];
  }) {
    this.id = params.id;
    this.latitude = params.latitude;
    this.longitude = params.longitude;
    this.heightAboveSea = params.heightAboveSea;
    this.heightAboveGround = params.heightAboveGround;
    this.location = params.location;
    this.installationDate = params.installationDate;
    this.activationDate = params.activationDate;
    this.deactivationDate = params.deactivationDate;
    this.useCase = params.useCase;
    this.sensor = params.sensor;
    this.sensorEpoches = params.sensorEpoches;
  }

  public static fromDto(dto: SensorSituationDto): SensorSituation {
    return new SensorSituation({
      id: dto.id,
      latitude: dto.latitude,
      longitude: dto.longitude,
      heightAboveSea: dto.heightAboveSea,
      heightAboveGround: dto.heightAboveGround,
      location: dto.location,
      installationDate: DateUtils.fromIsoString(dto.installationDate),
      activationDate: DateUtils.fromNullableIsoString(dto.activationDate),
      deactivationDate: DateUtils.fromNullableIsoString(dto.deactivationDate),
      useCase: dto.useCase,
      sensor: dto.sensor ? Sensor.fromDto(dto.sensor) : dto.sensor,
      sensorEpoches: dto.sensorEpoches?.map((sensorEpoch) =>
        SensorEpoch.fromDto(sensorEpoch)
      ),
    });
  }
}
