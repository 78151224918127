import {
  NotificationDto,
  isNotificationCompletedAuditDto,
} from "services/apis/ceaMoonshot/dtos/notification/NotificationDto";
import { ModelId } from "../common/Model";
import { NotificationType } from "./NotificationType";
import { z } from "zod";
import SchemaUtils from "utils/SchemaUtils";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";
import Sensor from "models/sensor/Sensor";

export type NotificationCompletedAuditData = {
  auditId: ModelId;
  auditGlobalErrorStatus: GlobalAuditionStatus;
  auditExecutionDateTime: Date;
  sensorId: ModelId;
  sensorIdentifier: Sensor["identifier"];
};

export default class Notification {
  public readonly id: ModelId;
  public readonly type: NotificationType;
  public readonly target: string;
  public readonly creationDate: Date;
  public readonly dismissDate: Date | null;
  public readonly data: NotificationCompletedAuditData | null;

  private constructor(params: {
    id: ModelId;
    type: NotificationType;
    target: string;
    creationDate: Date;
    dismissDate: Date | null;
    data: NotificationCompletedAuditData | null;
  }) {
    this.id = params.id;
    this.type = params.type;
    this.target = params.target;
    this.creationDate = params.creationDate;
    this.dismissDate = params.dismissDate;
    this.data = params.data;
  }

  public static fromDto(dto: NotificationDto): Notification {
    return new Notification({
      id: dto.id,
      type: dto.type,
      target: dto.target,
      creationDate: new Date(dto.creationDate),
      dismissDate: dto.dismissDate ? new Date(dto.dismissDate) : null,
      data:
        (isNotificationCompletedAuditDto(dto) && {
          auditId: dto.data.auditId,
          auditGlobalErrorStatus: dto.data.auditGlobalErrorStatus,
          auditExecutionDateTime: new Date(dto.data.auditExecutionDateTime),
          sensorId: dto.data.sensorId,
          sensorIdentifier: dto.data.sensorIdentifier,
        }) ||
        null,
    });
  }

  public isNotificationCompletedAudit(): this is Notification & {
    type: NotificationType.CompletedAudit;
    data: NotificationCompletedAuditData;
  } {
    const dataValidation = z
      .object({
        auditId: SchemaUtils.idSchema,
        auditGlobalErrorStatus: z.nativeEnum(GlobalAuditionStatus),
        auditExecutionDateTime: z.date(),
        sensorId: SchemaUtils.idSchema,
        sensorIdentifier: z.string(),
      })
      .safeParse(this.data);
    return (
      this.type === NotificationType.CompletedAudit && dataValidation.success
    );
  }
}
