import * as Leaflet from "leaflet";
import { memo, PropsWithChildren, useCallback, useMemo } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  ZoomControl,
} from "react-leaflet";
import Sensor from "models/sensor/Sensor";
import FlyMapTo from "./FlyMapTo";
import {
  buildIcon,
  grayIcon,
  grayIconSelected,
  greenIcon,
  greenIconSelected,
  greenDarkIconSelected,
} from "./icons";
import SensorDataUtils from "utils/SensorResultUtils";
import { Typography } from "@mui/material";
import "./style.css";
import useUser from "hooks/queries/useUser";

type Props = {
  onSensorSelection?: (sensor: Sensor) => void;
  interactive: boolean;
  isLaboratoryView?: boolean;
  sensors: Sensor[];
  selectedSensor: Sensor | null;
};

const parisCoordinates: Leaflet.LatLngExpression = [
  48.825 + (48.9 - 48.825) / 2,
  2.276 + (2.4 - 2.276) / 2,
];

function SensorsMap({
  onSensorSelection,
  interactive,
  isLaboratoryView,
  sensors,
  selectedSensor,
  children,
}: PropsWithChildren<Props>) {
  const { userLaboratoryId } = useUser();

  const centerCoordinates = useMemo(() => {
    if (selectedSensor && selectedSensor.currentSituation) {
      const centerCoordinates: Leaflet.LatLngTuple = [
        selectedSensor.currentSituation.latitude,
        selectedSensor.currentSituation.longitude,
      ];
      return centerCoordinates;
    }
    return parisCoordinates;
  }, [selectedSensor]);

  const getMarkerIcon = useCallback(
    (sensor: Sensor) => {
      const isSensorSelected = sensor.id === selectedSensor?.id;

      const isUserLaboratorySensor = userLaboratoryId === sensor.laboratory?.id;

      if (!isSensorSelected) {
        return isUserLaboratorySensor && isLaboratoryView
          ? buildIcon({
              ...greenIcon,
              isSelectable: interactive,
            })
          : buildIcon({
              ...grayIcon,
              isSelectable: interactive,
            });
      }

      if (isLaboratoryView) {
        return isUserLaboratorySensor
          ? buildIcon({
              ...greenDarkIconSelected,
              isSelectable: interactive,
            })
          : buildIcon({
              ...grayIconSelected,
              isSelectable: interactive,
            });
      }

      return buildIcon({
        ...greenIconSelected,
        isSelectable: interactive,
      });
    },
    [interactive, isLaboratoryView, userLaboratoryId, selectedSensor?.id]
  );

  const computeMarkerEventHandlers = useCallback(
    (params: { sensor: Sensor }) => ({
      click: () => {
        onSensorSelection?.(params.sensor);
      },
    }),
    [onSensorSelection]
  );

  return (
    <MapContainer
      id="map"
      style={{ width: "100%", height: "100%", zIndex: 4 }}
      center={centerCoordinates}
      zoom={9}
      scrollWheelZoom={false}
      zoomControl={false}
    >
      <TileLayer
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
      />

      <ZoomControl position="bottomright" />
      {sensors.map((sensor) => {
        const markerIcon = getMarkerIcon(sensor);

        return (
          sensor.currentSituation && (
            <Marker
              key={sensor.id}
              interactive={interactive}
              position={[
                sensor.currentSituation.latitude,
                sensor.currentSituation.longitude,
              ]}
              icon={markerIcon}
              eventHandlers={computeMarkerEventHandlers({ sensor })}
            >
              <Tooltip permanent>
                <Typography variant="body2" fontSize={12}>
                  {SensorDataUtils.formatDisplayName(sensor)}
                </Typography>
              </Tooltip>
            </Marker>
          )
        );
      })}
      <FlyMapTo position={centerCoordinates} />
      {children}
    </MapContainer>
  );
}

export default memo(SensorsMap);
