import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import SortTableCell from "components/element/SortTableCell";
import I18n from "../I18n";
import { memo, useCallback, useEffect, useState } from "react";
import { Direction } from "models/common/Direction";
import DateUtils, { DateFormat } from "utils/DateUtils";
import SortUtils from "utils/SortUtils";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import NewAuditDialog from "../dialogs/NewAuditDialog";
import useOpenable from "hooks/useOpenable";
import { OpenInNew } from "@mui/icons-material";
import { Link } from "react-router-dom";
import useFetchedApplicationConfig from "hooks/useFetchedApplicationConfig";
import SensorEpoch from "models/sensor/SensorEpoch";

type Props = {
  sensorEpoches: SensorEpoch[];
};

function TransactionsTable(props: Readonly<Props>) {
  const { applicationConfig } = useFetchedApplicationConfig();
  const newAuditDialog = useOpenable();
  const { sensorId } = useSelectedSensorIdContext();
  const [sortedSensorEpoches, setSortedSensorEpoches] = useState<SensorEpoch[]>(
    []
  );
  const [newAuditDialogPrefill, setNewAuditDialogPrefill] = useState<{
    date: Date;
  } | null>(null);

  const handleAuditActionClick = useCallback(
    (prefill: { date: Date }) => {
      setNewAuditDialogPrefill(prefill);
      newAuditDialog.open();
    },
    [newAuditDialog]
  );

  useEffect(() => {
    setSortedSensorEpoches([
      ...props.sensorEpoches.sort((a, b) =>
        SortUtils.sortABNum({
          valueA: a.date.getTime() ?? 0,
          valueB: b.date.getTime() ?? 0,
          direction: Direction.Desc,
        })
      ),
    ]);
  }, [props.sensorEpoches]);

  const sortByEpochDate = useCallback((direction: Direction) => {
    setSortedSensorEpoches((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABNum({
          valueA: a.date.getTime() ?? 0,
          valueB: b.date.getTime() ?? 0,
          direction,
        })
      ),
    ]);
  }, []);

  if (!sensorId) {
    return null;
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650, overflowX: "auto" }}>
          <TableHead>
            <TableRow>
              <SortTableCell
                onSort={sortByEpochDate}
                divider
                initialDirection={Direction.Desc}
              >
                {I18n.translate("general_text.date")}
              </SortTableCell>
              <SortTableCell divider>
                {I18n.translate(
                  "pages.sensor.sub_pages.transactions.table.results_transaction_address"
                )}
              </SortTableCell>
              <SortTableCell divider>
                {I18n.translate(
                  "pages.sensor.sub_pages.transactions.table.raw_transaction_address"
                )}
              </SortTableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedSensorEpoches
              .filter(
                (sensorEpoch) =>
                  sensorEpoch.sensorRawSession || sensorEpoch.lastResultsSession
              )
              .map((sensorEpoch) => (
                <TableRow key={sensorEpoch.id}>
                  <TableCell width={100}>
                    {sensorEpoch.date
                      ? DateUtils.formatUTCDate(
                          sensorEpoch.date,
                          DateFormat.DayMonthYear
                        )
                      : "--"}
                  </TableCell>

                  <TableCell width={600}>
                    <Box display="flex" alignItems="center">
                      <Box width={570}>
                        {sensorEpoch.lastResultsSession?.transactionHash ??
                          "--"}
                      </Box>

                      {sensorEpoch.lastResultsSession?.transactionHash && (
                        <Link
                          to={`${applicationConfig.blockExplorerUrl}/${sensorEpoch.lastResultsSession.transactionHash}`}
                          target="_blank"
                        >
                          <IconButton>
                            <OpenInNew />
                          </IconButton>
                        </Link>
                      )}
                    </Box>
                  </TableCell>

                  <TableCell width={600}>
                    <Box display="flex" alignItems="center">
                      <Box width={570}>
                        {sensorEpoch.sensorRawSession?.transactionHash ?? "--"}
                      </Box>

                      {sensorEpoch.sensorRawSession?.transactionHash && (
                        <Link
                          to={`${applicationConfig.blockExplorerUrl}/${sensorEpoch.sensorRawSession.transactionHash}`}
                          target="_blank"
                        >
                          <IconButton>
                            <OpenInNew />
                          </IconButton>
                        </Link>
                      )}
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() =>
                        handleAuditActionClick({
                          date: sensorEpoch.date ?? new Date(),
                        })
                      }
                    >
                      {I18n.translate(
                        "pages.sensor.sub_pages.transactions.table.audit_action"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {newAuditDialog.isOpen && (
        <NewAuditDialog
          open={newAuditDialog.isOpen}
          onClose={newAuditDialog.close}
          prefill={newAuditDialogPrefill ?? undefined}
        />
      )}
    </>
  );
}

export default memo(TransactionsTable);
