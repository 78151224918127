import { ModelId } from "models/common/Model";
import { useQuery } from "react-query";
import { container } from "tsyringe";
import useIdValidation from "../useIdValidation";
import SensorAuditService from "services/SensorAuditService";

function sensorAuditQueryKey(id: ModelId | null) {
  return ["sensor-audit", id];
}

const staleTimeInMs = 1000 * 60 * 5;

function useSensorAudit(id: ModelId | null) {
  const sensorAuditIdValidation = useIdValidation(id);

  const sensorAuditQuery = useQuery(
    sensorAuditQueryKey(id),
    async () =>
      sensorAuditIdValidation.success
        ? container
            .resolve(SensorAuditService)
            .getById(sensorAuditIdValidation.data)
        : null,
    {
      enabled: sensorAuditIdValidation.success,
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  return {
    ...sensorAuditQuery,
    audit: sensorAuditQuery.data ?? null,
  };
}

export default useSensorAudit;
