import { Box, Typography } from "@mui/material";
import * as styles from "./styles";
import { memo } from "react";
import useMediaQueries from "hooks/useMediaQueries";

type Props = {
  average: number;
};

function Co2AverageChart({ average }: Props) {
  const mediaQueries = useMediaQueries();

  return (
    <Box sx={styles.root(mediaQueries)}>
      <Typography sx={styles.data}>{average.toFixed(2)}</Typography>
      <Typography sx={styles.unit}>ppm</Typography>
    </Box>
  );
}

export default memo(Co2AverageChart);
