import { SxProps, Theme } from "@mui/material";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";

function getChipBackgroundColor(
  theme: Theme
): Record<GlobalAuditionStatus, string> {
  return {
    [GlobalAuditionStatus.AllClear]: theme.palette.primary.main,
    [GlobalAuditionStatus.OneError]: theme.palette.warning.main,
    [GlobalAuditionStatus.MultipleErrors]: theme.palette.error.main,
    [GlobalAuditionStatus.FatalError]: theme.palette.error.main,
    [GlobalAuditionStatus.Unknown]: theme.palette.info.light,
  };
}

export const root: (status: GlobalAuditionStatus) => SxProps<Theme> =
  (status) => (theme) => {
    return {
      color: theme.palette.primary.contrastText,
      background: status
        ? getChipBackgroundColor(theme)[status]
        : theme.palette.info.light,
      height: theme.spacing(4),
      userSelect: "none",

      "& .MuiChip-label": {
        minHeight: theme.spacing(3),
      },
    };
  };
