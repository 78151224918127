import { Delete } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { memo } from "react";
import * as styles from "../styles";
import I18n from "components/materials/I18n";
import Laboratory from "models/laboratory/Laboratory";

type Props = {
  laboratories: Laboratory[];
  onDelete: (laboratory: Laboratory) => void;
};

function WhitelistLaboratories({ laboratories, onDelete }: Props) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.header(!!laboratories.length)}>
        <Typography variant="subtitle1">
          <I18n map={"components.whitelist_laboratories.title"} />
        </Typography>
      </Box>

      <Box sx={styles.container}>
        {laboratories.map((laboratory) => {
          return (
            <Box key={laboratory.id} sx={styles.card}>
              <Typography sx={styles.label}>{laboratory.name}</Typography>

              <IconButton onClick={() => onDelete(laboratory)}>
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default memo(WhitelistLaboratories);
