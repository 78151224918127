import { Card, CardContent } from "@mui/material";
import useOpenable from "hooks/useOpenable";
import { WidgetType } from "models/widgets/WidgetType";
import EditWidgetDialog from "../../EditWidgetDialog";
import { memo, useCallback, useState } from "react";
import Widget from "models/widgets/Widget";
import useSensorWidgets from "hooks/queries/useSensorWidgets";
import * as styles from "./styles";
import WidgetPicture from "components/materials/WidgetPicture";
import I18n from "components/materials/I18n";
import useSnackBarContext from "hooks/useSnackBarContext";

type Props = {
  widgetType: WidgetType;
  createWidgetQuery: ReturnType<typeof useSensorWidgets>["createWidgetQuery"];
};

const widgetTitleTranslation: Record<WidgetType, string> = {
  [WidgetType.Co2Average]: "widgets.co2_average.title",
  [WidgetType.Co2Evolution]: "widgets.co2_evolution.title",
  [WidgetType.Co2RelativeAverage]: "widgets.co2_relative_average.title",
  [WidgetType.BlockchainEnergyCost]: "widgets.blockchain_energy_cost.title",
  [WidgetType.SensorMap]: "widgets.sensor_map.title",
};

const imageSx = { objectFit: "contain" };

function AddWidgetAction({ widgetType, createWidgetQuery }: Props) {
  const editWidgetDialog = useOpenable();
  const [widget, setWidget] = useState<Widget | null>(null);
  const snackBar = useSnackBarContext();

  const handleAddWidget = useCallback(async () => {
    if (createWidgetQuery.isLoading) return;
    try {
      const createdWidget = await createWidgetQuery.mutateAsync({ widgetType });
      setWidget(createdWidget);
      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.add_widget.alert.add.success"
        ),
      });

      editWidgetDialog.open();
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.add_widget.alert.add.error"
        ),
      });
    }
  }, [createWidgetQuery, editWidgetDialog, snackBar, widgetType]);

  return (
    <>
      <Card onClick={handleAddWidget} sx={styles.root} elevation={0}>
        <CardContent sx={styles.content}>
          <WidgetPicture
            widgetType={widgetType}
            alt={`${widgetTitleTranslation[widgetType]} widget image`}
            sx={imageSx}
          />
        </CardContent>
      </Card>

      {widget && (
        <EditWidgetDialog
          widget={widget}
          isOpen={editWidgetDialog.isOpen}
          close={editWidgetDialog.close}
          fromAddWidgetDialog
        />
      )}
    </>
  );
}

export default memo(AddWidgetAction);
