import { SxProps, Theme } from "@mui/material";
import { borderRadius } from "themes/constants";

export const content: SxProps<Theme> = (theme) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

export const title: SxProps<Theme> = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const margin: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2),
});

export const code: SxProps<Theme> = (theme) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  width: "fit-content",
  borderRadius,
  backgroundColor: theme.palette.grey[100],
});
