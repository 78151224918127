import DateUtils from "utils/DateUtils";
import SensorSituation from "./SensorSituation";
import { SensorEpochDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorEpochDto";
import SensorResultsSession from "./SensorResultsSession";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";
import { ModelId } from "models/common/Model";
import SensorRawSession from "./SensorRawSession";

export default class SensorEpoch {
  public readonly id: ModelId;
  public readonly date: Date;
  public readonly notarizationStatus: GlobalAuditionStatus;
  public readonly sensorSituation?: SensorSituation | null;
  public readonly sensorRawSession?: SensorRawSession | null;
  public readonly sensorResultsSessions?: SensorResultsSession[];
  public readonly lastResultsSession?: SensorResultsSession | null;

  private constructor(params: {
    id: ModelId;
    date: Date;
    notarizationStatus: GlobalAuditionStatus;
    sensorSituation?: SensorSituation | null;
    sensorRawSession?: SensorRawSession | null;
    sensorResultsSessions?: SensorResultsSession[];
  }) {
    this.id = params.id;
    this.date = params.date;
    this.notarizationStatus = params.notarizationStatus;
    this.sensorSituation = params.sensorSituation;
    this.sensorRawSession = params.sensorRawSession;
    this.sensorResultsSessions = params.sensorResultsSessions;
    if (params.sensorResultsSessions) {
      this.lastResultsSession = this.retrieveLastResultsSession();
    }
  }

  public static fromDto(dto: SensorEpochDto): SensorEpoch {
    return new SensorEpoch({
      id: dto.id,
      date: DateUtils.fromIsoString(dto.date),
      notarizationStatus: dto.notarizationStatus,
      sensorSituation: dto.sensorSituation
        ? SensorSituation.fromDto(dto.sensorSituation)
        : dto.sensorSituation,
      sensorRawSession: dto.sensorRawSession
        ? SensorRawSession.fromDto(dto.sensorRawSession)
        : dto.sensorRawSession,
      sensorResultsSessions: dto.sensorResultsSessions?.map(
        SensorResultsSession.fromDto
      ),
    });
  }

  private retrieveLastResultsSession():
    | SensorResultsSession
    | null
    | undefined {
    if (this.sensorResultsSessions?.length) {
      const sortedSessions = this.sensorResultsSessions.sort(
        (a, b) => b.version - a.version
      );
      return sortedSessions[0];
    }
    return null;
  }
}
