import { SxProps, Theme } from "@mui/material";
import { Direction } from "models/common/Direction";
import { customEasing } from "themes/constants";

export const container: SxProps<Theme> = (theme) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(3),
  };
};

export const content: (isSortable: boolean) => SxProps<Theme> =
  (isSortable) => (theme) => {
    return {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      cursor: isSortable ? "pointer" : "default",
      whiteSpace: "nowrap",
    };
  };

export const arrow: (direction: Direction) => SxProps<Theme> =
  (direction) => (theme) => {
    return {
      transform:
        direction === Direction.Desc ? "rotate(180deg)" : "rotate(0deg)",
      transition: `transform 250ms ${customEasing}`,
    };
  };
