import { GridLayoutConfig, GridLayoutSize } from "models/common/GridLayoutSize";
import Widget from "models/widgets/Widget";
import {
  screenXL,
  screenLG,
  screenMD,
  screenSM,
  screenXS,
  spacingLG,
  spacingMD,
} from "themes/constants";
import CSSUtils from "utils/CSSUtils";
import { Layouts } from "react-grid-layout";
import { MediaQueries } from "hooks/useMediaQueries";

export default class WidgetsContainerUtils {
  public static readonly gridLayoutConfig: GridLayoutConfig = {
    xl: {
      cols: 12,
      width:
        // We need to substact the page paddingX+1 to the screen width for the container layout breakpoints
        // in order to be be screen size precisely responsive
        CSSUtils.getSizeNumberFromPx(screenXL) -
        CSSUtils.getSizeNumberFromPx(spacingLG) * 2 -
        1,
      height: 125,
    },
    lg: {
      cols: 12,
      width:
        CSSUtils.getSizeNumberFromPx(screenLG) -
        CSSUtils.getSizeNumberFromPx(spacingLG) * 2 -
        1,
      height: 125,
    },
    md: {
      cols: 10,
      width:
        CSSUtils.getSizeNumberFromPx(screenMD) -
        CSSUtils.getSizeNumberFromPx(spacingLG) * 2 -
        1,
      height: 125,
    },
    sm: {
      cols: 6,
      width:
        CSSUtils.getSizeNumberFromPx(screenSM) -
        CSSUtils.getSizeNumberFromPx(spacingMD) * 2 -
        1,
      height: 125,
    },
    xs: {
      cols: 4,
      width:
        CSSUtils.getSizeNumberFromPx(screenXS) -
        CSSUtils.getSizeNumberFromPx(spacingMD) * 2 -
        1,
      height: 125,
    },
    xxs: {
      cols: 2,
      width: 0,
      height: 125,
    },
  };

  public static readonly breakpoints = Object.entries(
    WidgetsContainerUtils.gridLayoutConfig
  ).reduce((acc, [key, value]) => {
    acc[key as GridLayoutSize] = value.width;
    return acc;
  }, {} as Record<GridLayoutSize, number>);

  public static readonly cols = Object.entries(
    WidgetsContainerUtils.gridLayoutConfig
  ).reduce((acc, [key, value]) => {
    acc[key as GridLayoutSize] = value.cols;
    return acc;
  }, {} as Record<GridLayoutSize, number>);

  public static isWidgetAloneOnRow({
    mediaQueries,
    widget,
    layouts,
  }: {
    mediaQueries: MediaQueries;
    widget: Widget;
    layouts: Layouts;
  }): boolean {
    if (mediaQueries.isXl) {
      return (
        layouts["xl"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["xl"]
      );
    }

    if (mediaQueries.isLg) {
      return (
        layouts["lg"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["lg"]
      );
    }

    if (mediaQueries.isMd) {
      return (
        layouts["md"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["md"]
      );
    }

    if (mediaQueries.isSm) {
      return (
        layouts["sm"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["sm"]
      );
    }

    if (mediaQueries.isXs) {
      return (
        layouts["xs"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["xs"]
      );
    }

    if (mediaQueries.isXxs) {
      return (
        layouts["xxs"]?.find((layout) => layout.i === widget.id.toString())
          ?.w === WidgetsContainerUtils.cols["xxs"]
      );
    }

    return false;
  }

  public static rowHeight(mediaQueries: MediaQueries): number {
    if (mediaQueries.isStrictlyXl) {
      return WidgetsContainerUtils.gridLayoutConfig.xl.height;
    }

    if (mediaQueries.isStrictlyLg) {
      return WidgetsContainerUtils.gridLayoutConfig.lg.height;
    }

    if (mediaQueries.isStrictlyMd) {
      return WidgetsContainerUtils.gridLayoutConfig.md.height;
    }

    if (mediaQueries.isStrictlySm) {
      return WidgetsContainerUtils.gridLayoutConfig.sm.height;
    }

    if (mediaQueries.isStrictlyXs) {
      return WidgetsContainerUtils.gridLayoutConfig.xs.height;
    }

    if (mediaQueries.isStrictlyXxs) {
      return WidgetsContainerUtils.gridLayoutConfig.xxs.height;
    }

    return 0;
  }
}
