import { ModelId } from "models/common/Model";
import { WidgetType } from "./WidgetType";
import {
  BlockchainEnergyCostWidgetPropertiesDto,
  Co2AverageWidgetPropertiesDto,
  Co2EvolutionWidgetPropertiesDto,
  Co2RelativeAverageWidgetPropertiesDto,
  DateBoundaryDto,
  SensorMapWidgetPropertiesDto,
  WidgetDto,
  isFixDateBoundaryDto,
} from "services/apis/ceaMoonshot/dtos/widget/WidgetDto";
import { WidgetLayouts } from "./WidgetLayouts";
import { Nullable } from "types/common";
import { addSeconds, differenceInSeconds } from "date-fns";
import DateUtils from "utils/DateUtils";

export type FixDateBoundary = {
  fix: Date;
};

export type RelativeDateBoundary = {
  relative: number;
};

export type DateBoundary = FixDateBoundary | RelativeDateBoundary;

export function isFixDateBoundary(
  dateBoundary: DateBoundary
): dateBoundary is FixDateBoundary {
  return "fix" in dateBoundary;
}

export function isRelativeDateBoundary(
  dateBoundary: DateBoundary
): dateBoundary is RelativeDateBoundary {
  return "relative" in dateBoundary;
}

export function retrieveFixDateBoundary(dateBoundary: DateBoundary): Date {
  return isFixDateBoundary(dateBoundary)
    ? dateBoundary.fix
    : addSeconds(DateUtils.nowUTC(), dateBoundary.relative);
}

export function retrieveRelativeDateBoundary(
  dateBoundary: DateBoundary
): number {
  return isFixDateBoundary(dateBoundary)
    ? differenceInSeconds(
        retrieveFixDateBoundary(dateBoundary),
        DateUtils.nowUTC()
      )
    : dateBoundary.relative;
}

export function convertToDateBoundaryDto(
  dateBoundary: DateBoundary
): DateBoundaryDto {
  const dateBoundaryDto = isFixDateBoundary(dateBoundary)
    ? { fix: dateBoundary.fix.toISOString() }
    : { relative: dateBoundary.relative };
  return dateBoundaryDto;
}

function convertToDateBoundary(dateBoundaryDto: DateBoundaryDto): DateBoundary {
  const dateBoundary = isFixDateBoundaryDto(dateBoundaryDto)
    ? { fix: new Date(dateBoundaryDto.fix) }
    : { relative: dateBoundaryDto.relative };
  return dateBoundary;
}

type Co2AverageWidgetProperties = {
  sensorId: ModelId;
  fromDate: DateBoundary;
  toDate: DateBoundary;
};
export type Co2AverageWidget = Widget & {
  properties: Co2AverageWidgetProperties;
};

type BlockchainEnergyCostWidgetProperties = {
  sensorId: ModelId;
  fromDate: DateBoundary;
  toDate: DateBoundary;
};
export type BlockchainEnergyCostWidget = Widget & {
  properties: BlockchainEnergyCostWidgetProperties;
};

type SensorMapWidgetProperties = {
  targetSensorId: ModelId;
  sensorIds: ModelId[];
  isActiveAtDate: DateBoundary;
};
export type SensorMapWidget = Widget & {
  properties: SensorMapWidgetProperties;
};

type Co2EvolutionWidgetProperties = {
  sensorIds: ModelId[];
  fromDate: DateBoundary;
  toDate: DateBoundary;
};
export type Co2EvolutionWidget = Widget & {
  properties: Co2EvolutionWidgetProperties;
};

type Co2RelativeAverageWidgetProperties = {
  targetSensorId: ModelId;
  sensorIds: ModelId[];
  fromDate: DateBoundary;
  toDate: DateBoundary;
};
export type Co2RelativeAverageWidget = Widget & {
  properties: Co2RelativeAverageWidgetProperties;
};

export default class Widget {
  public readonly id: ModelId;
  public readonly type: WidgetType;
  public readonly layouts: WidgetLayouts;
  public readonly properties:
    | Co2AverageWidgetProperties
    | BlockchainEnergyCostWidgetProperties
    | SensorMapWidgetProperties
    | Co2EvolutionWidgetProperties
    | Co2RelativeAverageWidgetProperties;

  private constructor(params: {
    id: ModelId;
    type: WidgetType;
    layouts: WidgetLayouts;
    properties:
      | Co2AverageWidgetProperties
      | BlockchainEnergyCostWidgetProperties
      | SensorMapWidgetProperties
      | Co2EvolutionWidgetProperties
      | Co2RelativeAverageWidgetProperties;
  }) {
    this.id = params.id;
    this.type = params.type;
    this.layouts = params.layouts;
    this.properties = params.properties;
  }

  public static fromDto(dto: WidgetDto): Widget {
    return new Widget({
      id: dto.id,
      type: dto.type,
      layouts: dto.layouts,
      properties: Widget.transformWidgetDtoProperties(dto),
    });
  }

  private static transformWidgetDtoProperties(
    dto: WidgetDto
  ): Widget["properties"] {
    if (this.isCo2AverageWidgetDto(dto)) {
      return {
        sensorId: dto.properties.sensorId,
        fromDate: convertToDateBoundary(dto.properties.fromDate),
        toDate: convertToDateBoundary(dto.properties.toDate),
      };
    }
    if (this.isBlockchainEnergyCostWidgetDto(dto)) {
      return {
        sensorId: dto.properties.sensorId,
        fromDate: convertToDateBoundary(dto.properties.fromDate),
        toDate: convertToDateBoundary(dto.properties.toDate),
      };
    }
    if (this.isSensorMapWidgetDto(dto)) {
      return {
        targetSensorId: dto.properties.targetSensorId,
        sensorIds: dto.properties.sensorIds,
        isActiveAtDate: convertToDateBoundary(dto.properties.isActiveAtDate),
      };
    }
    if (this.isCo2EvolutionWidgetDto(dto)) {
      return {
        sensorIds: dto.properties.sensorIds,
        fromDate: convertToDateBoundary(dto.properties.fromDate),
        toDate: convertToDateBoundary(dto.properties.toDate),
      };
    }
    if (this.isCo2RelativeAverageWidgetDto(dto)) {
      return {
        targetSensorId: dto.properties.targetSensorId,
        sensorIds: dto.properties.sensorIds,
        fromDate: convertToDateBoundary(dto.properties.fromDate),
        toDate: convertToDateBoundary(dto.properties.toDate),
      };
    }
    throw new Error(`Unknown widget type: ${dto.type}`);
  }

  public isCo2AverageWidget(): this is Widget & {
    properties: Co2AverageWidgetProperties;
  } {
    return this.type === WidgetType.Co2Average;
  }

  public isBlockchainEnergyCostWidget(): this is Widget & {
    properties: BlockchainEnergyCostWidgetProperties;
  } {
    return this.type === WidgetType.BlockchainEnergyCost;
  }

  public isSensorMapWidget(): this is Widget & {
    properties: SensorMapWidgetProperties;
  } {
    return this.type === WidgetType.SensorMap;
  }

  public isCo2EvolutionWidget(): this is Widget & {
    properties: Co2EvolutionWidgetProperties;
  } {
    return this.type === WidgetType.Co2Evolution;
  }

  public isCo2RelativeAverageWidget(): this is Widget & {
    properties: Co2RelativeAverageWidgetProperties;
  } {
    return this.type === WidgetType.Co2RelativeAverage;
  }

  private static isCo2AverageWidgetDto(dto: WidgetDto): dto is WidgetDto & {
    properties: Co2AverageWidgetPropertiesDto;
  } {
    return dto.type === WidgetType.Co2Average;
  }

  private static isBlockchainEnergyCostWidgetDto(
    dto: WidgetDto
  ): dto is WidgetDto & {
    properties: BlockchainEnergyCostWidgetPropertiesDto;
  } {
    return dto.type === WidgetType.BlockchainEnergyCost;
  }

  private static isSensorMapWidgetDto(dto: WidgetDto): dto is WidgetDto & {
    properties: SensorMapWidgetPropertiesDto;
  } {
    return dto.type === WidgetType.SensorMap;
  }

  private static isCo2EvolutionWidgetDto(dto: WidgetDto): dto is WidgetDto & {
    properties: Co2EvolutionWidgetPropertiesDto;
  } {
    return dto.type === WidgetType.Co2Evolution;
  }

  private static isCo2RelativeAverageWidgetDto(
    dto: WidgetDto
  ): dto is WidgetDto & {
    properties: Co2RelativeAverageWidgetPropertiesDto;
  } {
    return dto.type === WidgetType.Co2RelativeAverage;
  }

  public static isPropertiesFullFilled<T extends Widget["properties"]>(
    properties: Nullable<T>
  ): properties is T {
    const isFullfilled = !Object.values(properties).some((v) => {
      return typeof v === "object" && v !== null
        ? Object.values(v).some((vChild) => vChild === null)
        : v === null;
    });
    return isFullfilled;
  }

  public static hasRelativeDates(properties: Widget["properties"]) {
    return (
      ("fromDate" in properties &&
        isRelativeDateBoundary(properties.fromDate)) ||
      ("toDate" in properties && isRelativeDateBoundary(properties.toDate)) ||
      ("isActiveAtDate" in properties &&
        isRelativeDateBoundary(properties.isActiveAtDate))
    );
  }
}
