import { Box, Paper } from "@mui/material";
import { memo } from "react";
import useUser from "hooks/queries/useUser";
import * as styles from "./styles";
import ExternalUserSensorsSearchBar from "components/materials/Header/ExternalUserSensorsSearchBar";
import SignInButton from "components/materials/authentication/SignInButton";
import SignOutButton from "components/materials/authentication/SignOutButton";
import HeaderLogo from "components/materials/Header/HeaderLogo";
import Module from "components/materials/Module";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import SwitchViewButton from "components/materials/Header/SwitchViewButton";
import NotificationsButton from "components/materials/Header/NotificationsButton";
import useCurrentPageType, { CurrentPageType } from "hooks/useCurrentPageType";
import LaboratorySensorsSearchBar from "../LaboratorySensorsSearchBar";
import useRoutes from "hooks/useRoutes";

function HeaderSm() {
  const { user } = useUser();
  const { currentPageType } = useCurrentPageType();
  const routes = useRoutes();

  return (
    <Paper sx={styles.root} elevation={0}>
      <Box sx={styles.navBar}>
        <HeaderLogo />
        {user &&
          currentPageType === CurrentPageType.ExternalUser &&
          !routes.home.isCurrent && <ExternalUserSensorsSearchBar />}
        {user?.isLabUser() &&
          currentPageType === CurrentPageType.Laboratory &&
          !routes.home.isCurrent && <LaboratorySensorsSearchBar />}
      </Box>

      <Box sx={styles.actions}>
        {user?.isLabUser() &&
          currentPageType === CurrentPageType.Laboratory &&
          !routes.home.isCurrent && (
            <Module
              from={
                container.resolve(ModuleConfig).get().modules.components.header
                  .notifications
              }
            >
              <NotificationsButton />
            </Module>
          )}

        {user?.isLabUser() && !routes.home.isCurrent && (
          <Module
            from={
              container.resolve(ModuleConfig).get().modules.components.header
                .switchView
            }
          >
            <SwitchViewButton variant="text" />
          </Module>
        )}

        {!user && <SignInButton />}
        {user && <SignOutButton variant="text" />}
      </Box>
    </Paper>
  );
}

export default memo(HeaderSm);
