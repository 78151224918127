import assert from "assert";
import UserNotConnectedError from "errors/UserNotConnectedError";
import { ModelId } from "models/common/Model";
import User from "models/user/User";
import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import AuthenticationService from "services/AuthenticationService";
import { container } from "tsyringe";

const authenticatedUserQueryKey = "authenticated-user";
const staleTimeInMs = 1000 * 60 * 5;

function useUser() {
  const queryClient = useQueryClient();

  const query = useQuery(
    authenticatedUserQueryKey,
    async () => container.resolve(AuthenticationService).autoConnect(),
    {
      retry: (failureCount) => {
        return failureCount < 1;
      },
      refetchOnWindowFocus: (query) => {
        return query.isStale();
      },
      staleTime: staleTimeInMs,
    }
  );

  const autoConnect = query.refetch;

  const clearUser = useCallback(async () => {
    container.resolve(AuthenticationService).signOut();
    queryClient.setQueryData(authenticatedUserQueryKey, null);

    // auto connect is used to presign
    autoConnect();
  }, [autoConnect, queryClient]);

  const getConnectedUser: () => User = useCallback(() => {
    assert(query.data, new UserNotConnectedError());
    return query.data;
  }, [query.data]);

  const user = query.data ?? null;
  const userLaboratoryId = user?.laboratory?.id;

  const isFromUserLaboratory = useCallback(
    (laboratoryIdToCompare: ModelId | null) => {
      return (
        userLaboratoryId !== undefined &&
        laboratoryIdToCompare === userLaboratoryId
      );
    },
    [userLaboratoryId]
  );

  return {
    ...query,
    user,
    userLaboratoryId,
    isFromUserLaboratory,
    autoConnect,
    clearUser,
    getConnectedUser,
  };
}

export default useUser;
