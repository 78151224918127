import { Box, Typography } from "@mui/material";
import Co2EvolutionChart from "components/element/Co2EvolutionChart";
import I18n from "components/materials/I18n";
import useCo2EvolutionData from "hooks/queries/widgetData/useCo2EvolutionData";
import { Co2EvolutionWidget } from "models/widgets/Widget";
import DateTimeRange from "components/element/DateTimeRange";
import * as styles from "./styles";
import { DateFormat } from "utils/DateUtils";
import { Nullable } from "types/common";
import { memo, useCallback, useEffect, useState } from "react";
import EditionForm from "./EditionForm";
import CircularLoader from "components/element/CircularLoader";
import DelayUtils from "utils/DelayUtils";

type Props = {
  widget: Co2EvolutionWidget;
  shouldShowEditionForm: boolean;
  onPropertiesChange?: (
    properties: Nullable<Co2EvolutionWidget["properties"]> | null
  ) => void;
  onUserValidation?: (properties: Co2EvolutionWidget["properties"]) => void;
};

function Co2Evolution({
  widget,
  shouldShowEditionForm,
  onPropertiesChange,
  onUserValidation,
}: Readonly<Props>) {
  const [properties, setProperties] = useState<
    Co2EvolutionWidget["properties"]
  >(widget.properties);

  const adjustLabelsSizes = useCallback(() => {
    const sensorLabels = document.getElementsByClassName(
      "apexcharts-legend-text"
    ) as HTMLCollectionOf<HTMLSpanElement>;

    const sensorLabelContainers = Array.from(sensorLabels);
    for (const sensorLabel of sensorLabelContainers) {
      const sensorLabelWidth = sensorLabel.getBoundingClientRect().width;

      sensorLabel.style.marginLeft = `calc(46px - ${sensorLabelWidth}px`;

      const labelMarker = sensorLabel.parentElement?.getElementsByClassName(
        "apexcharts-legend-marker"
      )[0] as HTMLSpanElement;

      labelMarker.style.width = `calc(${sensorLabelWidth}px + 8px)`;
    }
  }, []);

  useEffect(() => {
    setProperties(widget.properties);
  }, [widget.properties]);

  const { allSensorResultsAveragesPerHour, ...query } = useCo2EvolutionData(
    widget.id,
    properties
  );

  // We need this workaround to adjust the labels sizes after the chart is rendered
  useEffect(() => {
    (async () => {
      if (allSensorResultsAveragesPerHour.length > 0) {
        await DelayUtils.wait(20);
        adjustLabelsSizes();
      }
    })();
  }, [adjustLabelsSizes, allSensorResultsAveragesPerHour.length]);

  const validate = useCallback(
    (properties: Co2EvolutionWidget["properties"]) => {
      onUserValidation?.(properties);
      setProperties(properties);
    },
    [onUserValidation]
  );

  return (
    <Box sx={styles.root}>
      {shouldShowEditionForm && (
        <EditionForm
          properties={properties}
          onSubmit={validate}
          onPropertiesChange={onPropertiesChange}
        />
      )}
      <Box sx={styles.content}>
        <Box sx={styles.rangeDateContainer}>
          <DateTimeRange
            fromDate={properties.fromDate}
            toDate={properties.toDate}
            format={DateFormat.DayMonthYearBulletHourMinute}
          />
        </Box>

        <Box>
          {query.isLoading && (
            <Box sx={styles.loadingContainer}>
              <CircularLoader />
            </Box>
          )}

          {query.isFetched && allSensorResultsAveragesPerHour.length > 0 && (
            <Box sx={styles.chartContainer}>
              <Co2EvolutionChart
                allSensorResultsAveragesPerHour={
                  allSensorResultsAveragesPerHour
                }
              />
            </Box>
          )}
          {query.isFetched && allSensorResultsAveragesPerHour.length === 0 && (
            <Typography variant="body2">
              <I18n map="general_text.no_data" />
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default memo(Co2Evolution);
