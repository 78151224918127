import * as styles from "./styles";
import { DragIndicator } from "@mui/icons-material";
import { useRef, useCallback, memo, CSSProperties } from "react";

type Props = {
  actionStyles: CSSProperties;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};

function DragAction({ actionStyles, onDragStart, onDragEnd }: Props) {
  const dragging = useRef(false);

  const enableDrag = useCallback(() => {
    if (!dragging.current) {
      dragging.current = true;
      onDragStart?.();
    }
  }, [onDragStart]);

  const disableDrag = useCallback(() => {
    if (dragging.current) {
      dragging.current = false;
      onDragEnd?.();
    }
  }, [onDragEnd]);

  return (
    <DragIndicator
      sx={styles.root(actionStyles)}
      color="action"
      onMouseOver={enableDrag}
      onMouseOut={disableDrag}
      onTouchStartCapture={enableDrag}
      onTouchEndCapture={disableDrag}
    />
  );
}

export default memo(DragAction);
