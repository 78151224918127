import { Button } from "@mui/material";
import SignInDialog from "../SignInDialog";
import useOpenable from "hooks/useOpenable";
import I18n from "components/materials/I18n";
import { memo } from "react";

function SignInButton({ signInText }: Readonly<{ signInText?: string }>) {
  const { isOpen, open, close } = useOpenable();

  return (
    <div>
      <Button variant="contained" color="primary" onClick={open}>
        {signInText ??
          I18n.translate("components.authentication.sign_in_button")}
      </Button>
      <SignInDialog isOpen={isOpen} onClose={close} />
    </div>
  );
}

export default memo(SignInButton);
