import { Add } from "@mui/icons-material";
import Action from "../Action";
import I18n from "components/materials/I18n";
import { memo } from "react";
import useOpenable from "hooks/useOpenable";
import AddWidgetDialog from "components/materials/dialogs/AddWidgetDialog";

function DataActions() {
  const { isOpen, open, close } = useOpenable();

  return (
    <>
      <Action
        icon={<Add />}
        label={I18n.translate(
          "components.sensor_page_links_and_actions.actions.add_widget"
        )}
        onClick={open}
      />
      <AddWidgetDialog isOpen={isOpen} close={close} />
    </>
  );
}

export default memo(DataActions);
