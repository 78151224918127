import { SxProps, Theme } from "@mui/material";

export const root: (params: {
  dismissAnimationDuration: number;
  isDismissed: boolean;
}) => SxProps<Theme> =
  ({ dismissAnimationDuration, isDismissed }) =>
  (theme) => ({
    display: "flex",
    flexDirection: "column",
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    position: "relative",
    transition: `transform ${dismissAnimationDuration / 1000}s ease-in-out`,
    transform: isDismissed ? "translateX(100%)" : "translateX(0)",
  });

export const header: SxProps<Theme> = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(2),
});

export const description: SxProps<Theme> = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  alignItems: "flex-start",
  paddingRight: theme.spacing(3),
});

export const action: SxProps<Theme> = (theme) => ({
  marginTop: theme.spacing(2),
});
