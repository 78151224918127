import { Button } from "@mui/material";
import { memo } from "react";

type Props = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error";
};

function Action({ icon, label, onClick, variant, color }: Props) {
  return (
    <Button
      variant={variant ?? "contained"}
      startIcon={icon}
      onClick={onClick}
      color={color}
    >
      {label}
    </Button>
  );
}

export default memo(Action);
