import { Box, TextField } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import * as styles from "./styles";
import I18n from "components/materials/I18n";
import RelativeDateUtils, { ParsedRelativeDate } from "utils/RelativeDateUtils";
import FormErrors from "../../FormErrors";

type Props = {
  defaultValue?: number | null;
  onRelativeDateChange: (relativeDate: number) => void;
  errors?: string[];
  minRelative?: number;
  maxRelative?: number;
};

function RelativeDatePicker({
  defaultValue,
  onRelativeDateChange,
  errors,
}: Props) {
  const relativeDate = useMemo(
    () => RelativeDateUtils.fromSeconds(defaultValue ?? 0),
    [defaultValue]
  );

  const [relativeDays, setRelativeDays] = useState<string>(
    relativeDate.days.toString()
  );

  const [relativeHours, setRelativeHours] = useState<string>(
    relativeDate.hours.toString()
  );
  const [relativeMinutes, setRelativeMinutes] = useState<string>(
    relativeDate.minutes.toString()
  );
  const [relativeSeconds, setRelativeSeconds] = useState<string>(
    relativeDate.seconds.toString()
  );

  const handleRelativeDateChange = useCallback(
    (relativeValues: Partial<ParsedRelativeDate>) => {
      const relativeDateSeconds = -(
        (relativeValues.days ?? relativeDate.days) * 24 * 60 * 60 +
        (relativeValues.hours ?? relativeDate.hours) * 60 * 60 +
        (relativeValues.minutes ?? relativeDate.minutes) * 60 +
        (relativeValues.seconds ?? relativeDate.seconds)
      );

      onRelativeDateChange(relativeDateSeconds);
    },
    [
      onRelativeDateChange,
      relativeDate.days,
      relativeDate.hours,
      relativeDate.minutes,
      relativeDate.seconds,
    ]
  );

  const handleRelativeDaysChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const relativeDaysValue = event.target.value;
      setRelativeDays(relativeDaysValue);
      const relativeDays = parseInt(relativeDaysValue);
      if (isNaN(relativeDays) || relativeDays < 0) {
        return;
      }

      handleRelativeDateChange({
        days: relativeDays,
      });
    },
    [handleRelativeDateChange]
  );

  const handleRelativeHoursChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const relativeHoursValue = event.target.value;
      setRelativeHours(relativeHoursValue);
      const relativeHours = parseInt(relativeHoursValue);
      if (isNaN(relativeHours) || relativeHours < 0 || relativeHours > 23) {
        return;
      }

      handleRelativeDateChange({
        hours: relativeHours,
      });
    },
    [handleRelativeDateChange]
  );

  const handleRelativeMinutesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const relativeMinutesValue = event.target.value;
      setRelativeMinutes(relativeMinutesValue);
      const relativeMinutes = parseInt(relativeMinutesValue);
      if (
        isNaN(relativeMinutes) ||
        relativeMinutes < 0 ||
        relativeMinutes > 59
      ) {
        return;
      }

      handleRelativeDateChange({
        minutes: relativeMinutes,
      });
    },
    [handleRelativeDateChange]
  );

  const handleRelativeSecondsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const relativeSecondsValue = event.target.value;
      setRelativeSeconds(relativeSecondsValue);
      const relativeSeconds = parseInt(relativeSecondsValue);
      if (
        isNaN(relativeSeconds) ||
        relativeSeconds < 0 ||
        relativeSeconds > 59
      ) {
        return;
      }

      handleRelativeDateChange({
        seconds: relativeSeconds,
      });
    },
    [handleRelativeDateChange]
  );

  const isDaysError =
    relativeDays === "" ||
    isNaN(parseInt(relativeDays)) ||
    parseInt(relativeDays) < 0;
  const isHoursError =
    relativeHours === "" ||
    isNaN(parseInt(relativeHours)) ||
    parseInt(relativeHours) < 0 ||
    parseInt(relativeHours) > 23;
  const isMinutesError =
    relativeMinutes === "" ||
    isNaN(parseInt(relativeMinutes)) ||
    parseInt(relativeMinutes) < 0 ||
    parseInt(relativeMinutes) > 59;
  const isSecondsError =
    relativeSeconds === "" ||
    isNaN(parseInt(relativeSeconds)) ||
    parseInt(relativeSeconds) < 0 ||
    parseInt(relativeSeconds) > 59;

  const allErrors = [...(errors ?? [])];
  if (isDaysError)
    allErrors.push(
      I18n.translate("components.relative_date_input.errors.days")
    );
  if (isHoursError)
    allErrors.push(
      I18n.translate("components.relative_date_input.errors.hours")
    );
  if (isMinutesError)
    allErrors.push(
      I18n.translate("components.relative_date_input.errors.minutes")
    );
  if (isSecondsError)
    allErrors.push(
      I18n.translate("components.relative_date_input.errors.seconds")
    );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.textFields}>
        <TextField
          type="string"
          label="days"
          value={relativeDays}
          onChange={handleRelativeDaysChange}
          error={isDaysError || allErrors.length > 0}
        />

        <TextField
          type="string"
          label="hours"
          value={relativeHours}
          onChange={handleRelativeHoursChange}
          error={isHoursError || allErrors.length > 0}
        />

        <TextField
          type="string"
          label="min"
          value={relativeMinutes}
          onChange={handleRelativeMinutesChange}
          error={isMinutesError || allErrors.length > 0}
        />

        <TextField
          type="string"
          label="sec"
          value={relativeSeconds}
          onChange={handleRelativeSecondsChange}
          error={isSecondsError || allErrors.length > 0}
        />
      </Box>
      {allErrors && allErrors.length > 0 && <FormErrors errors={allErrors} />}
    </Box>
  );
}

export default memo(RelativeDatePicker);
