import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { CSSProperties, memo, useCallback, useMemo, useState } from "react";
import { container } from "tsyringe";
import I18n from "components/materials/I18n";
import { SensorAuditExecutionMode } from "models/sensor/SensorAuditExecutionMode";
import SensorAuditService from "services/SensorAuditService";
import DownloadUtils from "utils/DownloadUtils";
import * as styles from "./styles";
import ReactMarkdown, { Components } from "react-markdown";
import { noticeMarkdown } from "./notice";
import CopyToClipboard from "components/element/CopyToClipboard";
import DateUtils, { DateFormat } from "utils/DateUtils";
import { borderRadius } from "themes/constants";
import useSnackBarContext from "hooks/useSnackBarContext";

type Props = {
  isOpen: boolean;
  onClose: () => void;

  params: {
    date: Date;
    executionMode: SensorAuditExecutionMode;
    sensorId: number;
  };
};

function LocalNoticeDialog({ isOpen, onClose, params }: Readonly<Props>) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const snackBar = useSnackBarContext();
  const download = useCallback(async () => {
    setLoading(true);

    try {
      const blob = await container.resolve(SensorAuditService).downloadAudits({
        ...params,
        fromDate: params.date,
        toDate: params.date,
      });

      DownloadUtils.downloadBlob({
        blob,
        filename: `audits-${DateUtils.formatUTCDate(
          new Date(),
          DateFormat.DayMonthYearHourMinuteSecondsNoSpace
        )}Z.zip`,
      });

      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.new_audit.local.alert.download.success"
        ),
      });
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.new_audit.local.alert.download.error"
        ),
      });
    }
    setLoading(false);
  }, [params, snackBar]);

  const copyToClipboardStyle: CSSProperties = useMemo(
    () => ({
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "fit-content",
      borderRadius,
      backgroundColor: theme.palette.grey[100],
      svg: {
        fill: theme.palette.primary.main,
      },
    }),
    [theme]
  );

  const mappingComponents: Partial<Components> = {
    p: ({ children }) => (
      <Typography component="div" sx={styles.margin} variant="body1">
        {children}
      </Typography>
    ),
    h1: ({ children }) => (
      <Typography sx={styles.margin} variant="h3">
        {children}
      </Typography>
    ),
    h3: ({ children }) => (
      <Typography sx={styles.margin} variant="h6">
        {children}
      </Typography>
    ),
    h4: ({ children }) => (
      <Typography sx={styles.margin} variant="subtitle1">
        {children}
      </Typography>
    ),
    em: ({ children }) => (
      <CopyToClipboard
        incomingStyle={copyToClipboardStyle}
        text={children?.toString() ?? ""}
      >
        <Typography
          sx={{ color: theme.palette.primary.main }}
          variant="body1"
          component="span"
        >
          {children}
        </Typography>
      </CopyToClipboard>
    ),
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle sx={styles.title}>
        <I18n map={"components.dialogs.new_audit.title"} />
        <LoadingButton
          variant="contained"
          color="primary"
          startIcon={<Download />}
          onClick={download}
          loading={loading}
        >
          {I18n.translate("general_text.download")}
        </LoadingButton>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Typography
          sx={{ color: theme.palette.primary.main }}
          variant="subtitle1"
        >
          {I18n.translate("components.dialogs.new_audit.local.notice_info")}
        </Typography>
        <ReactMarkdown components={mappingComponents}>
          {noticeMarkdown}
        </ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
}

export default memo(LocalNoticeDialog);
