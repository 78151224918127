import { WidgetType } from "models/widgets/WidgetType";

export default class WidgetPictureUtils {
  public static readonly fallbackPictureUrl =
    "/images/no-image-placeholder.png";

  private static readonly widgetsImagesFolder = "/images/widgets";

  private static readonly widgetPictureNames: Record<WidgetType, string> = {
    [WidgetType.Co2Average]: `co2-average.png`,
    [WidgetType.BlockchainEnergyCost]: `blockchain-energy-cost.png`,
    [WidgetType.SensorMap]: `sensor-map.png`,
    [WidgetType.Co2RelativeAverage]: `co2-relative-average.png`,
    [WidgetType.Co2Evolution]: `co2-evolution.png`,
  };

  private static getPictureUrlFromWidgetTypeInPublicFolder(
    widgetType: WidgetType
  ): string {
    return `${WidgetPictureUtils.widgetsImagesFolder}/${WidgetPictureUtils.widgetPictureNames[widgetType]}`;
  }

  public static getPicture(widgetType: WidgetType): string {
    return WidgetPictureUtils.getPictureUrlFromWidgetTypeInPublicFolder(
      widgetType
    );
  }
}
