import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import { JwtPairDto } from "./dtos/authentication/JwtPairDto";
import { UserDto } from "./dtos/user/UserDto";

@singleton()
export default class AuthenticationApi extends BaseApi {
  public async signOut(accessToken: string): Promise<void> {
    const url = new URL(`${this.apiUrl}/authentication/sign-out`);
    try {
      await this.postRequest(url, {
        accessToken,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public async presign(): Promise<JwtPairDto> {
    const url = new URL(`${this.apiUrl}/authentication/presign`);
    return this.postRequest<JwtPairDto>(url);
  }

  public async createJwtPairByGoogleCredential(
    credential: string
  ): Promise<JwtPairDto> {
    const url = new URL(
      `${this.apiUrl}/authentication/jwt-pair-by-google-credential`
    );
    return this.postRequest<JwtPairDto>(url, { credential });
  }

  public async createJwtPairByEmailPassword({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<JwtPairDto> {
    const url = new URL(
      `${this.apiUrl}/authentication/jwt-pair-by-email-password`
    );

    return this.postRequest<JwtPairDto>(url, {
      email,
      password,
    });
  }

  public async signIn(): Promise<UserDto> {
    const url = new URL(`${this.apiUrl}/authentication/sign-in`);
    return this.getRequest<UserDto>(url);
  }
}
