import { singleton } from "tsyringe";
import BaseApi from "./BaseApi";
import UrlUtils from "utils/UrlUtils";
import { DtoDate, DtoId } from "./dtos/common/Dto";
import { NotificationDto } from "./dtos/notification/NotificationDto";

@singleton()
export default class NotificationApi extends BaseApi {
  public async findMany(params?: {
    queryParams?: {
      target?: string;
      dismissDate?: DtoDate | null;
    };
  }): Promise<NotificationDto[]> {
    const url = new URL(`${this.apiUrl}/notifications`);

    if (params?.queryParams) {
      UrlUtils.appendQueryParams(url, params.queryParams);
    }

    return this.getRequest<NotificationDto[]>(url);
  }

  public async updateNotification(params: {
    notificationId: DtoId;
    dismissDate?: DtoDate | null;
  }): Promise<NotificationDto | null> {
    const url = new URL(
      `${this.apiUrl}/notifications/${params.notificationId}`
    );

    return this.patchRequest<NotificationDto>(url, {
      dismissDate: params.dismissDate,
    });
  }
}
