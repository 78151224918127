import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import CloseButton from "components/element/CloseButton";
import Actions from "./Actions";
import useWhitelist from "hooks/queries/useWhitelist";
import { ModelId } from "models/common/Model";
import useUsers from "hooks/queries/useUsers";
import WhitelistUsers from "../whitelists/WhitelistUsers";
import User from "models/user/User";
import { ArrayUtils } from "utils/ArrayUtils";
import useOpenable from "hooks/useOpenable";
import DeleteWhitelistDialog from "../DeleteWhitelistDialog";
import useUser from "hooks/queries/useUser";
import useSnackBarContext from "hooks/useSnackBarContext";
import UsersInputSingle from "components/element/inputs/UsersInputSingle";
import LaboratoriesInputSingle from "components/element/inputs/LaboratoriesInputSingle";
import WhitelistLaboratories from "../whitelists/WhitelistLaboratories";
import Laboratory from "models/laboratory/Laboratory";
import FormErrors from "components/element/FormErrors";
import useLaboratories from "hooks/queries/useLaboratories";

type Props = {
  isOpen: boolean;
  close: () => void;
  whitelistId: ModelId;
};

const closeButtonStyles: CSSProperties = {
  position: "absolute",
  right: "12px",
  top: "18px",
};

function EditWhitelistDialog({ isOpen, close, whitelistId }: Readonly<Props>) {
  const deleteWhitelistDialog = useOpenable();
  const [nameInputError, setNameInputError] = useState<string | null>(null);
  const [whitelistsInputError, setWhitelistsInputError] = useState<
    string | null
  >(null);

  const [whitelistUpdates, setWhitelistUpdates] = useState<{
    name: string;
    userIds: ModelId[];
    laboratoryIds: ModelId[];
  }>({
    name: "",
    userIds: [],
    laboratoryIds: [],
  });

  const { users } = useUsers();
  const { userLaboratoryId } = useUser();
  const nonLaboratoryUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.laboratory === null ||
          (user.laboratory && user.laboratory.id !== userLaboratoryId)
      ),
    [users, userLaboratoryId]
  );
  const usersInWhitelist = nonLaboratoryUsers.filter((user) =>
    whitelistUpdates.userIds.includes(user.id)
  );

  const { laboratories } = useLaboratories();
  const nonUserLaboratories = laboratories.filter(
    (laboratory) => userLaboratoryId !== laboratory.id
  );

  const laboratoriesInWhitelist = nonUserLaboratories.filter((laboratory) =>
    whitelistUpdates.laboratoryIds.includes(laboratory.id)
  );

  const { whitelist, updateWhitelistQuery } = useWhitelist(whitelistId ?? null);

  const handleWhitelistNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;
      setWhitelistUpdates((prev) => ({ ...prev, name }));
    },
    []
  );

  const handleWhitelistUserSelection = useCallback((userId: ModelId | null) => {
    if (!userId) return;

    setWhitelistUpdates((prev) => ({
      ...prev,
      userIds: ArrayUtils.removeDuplicates([...prev.userIds, userId]),
    }));
  }, []);

  const handleDeleteUser = useCallback((user: User) => {
    setWhitelistUpdates((prev) => {
      const userIds = prev.userIds.filter((id) => id !== user.id);
      return { ...prev, userIds };
    });
  }, []);

  const handleWhitelistLaboratorySelection = useCallback(
    (laboratoryId: ModelId | null) => {
      if (!laboratoryId) return;

      setWhitelistUpdates((prev) => ({
        ...prev,
        laboratoryIds: ArrayUtils.removeDuplicates([
          ...prev.laboratoryIds,
          laboratoryId,
        ]),
      }));
    },
    []
  );

  const handleDeleteLaboratory = useCallback((laboratory: Laboratory) => {
    setWhitelistUpdates((prev) => {
      const laboratoryIds = prev.laboratoryIds.filter(
        (id) => id !== laboratory.id
      );
      return { ...prev, laboratoryIds };
    });
  }, []);

  const snackBar = useSnackBarContext();
  const handleSave = useCallback(async () => {
    if (!whitelistUpdates.name) {
      setNameInputError(
        I18n.translate(
          "components.dialogs.manage_whitelist.form.errors.name_empty"
        )
      );
    }

    if (
      ![...whitelistUpdates.userIds, ...whitelistUpdates.laboratoryIds].length
    ) {
      setWhitelistsInputError(
        I18n.translate(
          "components.dialogs.manage_whitelist.form.errors.whitelists_empty"
        )
      );
    }

    if (
      !whitelistUpdates.name ||
      ![...whitelistUpdates.userIds, ...whitelistUpdates.laboratoryIds].length
    )
      return;

    try {
      await updateWhitelistQuery.mutateAsync({
        name: whitelistUpdates.name,
        userIds: whitelistUpdates.userIds,
        laboratoryIds: whitelistUpdates.laboratoryIds,
      });
      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.manage_whitelist.edit.alert.update.success"
        ),
      });
    } catch {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.manage_whitelist.edit.alert.update.error"
        ),
      });
    }

    close();
  }, [
    whitelistUpdates.name,
    whitelistUpdates.userIds,
    whitelistUpdates.laboratoryIds,
    close,
    updateWhitelistQuery,
    snackBar,
  ]);

  const handleDelete = useCallback(async () => {
    deleteWhitelistDialog.open();
  }, [deleteWhitelistDialog]);

  useEffect(() => {
    setWhitelistUpdates({
      name: whitelist?.name ?? "",
      userIds: whitelist?.whitelistedUsers?.map((user) => user.id) ?? [],
      laboratoryIds:
        whitelist?.whitelistedLaboratories?.map((lab) => lab.id) ?? [],
    });
  }, [whitelist]);

  return (
    <>
      <Dialog open={isOpen} onClose={close} fullWidth>
        <DialogTitle>
          {whitelistId
            ? I18n.translate("components.dialogs.manage_whitelist.edit.title")
            : I18n.translate(
                "components.dialogs.manage_whitelist.create.title"
              )}
          <CloseButton onClick={close} incomingStyle={closeButtonStyles} />
        </DialogTitle>

        <DialogContent sx={styles.content}>
          <Box mt={1}>
            <TextField
              value={whitelistUpdates.name}
              onChange={handleWhitelistNameChange}
              type="string"
              label={
                <I18n map="components.dialogs.manage_whitelist.form.name.label" />
              }
              error={!!nameInputError}
              helperText={nameInputError}
            />
          </Box>

          <Box>
            <UsersInputSingle
              // We use nonLaboratoryUsers because laboratory users are already granted by nature
              users={nonLaboratoryUsers}
              onChange={handleWhitelistUserSelection}
              label={
                <Typography variant="subtitle1">
                  <I18n map="components.dialogs.manage_whitelist.form.users.label" />
                </Typography>
              }
            />

            <WhitelistUsers
              users={usersInWhitelist}
              onDelete={handleDeleteUser}
            />
          </Box>

          <Box>
            <LaboratoriesInputSingle
              // We use nonUserLaboratories because laboratory users are already granted by nature
              laboratories={nonUserLaboratories}
              onChange={handleWhitelistLaboratorySelection}
              label={
                <Typography variant="subtitle1">
                  <I18n map="general_text.search_laboratory" />
                </Typography>
              }
            />

            <WhitelistLaboratories
              laboratories={laboratoriesInWhitelist}
              onDelete={handleDeleteLaboratory}
            />
          </Box>

          {whitelistsInputError && (
            <FormErrors errors={[whitelistsInputError]} />
          )}
        </DialogContent>

        <DialogActions sx={styles.actionsContainer}>
          <Actions
            onCancel={close}
            onSave={handleSave}
            saveQuery={updateWhitelistQuery}
            onDelete={handleDelete}
          />
        </DialogActions>
      </Dialog>

      <DeleteWhitelistDialog
        isOpen={deleteWhitelistDialog.isOpen}
        close={deleteWhitelistDialog.close}
        whitelist={whitelist}
      />
    </>
  );
}

export default memo(EditWhitelistDialog);
