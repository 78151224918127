import I18n from "components/materials/I18n";
import { differenceInSeconds } from "date-fns";
import Widget, {
  BlockchainEnergyCostWidget,
  isRelativeDateBoundary,
  retrieveFixDateBoundary,
  retrieveRelativeDateBoundary,
} from "models/widgets/Widget";
import { useCallback, useMemo, useState } from "react";
import { Nullable } from "types/common";
import DateUtils, { DateFormat } from "utils/DateUtils";
import RelativeDateUtils from "utils/RelativeDateUtils";

type ValidationErrors = {
  sensorIdErrors?: string[];
  fromRelativeDateInputErrors?: string[];
  toRelativeDateInputErrors?: string[];
  toFixDateInputErrors?: string[];
};

function useValidation({
  properties,
  nowUTC,
  minDate,
  maxDate,
}: {
  properties: Nullable<BlockchainEnergyCostWidget["properties"]>;
  nowUTC: Date;
  minDate?: Date;
  maxDate?: Date;
}) {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const hasErrors = Object.values(errors).some(
    (subErrors) => subErrors.length > 0
  );

  const validateSensorId = useCallback(
    (properties: Nullable<BlockchainEnergyCostWidget["properties"]>) => {
      const errors = [];
      if (!properties.sensorId) {
        errors.push(I18n.translate("widgets.common.edition.errors.sensor_id"));
      }
      return errors;
    },
    []
  );

  const fixFromDate = properties.fromDate
    ? retrieveFixDateBoundary(properties.fromDate)
    : null;
  const fixToDate = properties.toDate
    ? retrieveFixDateBoundary(properties.toDate)
    : null;

  const minFromDateRelative = useMemo(() => {
    if (fixFromDate === null) return undefined;

    return differenceInSeconds(
      DateUtils.min(minDate ?? nowUTC, nowUTC),
      nowUTC
    );
  }, [fixFromDate, minDate, nowUTC]);

  const maxFromDateRelative = useMemo(() => {
    if (fixToDate === null) return undefined;

    const relative = differenceInSeconds(
      DateUtils.min(fixToDate, maxDate ?? nowUTC),
      nowUTC
    );
    return relative;
  }, [fixToDate, maxDate, nowUTC]);

  const minToDateRelative = useMemo(() => {
    if (fixFromDate === null) return undefined;

    return differenceInSeconds(
      DateUtils.min(fixFromDate, maxDate ?? nowUTC),
      nowUTC
    );
  }, [fixFromDate, maxDate, nowUTC]);

  const maxToDateRelative = useMemo(() => {
    if (fixToDate === null) return undefined;

    return differenceInSeconds(
      DateUtils.min(maxDate ?? nowUTC, nowUTC),
      nowUTC
    );
  }, [fixToDate, maxDate, nowUTC]);

  const validateFromRelativeDate = useCallback(
    (properties: Nullable<BlockchainEnergyCostWidget["properties"]>) => {
      const errors = [];
      if (properties.fromDate && isRelativeDateBoundary(properties.fromDate)) {
        const isMinError =
          !!minFromDateRelative &&
          properties.fromDate.relative < minFromDateRelative;
        const isMaxError =
          !!maxFromDateRelative &&
          properties.fromDate.relative > maxFromDateRelative;

        if (isMinError || isMaxError) {
          minFromDateRelative &&
            errors.push(
              `${I18n.translate(
                "widgets.common.edition.errors.anterior"
              )} ${RelativeDateUtils.fromSecondsTotext(minFromDateRelative)}`
            );

          maxFromDateRelative &&
            errors.push(
              `${I18n.translate(
                "widgets.common.edition.errors.posterior"
              )} ${RelativeDateUtils.fromSecondsTotext(maxFromDateRelative)}`
            );
        }
      }
      return errors;
    },
    [minFromDateRelative, maxFromDateRelative]
  );

  const validateToRelativeDate = useCallback(
    (properties: Nullable<BlockchainEnergyCostWidget["properties"]>) => {
      const errors = [];
      if (properties.toDate && isRelativeDateBoundary(properties.toDate)) {
        const isMinError =
          minToDateRelative && properties.toDate.relative < minToDateRelative;
        const isMaxError =
          maxToDateRelative && properties.toDate.relative > maxToDateRelative;

        if (isMinError || isMaxError) {
          minToDateRelative &&
            errors.push(
              `${I18n.translate(
                "widgets.common.edition.errors.anterior"
              )} ${RelativeDateUtils.fromSecondsTotext(minToDateRelative)}`
            );

          maxToDateRelative &&
            errors.push(
              `${I18n.translate(
                "widgets.common.edition.errors.posterior"
              )} ${RelativeDateUtils.fromSecondsTotext(maxToDateRelative)}`
            );
        }
      }

      return errors;
    },
    [minToDateRelative, maxToDateRelative]
  );

  const validateToFixDate = useCallback(
    (properties: Nullable<BlockchainEnergyCostWidget["properties"]>) => {
      const errors = [];
      if (
        properties.toDate &&
        properties.fromDate &&
        retrieveRelativeDateBoundary(properties.toDate) <
          retrieveRelativeDateBoundary(properties.fromDate)
      ) {
        errors.push(
          `${I18n.translate(
            "widgets.common.edition.errors.anterior"
          )} ${DateUtils.formatUTCDate(
            retrieveFixDateBoundary(properties.fromDate),
            DateFormat.DayMonthShortYearHourMinute
          )}`
        );
      }
      return errors;
    },
    []
  );

  const validateProperties = useCallback(
    (
      properties: Nullable<BlockchainEnergyCostWidget["properties"]>
    ): {
      errors: ValidationErrors;
      validatedProperties: BlockchainEnergyCostWidget["properties"] | null;
    } => {
      const updatedErrors: ValidationErrors = {
        ...errors,
        sensorIdErrors: validateSensorId(properties),
        fromRelativeDateInputErrors: validateFromRelativeDate(properties),
        toRelativeDateInputErrors: validateToRelativeDate(properties),
        toFixDateInputErrors: validateToFixDate(properties),
      };

      setErrors(updatedErrors);

      const hasErrors = Object.values(updatedErrors).some(
        (subErrors) => subErrors.length > 0
      );

      return {
        errors,
        validatedProperties:
          Widget.isPropertiesFullFilled(properties) && !hasErrors
            ? properties
            : null,
      };
    },
    [
      errors,
      validateFromRelativeDate,
      validateSensorId,
      validateToFixDate,
      validateToRelativeDate,
    ]
  );

  return {
    errors,
    hasErrors,
    validateProperties,
  };
}

export default useValidation;
