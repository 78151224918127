import { ModelId, ModelMedia } from "../common/Model";
import { SensorDto } from "services/apis/ceaMoonshot/dtos/sensor/SensorDto";
import SensorSituation from "./SensorSituation";
import Laboratory from "models/laboratory/Laboratory";
import SensorAuditsRule from "./SensorAuditsRule";
import SortUtils from "utils/SortUtils";
import { Direction } from "models/common/Direction";

export default class Sensor {
  public readonly id: ModelId;
  public readonly identifier: string;
  public readonly type: string;
  public readonly name: string;
  public readonly blockchainAddress: string;
  public readonly blockchainActivationDate: Date;
  public readonly picture?: ModelMedia | null;
  public readonly laboratory?: Laboratory | null;
  public readonly situations?: SensorSituation[];
  public readonly auditsRule?: SensorAuditsRule | null;
  public readonly currentSituation?: SensorSituation | null;
  public readonly mostRecentSituation?: SensorSituation | null;

  private constructor(params: {
    id: ModelId;
    identifier: string;
    type: string;
    name: string;
    blockchainAddress: string;
    blockchainActivationDate: Date;
    picture?: ModelMedia | null;
    laboratory?: Laboratory | null;
    situations?: SensorSituation[];
    auditsRule?: SensorAuditsRule | null;
  }) {
    this.id = params.id;
    this.identifier = params.identifier;
    this.type = params.type;
    this.name = params.name;
    this.blockchainAddress = params.blockchainAddress;
    this.blockchainActivationDate = params.blockchainActivationDate;
    this.picture = params.picture;
    this.laboratory = params.laboratory;
    this.situations = params.situations;
    this.auditsRule = params.auditsRule;
    this.currentSituation = this.retrieveSituationAtDate(new Date());
    this.mostRecentSituation = this.retrieveMostRecentSituation();
  }

  public static fromDto(dto: SensorDto): Sensor {
    return new Sensor({
      id: dto.id,
      identifier: dto.identifier,
      type: dto.type,
      name: dto.name,
      blockchainAddress: dto.blockchainAddress,
      blockchainActivationDate: new Date(dto.blockchainActivationDate),
      picture: dto.picture,
      laboratory: dto.laboratory ? Laboratory.fromDto(dto.laboratory) : null,
      situations: dto.situations
        ? dto.situations.map((situation) => SensorSituation.fromDto(situation))
        : [],
      auditsRule: dto.auditsRule
        ? SensorAuditsRule.fromDto(dto.auditsRule)
        : null,
    });
  }

  private retrieveSituationAtDate(
    date: Date
  ): SensorSituation | null | undefined {
    if (!this.situations) {
      return undefined;
    }

    const situation = this.situations?.find((situation) => {
      const isActivated =
        situation.activationDate && situation.activationDate <= date;
      if (!isActivated) return false;

      const isStillActive =
        !situation.deactivationDate || situation.deactivationDate >= date;

      return isStillActive;
    });

    return situation || null;
  }

  private retrieveMostRecentSituation(): SensorSituation | null | undefined {
    if (!this.situations) {
      return undefined;
    }

    const sortedSituations = this.situations.sort((a, b) =>
      SortUtils.sortABNum({
        valueA: a.installationDate.getTime(),
        valueB: b.installationDate.getTime(),
        direction: Direction.Desc,
      })
    );

    const mostRecentSituation = sortedSituations[0];

    return mostRecentSituation || null;
  }
}
