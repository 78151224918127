export default class CoordinateUtils {
  public static toDegreeTime(coordinate: number): string {
    const degree = Math.floor(coordinate);
    const restDegree = coordinate - degree; // 0.4756

    const rawMinutes = restDegree * 60; //28.536
    const minutes = Math.floor(rawMinutes);

    const seconds = Math.floor((rawMinutes - minutes) * 60);

    return `${degree}°${minutes}'${seconds}"`;
  }
}
