import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { CSSProperties, memo, useCallback } from "react";
import I18n from "components/materials/I18n";
import * as styles from "./styles";
import CloseButton from "components/element/CloseButton";
import useWhitelist from "hooks/queries/useWhitelist";
import Whitelist from "models/laboratory/Whitelist";
import SensorAuditsRule from "models/sensor/SensorAuditsRule";
import Sensor from "models/sensor/Sensor";
import useSnackBarContext from "hooks/useSnackBarContext";
import { LoadingButton } from "@mui/lab";

type Props = {
  isOpen: boolean;
  close: () => void;
  whitelist: Whitelist | null;
};

const closeButtonStyles: CSSProperties = {
  position: "absolute",
  right: "12px",
  top: "18px",
};

function DeleteWhitelistDialog({ isOpen, close, whitelist }: Readonly<Props>) {
  const { deleteWhitelistQuery } = useWhitelist(whitelist?.id ?? null);

  const impactedSensors: Sensor[] =
    whitelist?.sensorAuditsRules
      ?.filter(
        (
          sensorAuditsRule
        ): sensorAuditsRule is SensorAuditsRule & { sensor: Sensor } =>
          !!sensorAuditsRule.sensor
      )
      ?.map((sensorAuditsRule) => sensorAuditsRule.sensor) ?? [];

  const snackBar = useSnackBarContext();
  const handleDelete = useCallback(async () => {
    try {
      await deleteWhitelistQuery.mutateAsync();
      snackBar.open({
        severity: "success",
        message: I18n.translate(
          "components.dialogs.manage_whitelist.delete.alert.delete.success"
        ),
      });
    } catch (error) {
      snackBar.open({
        severity: "error",
        message: I18n.translate(
          "components.dialogs.manage_whitelist.delete.alert.delete.error"
        ),
      });
    }

    close();
  }, [close, deleteWhitelistQuery, snackBar]);

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>
        {`${I18n.translate(
          "components.dialogs.manage_whitelist.delete.title"
        )} ${whitelist?.name}`}

        <CloseButton onClick={close} incomingStyle={closeButtonStyles} />
      </DialogTitle>

      <DialogContent sx={styles.content}>
        <Box component="span" sx={styles.text}>
          <Typography variant="body1" mr={1}>
            {I18n.translate("components.dialogs.manage_whitelist.delete.text")}
          </Typography>
          <Typography variant="subtitle1">{whitelist?.name}</Typography>
          <Typography variant="body1">?</Typography>
        </Box>

        {impactedSensors.length > 0 && (
          <Alert severity="warning">
            <Typography fontWeight={600}>
              {I18n.translate(
                "components.dialogs.manage_whitelist.delete.warning"
              )}
            </Typography>
            <List disablePadding>
              {impactedSensors.map((sensor) => (
                <ListItem key={sensor.id}>
                  <ListItemText>• {sensor.identifier}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="info" onClick={close}>
          {I18n.translate("general_text.cancel")}
        </Button>

        <LoadingButton
          variant="contained"
          color="error"
          onClick={handleDelete}
          disabled={deleteWhitelistQuery.isLoading}
          loading={deleteWhitelistQuery.isLoading}
        >
          {I18n.translate("general_text.delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default memo(DeleteWhitelistDialog);
