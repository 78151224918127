import { SxProps, Theme } from "@mui/material";
import { MediaQueries } from "hooks/useMediaQueries";
import { borderRadius } from "themes/constants";

export const root: (mediaQueries: MediaQueries) => SxProps<Theme> =
  (mediaQueries) => (theme) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    borderRadius,
    backgroundColor: theme.palette.grey[100],

    ...(mediaQueries.isSm && {
      flexDirection: "row",
      alignItems: "center",
      gap: theme.spacing(5),
    }),
  });

export const imageContainer: SxProps<Theme> = (theme) => ({
  width: 160,
  height: 160,
});

export const image: SxProps<Theme> = (theme) => ({
  width: 160,
  height: 160,
  borderRadius,
  objectFit: "cover",
  imageOrientation: "from-image",
});

export const content: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(3),
  flexDirection: "column",
});

export const description: SxProps<Theme> = (theme) => ({
  gap: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const buttonContainer: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(2),
});
