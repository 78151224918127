import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import GlobalAuditionStatusChip from "components/element/chips/GlobalAuditionStatusChip";
import SortTableCell from "components/element/SortTableCell";
import I18n from "../I18n";
import { memo, useCallback, useEffect, useState } from "react";
import { Direction } from "models/common/Direction";
import DateUtils, { DateFormat } from "utils/DateUtils";
import SensorAudit from "models/sensor/SensorAudit";
import ExecutionStatusChip from "components/element/chips/ExecutionStatusChip";
import { useNavigate } from "react-router-dom";
import { GlobalAuditionStatus } from "models/auditReport/GlobalAuditionStatus";
import SortUtils from "utils/SortUtils";
import useRoutes from "hooks/useRoutes";
import useSelectedSensorIdContext from "hooks/useSelectedSensorContext";
import useCurrentPageType from "hooks/useCurrentPageType";

type Props = {
  audits: SensorAudit[];
};

function AuditsTable(props: Readonly<Props>) {
  const navigate = useNavigate();
  const routes = useRoutes();
  const { currentPageType } = useCurrentPageType();
  const { sensorId } = useSelectedSensorIdContext();
  const [sensorAudits, setSensorAudits] = useState<SensorAudit[]>([]);

  useEffect(() => {
    setSensorAudits([
      ...props.audits.sort((a, b) =>
        SortUtils.sortABNum({
          valueA: a.executionDateTime.getTime(),
          valueB: b.executionDateTime.getTime(),
          direction: Direction.Desc,
        })
      ),
    ]);
  }, [props.audits]);

  const sortByExecutionDate = useCallback((direction: Direction) => {
    setSensorAudits((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABNum({
          valueA: a.executionDateTime.getTime(),
          valueB: b.executionDateTime.getTime(),
          direction,
        })
      ),
    ]);
  }, []);

  const sortByTargetDate = useCallback((direction: Direction) => {
    setSensorAudits((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABNum({
          valueA: a.sensorEpoch?.date.getTime() ?? 0,
          valueB: b.sensorEpoch?.date.getTime() ?? 0,
          direction,
        })
      ),
    ]);
  }, []);

  const sortByExecutionStatus = useCallback((direction: Direction) => {
    setSensorAudits((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.executionStatus,
          valueB: b.executionStatus,
          direction,
        })
      ),
    ]);
  }, []);

  const sortByNotarizationStatus = useCallback((direction: Direction) => {
    setSensorAudits((prev) => [
      ...prev.sort((a, b) =>
        SortUtils.sortABStr({
          valueA: a.report?.global.status ?? GlobalAuditionStatus.Unknown,
          valueB: b.report?.global.status ?? GlobalAuditionStatus.Unknown,
          direction,
        })
      ),
    ]);
  }, []);

  if (!sensorId) {
    return null;
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 1050, overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <SortTableCell onSort={sortByExecutionDate} divider>
              {I18n.translate(
                "pages.sensor.sub_pages.audits.table.execution_date"
              )}
            </SortTableCell>
            <SortTableCell onSort={sortByTargetDate} divider>
              {I18n.translate(
                "pages.sensor.sub_pages.audits.table.target_date"
              )}
            </SortTableCell>
            <SortTableCell onSort={sortByExecutionStatus} divider>
              {I18n.translate(
                "pages.sensor.sub_pages.audits.table.execution_status"
              )}
            </SortTableCell>
            <SortTableCell onSort={sortByNotarizationStatus} divider>
              {I18n.translate(
                "pages.sensor.sub_pages.audits.table.notarization_status"
              )}
            </SortTableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sensorAudits.map((sensorAudit) => (
            <TableRow key={sensorAudit.id}>
              <TableCell>
                {DateUtils.formatUTCDate(
                  sensorAudit.executionDateTime,
                  DateFormat.DayMonthYearHourMinute
                )}
              </TableCell>
              <TableCell>
                {(sensorAudit.sensorEpoch?.date &&
                  DateUtils.formatUTCDate(
                    sensorAudit.sensorEpoch.date,
                    DateFormat.DayMonthYear
                  )) ??
                  "--"}
              </TableCell>
              <TableCell>
                <ExecutionStatusChip status={sensorAudit.executionStatus} />
              </TableCell>
              <TableCell>
                <GlobalAuditionStatusChip
                  status={
                    sensorAudit.report?.global.status ??
                    GlobalAuditionStatus.Unknown
                  }
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() =>
                    navigate(
                      routes[currentPageType].sensor.sensorAudit.resolveRoute({
                        sensorId,
                        auditId: sensorAudit.id,
                      })
                    )
                  }
                >
                  {I18n.translate(
                    "pages.sensor.sub_pages.audits.table.view_report"
                  )}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(AuditsTable);
