import { Box, IconButton, Paper, Typography } from "@mui/material";
import Sensor from "models/sensor/Sensor";
import { memo, useCallback } from "react";
import * as styles from "./styles";
import DateUtils, { DateFormat } from "utils/DateUtils";
import mapMarkerGreen from "icons/mapMarkers/mapMarkerGreen.svg";
import useFlyMap from "hooks/useFlyMap";
import SensorDataUtils from "utils/SensorResultUtils";
import { DateBoundary, retrieveFixDateBoundary } from "models/widgets/Widget";

type Props = {
  sensor: Sensor;
  isActiveAtDate: DateBoundary;
};

function SimpleMapTooltip({ sensor, isActiveAtDate }: Props) {
  const { flyTo } = useFlyMap();
  const fixIsActiveAtDate = retrieveFixDateBoundary(isActiveAtDate);

  const handleClickOnMarker = useCallback(() => {
    flyTo({
      latitude: sensor?.currentSituation?.latitude || 0,
      longitude: sensor?.currentSituation?.longitude || 0,
    });
  }, [
    flyTo,
    sensor?.currentSituation?.latitude,
    sensor?.currentSituation?.longitude,
  ]);

  return (
    <Paper sx={styles.root}>
      <IconButton onClick={handleClickOnMarker}>
        <Box component="img" src={mapMarkerGreen} alt="" />
      </IconButton>

      <Typography sx={styles.sensorName}>
        {SensorDataUtils.formatDisplayName(sensor)}
      </Typography>

      <Typography>
        {DateUtils.formatUTCDate(
          fixIsActiveAtDate,
          DateFormat.DayMonthYearBulletHourMinute
        )}
      </Typography>
    </Paper>
  );
}

export default memo(SimpleMapTooltip);
